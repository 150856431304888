import React from "react";
import { Button, Modal, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import TimeTrackForm from "../staff/TimeTrackList/TimeTrackForm";

export default function TimeTrackModal(props) {
    const dispatch = useDispatch();
    let type = props.type;
    let timeId = props.timeId;

    return (
        <Modal size="fullscreen" open={true} onClose={() => dispatch(closeModal())} style={{ marginLeft: 40 }} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>&nbsp;</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                        <h2 className="ui center aligned header">DRG Time Tracking Form</h2>
                    </div>

                    <Divider />

                    <TimeTrackForm type={type} timeId={timeId} />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
