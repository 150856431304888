import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Label } from "semantic-ui-react";
import { leaveApprove, generateLeaveStats, generateNew } from "../managerActions";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MyRadioButton from "../../../app/common/form/MyRadioButton";
import MySelectInput from "../../../app/common/form/MySelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyTextArea from "../../../app/common/form/MyTextArea";

export default function LeaveAppForm(props) {
    const dispatch = useDispatch();
    const [annualTo, setAnnualTo] = useState("");
    const [annualFrom, setAnnualFrom] = useState("");
    const leaveType = [
        { key: "Annual", text: "Annual Leave", value: "Annual" },
        { key: "Sick", text: "Sick Leave", value: "Sick" },
        { key: "Study", text: "Study Leave", value: "Study" },
        { key: "Unpaid", text: "Unpaid Leave", value: "Unpaid" },
        { key: "Maternity", text: "Maternity Leave", value: "Maternity" },
        { key: "Family", text: "Family Leave", value: "Family" },
    ];
    let leave = props.leave;
    let company = props.company;
    let currentUser = props.currentUser.displayName;

    function updateTo(value) {
        setAnnualTo(value);
    }
    function updateFrom(value) {
        setAnnualFrom(value);
    }

    return (
        <Formik
            initialValues={{ denyReason: "" }}
            validate={(values) => {
                const error = {};

                if (props.preApprove && props.preApprove === true) {
                    if (!values.denyReason) {
                        error.denyReason = "Please enter a reason";
                    }
                }

                return error;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                const { leave } = props;

                let leaveApproveData = {
                    leaveType: leave.leaveType,
                    userUid: leave.userUid,
                };

                if (leave.id) {
                    leaveApproveData.id = leave.id;
                } else if (leave.leaveId) {
                    leaveApproveData.id = leave.leaveId;
                }

                leaveApproveData.status = values.status;

                if (values.leaveType) {
                    leaveApproveData.leaveType = values.leaveType;
                }

                if (values.denyReason) {
                    leaveApproveData.denyReason = values.denyReason;
                }

                if (values.changeAnnualFrom) {
                    leaveApproveData.annualFrom = values.changeAnnualFrom;
                }

                if (values.changeAnnualTo) {
                    leaveApproveData.annualTo = values.changeAnnualTo;
                }

                leaveApproveData.actionDate = new Date();

                leaveApproveData.actionPerson = currentUser;

                leaveApproveData.preApprove = props.preApprove;

                await leaveApprove(leaveApproveData);

                if (company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method") {
                    await generateNew(leave.userUid);
                } else {
                    await generateLeaveStats(leave.userUid, "Leave has been updated");
                }

                setSubmitting(false);
                dispatch(closeModal());
            }}
        >
            {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Form.Group inline>
                        <label>Status:</label>
                        <MyRadioButton name="status" type="radio" value="Approved" label="" labelcb="Approve" />

                        <MyRadioButton name="status" type="radio" value="Denied" label="" labelcb="Deny" />

                        {props.preApprove && props.preApprove === false && <MyRadioButton name="status" type="radio" value="Cancelled" label="" labelcb="Cancel" />}
                    </Form.Group>

                    <Form.Group inline>
                        {props.preApprove && props.preApprove === false && (
                            <>
                                <Form.Field>
                                    <label>Change From Date (Optional)</label>
                                    <DatePicker
                                        name="changeAnnualFrom"
                                        dateFormat="yyyy-MM-dd"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                        value={values.changeAnnualFrom}
                                        selected={annualFrom}
                                        onChange={(value) => {
                                            updateFrom(value);
                                            setFieldValue("changeAnnualFrom", value.value);
                                        }}
                                        disabledKeyboardNavigation
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Change To Date (Optional)</label>
                                    <DatePicker
                                        name="changeAnnualTo"
                                        dateFormat="yyyy-MM-dd"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                        value={values.changeAnnualTo}
                                        selected={annualTo}
                                        onChange={(value) => {
                                            updateTo(value);
                                            setFieldValue("changeAnnualTo", value.value);
                                        }}
                                        disabledKeyboardNavigation
                                    />
                                </Form.Field>
                            </>
                        )}

                        {leave.changeReason && leave.changeReason.length > 0 && (
                            <Form.Field>
                                <label>Change Leave Type (Optional)</label>
                                <MySelectInput
                                    name="leaveType"
                                    options={leaveType}
                                    value={values.leaveType}
                                    onChange={(field, value) => setFieldValue("leaveType", value.value)}
                                    placeholder="Select your Leave Type"
                                />
                            </Form.Field>
                        )}
                    </Form.Group>

                    {props.preApprove && props.preApprove === false ? <p>If denied, please provide a reason:</p> : <p>Please provide a reason:</p>}

                    <MyTextArea name="denyReason" />

                    {error && (
                        <Label basic color="red">
                            {error}
                        </Label>
                    )}
                    <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue">
                        SUBMIT
                    </Button>
                </form>
            )}
        </Formik>
    );
}
