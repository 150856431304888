import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Divider } from "semantic-ui-react";
import { getBlockedDates, leaveChange, leaveTrack, leaveTrackSpecial } from "../staffActions";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MyTextArea from "../../../app/common/form/MyTextArea";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useCallback } from "react";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useEffect } from "react";

export default function LeaveChangeFormModal(props) {
    // let leave = props.leave;
    console.log(props);
    let company = props.company;
    let profileClient = props.profileClient;
    const dispatch = useDispatch();
    let parts = window.location.href.split("/");
    let leaveId = parts.pop() || parts.pop();
    const { currentUserProfile } = useSelector((state) => state.profile);
    const [blockedDates, setBlockedDates] = useState([]);
    const [annualFrom, setAnnualFrom] = useState(props.leave.annualFrom.toDate());
    const [toMinDate, setToMinDate] = useState(props.leave.annualFrom.toDate());
    const [leaveList, setLeaveList] = useState([]);
    const [availDays, setAvailDays] = useState("0");
    const [availDate, setAvailDate] = useState("");
    const [annualTo, setAnnualTo] = useState(props.leave.annualTo.toDate());
    const [halfDay, setHalfDay] = useState(props.leave.halfDay);
    const [annualdays, setAnnualDays] = useState(props.leave.annualDays);
    const [allowSaturday, setAllowSaturday] = useState(props.company.saturdayWorkDay);
    const [allowSunday, setAllowSunday] = useState(props.company.sundayWorkDay);

    useEffect(() => {
        getBlockedDates().then(
            (res) => {
                setBlockedDates(res);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    function calcDays(type, val, annualFromDate, annualToDate, halfDaySpec, blockedDatesDays) {
        let annualFrom = annualFromDate;
        let annualTo = annualToDate;
        let halfDay = halfDaySpec;

        if (halfDay == undefined) {
            halfDay = "no";
        }
        let c = 0;

        if (type === "annualFrom") {
            updateAnnualFrom(val);
            annualFrom = val;

            for (var i = 0; i < leaveList.length; i++) {
                if (leaveList[i].datePeriod.getMonth() == val.getMonth()) {
                    setAvailDays(leaveList[i].annualLeft);
                    // console.log(leaveList[i]);
                    setAvailDate(format(leaveList[i].datePeriod, "MMMM yyyy"));
                }
            }
        }
        if (type === "annualTo") {
            updateAnnualTo(val);
            annualTo = val;
        }
        if (type === "halfDay") {
            updateHalfDay(val);
            halfDay = val;
        }

        let blockedDates = blockedDatesDays;

        if (annualFrom !== "" && annualTo === "" && halfDay !== "no") {
            c = 0.5;

            if (type === "annualFrom") {
                annualTo = annualFrom;
            }
            if (type === "annualTo") {
                annualFrom = annualTo;
            }
        }

        if (annualFrom !== "" && annualTo !== "") {
            if (halfDay !== "no") {
                updateAnnualTo(annualFrom);
                annualTo = annualFrom;
                c = 0.5;
            } else {
                let a = annualFrom;
                let b = annualTo;
                let foundblock = 0;
                let compare_dates = function (date1, date2) {
                    if (date1 > date2) return "<";
                    else if (date1 < date2) return ">";
                    else return "=";
                };
                let compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));

                while (compared === ">" || compared === "=") {
                    // if (format(a, "EEE") !== "Sat" && format(a, "EEE") !== "Sun") {
                    if ((format(a, "EEE") == "Sat" && allowSaturday == true) || (format(a, "EEE") == "Sun" && allowSunday == true) || (format(a, "EEE") !== "Sat" && format(a, "EEE") !== "Sun")) {
                        foundblock = 0;
                        // eslint-disable-next-line no-loop-func
                        Object.keys(blockedDates).forEach(function (key) {
                            let helperDate = blockedDates[key];
                            if (format(helperDate, "yyyy-MM-dd") === format(a, "yyyy-MM-dd")) {
                                foundblock = 1;
                            }
                        });
                        if (foundblock === 0) {
                            c++; //add 1 to your counter if its not a weekend day
                        }
                    }
                    a = addDays(new Date(a), 1);
                    compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));
                }
            }
        }

        if (c === 0) {
            c = null;
        }

        updateAnnualDays(c);
    }

    const updateAnnualFrom = useCallback((value) => {
        setAnnualFrom(new Date(value));
        setToMinDate(new Date(value));
    }, []);
    const updateAnnualTo = useCallback((value) => {
        setAnnualTo(value);
    }, []);
    const updateHalfDay = useCallback((value) => {
        setHalfDay(value);
    }, []);
    const updateAnnualDays = useCallback((value) => {
        setAnnualDays(value);
    }, []);

    return (
        <Formik
            initialValues={{ changeReason: "", annualFrom: props.leave.annualFrom.toDate(), annualTo: props.leave.annualTo.toDate() }}
            enableReinitialize={true}
            validate={(values) => {
                const errors = {};

                if (!values.changeReason) {
                    errors.changeReason = "Required";
                }

                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                values.annualDays = annualdays;
                values.companyId = profileClient.companyId;
                values.departmentId = profileClient.departmentId;
                values.displayName = profileClient.displayName;
                values.leaveType = props.leave.leaveType;
                values.unpaidLeave = props.leave.unpaidLeave;
                values.useAccumulative = props.leave.useAccumulative;
                values.useAccumulative2 = props.leave.useAccumulative2;

                if (props.leave.uploadFile !== undefined) {
                    values.uploadFileURL = props.leave.uploadFile;
                }

                values.annualFrom = annualFrom;
                values.annualTo = annualTo;

                if (profileClient.approvalType) {
                    values.approvalType = profileClient.approvalType;

                    if (profileClient.approvalType === "Specific User" && profileClient.approvalUser) {
                        values.approvalUser = profileClient.approvalUser;
                    }
                }

                if (values.halfDay == undefined) {
                    values.halfDay = "no";
                }

                if (values.halfDay !== "no" && values.annualTo === "") {
                    values.annualTo = values.annualFrom;
                }

                values.actionType = "add";
                values.userUid = currentUserProfile.id;

                let change = {};
                if (values.changeReason) {
                    change.changeReason = values.changeReason;
                }
                change.id = leaveId;

                change.companyId = currentUserProfile.companyId;
                change.departmentId = [currentUserProfile.departmentId];

                console.log(values);
                console.log(change);

                if (values.leaveType === "Special Leave") {
                    await leaveTrackSpecial(values);
                } else {
                    await leaveTrack(values);
                }

                await leaveChange(change);

                setSubmitting(false);
                dispatch(closeModal());
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <div className="content_top">
                        <h2 className="ui center aligned header">DRG Change Leave Request</h2>
                        <p>Change Submitted Leave</p>
                    </div>
                    <Divider />
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Half Day</label>

                            <label>
                                <input
                                    name="halfDay"
                                    type="radio"
                                    value="no"
                                    checked={halfDay === "no" ? true : false}
                                    onChange={(event) => {
                                        calcDays("halfDay", "no", annualFrom, annualTo, halfDay, blockedDates);
                                        setFieldValue("halfDay", "no");
                                    }}
                                />{" "}
                                No
                            </label>

                            <label>
                                <input
                                    name="halfDay"
                                    type="radio"
                                    value="morning"
                                    onChange={(event) => {
                                        calcDays("halfDay", "morning", annualFrom, annualTo, halfDay, blockedDates);
                                        setFieldValue("halfDay", "morning");
                                    }}
                                />{" "}
                                Yes - Morning
                            </label>

                            <label>
                                <input
                                    name="halfDay"
                                    type="radio"
                                    value="afternoon"
                                    onChange={(event) => {
                                        calcDays("halfDay", "afternoon", annualFrom, annualTo, halfDay, blockedDates);
                                        setFieldValue("halfDay", "afternoon");
                                    }}
                                />{" "}
                                Yes - Afternoon
                            </label>
                        </Form.Field>
                        <Form.Field>
                            <label>From</label>
                            <DatePicker
                                labelTop="From"
                                name="annualFrom"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                value={values.annualFrom}
                                selected={annualFrom}
                                disabledKeyboardNavigation
                                inline
                                onChange={(value) => {
                                    setFieldValue("annualFrom", value);
                                    calcDays("annualFrom", value, annualFrom, annualTo, halfDay, blockedDates);
                                }}
                                excludeDates={blockedDates}
                                // minDate={minSelected}
                            />

                            {errors && errors.annualFrom ? <p className="ui red basic label">{errors.annualFrom}</p> : null}
                        </Form.Field>

                        <Form.Field>
                            <label>To</label>
                            <DatePicker
                                labelTop="To"
                                name="annualTo"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                value={values.annualTo}
                                selected={annualTo}
                                onChange={(value) => {
                                    calcDays("annualTo", value, annualFrom, annualTo, halfDay, blockedDates);
                                    setFieldValue("annualTo", value);
                                }}
                                disabledKeyboardNavigation
                                inline
                                excludeDates={blockedDates}
                                minDate={toMinDate}
                            />
                            {errors && errors.annualTo ? <p className="ui red basic label">{errors.annualTo}</p> : null}
                        </Form.Field>

                        <Form.Field>
                            <label>No. of Days</label>
                            <MyTextInput name="annualDays" type="text" value={annualdays || "0"} readOnly={true} />
                        </Form.Field>
                    </Form.Group>

                    <label>
                        <strong>Reason for Change</strong>
                    </label>
                    <MyTextArea name="changeReason" />

                    <Button disabled={!dirty || isSubmitting} loading={isSubmitting} fluid size="large" color="blue" type="submit">
                        REQUEST CHANGE
                    </Button>
                </form>
            )}
        </Formik>
    );
}
