import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Label, Divider, Icon, Header, Grid } from "semantic-ui-react";
import { supportTrack, getUsersList, getAuthUser } from "../supportActions";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextArea from "../../../app/common/form/MyTextArea";

export default function SupportReqForm(props) {
    const [requestTypeData, setRequestTypeData] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [assignHRUsers, setAssignHRUsers] = useState([]);
    const [profile, setProfile] = useState([]);
    const dispatch = useDispatch();

    const requestType = [
        { key: "Payslip", text: "Payslip", value: "Payslip" },
        { key: "IRP5", text: "IRP5", value: "IRP5" },
        { key: "Leave", text: "Leave", value: "Leave" },
        { key: "Change of Banking Details", text: "Change of Banking Details", value: "Change of Banking Details" },
        { key: "Other", text: "Other", value: "Other" },
    ];

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    getUsersList(res.data().companyId).then(
                        (data) => {
                            let list = [];
                            data.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                    list.push(appObj);
                                }
                            });

                            let assignUsersData = [
                                {
                                    key: "",
                                    text: "Choose a Line Manager",
                                    value: "",
                                },
                            ];
                            let assignHRUsersData = [
                                {
                                    key: "",
                                    text: "Choose an HR User",
                                    value: "",
                                },
                            ];

                            Object.keys(list).forEach(function (key) {
                                if (list[key].isLineManager === true) {
                                    assignUsersData.push({
                                        key: list[key].id,
                                        text: list[key].displayName && list[key].displayName.length > 0 ? list[key].displayName : list[key].email,
                                        value: list[key].displayName && list[key].displayName.length > 0 ? list[key].id + "|" + list[key].displayName : list[key].id + "|" + list[key].email,
                                    });
                                }

                                if (res.data().hrCompany === true && list[key].isHR === true) {
                                    assignHRUsersData.push({
                                        key: list[key].id,
                                        text: list[key].displayName && list[key].displayName.length > 0 ? list[key].displayName : list[key].email,
                                        value: list[key].displayName && list[key].displayName.length > 0 ? list[key].id + "|" + list[key].displayName : list[key].id + "|" + list[key].email,
                                    });
                                }
                            });

                            setAssignUsers(assignUsersData);
                            setAssignHRUsers(assignHRUsersData);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    function changeRequest(value) {
        setRequestTypeData(value);
    }

    return (
        <Formik
            initialValues={{ requestDescription: "" }}
            validate={(values) => {
                const error = {};
                return error;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                values.companyId = profile.companyId;
                values.departmentId = profile.departmentId;
                values.displayName = profile.displayName;

                await supportTrack(values);

                setSubmitting(false);
                dispatch(closeModal());
            }}
        >
            {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={6}>
                                <Form.Field>
                                    <label>Type</label>
                                    <MySelectInput
                                        name="requestType"
                                        options={requestType}
                                        value={values.requestType}
                                        onChange={(field, value) => {
                                            changeRequest(value.value);
                                            setFieldValue("requestType", value.value);
                                        }}
                                        placeholder="Select your Request Type"
                                    />
                                    {requestTypeData === "Change of Banking Details" && <Label color="red">Please upload proof of your new bank account</Label>}
                                </Form.Field>
                            </Grid.Column>

                            {/* <Grid.Column width={5}>
                                <Form.Field>
                                    <label>Line Manager</label>
                                    <MySelectInput name="assignedUid" options={assignUsers} placeholder="Choose a Line Manager" />
                                </Form.Field>
                            </Grid.Column> */}

                            <Grid.Column width={5}>
                                <Form.Field>
                                    <label>HR</label>
                                    <MySelectInput name="assignedHRUid" options={assignHRUsers} placeholder="Choose an HR User" />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2}>
                            <Grid.Column width={10}>
                                <Form.Field>
                                    <label>Query</label>
                                    <MyTextArea name="requestDescription" />
                                </Form.Field>
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <p>Upload supporting documents</p>
                                <input
                                    id="uploadFile"
                                    name="uploadFile"
                                    type="file"
                                    onChange={(event) => {
                                        setFieldValue("uploadFile", event.currentTarget.files[0]);
                                    }}
                                    className="form-control"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />

                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" fluid size="large" color="blue">
                        SUBMIT REQUEST
                    </Button>
                </form>
            )}
        </Formik>
    );
}
