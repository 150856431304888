import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Segment, Grid, Button, Label } from "semantic-ui-react";
import format from "date-fns/format";
import LeaveViewRequest from "./LeaveViewRequest";
import LeaveLeftTbl from "../LeaveList/LeaveLeftTbl";
import LeaveComments from "../LeaveList/LeaveComments";
import { openModal } from "../../../app/common/modals/modalReducer";
import { deleteLeave, generateLeaveStats, generateNewLeaveStats, getAuthUser } from "../../manager/managerActions";
import { Link } from "react-router-dom";
import firebase from "../../../app/config/firebase";
import { startOfMonth } from "date-fns/esm";
import LeaveLeftNew from "../LeaveList/LeaveLeftNew";
import LeaveLeftOld from "../LeaveList/LeaveLeftOld";
import { addDays } from "date-fns";
const firestore = firebase.firestore();

export default function LeaveViewPage(props) {
    let leaveId = props.match.params.id;
    const [leaveCurrent, setLeaveCurrent] = useState([]);
    const [profileClient, setProfileClient] = useState([]);
    const [authUser, setAuthUser] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [leaveMonthly, setLeaveMonthly] = useState([]);
    const [company, setCompany] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leave")
            .doc(leaveId)
            .onSnapshot(async (result) => {
                let obj = { ...result.data(), id: result.id };
                setLeaveCurrent(obj);

                if (obj.userUid !== undefined) {
                    await firestore
                        .collection("users")
                        .doc(obj.userUid)
                        .onSnapshot(async (doc) => {
                            let userObj = { ...doc.data(), id: doc.id };
                            setProfileClient(userObj);

                            await firestore
                                .collection("companies")
                                .doc(userObj.companyId)
                                .onSnapshot(async (company) => {
                                    let cData = { ...company.data(), id: company.id };
                                    setCompany(cData);

                                    if (cData.leaveMethodType !== undefined && cData.leaveMethodType === "New Method") {
                                        await firestore
                                            .collection("leaveStatus")
                                            .where("userUid", "==", obj.userUid)
                                            .where("toDate", ">", new Date())
                                            .where("periodType", "==", "Leave")
                                            .onSnapshot((snapshot) => {
                                                // setLeave(snapshot.docs[0].data());
                                                const datePeriodValue =
                                                    cData.leaveMonthType == "Custom From and To Date"
                                                        ? addDays(startOfMonth(obj.annualFrom.toDate()), Number(cData.leaveFromDay) - 1)
                                                        : startOfMonth(obj.annualFrom.toDate());
                                                firestore
                                                    .collection("leaveStatus")
                                                    .doc(snapshot.docs[0].id)
                                                    .collection("Months")
                                                    .where("datePeriod", "==", datePeriodValue)
                                                    .onSnapshot((snapshot2) => {
                                                        if (snapshot2.empty === false) {
                                                            setLeaveMonthly(snapshot2.docs[0].data());
                                                        }
                                                    });
                                            });
                                    }
                                });
                        });
                }
            });

        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setAuthUser(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        return () => {
            unsubscribe();
        };
    }, []);

    async function removeLeave(leave, company) {
        setDeleting(true);

        let userId = leave.userUid;

        await deleteLeave(leave);

        let month = format(leave.annualFrom.toDate(), "MM");
        let year = format(leave.annualFrom.toDate(), "yyyy");

        let monthNow = format(Date.now(), "M");
        let yearNow = format(Date.now(), "yyyy");

        //Check if this is a previous years leave, if it is update previous years leave too
        if (month < monthNow && year === yearNow) {
            year--;
            let changeYear = new Date(year, 1, 1);

            if (company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method") {
                await generateNewLeaveStats(userId);
            } else {
                await generateLeaveStats(userId, "", changeYear);
            }
        }

        if (company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method") {
            await generateNewLeaveStats(userId);
        } else {
            await generateLeaveStats(userId, "Leave has been deleted");
        }

        setDeleting(false);

        //Route user back as this page won't exist anymore after deleting
        props.history.push("/clients/leaveview/" + userId);
    }

    return (
        <>
            {(authUser.id !== profileClient.id && (authUser.isAdmin !== false || authUser.isLineManager !== false)) || authUser.id === profileClient.id ? (
                <Container>
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment>
                                    {leaveCurrent.status === "Denied" && (
                                        <Label color="red" style={{ fontSize: 15 }}>
                                            {leaveCurrent.status}
                                        </Label>
                                    )}
                                    {leaveCurrent.status === "Approved" && (
                                        <Label color="green" style={{ fontSize: 15 }}>
                                            {leaveCurrent.status}
                                        </Label>
                                    )}
                                    {(leaveCurrent.status === "Pending Change" || leaveCurrent.status === "Pending") && (
                                        <Label color="orange" style={{ fontSize: 15 }}>
                                            {leaveCurrent.status}
                                        </Label>
                                    )}
                                    {/* {console.log(leaveCurrent, authUser, profileClient)} */}
                                    {((leaveCurrent.negativeRequested === true &&
                                        authUser.isHR === true &&
                                        profileClient.companyNegLeaveApproval === "HR User" &&
                                        leaveCurrent.status !== "Denied" &&
                                        leaveCurrent.status !== "Approved") ||
                                        ((profileClient.companyNegLeaveApproval == "Line Manager" ||
                                            profileClient.companyNegLeaveApproval == undefined ||
                                            profileClient.companyNegLeaveApproval == "") &&
                                            authUser.isLineManager === true &&
                                            leaveCurrent.negativeRequested == true &&
                                            leaveCurrent.status !== "Denied" &&
                                            leaveCurrent.status !== "Approved") ||
                                        (leaveCurrent.negativeRequested === undefined &&
                                            authUser.isLineManager === true &&
                                            (leaveCurrent.status === "Pending Change" || leaveCurrent.status === "Pending"))) && (
                                        <Button
                                            color="blue"
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modalType: "LeaveAppModal",
                                                        modalProps: { leaveCurrent: leaveCurrent, profileClient: profileClient, authUser: authUser, company: company, preApprove: false },
                                                    })
                                                )
                                            }
                                        >
                                            APPROVE/DENY LEAVE
                                        </Button>
                                    )}
                                    {authUser &&
                                        profileClient &&
                                        authUser.isStaff &&
                                        profileClient.id === authUser.id &&
                                        ((leaveCurrent && leaveCurrent.status === "Approved") || leaveCurrent.status === "Denied") && (
                                            <Button
                                                color="blue"
                                                onClick={() =>
                                                    dispatch(openModal({ modalType: "LeaveChangeModal", modalProps: { leaveCurrent: leaveCurrent, profileClient: profileClient, company: company } }))
                                                }
                                            >
                                                REQUEST CHANGE
                                            </Button>
                                        )}
                                    {authUser.isSuper && (
                                        <Button
                                            color="red"
                                            loading={deleting}
                                            disabled={deleting}
                                            onClick={() => window.confirm("Are you sure you wish to delete this item?") && removeLeave(leaveCurrent, company)}
                                        >
                                            DELETE
                                        </Button>
                                    )}
                                    {(authUser.isSuper || authUser.isAdmin) && leaveCurrent.status === "Approved" && (
                                        <Button
                                            color="yellow"
                                            loading={deleting}
                                            disabled={deleting}
                                            onClick={() =>
                                                dispatch(openModal({ modalType: "LeaveTrackModal", modalProps: { profile: authUser, profileClient: profileClient, type: "edit", company: company } }))
                                            }
                                        >
                                            EDIT
                                        </Button>
                                    )}
                                </Segment>
                            </Grid.Column>

                            <Grid.Column>
                                <Segment textAlign="right">
                                    {authUser && authUser.isLineManager && (
                                        <Button color="grey" as={Link} to={`/manager`}>
                                            BACK TO OUTSTANDING
                                        </Button>
                                    )}

                                    <Button color="grey" as={Link} to={`/clients/leaveview/${leaveCurrent.userUid}`}>
                                        LEAVE REQUESTS
                                    </Button>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={2}>
                            <Grid.Column width={10}>
                                <LeaveViewRequest leaveCurrent={leaveCurrent} profileClient={profileClient} leaveMonthly={leaveMonthly} company={company} authUser={authUser} />
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <Segment style={{ fontSize: 13 }}>
                                    {company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method" && profileClient && profileClient.id && (
                                        <LeaveLeftNew profileClient={profileClient} />
                                    )}
                                </Segment>
                                <Segment style={{ fontSize: 13 }}>
                                    {(company && company.leaveMethodType === undefined) || (company && company.leaveMethodType === "Old Method") ? (
                                        <LeaveLeftOld profileClient={profileClient} />
                                    ) : null}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                {leaveCurrent.status === "Denied" && (
                                    <p>
                                        {leaveCurrent.status} by: <b>{leaveCurrent.actionPerson}</b> on {leaveCurrent.actionDate && format(leaveCurrent.actionDate.toDate(), "d MMMM yyyy")}
                                    </p>
                                )}
                                {leaveCurrent.status === "Approved" && (
                                    <p>
                                        {leaveCurrent.status} by: <b>{leaveCurrent.actionPerson}</b> on {leaveCurrent.actionDate && format(leaveCurrent.actionDate.toDate(), "d MMMM yyyy")}
                                    </p>
                                )}
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <Segment>{leaveCurrent && leaveCurrent.id && <LeaveComments leave={leaveCurrent} />}</Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            ) : (
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            )}
        </>
    );
}
