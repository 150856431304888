import cuid from "cuid";
import { SubmissionError } from "redux-form";

import { toastr } from "react-redux-toastr";
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";

export const logout = () => {
    return firebase.auth().signOut();
};

export const updateProfile =
    (user) =>
    async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        const { isLoaded, isEmpty, ...updatedUser } = user;

        if (updatedUser.dateOfBirth !== getState().firebase.profile.dateOfBirth) {
            updatedUser.dateOfBirth = updatedUser.dateOfBirth;
        }

        try {
            await firebase.updateProfile(updatedUser);
            toastr.success("Success", "Profile updated");
        } catch (e) {
            console.log(e);
        }
    };

export async function updateOnboardingProfile(profile) {
    // const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const firestore = firebase.firestore();
    let userId = "";

    const { isLoaded, isEmpty, newPassword1, newPassword2, ...updatedUser } = profile;

    if (profile.userType === "edit") {
        userId = profile.EditUserId;
    } else {
        userId = firebase.auth().currentUser.uid;
    }

    try {
        if (updatedUser.uploadFile) {
            const path = `${userId}/user_images`;
            const options = {
                name: cuid() + "-" + updatedUser.uploadFile.name,
            };
            const file = updatedUser.uploadFile;
            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());
            updatedUser.fileName = updatedUser.uploadFile.name;
            updatedUser.fileUrl = uploadedFile;
            updatedUser.documentNameRand = options.name;
            updatedUser.uploadFile = "";
            updatedUser.photoURL = uploadedFile;
        }
        if (updatedUser.uploadFile2) {
            const path = `${userId}/onboarding`;
            const options = {
                name: cuid() + "-" + updatedUser.uploadFile2.name,
            };
            const file = updatedUser.uploadFile2;
            // upload the file to firebase storage
            let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());
            await firestore.add(
                {
                    collection: "users",
                    doc: userId,
                    subcollections: [{ collection: "onboarding" }],
                },
                {
                    creadeDate: new Date(Date.now()),
                    downloadURL: downloadURL,
                    fileName: updatedUser.uploadFile2.name,
                }
            );
        }

        updatedUser.onboardingDone = true;

        if (profile.userType !== "edit") {
            if (!profile.onboardingDone || profile.onboardingDone === false) {
                let credential = firebase.auth.EmailAuthProvider.credential(profile.creds_email, profile.creds_password);
                await user.reauthenticateWithCredential(credential).then(function () {
                    console.log("User re-authenticated.");
                });
                await user.updatePassword(newPassword1);
            }

            try {
                await firebase.updateProfile(updatedUser);
                if (!profile.onboardingDone || profile.onboardingDone === false) {
                    toast.success("Onboarding completed");
                } else {
                    toast.success("Profile updated");
                }
            } catch (e) {
                console.log(e.message);
                throw new SubmissionError({
                    _error: e.message,
                });
            }
        } else {
            delete updatedUser.userType;
            delete updatedUser.EditUserId;
            await firestore.collection("users").doc(userId).set(updatedUser);
        }
    } catch (e) {
        console.log(e.message);

        throw new SubmissionError({
            _error: e.message,
        });
    }
}

export const uploadProfileImage =
    (file, fileName) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const imageName = cuid();
        const firebase = getFirebase();
        const firestore = getFirestore();
        const user = firebase.auth().currentUser;
        const path = `${user.uid}/user_images`;
        const options = {
            name: imageName,
        };

        try {
            dispatch(asyncActionStart());

            // upload the file to firebase storage
            let uploadedFile = await firebase.uploadFile(path, file, null, options);

            // get url of image
            let downloadURL = await uploadedFile.uploadTaskSnapshot.downloadURL;

            // get userdoc from firestore
            let userDoc = await firestore.get(`users/${user.uid}`);

            // check if user has photo, if not update profile with new image
            if (!userDoc.data().photoURL) {
                //Firestore doc
                await firebase.updateProfile({
                    photoURL: downloadURL,
                });

                //Firebase auth image
                await user.updateProfile({
                    photoURL: downloadURL,
                });
            }

            // add the new photo to photos collection
            await firestore.add(
                {
                    collection: "users",
                    doc: user.uid,
                    subcollections: [{ collection: "photos" }],
                },
                {
                    name: imageName,
                    url: downloadURL,
                }
            );

            dispatch(asyncActionFinish());
        } catch (e) {
            console.log(e);
            dispatch(asyncActionError());
            throw new Error("Problem uploading photo");
        }
    };

export const deletePhoto =
    (photo) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const user = firebase.auth().currentUser;

        try {
            await firebase.deleteFile(`${user.uid}/user_images/${photo.name}`);
            await firestore.delete({
                collection: "users",
                doc: user.uid,
                subcollections: [{ collection: "photos", doc: photo.id }],
            });
        } catch (e) {
            console.log(e);
            throw new Error("Problem deleting the photo");
        }
    };

export const setMainPhoto = (photo) => async (dispatch, getState) => {
    dispatch(asyncActionStart());
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;
    const today = new Date(Date.now());
    let userDocRef = firestore.collection("users").doc(user.uid);
    let eventAttendeeRef = firestore.collection("event_attendee");

    try {
        let batch = firestore.batch();

        await batch.update(userDocRef, {
            photoURL: photo.url,
        });

        let eventQuery = await eventAttendeeRef.where("userUid", "==", user.uid).where("eventDate", ">", today);

        let eventQuerySnap = await eventQuery.get();

        for (let i = 0; i < eventQuerySnap.docs.length; i++) {
            let eventDocRef = await firestore.collection("events").doc(eventQuerySnap.docs[i].data().eventId);
            let event = await eventDocRef.get();

            if (event.data().hostUid === user.uid) {
                batch.update(eventDocRef, {
                    hostPhotoURL: photo.url,
                    [`attendees.${user.uid}.photoURL`]: photo.url,
                });
            } else {
                batch.update(eventDocRef, {
                    [`attendees.${user.uid}.photoURL`]: photo.url,
                });
            }
        }

        await batch.commit();
        dispatch(asyncActionFinish());
    } catch (e) {
        console.log(e);
        dispatch(asyncActionError());
        throw new Error("Problem setting main photo");
    }
};

export const uploadUserFiles =
    (file, fileName, userId) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const imageName = cuid();
        const firebase = getFirebase();
        const firestore1 = getFirestore();

        const path = `${userId}/onboarding`;
        const options = {
            name: imageName,
        };

        try {
            dispatch(asyncActionStart());

            // upload the file to firebase storage
            let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            await firestore1.add(
                {
                    collection: "users",
                    doc: userId,
                    subcollections: [{ collection: "onboarding" }],
                },
                {
                    creadeDate: new Date(Date.now()),
                    downloadURL: downloadURL,
                    fileName: fileName,
                }
            );

            dispatch(asyncActionFinish());

            toastr.success("Success!", "File has been uploaded");
        } catch (e) {
            console.log(e);
            dispatch(asyncActionError());
            throw new Error("Problem uploading photo");
        }
    };

export const uploadUserPhoto =
    (file, fileName, userId) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const imageName = cuid();
        const firebase = getFirebase();
        const firestore1 = getFirestore();
        const user = firebase.auth().currentUser;

        const path = `${userId}/user_images`;
        const options = {
            name: imageName,
        };

        try {
            dispatch(asyncActionStart());

            // upload the file to firebase storage
            let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            await firestore1.add(
                {
                    collection: "users",
                    doc: userId,
                    subcollections: [{ collection: "photos" }],
                },
                {
                    creadeDate: new Date(Date.now()),
                    downloadURL: downloadURL,
                    fileName: fileName,
                }
            );

            //Firestore doc
            await firebase.updateProfile({
                photoURL: downloadURL,
            });

            //Firebase auth image
            await user.updateProfile({
                photoURL: downloadURL,
            });

            dispatch(asyncActionFinish());

            toastr.success("Success!", "Profile picture has been updated");
        } catch (e) {
            console.log(e);
            dispatch(asyncActionError());
            throw new Error("Problem uploading photo");
        }
    };

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;

    if (auth && auth.uid) {
        let userData = auth.uid;
        const firestore = firebase.firestore();
        return firestore.collection("users").doc(userData).get();
    } else {
        return "";
    }
};

export const getAuth = () => {
    const auth = firebase.auth().currentUser.providerData[0].providerId;
    return auth;
};

export async function getProfileClient(id) {
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(id).get();
}
