import React, { useCallback, useState, useEffect } from "react";
import { Button, Grid, Label, Segment } from "semantic-ui-react";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addExpenseTracking } from "../staffActions";
import { useSelector } from "react-redux";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
import { Link } from "react-router-dom";
const firestore = firebase.firestore();

export default function AddExpensePage(props) {
    let url = window.location.href.split("/");
    let lastSegment = url.pop() || url.pop();
    const [profileClient, setProfileClient] = useState({});
    const [clientView, setClientView] = useState(false);
    const categories = [
        { key: "", text: "Choose a Category", value: "" },
        { key: "Travel", text: "Travel", value: "Travel" },
        { key: "Fuel", text: "Fuel", value: "Fuel" },
        { key: "Reimbursement non taxable", text: "Reimbursement non taxable", value: "Reimbursement non taxable" },
        { key: "Reimbursement taxable", text: "Reimbursement taxable", value: "Reimbursement taxable" },
    ];

    const expenseType = [
        { key: "", text: "Choose a Type", value: "" },
        { key: "Business", text: "Business", value: "Business" },
        { key: "Personal", text: "Personal", value: "Personal" },
    ];
    const { currentUserProfile } = useSelector((state) => state.profile);

    const [date, setDate] = useState(new Date());
    const [category, setCategory] = useState("");
    const [dateTimeFrom, setDateTimeFrom] = useState(new Date());
    const [dateTimeTo, setDateTimeTo] = useState(new Date());

    useEffect(() => {
        if (lastSegment !== "add") {
            firestore
                .collection("users")
                .doc(lastSegment)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                        setProfileClient({ ...snapshot.data(), id: snapshot.id });
                        setClientView(true);
                    }
                });
        }
    }, []);

    const updateDate = useCallback((value) => {
        setDate(value);
    }, []);

    const updateDateTimeFrom = useCallback((value) => {
        setDateTimeFrom(value);
    }, []);

    const updateDateTimeTo = useCallback((value) => {
        setDateTimeTo(value);
    }, []);

    function changeCategory(value) {
        setCategory(value);
    }

    return (
        <div>
            <Segment>
                <h2>Add Expenses</h2>
                <Formik
                    initialValues={{ category: "", description: "", amount: "", uploadFile: "", date: "" }}
                    enableReinitialize
                    validate={(values) => {
                        const error = {};
                        if (!values.category) {
                            error.category = "Required";
                        }

                        if (!values.amount) {
                            error.amount = "Required";
                        }
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        values.status = "In Progress";

                        if (values.category !== "Fuel" && values.category !== "Travel" && values.date == "") {
                            values.date = new Date();
                        }

                        if ((values.category == "Fuel" || values.category == "Travel") && values.dateTimeFrom == undefined) {
                            values.dateTimeFrom = new Date(format(new Date(), "yyyy-MM-dd HH:mm"));
                        }

                        if ((values.category == "Fuel" || values.category == "Travel") && values.dateTimeTo == undefined) {
                            values.dateTimeTo = new Date(format(new Date(), "yyyy-MM-dd HH:mm"));
                        }

                        if (lastSegment == "add") {
                            values.displayName = currentUserProfile.displayName;
                            values.userUid = currentUserProfile.id;
                            values.companyId = currentUserProfile.companyId;
                            values.expenseToDate = currentUserProfile.expenseToDate ? currentUserProfile.expenseToDate : 28;
                        } else {
                            values.displayName = profileClient.displayName;
                            values.userUid = profileClient.id;
                            values.addedBy = currentUserProfile.id;
                            values.companyId = profileClient.companyId;
                            values.expenseToDate = profileClient.expenseToDate ? profileClient.expenseToDate : 28;
                        }

                        await addExpenseTracking(values);
                        setSubmitting(false);
                        if (lastSegment == "add") {
                            window.location.replace("/expenses/view/all/");
                        } else {
                            window.location.replace("/expenses/view/all/" + profileClient.id);
                        }
                    }}
                >
                    {({ values, error, handleSubmit, touched, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Column width={8}>
                                    <MySelectInput
                                        labelTop="Category"
                                        name="category"
                                        options={categories}
                                        value={values.category}
                                        onChange={(field, value) => {
                                            {
                                                changeCategory(value.value);
                                                setFieldValue("category", value.value);
                                            }
                                        }}
                                        placeholder="Category"
                                        clearable={false}
                                    />

                                    <MyTextInput labelTop="Amount:" name="amount" placeholder="Amount" />

                                    <br />

                                    {category === "Fuel" || category === "Travel" ? null : (
                                        <>
                                            <label>
                                                <b>Date:</b>
                                            </label>
                                            <DatePicker
                                                name="date"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.date || new Date()}
                                                selected={date}
                                                onChange={(value) => {
                                                    updateDate(value);
                                                    setFieldValue("date", value);
                                                }}
                                                disabledKeyboardNavigation
                                            />
                                        </>
                                    )}

                                    <br />
                                </Grid.Column>

                                <Grid.Column width={8}>
                                    <MySelectInput
                                        labelTop="Type"
                                        name="type"
                                        options={expenseType}
                                        value={values.type}
                                        onChange={(field, value) => {
                                            setFieldValue("type", value.value);
                                        }}
                                        placeholder="Type"
                                        clearable={false}
                                    />

                                    <MyTextInput labelTop="Description:" name="description" placeholder="Description" />

                                    <br />
                                    <label>
                                        <b>File to Upload</b>
                                    </label>
                                    <input
                                        id="uploadFile"
                                        name="uploadFile"
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue("uploadFile", event.currentTarget.files[0]);
                                        }}
                                        className="form-control"
                                    />
                                </Grid.Column>

                                {category === "Fuel" || category === "Travel" ? (
                                    <>
                                        <Grid.Column width={16}>
                                            <hr />
                                            <h3 style={{ textAlign: "center" }}>{category}</h3>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label>
                                                <b>Date / Time From:</b>
                                            </label>
                                            <DatePicker
                                                name="dateTimeFrom"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                showTimeSelect={true}
                                                dropdownMode="select"
                                                value={values.dateTimeFrom || new Date()}
                                                selected={dateTimeFrom}
                                                onChange={(value) => {
                                                    updateDateTimeFrom(value);
                                                    setFieldValue("dateTimeFrom", value);
                                                }}
                                                disabledKeyboardNavigation
                                            />
                                            <br />
                                            <br />
                                            <MyTextInput labelTop="Location From:" name="locationFrom" placeholder="Location From" />
                                            <MyTextInput labelTop="Odometer From:" name="odometerFrom" placeholder="Odometer From" />
                                            <MyTextInput labelTop="Client Name:" name="clientName" placeholder="Client Name" />
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label>
                                                <b>Date / Time To:</b>
                                            </label>
                                            <DatePicker
                                                name="dateTimeTo"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                showTimeSelect={true}
                                                dropdownMode="select"
                                                value={values.dateTimeTo || new Date()}
                                                selected={dateTimeTo}
                                                onChange={(value) => {
                                                    updateDateTimeTo(value);
                                                    setFieldValue("dateTimeTo", value);
                                                }}
                                                disabledKeyboardNavigation
                                            />
                                            <br />
                                            <br />
                                            <MyTextInput labelTop="Location To:" name="locationTo" placeholder="Location To" />
                                            <MyTextInput labelTop="Odometer To:" name="odometerTo" placeholder="Odometer To" />
                                        </Grid.Column>
                                    </>
                                ) : null}

                                <Grid.Column width={8}>
                                    <Button color="blue" type="submit" loading={isSubmitting}>
                                        Submit
                                    </Button>
                                    {clientView === false ? (
                                        <Link as="a" to={`/expenses/view/all`}>
                                            <Button>Cancel</Button>
                                        </Link>
                                    ) : (
                                        <Link as="a" to={`/expenses/view/all/${profileClient.id}`}>
                                            <Button>Cancel</Button>
                                        </Link>
                                    )}
                                </Grid.Column>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Segment>
        </div>
    );
}
