import { toast } from "react-toastify";
import firebase from "../../app/config/firebase";

const firestore = firebase.firestore();

export async function markRead(notif) {
    try {
        let notification = {
            read: true,
            readOn: new Date(Date.now()),
        };

        await firestore.collection("notifications").doc(notif.id).update(notification);
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getNotifications() {
    const auth = firebase.auth().currentUser.uid;
    return firestore.collection("notifications").where("userUid", "==", auth).where("read", "==", false).get();
}
