import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { uploadResource } from "../resourceActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getUsers, getCompanies, getAuthUser } from "../resourceActions";

export default function ResourcesUploadForm(props) {
    const [assignUsers, setAssignUsers] = useState([{ key: "", text: "Choose a User", value: "" }]);
    const [assignCompanies, setAssignCompanies] = useState([{ key: "", text: "Choose a Company", value: "" }]);
    const [viewedByUser, setViewedByUser] = useState([]);
    const [authUser, setAuthUser] = useState([]);
    const [initial, setInitial] = useState({ documentName: "", viewedBy: "" });

    useEffect(() => {
        getUsers().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                getAuthUser().then(
                    (result) => {
                        if (result.exists) {
                            setAuthUser(result.data());
                            let assignUsers = [
                                {
                                    key: "",
                                    text: "Choose a User",
                                    value: "",
                                },
                            ];
                            let usrs = [];

                            if (result.data().isSuper === true) {
                                Object.keys(list).forEach(function (key) {
                                    usrs.push({
                                        key: list[key].id,
                                        text:
                                            list[key].displayName && list[key].displayName.length > 0
                                                ? list[key].companyName + " - " + list[key].displayName
                                                : list[key].companyName + " - " + list[key].email,
                                        value: list[key].id,
                                    });
                                });
                            } else if (result.data().isAdmin === true) {
                                Object.keys(list).forEach(function (key) {
                                    if (list[key].companyId === result.data().companyId) {
                                        usrs.push({
                                            key: list[key].id,
                                            text:
                                                list[key].displayName && list[key].displayName.length > 0
                                                    ? list[key].companyName + " - " + list[key].displayName
                                                    : list[key].companyName + " - " + list[key].email,
                                            value: list[key].id,
                                        });
                                    }
                                });
                                setInitial({ documentName: "", viewedBy: "", companyId: result.data().companyId });
                            }

                            usrs.sort((a, b) => (b.text < a.text ? 1 : -1));
                            let allUsers = [assignUsers, usrs];
                            setAssignUsers(allUsers.flat());
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            },
            (err) => {
                console.log(err);
            }
        );
        getCompanies().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                getAuthUser().then(
                    (result) => {
                        if (result.exists) {
                            let assignCompanies = [
                                {
                                    key: "",
                                    text: "Choose a Company",
                                    value: "",
                                },
                            ];
                            let comps = [];

                            if (result.data().isSuper === true) {
                                Object.keys(list).forEach(function (key) {
                                    comps.push({
                                        key: list[key].id,
                                        text: list[key].companyName,
                                        value: list[key].id,
                                    });
                                });
                            } else if (result.data().isAdmin === true) {
                                Object.keys(list).forEach(function (key) {
                                    if (list[key].id === result.data().companyId) {
                                        comps.push({
                                            key: list[key].id,
                                            text: list[key].companyName,
                                            value: list[key].id,
                                        });
                                    }
                                });
                                setInitial({ documentName: "", viewedBy: "", companyId: result.data().companyId });
                            }

                            comps.sort((a, b) => (b.text < a.text ? 1 : -1));
                            let allCompanies = [assignCompanies, comps];
                            setAssignCompanies(allCompanies.flat());
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    const updateViewd = useCallback((value) => {
        setViewedByUser(value);
    }, []);

    function changeViewed(value) {
        updateViewd(value.value);
    }

    let viewedBy = [];

    if (authUser.isSuper) {
        viewedBy.push({ key: "All", text: "All", value: "All" }, { key: "Company", text: "Company", value: "Company" }, { key: "User", text: "User", value: "User" });
    } else {
        viewedBy.push({ key: "Company", text: "Company", value: "Company" }, { key: "User", text: "User", value: "User" });
    }

    return (
        <Segment>
            <Formik
                initialValues={initial}
                enableReinitialize={true}
                validate={(values) => {
                    const error = {};
                    if (values.documentName === "") {
                        error.documentName = "Required";
                    }
                    if (values.viewedBy === "") {
                        error.viewedBy = "Required";
                    }

                    if (values.viewedBy === "Company") {
                        if (values.companyId === undefined) {
                            error.companyId = "Required";
                        }
                    } else if (values.viewedBy === "User") {
                        if (values.userUid === undefined) {
                            error.userUid = "Required";
                        }
                    }

                    return error;
                }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                    values.documentFile = "company";
                    await uploadResource(values);
                    setSubmitting(false);
                    resetForm();
                }}
            >
                {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Document Description</label>
                                        <MyTextInput name="documentName" placeholder="Enter a description for the file" />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <label>Viewed By</label>
                                        <MySelectInput
                                            name="viewedBy"
                                            options={viewedBy}
                                            value={values.viewedBy}
                                            onChange={(field, value) => {
                                                changeViewed(value);
                                                setFieldValue("viewedBy", value.value);
                                            }}
                                            placeholder="Who is this for?"
                                            clearable={false}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <label>File to Upload</label>
                                        <input
                                            id="uploadFile"
                                            name="uploadFile"
                                            type="file"
                                            onChange={(event) => {
                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                            }}
                                            className="form-control"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={3} verticalAlign="bottom">
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Company</label>
                                        <MySelectInput
                                            disabled={viewedByUser !== "Company"}
                                            name="companyId"
                                            options={assignCompanies}
                                            value={values.companyId}
                                            onChange={(field, value) => setFieldValue("companyId", value.value)}
                                            placeholder="Choose a Company"
                                            clearable={false}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field>
                                        <label>User</label>
                                        <MySelectInput
                                            disabled={viewedByUser !== "User"}
                                            name="userUid"
                                            options={assignUsers}
                                            value={values.userUid}
                                            onChange={(field, value) => setFieldValue("userUid", value.value)}
                                            placeholder="Choose a User"
                                            clearable={false}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" type="submit" color="blue">
                                        UPLOAD
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Segment>
    );
}
