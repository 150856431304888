import React, { Component } from "react";
import { Container, Grid, Image } from "semantic-ui-react";

export default class NavLogo extends Component {
    render() {
        const { profile, h1, p } = this.props;

        let thumbnail = "/assets/user.png";

        thumbnail = profile.photoURL;

        return (
            <Container className="navlogo">
                <Grid stackable columns={2} style={{ with: "100%" }}>
                    <Grid.Row verticalAlign="middle" columns={3}>
                        <Grid.Column width={6}>
                            <h1>{h1}</h1>
                            <p>{p}</p>
                        </Grid.Column>

                        <Grid.Column width={5} textAlign="right">
                            <Image spaced="right" src={profile.logoUrl || "/assets/logo.png"} />
                        </Grid.Column>

                        <Grid.Column width={5} textAlign="right">
                            <Image spaced="right" src={thumbnail} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
