import React, { useState } from "react";
import { Formik, Form } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button, Label, Grid, Segment } from "semantic-ui-react";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { resetPassword } from "./authActions";
import { generateLeaveStats } from "../manager/managerActions";

const version = "3.13.33";

export default function LoginForm({ history }) {
    const [forgot, setForgot] = useState(false);

    function changeType() {
        setForgot(true);
    }
    function changeTypeFalse() {
        setForgot(false);
    }

    return (
        <div className="login_bg">
            <Grid textAlign="center">
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email = "Required";
                                }
                                if (forgot == false) {
                                    if (!values.password) {
                                        errors.password = "Required";
                                    }
                                }
                                return errors;
                            }}
                            onSubmit={async (values, { setSubmitting, setErrors }) => {
                                try {
                                    if (forgot === true) {
                                        console.log("1");
                                        await resetPassword(values.email);
                                        changeTypeFalse();
                                        setSubmitting(false);
                                    } else {
                                        let details = await signInWithEmail(values);
                                        await generateLeaveStats(details.user.uid, "");
                                        // history.push("/dashboard/landing");

                                        history.push({
                                            pathname: "/dashboard/landing",
                                            state: { values },
                                        });

                                        setSubmitting(false);
                                    }
                                } catch (error) {
                                    console.log(error);
                                    setErrors({ auth: "Problem with username or password" });
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ isSubmitting, isValid, dirty, errors }) => (
                                <Form className="ui form">
                                    <img src="/assets/logo.svg" alt="DRG Online" style={{ marginTop: -100, marginBottom: 15, width: "100px" }} />
                                    <h1>Welcome back</h1>
                                    <p>Stay up to date with HR, and sign in.</p>
                                    <Segment>
                                        <MyTextInput name="email" placeholder="Email Address" />
                                        <MyTextInput name="password" placeholder="Password" type="password" hidden={forgot === true ? true : false} />
                                        {errors.auth && <Label basic color="red" style={{ marginBottom: 10 }} content={errors.auth} />}
                                        <br />
                                        <Button
                                            loading={isSubmitting}
                                            disabled={!dirty || isSubmitting}
                                            type="submit"
                                            fluid
                                            size="large"
                                            color="blue"
                                            content={forgot === true ? "Send Reset Link" : "Login"}
                                        />
                                        <br />
                                        <p>V {version}</p>
                                    </Segment>
                                </Form>
                            )}
                        </Formik>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Button style={{ marginTop: 20 }} onClick={changeType}>
                            Forgot password?
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}
