import React from "react";
import { Modal, Button, Image, Divider, Form, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MySelectInput from "../../app/common/form/MySelectInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyRadioButton from "../../app/common/form/MyRadioButton";
import MyTextArea from "../../app/common/form/MyTextArea";
import { expenseApprove } from "../manager/managerActions";

export default function ExpenseAppModal(props) {
    let { expense, expenseId, profileClient } = props;
    let header_cls = "orange_header";
    const dispatch = useDispatch();
    const { currentUserProfile } = useSelector((state) => state.profile);

    const categories = [
        { key: "", text: "Choose a Category", value: "" },
        { key: "Travel", text: "Travel", value: "Travel" },
        { key: "Fuel", text: "Fuel", value: "Fuel" },
        { key: "Reimbursement non taxable", text: "Reimbursement non taxable", value: "Reimbursement non taxable" },
        { key: "Reimbursement taxable", text: "Reimbursement taxable", value: "Reimbursement taxable" },
    ];

    if (expense.status === "Approved") {
        header_cls = "green_header";
    } else if (expense.status === "Denied") {
        header_cls = "red_header";
    }

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>
                <Image style={{ zIndex: 2, maxHeight: "130px", maxWidth: "130px", width: "auto" }} avatar spaced="right" size="small" src={profileClient.photoURL || "/assets/user.png"} />

                <div className={header_cls}>
                    <div className="ui right aligned header" style={{ marginRight: 40, marginTop: 5, color: "white" }}>
                        <h3>{expense.status}</h3>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                        <h2 className="ui center aligned header">APPROVE/DENY EXPENSE FORM</h2>
                    </div>

                    <Divider />

                    <Formik
                        initialValues={expense}
                        validate={(values) => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            values.approvedBy = currentUserProfile.id;
                            await expenseApprove(values);
                            setSubmitting(false);
                            dispatch(closeModal());
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Form.Group inline>
                                    <label>Status:</label>
                                    <MyRadioButton name="status" type="radio" value="Approved" label="" labelcb="Approve" />

                                    <MyRadioButton name="status" type="radio" value="Denied" label="" labelcb="Deny" />
                                </Form.Group>

                                <Grid>
                                    <Grid.Column width={5}>
                                        <MySelectInput
                                            labelTop="Change Category (Optional)"
                                            name="category"
                                            options={categories}
                                            value={values.category}
                                            onChange={(field, value) => {
                                                setFieldValue("category", value.value);
                                            }}
                                            placeholder="Category"
                                            clearable={false}
                                        />
                                    </Grid.Column>
                                </Grid>

                                <p>If denied, please provide a reason:</p>

                                <MyTextArea name="denyReason" />

                                <br />

                                <Button type="submit" loading={isSubmitting} disabled={isSubmitting} color="blue" size="large" fluid>
                                    Submit
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
