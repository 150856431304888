import firebase from "../../app/config/firebase";

const firestore = firebase.firestore();

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getProfileClient(id) {
    //Security Check
    // const auth = firebase.auth().currentUser;
    // let userId = auth.uid;
    // let userInfo = [];
    // await firestore
    //     .collection("users")
    //     .doc(userId)
    //     .get()
    //     .then(
    //         (res) => {
    //             if (res.exists) {
    //                 let appObj = { ...res.data(), ["id"]: `${res.id}` };
    //                 userInfo = appObj;
    //             }
    //         },
    //         (err) => {
    //             console.log(err);
    //         }
    //     );

    let idUser = "";

    //If the id is home or landing we need to get currently logged in users ID
    if (id === "home" || id === "landing") {
        idUser = firebase.auth().currentUser.uid;
    } else {
        idUser = id;
    }

    let data = await firestore.collection("users").doc(idUser).get();

    // if (userInfo.isSuper || (userInfo.isAdmin && data.data().companyId === userInfo.companyId)) {
    return data;
    // }
}

export async function getNotifications() {
    const auth = firebase.auth().currentUser.uid;
    return firestore.collection("notifications").where("userUid", "==", auth).where("read", "==", false).where("notificationType", "==", "Notification").get();
}

export async function getNotificationActions() {
    const auth = firebase.auth().currentUser.uid;
    return firestore.collection("notifications").where("userUid", "==", auth).where("read", "==", false).where("notificationType", "==", "Action").get();
}
