import React, { useState, useEffect } from "react";
import { Button, Container, Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";
import IRP5UploadForm from "./IRP5UploadForm";
import format from "date-fns/format";
import { deleteIRP5, getAuthUser, getIrp5, getProfile } from "../irp5Actions";
import { Link } from "react-router-dom";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function IRP5DashboardPage(props) {
    const [profile, setProfile] = useState([]);
    const [profileOriginal, setProfileOriginal] = useState([]);
    const [clientView, setClientView] = useState(false);
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();
    const [irp5, setIrp5] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    if (id !== res.data().id && id === "irp5") {
                        // setProfileOriginal(res.data());
                        setClientView(false);
                    } else {
                        getProfile(id).then(
                            (res) => {
                                if (res.exists) {
                                    setProfileOriginal(res.data());
                                }
                            },
                            (err) => {
                                console.log(err);
                            }
                        );
                        setClientView(true);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getIrp5(id).then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        // console.log(doc.data());
                        // if (doc.data().status === undefined || doc.data().status === "Mapped") {
                            let appObj = { ...doc.data(), id: doc.id };
                            list.push(appObj);
                        // }
                    }
                });

                setIrp5(list);
            },
            (err) => {
                console.log(err);
            }
        );

        let userId = "";

        if (id == "irp5") {
            userId = firebase.auth().currentUser.uid;
        } else {
            userId = id;
        }

        const unsubscribe = firestore
            .collection("irp5")
            .where("userUid", "==", userId)
            .where("status", "!=", "Unsorted")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setIrp5(list);
            });

        return () => unsubscribe();
    }, []);

    function removeFile(irp5) {
        deleteIRP5(irp5);
    }
    return (
        <Container>
            {profile && (profile.isSuper || profile.isAdmin || profile.isHR) ? <IRP5UploadForm profileOriginal={profileOriginal} /> : null}

            {clientView === true && (
                <Segment style={{ marginBottom: "20px" }}>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <h1>Staff Member: {profileOriginal.displayName ? profileOriginal.displayName : profileOriginal.email}</h1>
                            </Grid.Column>

                            <Grid.Column textAlign="right">
                                <Link className="ui button" as="a" to={"/home/" + profileOriginal.id}>
                                    Back to Dashboard
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}

            {irp5 && irp5.length === 0 && <p>No IRP5's Uploaded</p>}

            {irp5 &&
                irp5.length > 0 &&
                irp5.map((irp5) => (
                    <Segment secondary key={irp5.id}>
                        <Grid>
                            <GridRow>
                                <GridColumn width="four" verticalAlign="middle">
                                    {irp5.documentName}
                                </GridColumn>

                                <GridColumn width="twelve">
                                    <Segment>
                                        <Grid>
                                            <GridRow>
                                                <GridColumn width="eight" verticalAlign="middle">
                                                    {format(irp5.date.toDate(), "d MMMM yyyy")}
                                                    <br />
                                                    Version {irp5.version}
                                                </GridColumn>

                                                <GridColumn width="eight" textAlign="right">
                                                    <Button color="blue" as="a" target="_blank" href={irp5.fileUrl}>
                                                        Download File
                                                    </Button>

                                                    {(profile.isSuper || profileOriginal.isSuper) && (
                                                        <Button color="red" onClick={() => window.confirm("Are you sure you wish to delete this item?") && removeFile(irp5)}>
                                                            Delete File
                                                        </Button>
                                                    )}
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                ))}
        </Container>
    );
}
