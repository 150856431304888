import React from "react";
import { Container, Segment, Grid, Button, Label } from "semantic-ui-react";
import format from "date-fns/format";
import { useDispatch } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function (props) {
    const { leaveCurrent, profileClient, leaveMonthly, company, authUser } = props;
    const dispatch = useDispatch();

    return (
        <Container>
            <Segment>
                {leaveCurrent.negativeRequested !== undefined &&
                    leaveCurrent.negativeRequested === true &&
                    (leaveCurrent.negativeEscalated === undefined || leaveCurrent.negativeEscalated === false) &&
                    profileClient.companyNegLeaveApproval === "HR User" &&
                    authUser.isLineManager === true && (
                        <div style={{ marginBottom: "20px" }}>
                            <h2 style={{ color: "red" }}>Negative Leave Requested</h2>
                            <Button
                                color="blue"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modalType: "LeaveAppModal",
                                            modalProps: { leaveCurrent: leaveCurrent, authUser: authUser, profileClient: profileClient, company: company, preApprove: true },
                                        })
                                    )
                                }
                            >
                                Escalate to HR / Deny
                            </Button>
                        </div>
                    )}
                {leaveCurrent.negativeRequested !== undefined &&
                    leaveCurrent.negativeRequested === true &&
                    profileClient.companyNegLeaveApproval === "HR User" &&
                    leaveCurrent.negativeEscalated === true &&
                    authUser.isHR === true && (
                        <div style={{ marginBottom: "30px" }}>
                            <h2 style={{ color: "red" }}>Negative Leave Requested</h2>
                            <p>
                                <b>Line Manager Pre Approval Status:</b>

                                {leaveCurrent.escalateStatus === "Denied" && (
                                    <Label color="red" style={{ fontSize: 15 }}>
                                        {leaveCurrent.escalateStatus}
                                    </Label>
                                )}
                                {leaveCurrent.escalateStatus === "Approved" && (
                                    <Label color="green" style={{ fontSize: 15 }}>
                                        {leaveCurrent.escalateStatus}
                                    </Label>
                                )}
                            </p>

                            <p>
                                <b>Line Manager Note:</b> {leaveCurrent.escalateNote}
                            </p>
                            <hr />
                        </div>
                    )}
                <Grid className="modal_mock_form" stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <label>Employee</label>
                            <div className="mock_input">{leaveCurrent.displayName}</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>Site</label>
                            <div className="mock_input">{profileClient && profileClient.siteName ? profileClient.siteName : profileClient.site ? profileClient.site : "N/A"}</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>Employee Number</label>
                            <div className="mock_input">{profileClient && profileClient.employeeNr ? profileClient.employeeNr : "N/A"}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <label>From</label>
                            <div className="mock_input">{leaveCurrent.annualFrom && format(leaveCurrent.annualFrom.toDate(), "d MMMM yyyy")}</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>To</label>
                            <div className="mock_input">{leaveCurrent.annualTo && format(leaveCurrent.annualTo.toDate(), "d MMMM yyyy")}</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>Number of Working Days</label>
                            <div className="mock_input">{leaveCurrent.annualDays}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <label>Leave</label>
                            <div className="mock_input">{leaveCurrent.leaveType} Leave</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>Unpaid</label>
                            <div className="mock_input">{leaveCurrent.unpaidLeave}</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>Half Day</label>
                            <div className="mock_input">{leaveCurrent.halfDay}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <label>Description</label>
                            <div className="mock_input">{leaveCurrent.description ? leaveCurrent.description : "N/A"}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Date Applied for leave</label>
                            <div className="mock_input">{leaveCurrent.createDate && format(leaveCurrent.createDate.toDate(), "d MMMM yyyy")}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <label>Date Request was Actioned</label>
                            <div className="mock_input">{leaveCurrent.actionDate && format(leaveCurrent.actionDate.toDate(), "d MMMM yyyy")}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <label>Added By</label>
                            <div className="mock_input">{leaveCurrent.addedByDisplayName ? leaveCurrent.addedByDisplayName : "N/A"}</div>
                        </Grid.Column>

                        <Grid.Column>
                            <label>Annual Leave Available ({leaveCurrent.annualFrom && format(leaveCurrent.annualFrom.toDate(), "MMMM yyyy")})</label>
                            <div className="mock_input">
                                {leaveMonthly.annualLeft
                                    ? leaveMonthly.annualLeft
                                    : profileClient &&
                                        leaveCurrent.annualFrom &&
                                        profileClient["leaveMonths"] &&
                                        profileClient["leaveMonths"][new Date().getFullYear()] &&
                                        profileClient["leaveMonths"][new Date().getFullYear()][format(leaveCurrent.annualFrom.toDate(), "M")]
                                        ? profileClient["leaveMonths"][new Date().getFullYear()][format(leaveCurrent.annualFrom.toDate(), "M")].left
                                        : "N/A"}
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    {leaveCurrent && leaveCurrent.denyReason && (
                        <Grid.Row>
                            <Grid.Column>
                                <label>Deny Reason</label>
                                <p className="mock_input">{leaveCurrent.denyReason}</p>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {leaveCurrent.fileName && leaveCurrent.fileUrl && (
                        <Grid.Row>
                            <Grid.Column>
                                <h2>Supporting Documents</h2>
                                <Button color="blue" as="a" target="_blank" href={leaveCurrent.fileUrl}>
                                    {leaveCurrent.fileName}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </Segment>
        </Container>
    );
}
