import React from "react";
import { Header, Segment, Grid } from "semantic-ui-react";

export default function BankingPage({ user }) {
    return (
        <Segment>
            <Header dividing size="large" content="Banking Details" />

            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={4}>
                        <strong>Type of Bank A/C:</strong>
                    </Grid.Column>

                    <Grid.Column width={4}>{user.bank_type}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column width={4}>
                        <strong>Name of Bank:</strong>
                    </Grid.Column>

                    <Grid.Column width={4}>{user.bank_name}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column width={4}>
                        <strong>Bank Account No:</strong>
                    </Grid.Column>

                    <Grid.Column width={4}>{user.bank_acc_no}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column width={4}>
                        <strong>Branch Name & Code:</strong>
                    </Grid.Column>

                    <Grid.Column width={4}>{user.bank_branch}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}
