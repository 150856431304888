import firebase from "firebase";

//DRG - Live
const DRGLive = {
    apiKey: "AIzaSyCe4OqsAeeXMljIS29QYJPeA570Hpe3K3A",
    authDomain: "drg-outsourcing.firebaseapp.com",
    databaseURL: "https://drg-outsourcing.firebaseio.com",
    projectId: "drg-outsourcing",
    storageBucket: "drg-outsourcing.appspot.com",
    messagingSenderId: "375759632727",
    appId: "1:375759632727:web:673395d86928a986",
    desc: "DRG - Live",
};

//DRG - Test
const DRGTest = {
    apiKey: "AIzaSyDlsSuDAA7sZw3Dyf2HRDHM5JmDMKiUCXM",
    authDomain: "drg-outsourcing-test.firebaseapp.com",
    databaseURL: "https://drg-outsourcing-test.firebaseio.com",
    projectId: "drg-outsourcing-test",
    storageBucket: "drg-outsourcing-test.appspot.com",
    messagingSenderId: "814804993322",
    appId: "1:814804993322:web:5cc3f9b754344a06",
    desc: "DRG - Test",
};

//Think - Live
const ThinkLive = {
    apiKey: "AIzaSyDfu4amaOD7Drc310cM8qP90z1IwkBFhiM",
    authDomain: "think-drg.firebaseapp.com",
    databaseURL: "https://think-drg.firebaseapp.com",
    projectId: "think-drg",
    storageBucket: "think-drg.appspot.com",
    messagingSenderId: "171717100477",
    appId: "1:171717100477:web:787b833a42292b25b108d4",
    desc: "Think - Live",
};

//Think - Test
const ThinkTest = {
    apiKey: "AIzaSyAaTnUg0zDUMI407sbeBUYMdPg1XLdbXuw",
    authDomain: "think-drg---test.firebaseapp.com",
    projectId: "think-drg---test",
    storageBucket: "think-drg---test.appspot.com",
    messagingSenderId: "74814201394",
    appId: "1:74814201394:web:b03d539fe29a3bb6b3262b",
    desc: "Think - Live",
};

let firebaseConfig = {};
const hostname = window && window.location && window.location.hostname;

//Figure out which Firebase instance to connect to
if (hostname === "think-drg.web.app" || hostname === "drgonline.think.org.za") {
    firebaseConfig = ThinkLive;
} else if (hostname === "drgonline.co.za" || hostname === "drg-outsourcing.web.app" || hostname === "drg-outsourcing.firebaseapp.com") {
    firebaseConfig = DRGLive;
} else if (hostname === "drg-outsourcing-test.web.app" || hostname === "drg-outsourcing-test.firebaseapp.com") {
    firebaseConfig = DRGTest;
} else if (hostname === "localhost") {
    firebaseConfig = ThinkLive;
    // firebaseConfig = ThinkTest;
    // firebaseConfig = DRGLive;
    // firebaseConfig = DRGTest;
} else {
    firebaseConfig = DRGTest;
}

console.log("hostname: " + hostname);
console.log("DB Version: " + firebaseConfig.desc);

firebase.initializeApp(firebaseConfig);
firebase.firestore();

// let db = firebase.firestore(); //Note this line is now assigned to DB

// if (hostname === "localhost") {
//     db.settings({
//         host: "localhost:8080",
//         ssl: false,
//     });
// }

export default firebase;
