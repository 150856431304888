import React from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Label } from "semantic-ui-react";
import { timeApprove } from "../managerActions";
import { useSelector } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MyRadioButton from "../../../app/common/form/MyRadioButton";
import MyTextArea from "../../../app/common/form/MyTextArea";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";
import { toast } from "react-toastify";
import firebase from "../../../app/config/firebase";

export default function TimeAppForm(props) {
    const dispatch = useDispatch();
    let parts = window.location.href.split("/");
    let timeId = parts.pop() || parts.pop();
    const { currentUserProfile } = useSelector((state) => state.profile);

    console.log(currentUserProfile);
    console.log(props);
    return (
        <Formik
            initialValues={{ denyReason: "" }}
            validate={(values) => {
                const error = {};
                return error;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                await axios.post(apiURL + "/api/time_approve", { values: values, timeId: timeId, profile: currentUserProfile });

                let TimeActions = firebase.app().functions("europe-west2").httpsCallable("TimeActions");

                let timeInfo = {};

                timeInfo.timeId = timeId;
                timeInfo.denyReason = values.denyReason;
                timeInfo.status = values.status;
                timeInfo.userUid = props.time.timeUserUid;

                await TimeActions({ timeInfo: timeInfo })
                    .then(async (result) => {
                        if (result.data === "Email sent OK") {
                            toast.success("Email has been queued to send");
                            dispatch(closeModal());
                        } else {
                            toast.error("Oops", "Error sending email - Type 2");
                        }

                        console.log(result);

                        setSubmitting(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error("Oops", "Error sending email - Type 3");
                        setSubmitting(false);
                    });

                dispatch(closeModal());
                setSubmitting(false);
                window.location.reload();
            }}
        >
            {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Form.Group inline>
                        <label>Status:</label>
                        <MyRadioButton name="status" type="radio" value="Approved" label="" labelcb="Approve" />

                        <MyRadioButton name="status" type="radio" value="Denied" label="" labelcb="Deny" />

                        <MyRadioButton name="status" type="radio" value="Cancelled" label="" labelcb="Cancel" />
                    </Form.Group>

                    <p>If denied, please provide a reason:</p>

                    <MyTextArea name="denyReason" />

                    {error && (
                        <Label basic color="red">
                            {error}
                        </Label>
                    )}
                    <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue">
                        SUBMIT
                    </Button>
                </form>
            )}
        </Formik>
    );
}
