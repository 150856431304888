import React, { useState, useEffect } from "react";
import TimeTrackList from "../../staff/TimeTrackList/TimeTrackList";
import TimeDashboardStats from "../../staff/TimeDashboard/TimeDashboardStats";
import { Container, Grid, Segment } from "semantic-ui-react";
import { getAuthUser, getUserProfile, getTime } from "../clientActions";
import firebase from "../../../app/config/firebase";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";
import { Link } from "react-router-dom";

const firestore = firebase.firestore();

export default function TimeViewPage(props) {
    const [profile, setProfile] = useState([]);
    const [auth, setAuth] = useState([]);
    const [timetrack, setTimetrack] = useState([]);
    const [clientView, setClientView] = useState(false);
    const [checked, setChecked] = useState(false);
    let userId = props.match.params.clientId;

    const [timetrackList, setTimetrackList] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setAuth(res.data());

                    if (userId !== res.data().id) {
                        setClientView(true);
                    } else {
                        setClientView(false);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getUserProfile(userId).then(
            async (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    const response = await axios.post(apiURL + "/api/get_time_records", { profile: res.id });
                    setTimetrackList(response.data);
                    setChecked(true);
                }
            },
            (err) => {
                console.log(err);
            }
        );

        const unsubscribe = firestore
            .collection("timetrack")
            .where("userUid", "==", userId)
            .where("status", "in", ["Approved", "Pending", "In Progress"])
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });

                list.sort((a, b) => (a.minDate < b.minDate ? 1 : -1));
                setTimetrack(list);
            });

        return () => unsubscribe();
    }, []);

    // console.log(auth.isAdmin === false || auth.isLineManager === false);

    return (
        <>
            {(clientView === true && (auth.isAdmin !== false || auth.isLineManager !== false)) || clientView === false ? (
                <div>
                    {profile && (
                        <div style={{ padding: "40px 0" }}>
                            {clientView === true && (
                                <Container>
                                    <Segment textAlign="center" style={{ marginBottom: "20px" }}>
                                        <h1>Staff Member: {profile.displayName ? profile.displayName : profile.email}</h1>
                                        <Link className="ui button" style={{ float: "right", marginTop: "-40px" }} as="a" to={"/home/" + profile.id}>
                                            Back to Dashboard
                                        </Link>
                                    </Segment>
                                </Container>
                            )}

                            <TimeDashboardStats auth={auth} profile={profile} profileClient={profile} userUid={userId} clientView={clientView} />
                        </div>
                    )}

                    <Container>
                        <h2>Previous Time Tracking Records</h2>
                        {/* <TimeTrackList timetrack={timetrack} profileClient={profile} /> */}
                        {checked && checked === true ? (
                            <>
                                <TimeTrackList timetrack={timetrackList} profileClient={profile} />{" "}
                            </>
                        ) : (
                            <div className="ui segment" style={{ height: 100 }}>
                                <div className="ui active inverted dimmer">
                                    <div className="ui text loader">Loading</div>
                                </div>
                                <p></p>
                            </div>
                        )}
                    </Container>
                </div>
            ) : (
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            )}
        </>
    );
}
