import React, { useState, useEffect } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getAuthUser, getCompanies, getProjectTimeTrackAction } from "../reportsAction";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function ReportTimeTrack(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [profile, setProfile] = useState([]);
    const [Row, setRow] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companyFilterId, setCompanyFilterId] = useState("0");

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());

                    getCompanies().then(
                        (resData) => {
                            let list = [];
                            resData.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                    list.push(appObj);
                                }
                            });

                            let companiesList = [
                                {
                                    key: "",
                                    text: "Choose a Company",
                                    value: "",
                                },
                            ];

                            Object.keys(list).forEach(function (key) {
                                if (
                                    res.data().isSuper === true ||
                                    (res.data().isAdmin === true && res.data().companyId == list[key].id) ||
                                    (res.data().isHR === true && res.data().companyId == list[key].id) ||
                                    (res.data().isLineManager === true && res.data().companyId == list[key].id)
                                ) {
                                    companiesList.push({
                                        key: list[key].id,
                                        text: list[key].companyName,
                                        value: list[key].id,
                                    });
                                }
                            });

                            setCompaniesList(companiesList);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );

        // getProjectTimeTrackAction(companyFilterId, startDate, endDate).then(
        //     (res) => {
        //         let list = res[0];
        //         list.sort((a, b) => (a.totalTime < b.totalTime ? 1 : -1));
        //         setRow(list);
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );
        getTimePerProject(companyFilterId, startDate, endDate);
    }, [companyFilterId, startDate, endDate]);

    async function getTimePerProject(companyFilterId, startDate, endDate) {
        console.log(companyFilterId);
        console.log(startDate);
        console.log(endDate);
        const response = await axios.post(apiURL + "/api/get_month_time", { company: companyFilterId, startDate: startDate, endDate: endDate });
        let ProjectNameTimeArr = [];
        console.log(response.data);
        response.data.forEach(async (document) => {
            let projNames = await firestore.collection("projects").where("id", "==", document.projectId).get();
            if (projNames.empty === false) {
                ProjectNameTimeArr.push({
                    projectName: projNames.docs[0].data().projectName,
                    totalTime: Number.isInteger(document.totalTime) ? document.totalTime : parseFloat(document.totalTime).toFixed(2),
                });
            }
        });

        const someTimeoutAction = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(ProjectNameTimeArr);
                }, 2000);
            });
        };

        const TimeData = await someTimeoutAction();
        setRow(TimeData);
    }

    function filterData(value) {
        setStartDate(new Date(value));
        setEndDate(new Date(new Date(value).setMonth(new Date(value).getMonth() + 1)));
    }
    function changeId(value) {
        setCompanyFilterId(value);
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Project Activity Report</h1>
                            {/* <p>
                                from {format(startDate, "dd MMMM yyyy")} to {format(endDate, "dd MMMM yyyy")}
                            </p> */}
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            {Row && Row.length > 0 && (
                                <ExcelFile element={<Button color="blue">Download</Button>}>
                                    <ExcelSheet data={Row} name="Leave">
                                        <ExcelColumn label="Project Name" value="projectName" />
                                        <ExcelColumn label="Total Approved Time" value="totalTime" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )}

                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={6}>
                                    <Grid.Column>
                                        <label>Choose a period</label>
                                        {/* <DatePicker
                                            name="startDate"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            dropdownMode="select"
                                            value={values.startDate}
                                            selected={startDate}
                                            onChange={(value) => {
                                                filterData(value);
                                            }}
                                            disabledKeyboardNavigation
                                        /> */}
                                        <input type="month" selected={startDate} onChange={(value) => filterData(value.target.value)} />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="companyId"
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            value={values.companyId}
                                            onChange={(field, value) => {
                                                changeId(value.value);
                                                setFieldValue("companyId", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            <table className="ui celled table selectable">
                <thead>
                    <tr style={{ fontSize: 11 }}>
                        <th>Project Name</th>
                        <th>Total Approved Time</th>
                    </tr>
                </thead>

                <tbody style={{ fontSize: 11 }}>
                    {Row &&
                        Row.length > 0 &&
                        Row.map((timeTrackRow) => (
                            <tr key={timeTrackRow.id}>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.projectName}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.totalTime}</strong>
                                    </span>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Container>
    );
}
