import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "semantic-ui-react";
import { Segment } from "semantic-ui-react";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function ActionsBlock(props) {
    const [notifications, setNotifications] = useState([]);
    const [actions, setActions] = useState([]);
    useEffect(() => {
        const unsubscribeNotifications = firestore
            .collection("notifications")
            .where("userUid", "==", firebase.auth().currentUser.uid)
            .where("read", "==", false)
            .where("notificationType", "==", "Notification")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setNotifications(list);
            });

        const unsubscribeActions = firestore
            .collection("notifications")
            .where("userUid", "==", firebase.auth().currentUser.uid)
            .where("read", "==", false)
            .where("notificationType", "==", "Action")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setActions(list);
            });

        return () => {
            unsubscribeActions();
            unsubscribeNotifications();
        };
    }, []);
    return (
        <Segment>
            <h2>Your Profile</h2>
            <p>Let’s see what’s in store for today.</p>

            <div className="img">
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <h3>Notifications</h3>
                            <p className="number greentxt">{notifications.length}</p>
                            <h3>Unseen</h3>
                        </Grid.Column>

                        <Grid.Column>
                            <h3>Actions</h3>
                            <p className="number redtxt">{actions.length}</p>
                            <h3>Required</h3>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Link as="a" to="/notifications">
                                <Button color="blue">GO TO ACTION CENTRE</Button>
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>

            <div className="center">
                <p>
                    View your notifications{" "}
                    <Link as="a" to="/notifications">
                        here.
                    </Link>
                    <br />
                    <Link as="a" to="/notifications">
                        Follow up on action required.
                    </Link>
                </p>
            </div>
        </Segment>
    );
}
