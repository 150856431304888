import React, { useState, useEffect } from "react";
import ManagerDashboardStats from "./ManagerDashboardStats";
import ManagerLeaveList from "../ManagerLeaveList/ManagerLeaveList";
import { getAuthUser, getClientLeave, getClientTime } from "../managerActions";
import { Button, Label, List, ListItem, Segment } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../app/layout/ErrorBoundary";
import axios from "axios";
import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
import apiURL from "../../../app/config/apiURL";

export default function ManagerDashboardPage(props) {
    const [profile, setProfile] = useState([]);
    const [leave, setLeave] = useState([]);
    const [time, setTime] = useState([]);
    const [timePending, setTimePending] = useState([]);
    const [loading, setLoading] = useState(false);
    const [leaveLoading, setLeaveLoading] = useState(false);
    const [remote, setRemote] = useState([]);
    const [remoteLoading, setRemoteLoading] = useState(false);

    const [allData, setAllData] = useState(false);

    const firestore = firebase.firestore();

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        getAuthUser().then(
            async (res) => {
                if (res.exists) {
                    setRemoteLoading(true);
                    setLoading(true);
                    setProfile({ ...res.data(), id: res.id });
                    let userInfo = { ...res.data(), id: res.id };
                    const response = await axios.post(apiURL + "/api/get_notification_time", { profile: { ...res.data(), id: res.id } });
                    let timeData = response.data;
                    setTimePending(timeData);
                    setLoading(false);

                    for (var i = 0; i < timeData.length; i++) {
                        timeData[i].createDate = timeData[i].created_at;
                    }

                    let remoteList = [];

                    await firestore
                        .collection("remoteWork")
                        .where("companyId", "==", userInfo.companyId)
                        .where("approvalUser", "==", userInfo.id ? userInfo.id : ["None"])
                        .where("status", "==", "Pending")
                        .onSnapshot((snapshot) => {
                            let list = [];
                            snapshot.docs.forEach((result) => {
                                if (result.exists) {
                                    let obj = { ...result.data(), id: result.id };
                                    list.push(obj);
                                }
                            });
                            remoteList.push(list);
                        });

                    await firestore
                        .collection("remoteWork")
                        .where("approvalType", "==", "Line Manager/s in Department")
                        .where("companyId", "==", userInfo.companyId)
                        .where("departmentId", "in", userInfo.departments.length > 0 ? userInfo.departments : ["None"])
                        .where("status", "==", "Pending")
                        .onSnapshot((snapshot) => {
                            let list = [];
                            snapshot.docs.forEach((result) => {
                                if (result.exists) {
                                    let obj = { ...result.data(), id: result.id };
                                    list.push(obj);
                                }
                            });
                            remoteList.push(list);
                        });

                    const someTimeoutAction2 = () => {
                        return new Promise((resolve) => {
                            setTimeout(function () {
                                resolve(remoteList.flat());
                            }, 2000);
                        });
                    };

                    const statusRemote = await someTimeoutAction2();
                    setRemote(statusRemote);

                    let leavedata = [];

                    await getClientLeave().then(
                        (res) => {
                            setLeaveLoading(true);
                            setLeave(res);
                            setLeaveLoading(false);
                            leavedata.push(res);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );

                    await merge(statusRemote, timeData, leavedata.flat());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getClientTime().then(
            (res) => {
                setTime(res);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    async function merge(statusRemote, timeData, leavedata) {
        let allRows = leavedata.concat(timeData, statusRemote);

        allRows.sort((a, b) => (b.createDate < a.createDate ? 1 : -1));

        setAllData(allRows);
        setRemoteLoading(false);
    }

    return (
        <>
            <div>
                {profile.isLineManager && (
                    <div>
                        {/* <ErrorBoundary>
                            <ManagerDashboardStats leave={leave} time={timePending} remote={remote} loading={loading} />{" "}
                        </ErrorBoundary> */}

                        <h2>Current Outstanding Requests</h2>

                        <Link as="a" to={`/timetracking/outstanding`} style={{ float: "right", marginTop: "-50px" }}>
                            <Button color="grey">OUTSTANDING TIME</Button>
                        </Link>

                        {allData && allData.length === 0 && <p>No outstanding requests pending</p>}

                        {remoteLoading === true ? (
                            <div className="ui segment" style={{ height: 100 }}>
                                <div className="ui active inverted dimmer">
                                    <div className="ui text loader">Loading</div>
                                </div>
                                <p></p>
                            </div>
                        ) : (
                            <>
                                {profile.companyId && allData && allData.length > 0 && (
                                    <List>
                                        {allData &&
                                            allData.length > 0 &&
                                            allData.map((data) => (
                                                <ListItem key={data.id}>
                                                    <Segment>
                                                        <div className="ui grid">
                                                            <div className="ten wide column">
                                                                <div className="header">
                                                                    {data.displayName} {data.leaveType && <span> - {data.leaveType}</span>}
                                                                </div>
                                                                {/* Time Tracking Date */}
                                                                {data.minDate && data.minDate !== "" ? format(new Date(data.minDate), "d MMMM yyyy") : ""}
                                                                {data.maxDate && data.maxDate !== "" ? <span> - {format(new Date(data.maxDate), "d MMMM yyyy")}</span> : ""}

                                                                {/* Leave Tracking Date */}
                                                                {data.annualFrom && data.annualFrom !== "" ? format(data.annualFrom.toDate(), "d MMMM yyyy") : ""}
                                                                {data.annualTo && data.annualTo !== "" ? <span> - {format(data.annualTo.toDate(), "d MMMM yyyy")}</span> : ""}
                                                                {data.fromTime && data.fromTime !== "" ? format(data.fromTime.toDate(), "d MMMM yyyy HH:ii") : ""}
                                                                {data.toTime && data.toTime !== "" ? <span> - {format(data.toTime.toDate(), "HH:ii")}</span> : ""}

                                                                {/* Remote Tracking Date */}
                                                                {data.dateFrom && data.dateFrom !== "" ? format(data.dateFrom.toDate(), "d MMMM yyyy") : ""}
                                                                {data.dateTo && data.dateTo !== "" ? <span> - {format(data.dateTo.toDate(), "d MMMM yyyy")}</span> : ""}
                                                            </div>

                                                            {((data.fromTime && data.toTime) || (data.annualFrom && data.annualFrom)) && (
                                                                <div className="six wide column">
                                                                    <Link as="a" to={`/staff/view/${data.id}`} style={{ float: "right" }}>
                                                                        <Button color="orange">VIEW REQUEST</Button>
                                                                    </Link>
                                                                    <Label
                                                                        color="blue"
                                                                        style={{ fontSize: 15, float: "right", marginRight: 10, borderRadius: "10px", margin: "0 .25em 0 0", padding: "10px" }}
                                                                    >
                                                                        LEAVE
                                                                    </Label>
                                                                    {data.negativeRequested === true && (
                                                                        <Label
                                                                            color="red"
                                                                            style={{ fontSize: 15, float: "right", marginRight: 10, borderRadius: "10px", margin: "0 .25em 0 0", padding: "10px" }}
                                                                        >
                                                                            NEGATIVE
                                                                        </Label>
                                                                    )}
                                                                </div>
                                                            )}

                                                            {data.minDate && data.maxDate && (
                                                                <div className="six wide column">
                                                                    <Link as="a" to={`/timetracking/view/${data.id}`} style={{ float: "right" }}>
                                                                        <Button color="orange">VIEW REQUEST</Button>
                                                                    </Link>
                                                                    <Label
                                                                        color="blue"
                                                                        style={{ fontSize: 15, float: "right", marginRight: 10, borderRadius: "10px", margin: "0 .25em 0 0", padding: "10px" }}
                                                                    >
                                                                        TIME
                                                                    </Label>
                                                                </div>
                                                            )}

                                                            {data.dateFrom && (
                                                                <div className="six wide column">
                                                                    <Link as="a" to={`/staff/home/${data.userUid}`} style={{ float: "right" }}>
                                                                        <Button color="orange">VIEW REQUEST</Button>
                                                                    </Link>
                                                                    <Label
                                                                        color="blue"
                                                                        style={{ fontSize: 15, float: "right", marginRight: 10, borderRadius: "10px", margin: "0 .25em 0 0", padding: "10px" }}
                                                                    >
                                                                        WORK FROM HOME
                                                                    </Label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Segment>
                                                </ListItem>
                                            ))}
                                    </List>
                                )}
                            </>
                        )}

                        {/* <div className="ui grid container stackable" style={{ padding: "40px 0" }}>
                            <div className="wide column">
                                {profile.companyId && leave && leave.length > 0 && (
                                    <>
                                        {leaveLoading === true ? (
                                            <div className="ui segment" style={{ height: 100 }}>
                                                <div className="ui active inverted dimmer">
                                                    <div className="ui text loader">Loading</div>
                                                </div>
                                                <p></p>
                                            </div>
                                        ) : (
                                            <ManagerLeaveList leave={leave} profile={profile} />
                                        )}
                                    </>
                                )}
                                <div className="eight wide column">
                                    {leave && leave.length == 0 && (
                                        <>
                                            <h2>Current Leave Requests</h2>

                                            <p>No leave requests pending</p>
                                        </>
                                    )}
                                </div>

                                {loading === true ? (
                                    <div className="ui segment" style={{ height: 100 }}>
                                        <div className="ui active inverted dimmer">
                                            <div className="ui text loader">Loading</div>
                                        </div>
                                        <p></p>
                                    </div>
                                ) : (
                                    <>
                                        {profile.companyId && timePending && timePending.length > 0 ? (
                                            <>
                                                <h2>Current Time Requests</h2>

                                                <List>
                                                    {timePending &&
                                                        timePending.length > 0 &&
                                                        timePending.map((time) => (
                                                            <ListItem key={time.id}>
                                                                <Segment>
                                                                    <div className="ui grid">
                                                                        <div className="ten wide column">
                                                                            <div className="header">{time.displayName}</div>
                                                                            {time.minDate && time.minDate !== "" ? format(new Date(time.minDate), "d MMMM yyyy") : ""} -{" "}
                                                                            {time.maxDate && time.maxDate !== "" ? format(new Date(time.maxDate), "d MMMM yyyy") : ""}
                                                                        </div>

                                                                        <div className="six wide column">
                                                                            <Link as="a" to={`/timetracking/view/${time.id}`}>
                                                                                <Button color="orange">VIEW REQUEST</Button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </Segment>
                                                            </ListItem>
                                                        ))}
                                                </List>
                                            </>
                                        ) : (
                                            <>
                                                <h2>Current Time Requests</h2>

                                                <p>No time requests pendin</p>
                                            </>
                                        )}
                                    </>
                                )}

                                {remoteLoading === true ? (
                                    <div className="ui segment" style={{ height: 100 }}>
                                        <div className="ui active inverted dimmer">
                                            <div className="ui text loader">Loading</div>
                                        </div>
                                        <p></p>
                                    </div>
                                ) : (
                                    <>
                                        {profile.companyId && remote && remote.length > 0 ? (
                                            <>
                                                <h2>Current Remote Working Requests</h2>

                                                <List>
                                                    {remote &&
                                                        remote.length > 0 &&
                                                        remote.map((work) => (
                                                            <ListItem key={work.id}>
                                                                <Segment>
                                                                    <div className="ui grid">
                                                                        <div className="ten wide column">
                                                                            <div className="header">{work.displayName}</div>
                                                                            {work.dateFrom && work.dateFrom !== "" ? format(new Date(work.dateFrom.toDate()), "d MMMM yyyy") : ""}
                                                                        </div>

                                                                        <div className="six wide column">
                                                                            <Link as="a" to={`/staff/home/${work.id}`}>
                                                                                <Button color="orange">VIEW REQUEST</Button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </Segment>
                                                            </ListItem>
                                                        ))}
                                                </List>
                                            </>
                                        ) : (
                                            <>
                                                <h2>Current Remote Working Requests</h2>

                                                <p>No remote requests pending</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </>
    );
}
