import React from "react";
import TimeTrackListItem from "./TimeTrackListItem";
import { List } from "semantic-ui-react";

export default function TimeTrackList(props) {
    const { timetrack, profileClient } = props;
    return (
        <div>
            <List>{timetrack && timetrack.length > 0 && timetrack.map((tt) => <TimeTrackListItem key={tt.id} timetrack={tt} profileClient={profileClient} />)}</List>
        </div>
    );
}
