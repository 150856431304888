import React from "react";
import { ListItem, Segment, Label, Grid } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";

export default function LeaveListItem({ leave }) {
    return (
        <ListItem>
            <Segment>
                <Link as="a" to={"/staff/view/" + leave.id}>
                    <Grid>
                        <Grid.Row columns={3} verticalAlign="middle">
                            <Grid.Column width={6}>
                                <div className="header">
                                    {leave.annualFrom && format(leave.annualFrom.toDate(), "d MMMM yyyy")}
                                    {leave.annualTo && " - " + format(leave.annualTo.toDate(), "d MMMM yyyy")}
                                    {leave.fromTime && format(leave.fromTime.toDate(), "d MMMM yyyy H:m")}
                                    {leave.toTime && " - " + format(leave.toTime.toDate(), "H:m")}
                                    {(leave.useAccumulative && leave.useAccumulative === "Yes") || (leave.useAccumulative2 && leave.useAccumulative2 === "Yes") ? " - (Accumulative)" : ""}
                                </div>
                                <div style={{ color: "black" }}>
                                    {leave.leaveType} Leave {leave.description ? " - " + leave.description : ""}
                                </div>
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <div style={{ color: "black" }}>
                                    {leave.annualDays === 1 || leave.annualDays === -1 ? leave.annualDays + " Day" : leave.annualDays !== 0 && leave.annualDays + " Days"}
                                    {leave.unpaidLeave === true ? " (Unpaid)" : ""}
                                    {leave.timeHours && (leave.timeHours === 1 ? leave.timeHours + " Hour" : leave.timeHours !== 0 && leave.timeHours + " Hours")}
                                    {leave.negativeRequested === true && (
                                        <>
                                            &nbsp;&nbsp;<Label color="red">Negative requested</Label>
                                        </>
                                    )}
                                </div>
                                {leave.addedBy !== leave.userUid && leave.addedByDisplayName && <div style={{ color: "black" }}>Added By: {leave.addedByDisplayName}</div>}
                            </Grid.Column>

                            <Grid.Column width={4} textAlign="right">
                                {leave.status === "Approved" && <Label color="green">{leave.status}</Label>}
                                {leave.status === "Denied" && <Label color="red">{leave.status}</Label>}
                                {(leave.status === "Pending" || leave.status === "Pending Change") && <Label color="orange">{leave.status}</Label>}
                                {leave.status === "Cancelled" && <Label color="grey">{leave.status}</Label>}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Link>
            </Segment>
        </ListItem>
    );
}
