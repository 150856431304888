import React, { useState, useEffect } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getAuthUser, getCompanies, getUsersAction } from "../reportsAction";
import firebase from "../../../app/config/firebase";

const firestore = firebase.firestore();

export default function LineManagerReport(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [profile, setProfile] = useState([]);
    const [leave, setLeave] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companyFilterId, setCompanyFilterId] = useState("0");

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());

                    getCompanies().then(
                        (resData) => {
                            let list = [];
                            resData.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    list.push(appObj);
                                }
                            });

                            let companiesList = [
                                {
                                    key: "",
                                    text: "Choose a Company",
                                    value: "",
                                },
                            ];

                            Object.keys(list).forEach(function (key) {
                                if (
                                    res.data().isSuper === true ||
                                    (res.data().isAdmin === true && res.data().companyId == list[key].id) ||
                                    (res.data().isHR === true && res.data().companyId == list[key].id) ||
                                    (res.data().isLineManager === true && res.data().companyId == list[key].id)
                                ) {
                                    companiesList.push({
                                        key: list[key].id,
                                        text: list[key].companyName,
                                        value: list[key].id,
                                    });
                                }
                            });

                            setCompaniesList(companiesList);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );

        getUsersAction(companyFilterId).then(
            async (res) => {
                setLeave([]);
                let list = [];
                res.forEach(async (doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };

                        if (appObj.departmentId) {
                            firestore
                                .collection("departments")
                                .doc(appObj.departmentId)
                                .get()
                                .then(
                                    (result) => {
                                        if (res.exists) {
                                            appObj.departmentName = result.data().departmentName;
                                        }
                                    },
                                    (err) => {
                                        console.log(err);
                                    }
                                );
                        }

                        if (appObj.approvalType == "Line Manager/s in Department") {
                            await firestore
                                .collection("users")
                                .where("isLineManager", "==", true)
                                .where("isTerminated", "==", false)
                                .where("departments", "array-contains", appObj.departmentId)
                                .get()
                                .then(
                                    (result) => {
                                        if (result.empty == false) {
                                            let managersNames = "";
                                            let i = 0;
                                            result.forEach((document) => {
                                                if (i !== 0) {
                                                    managersNames += " | ";
                                                }

                                                managersNames += document.data().displayName + " - " + document.data().email;

                                                i = i + 1;
                                            });
                                            appObj.lineManagers = managersNames;
                                        }
                                    },
                                    (err) => {
                                        console.log(err);
                                    }
                                );
                        } else {
                            if (appObj.approvalUser) {
                                await firestore
                                    .collection("users")
                                    .doc(appObj.approvalUser)
                                    .get()
                                    .then(
                                        (resultUser) => {
                                            if (resultUser.exists) {
                                                appObj.specificUser = resultUser.data().displayName + " - " + resultUser.data().email;
                                            }
                                        },
                                        (err) => {
                                            console.log(err);
                                        }
                                    );
                            }
                        }

                        if (appObj.approvalHRUser) {
                            await firestore
                                .collection("users")
                                .doc(appObj.approvalHRUser)
                                .get()
                                .then(
                                    (resultUser) => {
                                        if (resultUser.exists) {
                                            appObj.approvalHRUser = resultUser.data().displayName + " - " + resultUser.data().email;
                                        }
                                    },
                                    (err) => {
                                        console.log(err);
                                    }
                                );
                        }

                        const someTimeoutAction = () => {
                            return new Promise((resolve) => {
                                setTimeout(function () {
                                    resolve(appObj);
                                }, 3000);
                            });
                        };

                        const status = await someTimeoutAction();

                        list.push(status);
                    }
                });

                const someTimeoutAction2 = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve(list);
                        }, 4000);
                    });
                };

                const fullList = await someTimeoutAction2();

                setLeave(fullList);
            },
            (err) => {
                console.log(err);
            }
        );
    }, [companyFilterId]);

    function changeId(value) {
        setCompanyFilterId(value);
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Line Managers Per User</h1>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            {leave && leave.length > 0 && (
                                <ExcelFile element={<Button color="blue">Download</Button>}>
                                    <ExcelSheet data={leave} name="Leave">
                                        <ExcelColumn label="Display Name" value="displayName" />
                                        <ExcelColumn label="Email Address" value="email" />
                                        <ExcelColumn label="Department Name" value="departmentName" />
                                        <ExcelColumn label="Line Manager" value="lineManagers" />
                                        <ExcelColumn label="Specific User" value="specificUser" />
                                        <ExcelColumn label="HR Approval" value="approvalHRUser" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )}

                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={6}>
                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="companyId"
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            value={values.companyId}
                                            onChange={(field, value) => {
                                                changeId(value.value);
                                                setFieldValue("companyId", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            <table className="ui celled table selectable">
                <thead>
                    <tr style={{ fontSize: 11 }}>
                        <th>Display Name</th>
                        <th>Email Address</th>
                        <th>Department Name</th>
                        <th>Line Manager</th>
                        <th>Specific User</th>
                        <th>HR Approval</th>
                    </tr>
                </thead>

                <tbody style={{ fontSize: 11 }}>
                    {leave &&
                        leave.length > 0 &&
                        leave.map((leaveRow) => (
                            <tr key={leaveRow.id}>
                                <td>
                                    <span>
                                        <strong>{leaveRow.displayName}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.email}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.departmentName}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.lineManagers}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.specificUser}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.approvalHRUser}</strong>
                                    </span>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Container>
    );
}
