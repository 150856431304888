import React from "react";
import { useEffect } from "react";
import firebase from "../../../app/config/firebase";
import { useState } from "react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Button, Grid, Segment, Form, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyRadioButton from "../../../app/common/form/MyRadioButton";
import { add, format } from "date-fns";
import { openModal } from "../../../app/common/modals/modalReducer";
import { addDays } from "date-fns/esm";
import Swal from "sweetalert2";

const firestore = firebase.firestore();

export default function WorkHomeLanding() {
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    const [workDays, setWorkDays] = useState([]);
    const [profile, setPofile] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [errorsFrom, setErrorsFrom] = useState("");
    const [toDate, setToDate] = useState("");
    const [deleting, setDelete] = useState(false);

    const dispatch = useDispatch();

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        firestore
            .collection("users")
            .doc(userId)
            .onSnapshot((snapshot) => {
                setPofile({ ...snapshot.data(), id: snapshot.id });
            });

        const unsubscribe = firestore
            .collection("remoteWork")
            .where("userUid", "==", userId)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((result) => {
                    list.push({ ...result.data(), id: result.id });
                });

                setWorkDays(list);
            });

        return () => unsubscribe();
    }, []);

    async function deleteRemoteDay(day) {
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to delete this entry!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setDelete(true);
                await firestore.collection("remoteWork").doc(day.id).delete();
                setDelete(false);
            }
        });
    }

    return (
        <>
            <Button color="grey" as={Link} to={"/home/" + userId}>
                BACK TO DASHBOARD
            </Button>
            <Segment>
                <Formik
                    initialValues={{ dateFrom: "", dateTo: "", halfDay: "", reason: "" }}
                    enableReinitialize={true}
                    validate={(values) => {
                        const error = {};

                        if (!values.dateFrom) {
                            error.dateFrom = "required";
                            setErrorsFrom("Date is Required");
                        }

                        return error;
                    }}
                    onSubmit={async (values, { resetForm, setSubmitting }) => {
                        values.userUid = profile.id;
                        values.displayName = profile.displayName;
                        values.companyId = profile.companyId;
                        values.departmentId = profile.departmentId;
                        values.approvalType = profile.approvalType;
                        values.createDate = new Date();
                        values.status = "Pending";
                        let totalDays = 0;

                        if (profile.approvalUser && profile.approvalUser !== "") {
                            values.approvalUser = profile.approvalUser;
                        }

                        if (values.dateTo === "") {
                            totalDays = "1";
                            delete values.dateTo;
                        } else {
                            let from = values.dateFrom;
                            let to = values.dateTo;
                            let compare_dates = function (date1, date2) {
                                if (date1 > date2) return "<";
                                else if (date1 < date2) return ">";
                                else return "=";
                            };

                            let compared = compare_dates(new Date(format(new Date(from), "yyyy/MM/dd")), new Date(format(new Date(to), "yyyy/MM/dd")));

                            console.log(compared);

                            while (compared === ">" || compared === "=") {
                                if (format(from, "EEE") !== "Sat" && format(from, "EEE") !== "Sun") {
                                    totalDays++;
                                }

                                from = add(new Date(format(new Date(from), "yyyy"), format(new Date(from), "MM"), format(new Date(from), "dd")), {
                                    years: 0,
                                    months: -1,
                                    days: 1,
                                });
                                compared = compare_dates(new Date(format(new Date(from), "yyyy/MM/dd")), new Date(format(new Date(to), "yyyy/MM/dd")));
                            }

                            console.log(totalDays);
                        }

                        values.totalDays = totalDays;

                        setTimeout(async function () {
                            setFromDate("");
                            setToDate("");
                            await firestore.collection("remoteWork").add(values);
                            setSubmitting(false);
                            resetForm();
                        }, 1000);
                    }}
                >
                    {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Row columns={5}>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Date From:</label>
                                            <DatePicker
                                                name="dateFrom"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.fromDate}
                                                selected={fromDate}
                                                onChange={(value) => {
                                                    setFromDate(value);
                                                    setFieldValue("dateFrom", value);
                                                    setErrorsFrom("");
                                                }}
                                                disabledKeyboardNavigation
                                                inline
                                            />
                                            {errorsFrom && errorsFrom !== "" && <p style={{ color: "red", border: "1px solid red", padding: "5px", borderRadius: "10px" }}>{errorsFrom}</p>}
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Date To:</label>
                                            <DatePicker
                                                name="dateTo"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.dateTo}
                                                selected={toDate}
                                                onChange={(value) => {
                                                    setToDate(value);
                                                    setFieldValue("dateTo", value);
                                                }}
                                                disabledKeyboardNavigation
                                                inline
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Half Day</label>
                                            <div style={{ display: "inline-flex" }}>
                                                <MyRadioButton name="halfDay" type="radio" value="No" label="" labelcb="No" />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <MyRadioButton name="halfDay" type="radio" value="Yes" label="" labelcb="Yes" />
                                            </div>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Reason for working from home</label>
                                            <MyTextInput name="reason" placeholder="Reason for working from home" />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <br />
                                            <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" type="submit" color="blue">
                                                SUBMIT
                                            </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Segment>

            <br />
            <br />

            {workDays && workDays.length === 0 && (
                <Segment>
                    <p>No days loaded</p>
                </Segment>
            )}

            {workDays && workDays.length > 0 && (
                <>
                    {workDays.map((days) => (
                        <>
                            {console.log(days)}
                            <Segment>
                                <Grid key={days.id}>
                                    <Grid.Row columns={3} verticalAlign="middle">
                                        <Grid.Column width={6}>
                                            <div className="header">
                                                <b>{days.dateFrom && format(days.dateFrom.toDate(), "d MMMM yyyy")}</b>
                                                <b>{days.dateTo && " - " + format(days.dateTo.toDate(), "d MMMM yyyy")}</b>
                                                <br />
                                                {days.reason}
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <div style={{ color: "black" }}>
                                                Half Day: <b>{days.halfDay}</b>
                                                <br />
                                                Total Days: <b>{days.totalDays}</b>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={4} textAlign="right">
                                            {days.status === "Approved" && <Label color="green">{days.status}</Label>}
                                            {days.status === "Denied" && <Label color="red">{days.status}</Label>}
                                            {days.status === "Pending" && <Label color="orange">{days.status}</Label>}
                                            {currentUserProfile && currentUserProfile.isLineManager && days.status === "Pending" && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <Button onClick={() => dispatch(openModal({ modalType: "RemoteWorkModal", modalProps: { days: days } }))} color="blue">
                                                        APPROVE/DENY
                                                    </Button>
                                                </>
                                            )}
                                            {currentUserProfile && currentUserProfile.isLineManager && days.status !== "Pending" && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <Button onClick={() => dispatch(openModal({ modalType: "EditRemoteWorkModal", modalProps: { days: days } }))} color="yellow">
                                                        EDIT
                                                    </Button>
                                                    <Button onClick={() => deleteRemoteDay(days)} loading={deleting} color="red">
                                                        DELETE
                                                    </Button>
                                                </>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </>
                    ))}
                </>
            )}
        </>
    );
}
