import React from "react";
import { Segment, Header, Divider, Label, Button, Icon } from "semantic-ui-react";
import { updatePassword } from "../../auth/authActions";

import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function AccountPage(props) {
    let providerId = props.providerId;
    return (
        <Segment>
            <Header dividing size="large" content="Account" />
            {providerId && providerId === "password" && (
                <div>
                    <Header color="teal" sub content="Change password" />
                    <p>Use this form to update your account settings</p>
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validate={(values) => {
                            const error = {};
                            if (!values.newPassword1) {
                                error.newPassword1 = "Please enter a password";
                            }
                            if (!values.newPassword2) {
                                error.newPassword2 = "Please confirm your new password";
                            }
                            if (values.newPassword1 !== values.newPassword2) {
                                error.newPassword2 = "Passwords do not match";
                            }
                            return error;
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            await updatePassword(values);
                            setSubmitting(false);
                            window.location.replace("/settings/basic");
                        }}
                    >
                        {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <MyTextInput width={8} name="newPassword1" type="password" pointing="left" inline={true} basic={true} placeholder="New Password" />
                                <MyTextInput width={8} name="newPassword2" type="password" inline={true} basic={true} pointing="left" placeholder="Confirm Password" />
                                {error && (
                                    <Label basic color="red">
                                        {error}
                                    </Label>
                                )}
                                <Divider />
                                <Button disabled={isSubmitting} size="large" positive content="Update Password" />
                            </form>
                        )}
                    </Formik>
                </div>
            )}

            {providerId && providerId === "facebook.com" && (
                <div>
                    <Header color="teal" sub content="Facebook Account" />
                    <p>Please visit Facebook to update your account settings</p>
                    <Button type="button" color="facebook">
                        <Icon name="facebook" />
                        Go to Facebook
                    </Button>
                </div>
            )}

            {providerId && providerId === "google.com" && (
                <div>
                    <Header color="teal" sub content="Google Account" />
                    <p>Please visit Google to update your account settings</p>
                    <Button type="button" color="google plus">
                        <Icon name="google plus" />
                        Go to Google
                    </Button>
                </div>
            )}
        </Segment>
    );
}
