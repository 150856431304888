import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Divider, Form, Grid, GridRow, GridColumn, Segment } from "semantic-ui-react";
import { createProject, updateProject } from "../superActions";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { getProject, getAuthUser, getCompany, getCompanyUsers } from "../superActions";
import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
const firestore = firebase.firestore();

export default function ProjectsDetailedPage(props) {
    let projectId = props.match.params.id;
    const [profile, setProfile] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [companySelected, setCompanySelected] = useState("");
    const [usersSelected, setusersSelected] = useState([]);
    const [initialValues, setInitialValues] = useState({ companyId: "", projectName: "", users: [], projectPrefix: "" });
    const [userList, setUserList] = useState([{ key: "", text: "None", value: "" }]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe3 = firestore.collection("companies").onSnapshot((snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });

            let companyListData = [];
            companyListData = list.map(function (company) {
                return {
                    key: company.id,
                    text: company.companyName,
                    value: company.id,
                };
            });
            companyListData.sort((a, b) => (b.text < a.text ? 1 : -1));

            setCompanyList(companyListData);
        });

        if (projectId !== "0") {
            const unsubscribe = firestore
                .collection("projects")
                .doc(projectId)
                .onSnapshot(async (snapshot) => {
                    setInitialValues(snapshot.data());
                    setusersSelected(snapshot.data().users);

                    await firestore
                        .collection("users")
                        .where("companyId", "==", snapshot.data().companyId)
                        .where("isTerminated", "==", false)
                        .onSnapshot((snapshot) => {
                            let list = [];

                            snapshot.docs.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    list.push(appObj);
                                }
                            });

                            let userList = [];
                            userList = list.map(function (user) {
                                return {
                                    key: user.id,
                                    text: user.displayName ? user.displayName + " (" + user.email + ")" : user.email,
                                    value: user.id,
                                };
                            });

                            userList.sort((a, b) => (b.text < a.text ? 1 : -1));

                            setUserList(userList);
                        });
                });

            const unsubscribe2 = firestore
                .collection("projects")
                .doc(projectId)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists === true) {
                        let companyData = { ...snapshot.data(), id: snapshot.id };
                        setCompanySelected(companyData.companyName);
                    }
                });

            return () => {
                unsubscribe();
                unsubscribe2();
            };
        } else {
            // setInitialValues

            if (currentUserProfile.isAdmin && currentUserProfile.companyId) {
                setInitialValues({ companyId: currentUserProfile.companyId });

                firestore
                    .collection("users")
                    .where("companyId", "==", currentUserProfile.companyId)
                    .where("isTerminated", "==", false)
                    .onSnapshot((snapshot) => {
                        let list = [];

                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });

                        let userList = [];
                        userList = list.map(function (user) {
                            return {
                                key: user.id,
                                text: user.displayName ? user.displayName + " (" + user.email + ")" : user.email,
                                value: user.id,
                            };
                        });

                        userList.sort((a, b) => (b.text < a.text ? 1 : -1));

                        setUserList(userList);
                    });
            }
        }

        return () => unsubscribe3();
    }, []);

    const updateUser = useCallback(async (value) => {
        await firestore
            .collection("users")
            .where("companyId", "==", value)
            .where("isTerminated", "==", false)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let userList = [];
                userList = list.map(function (user) {
                    return {
                        key: user.id,
                        text: user.displayName ? user.displayName + " (" + user.email + ")" : user.email,
                        value: user.id,
                    };
                });

                userList.sort((a, b) => (b.text < a.text ? 1 : -1));

                setUserList(userList);
            });
    }, []);

    function getUsersList(value) {
        updateUser(value.value);
    }

    console.log(initialValues);

    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validate={(values) => {
                        const errors = {};

                        if (!values.users) {
                            errors.users = "Required";
                        }
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (projectId !== "0") {
                            values.id = projectId;
                            await updateProject(values);
                        } else {
                            createProject(values);
                        }

                        setSubmitting(false);
                        props.history.push("/super/projects/");
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <GridRow columns={2}>
                                    <GridColumn>
                                        <Form.Field>
                                            <label>Company:</label>
                                            <MySelectInput
                                                width={8}
                                                name="companyId"
                                                options={companyList}
                                                value={values.companyId || ""}
                                                onChange={(field, value) => {
                                                    getUsersList(value);
                                                    setCompanySelected(value.value);
                                                    setFieldValue("companyId", value.value);
                                                }}
                                                placeholder="Select your Company"
                                                disabled={currentUserProfile.isSuper === true ? false : true}
                                            />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Users</label>
                                            <MySelectInput
                                                width={8}
                                                name="users"
                                                options={userList}
                                                value={values.users || []}
                                                placeholder="Select your Users"
                                                onChange={(field, value) => {
                                                    setFieldValue("users", value.value);
                                                }}
                                                disabled={currentUserProfile.isSuper === true || currentUserProfile.isAdmin === true ? false : true}
                                                multiple={true}
                                            />
                                            {errors && errors.users ? (
                                                <>
                                                    <br />
                                                    <p style={{ color: "#842029", backgroundColor: "#f8d7da", borderColor: "#f5c2c7", textAlign: "center", width: "150px", height: "20px" }}>
                                                        Users is required
                                                    </p>
                                                </>
                                            ) : null}
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Project Date From</label>
                                            <input
                                                type="date"
                                                className="SpreadsheetGridInput"
                                                name="projectFromDate"
                                                value={values.projectFromDate}
                                                onChange={(value) => setFieldValue("projectFromDate", value.target.value)}
                                                style={{ width: "100%" }}
                                            />
                                        </Form.Field>
                                    </GridColumn>

                                    <GridColumn>
                                        <Form.Field>
                                            <label>Project Name</label>
                                            <MyTextInput name="projectName" type="text" placeholder="Project Name" />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Project Prefix</label>
                                            <MyTextInput name="projectPrefix" type="text" placeholder="Project Prefix" />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Project Date To</label>
                                            <input
                                                type="date"
                                                className="SpreadsheetGridInput"
                                                name="projectToDate"
                                                value={values.projectToDate}
                                                onChange={(value) => setFieldValue("projectToDate", value.target.value)}
                                                style={{ width: "100%" }}
                                            />
                                        </Form.Field>
                                    </GridColumn>
                                </GridRow>
                            </Grid>

                            <Divider />
                            <Button type="submit" disabled={!dirty || isSubmitting} loading={isSubmitting} size="large" positive content={projectId.length > 1 ? "Update Project" : "Create Project"} />
                            <Button size="large" as={Link} to={`/super/projects/`}>
                                Cancel
                            </Button>
                        </form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}
