import { toastr } from "react-redux-toastr";
import firebase from "../../app/config/firebase";
import { reset } from "redux-form";
import cuid from "cuid";
import format from "date-fns/format";
import { toast } from "react-toastify";
import addSeconds from "date-fns/addSeconds";
import addDays from "date-fns/addDays";
import { date } from "yup";
import { subMonths } from "date-fns/esm";
import axios from "axios";
import apiURL from "../../app/config/apiURL";

export async function leaveTrack(leave) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    leave.annualFrom = leave.annualFrom;
    leave.annualTo = leave.annualTo;
    // leave.userUid = user.uid;
    leave.createDate = leave.createDate ? leave.createDate : new Date(Date.now());
    leave.editDate = new Date(Date.now());

    try {
        //check if requested leave goes into negative for current requested month?

        if (leave.uploadFile) {
            const path = `${firebase.auth().currentUser.uid}/leave`;
            const options = {
                name: cuid() + "-" + leave.uploadFile.name,
            };
            const file = leave.uploadFile;

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            leave.fileName = leave.uploadFile.name;
            leave.fileUrl = uploadedFile;
            leave.documentNameRand = options.name;
            leave.uploadFile = "";
        }

        // console.log(leave);

        if (leave.uploadFileURL && leave.uploadFileURL !== undefined) {
            leave.fileUrl = leave.uploadFileURL;
        }

        let createdLeaveID = "";

        let companyData = await firestore.collection("companies").doc(leave.companyId).get();

        if (companyData.data().leaveMethodType !== undefined && companyData.data().leaveMethodType === "New Method") {
            if (leave.leaveType !== "Sick") {
                await firestore
                    .collection("leaveStatus")
                    .where("userUid", "==", leave.userUid)
                    .where("periodType", "==", "Leave")
                    .orderBy("fromDate", "desc")
                    .get()
                    .then(async function (leaveYear) {
                        if (leave.leaveType == "Annual") {
                            await firestore
                                .collection("leaveStatus")
                                .doc(leaveYear.docs[0].id)
                                .collection("Months")
                                .onSnapshot(async (snapshot) => {
                                    let allDocs = snapshot.docs;
                                    await Object.keys(allDocs).forEach(function (key) {
                                        if (new Date().getMonth() === new Date(allDocs[key].data().datePeriod.toDate()).getMonth()) {
                                            if (leave.useAccumulative === "Yes" || leave.useAccumulative2 === "Yes") {
                                                if (
                                                    leave.useAccumulative === "Yes" &&
                                                    parseFloat(allDocs[key].data().accumTotal) - parseFloat(allDocs[key].data().accumUsed) - parseFloat(leave.annualDays) < 0
                                                ) {
                                                    leave.negativeRequested = true;
                                                }

                                                if (
                                                    leave.useAccumulative2 === "Yes" &&
                                                    parseFloat(allDocs[key].data().accumTotal2) - parseFloat(allDocs[key].data().accumUsed2) - parseFloat(leave.annualDays) < 0
                                                ) {
                                                    leave.negativeRequested = true;
                                                }
                                            } else {
                                                if (parseFloat(allDocs[key].data().annualLeft) - parseFloat(leave.annualDays) < 0) {
                                                    leave.negativeRequested = true;
                                                }
                                            }
                                        }
                                    });
                                });
                        } else {
                            if (leave.leaveType == "Study" && parseFloat(leaveYear.docs[0].data().studyLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "Family" && parseFloat(leaveYear.docs[0].data().familyLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "Maternity" && parseFloat(leaveYear.docs[0].data().maternityLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "Paternity" && parseFloat(leaveYear.docs[0].data().paternityLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "IOD" && parseFloat(leaveYear.docs[0].data().IODLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "Parental" && parseFloat(leaveYear.docs[0].data().parentalLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "Moving" && parseFloat(leaveYear.docs[0].data().movingLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "CSR" && parseFloat(leaveYear.docs[0].data().csrLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }

                            if (leave.leaveType == "Religious" && parseFloat(leaveYear.docs[0].data().religiousLeft) - parseFloat(leave.annualDays) < 0) {
                                leave.negativeRequested = true;
                            }
                        }
                    });
            } else {
                await firestore
                    .collection("leaveStatus")
                    .where("userUid", "==", leave.userUid)
                    .where("periodType", "==", "Sick")
                    .orderBy("fromDate", "desc")
                    .get()
                    .then(async function (leaveYear) {
                        if (parseFloat(leaveYear.docs[0].data().sickLeft) - parseFloat(leave.annualDays) < 0) {
                            leave.negativeRequested = true;
                        }
                    });
            }
        } else {
            let userData = await firestore.collection("users").doc(leave.userUid).get();
            let user = userData.data();
            let nonGrowingYear = new Date().getFullYear();
            let MonthData = new Date().getMonth() + 1;

            if (leave.leaveType == "Annual") {
                if (leave.useAccumulative === "Yes" || leave.useAccumulative2 === "Yes") {
                    if (
                        leave.useAccumulative == "Yes" &&
                        user &&
                        user["leaveMonths"] &&
                        user["leaveMonths"][nonGrowingYear] &&
                        user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                        user["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"]
                    ) {
                        if (
                            parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"]) -
                            parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed"]) -
                            parseFloat(leave.annualDays) <
                            0
                        ) {
                            leave.negativeRequested = true;
                        }
                    }

                    if (
                        leave.useAccumulative2 == "Yes" &&
                        user &&
                        user["leaveMonths"] &&
                        user["leaveMonths"][nonGrowingYear] &&
                        user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                        user["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"]
                    ) {
                        if (
                            parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"]) -
                            parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed2"]) -
                            parseFloat(leave.annualDays) <
                            0
                        ) {
                            leave.negativeRequested = true;
                        }
                    }
                } else {
                    if (user && user["leaveMonths"] && user["leaveMonths"][nonGrowingYear] && user["leaveMonths"][nonGrowingYear][MonthData]) {
                        if (parseFloat(user["leaveMonths"][nonGrowingYear][MonthData]["left"]) - parseFloat(leave.annualDays) < 0) {
                            leave.negativeRequested = true;
                        }
                    }
                }
            }

            if (
                leave.leaveType == "Study" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType === "Family" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "Maternity" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "Paternity" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "IOD" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "Parental" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "Moving" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "CSR" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (
                leave.leaveType == "Religious" &&
                user &&
                user["leaveMonths"] &&
                user["leaveMonths"][nonGrowingYear] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                user["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"]
            ) {
                if (
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"]) -
                    parseFloat(user["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousUsed"]) -
                    parseFloat(leave.annualDays) <
                    0
                ) {
                    leave.negativeRequested = true;
                }
            }

            if (leave.leaveType == "Sick" && user && user["leaveMonths"] && user["leaveMonths"]["sick"]) {
                if (parseFloat(user["leaveMonths"]["sick"]["sickTotal"]) - parseFloat(user["leaveMonths"]["sick"]["sickUsed"]) - parseFloat(leave.annualDays) < 0) {
                    leave.negativeRequested = true;
                }
            }
        }

        let userData = await firestore.collection("users").doc(leave.userUid).get();
        let user = userData.data();

        leave.specificApprovalHRUser = user.approvalHRUser;

        if (leave.specificApprovalHRUser === undefined) {
            delete leave.specificApprovalHRUser;
        }

        const someTimeoutAction = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(leave);
                }, 1000);
            });
        };

        const status = await someTimeoutAction();

        if (status.actionType == "add") {
            status.status = "Pending";
            let createdLeave = await firestore.collection("leave").add(status);
            createdLeaveID = createdLeave.id;
        } else {
            let createdLeave = await firestore.collection("leave").doc(status.editLeaveId).update(status);
            createdLeaveID = status.editLeaveId;
        }

        let notificationWording = "Leave requires approval";

        if (status.negativeRequested !== undefined && status.negativeRequested == true) {
            // notificationWording = "Negative Leave requires approval from HR";
            notificationWording = "Negative Leave requires pre - approval and escalation to HR";
        }

        if (status.approvalType === "Specific User") {
            //Action Center
            await firestore
                .collection("users")
                .where("companyId", "==", status.companyId)
                .where("id", "==", status.approvalUser)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        if (doc.exists) {
                            await firestore.collection("notifications").add({
                                createDate: new Date(Date.now()),
                                notificationType: "Action",
                                rowModule: "Leave",
                                rowType: status.leaveType,
                                rowId: createdLeaveID,
                                description: notificationWording,
                                read: false,
                                userUid: doc.data().id,
                                displayName: status.displayName,
                            });
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error getting line manager email: ", error);
                });
        } else {
            //Action Center
            await firestore
                .collection("users")
                .where("companyId", "==", status.companyId)
                .where("departments", "array-contains", status.departmentId)
                .where("isLineManager", "==", true)
                .where("isTerminated", "==", false)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        if (doc.exists) {
                            await firestore.collection("notifications").add({
                                createDate: new Date(Date.now()),
                                notificationType: "Action",
                                rowModule: "Leave",
                                rowType: status.leaveType,
                                rowId: createdLeaveID,
                                description: notificationWording,
                                read: false,
                                userUid: doc.data().id,
                                displayName: status.displayName,
                            });
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error getting line manager email: ", error);
                });
        }

        // if (status.negativeRequested !== undefined && status.negativeRequested === true) {
        //     let userData = await firestore.collection("users").doc(leave.userUid).get();
        //     let user = userData.data();

        //     if (user.approvalHRUser !== undefined) {
        //         await firestore.collection("notifications").add({
        //             createDate: new Date(Date.now()),
        //             notificationType: "Action",
        //             rowModule: "Leave",
        //             rowType: status.leaveType,
        //             rowId: createdLeaveID,
        //             description: notificationWording,
        //             read: false,
        //             userUid: user.approvalHRUser,
        //             displayName: status.displayName,
        //         });
        //     }
        // }

        toast.success("Leave has been requested");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function leaveTrackSpecial(leave) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    leave.fromTime = leave.fromTime;
    leave.toTime = leave.toTime;
    leave.userUid = user.uid;
    leave.status = "Pending";
    leave.createDate = leave.createDate ? leave.createDate : new Date(Date.now());
    leave.editDate = new Date(Date.now());

    try {
        if (leave.uploadFile) {
            const path = `${user.uid}/leave`;
            const options = {
                name: cuid() + "-" + leave.uploadFile.name,
            };
            const file = leave.uploadFile;

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            leave.fileName = leave.uploadFile.name;
            leave.fileUrl = uploadedFile;
            leave.documentNameRand = options.name;
            leave.uploadFile = "";
        }

        let createdLeave = await firestore.collection("leave").add(leave);

        //Action Center
        await firestore
            .collection("users")
            .where("companyId", "==", leave.companyId)
            .where("departments", "array-contains", leave.departmentId)
            .where("isLineManager", "==", true)
            .where("isTerminated", "==", false)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    if (doc.exists) {
                        await firestore.collection("notifications").add({
                            createDate: new Date(Date.now()),
                            notificationType: "Action",
                            rowModule: "Leave",
                            rowType: leave.leaveType,
                            rowId: createdLeave.id,
                            description: "Leave requires approval",
                            read: false,
                            userUid: doc.data().id,
                            displayName: leave.displayName,
                        });
                    }
                });
            })
            .catch(function (error) {
                console.log("Error getting line manager email: ", error);
            });

        toast.success("Leave has been requested");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function timeTrack(timetrack) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    if (timetrack.submitType == "edit") {
        timetrack.userUid = timetrack.editUserId;
        timetrack.companyId = timetrack.companyId;
        timetrack.departmentId = timetrack.departmentId;
        timetrack.displayName = timetrack.displayName;
    } else {
        if (timetrack.clientView == true) {
            timetrack.userUid = timetrack.timetrackUser;
            timetrack.adminUser = user.uid;
            timetrack.submittedBy = "Admin";
        } else {
            timetrack.userUid = user.uid;
        }
    }

    delete timetrack.clientView;

    if (timetrack.submitted_by !== undefined) {
        //
    }

    try {
        if (timetrack.timeId === "0") {
            timetrack.createDate = new Date(Date.now());
            delete timetrack.timeId;
            let createdTime = await firestore.collection("timetrack").add(timetrack);
            // toast.success("Time has been saved!");
            return createdTime.id;
        } else {
            await firestore.collection("timetrack").doc(timetrack.timeId).set(timetrack);
            // toast.success("Time has been saved!");
            return timetrack.timeId;
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function timeTrackRow(timetrackrow) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    try {
        if (timetrackrow.submitType == "edit") {
            timetrackrow.userUid = timetrackrow.editUserId;
            timetrackrow.status = "Pending";
        } else {
            if (timetrackrow.clientView == true) {
                timetrackrow.userUid = timetrackrow.timetrackUser;
                timetrackrow.adminUser = user.uid;
                timetrackrow.submittedBy = "Admin";
                timetrackrow.status = "Pending";
            } else {
                timetrackrow.userUid = user.uid;
                timetrackrow.status = "In Progress";
            }
        }

        if (timetrackrow.hoursRequired === undefined) {
            timetrackrow.hoursRequired = "0";
        }

        delete timetrackrow.clientView;

        // console.log(timetrackrow.rowId);
        if (timetrackrow.rowId === "0") {
            timetrackrow.createDate = new Date(Date.now());
            timetrackrow.day = timetrackrow.day;
            delete timetrackrow.rowId;

            await firestore.collection("timetrackRow").add(timetrackrow);
        } else {
            let RowId = timetrackrow.rowId;
            delete timetrackrow.rowId;
            await firestore.collection("timetrackRow").doc(RowId).update(timetrackrow);
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function removeDeleted(rowIds) {
    const firestore = firebase.firestore();

    Object.keys(rowIds).forEach(async function (key) {
        await firestore.collection("timetrackRow").doc(rowIds[key]).delete();
    });
}

export async function timeTrackClear(values) {
    const firestore = firebase.firestore();
    let userId = firebase.auth().currentUser.uid;

    if (values.clientView == true) {
        userId = values.timetrackUser;
    }

    try {
        //Remove existing In Progress Time Tracking
        let timeRef = firestore.collection("timetrack").where("userUid", "==", userId).where("status", "==", "In Progress");
        let timeSnap = await timeRef.get();

        for (let i = 0; i < timeSnap.docs.length; i++) {
            let id = timeSnap.docs[i].id;

            let timerowRef = firestore.collection("timetrackRow").where("timeId", "==", id);
            let timerowSnap = await timerowRef.get();

            for (let i = 0; i < timerowSnap.docs.length; i++) {
                let idRow = timerowSnap.docs[i].id;

                await firestore.collection("timetrackRow").doc(idRow).delete();
            }

            await firestore.collection("timetrack").doc(id).delete();
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function timeTrackRemove(id) {
    const firestore = firebase.firestore();
    await firestore.collection("timetrack").doc(id).delete();
}

export async function leaveManualTrack(leave) {
    const firestore = firebase.firestore();

    leave.annualFrom = leave.annualFrom;
    leave.annualTo = leave.annualTo;
    leave.createDate = leave.createDate ? leave.createDate : new Date(Date.now());
    leave.editDate = new Date(Date.now());

    console.log(leave.annualFrom);
    console.log(leave.annualTo);
    console.log(leave.companyId);
    console.log(leave.departmentId);

    try {
        await firestore
            .collection("users")
            .where("companyId", "==", leave.companyId)
            .where("departmentId", "==", leave.departmentId)
            .where("isLineManager", "==", true)
            .where("isTerminated", "==", false)
            .limit(1)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    if (doc.exists) {
                        leave.managerEmail = doc.data().email;
                    }
                });
            })
            .catch(function (error) {
                console.log("Error getting line manager email: ", error);
            });

        await firestore.collection("leave").add(leave);
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function leaveChange(leave) {
    const firestore = firebase.firestore();

    // let updatedLeave = {
    //     status: "Pending",
    //     changeReason: leave.changeReason,
    // };

    let updatedLeave = {
        status: "Cancelled",
        changeReason: leave.changeReason,
        description: "Dates Changed",
    };

    console.log(leave);

    try {
        await firestore.collection("leave").doc(leave.id).update(updatedLeave);

        //Action Center
        await firestore
            .collection("users")
            .where("companyId", "==", leave.companyId)
            .where("departments", "array-contains", leave.departmentId)
            .where("isLineManager", "==", true)
            .where("isTerminated", "==", false)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    if (doc.exists) {
                        await firestore.collection("notifications").add({
                            createDate: new Date(Date.now()),
                            notificationType: "Action",
                            rowModule: "Leave",
                            rowType: leave.leaveType,
                            rowId: leave.id,
                            description: "Leave change requested",
                            read: false,
                            userUid: doc.data().id,
                            displayName: leave.displayName,
                        });
                    }
                });
            })
            .catch(function (error) {
                console.log("Error getting line manager email: ", error);
            });

        toast.success("Leave Change has been requested");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function leaveComment(comment, leaveId) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;

    let userQuery = await firestore.collection("users").doc(auth.uid).get();
    let userData = userQuery.data();

    comment.userUid = auth.uid;
    comment.createDate = new Date(Date.now());
    comment.displayName = userData.displayName;

    try {
        await firestore.collection("leave").doc(leaveId).collection("comments").add(comment);
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function timeComment(comment, timeId) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;

    let userQuery = await firestore.collection("users").doc(auth.uid).get();
    let userData = userQuery.data();

    comment.userUid = auth.uid;
    comment.createDate = new Date(Date.now());
    comment.displayName = userData.displayName;

    try {
        await firestore.collection("timetrack").doc(timeId).collection("comments").add(comment);
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getInProgressTime(userId) {
    const firestore = firebase.firestore();

    if (userId == "landing" || userId == "home") {
        userId = firebase.auth().currentUser.uid;
    }

    try {
        let timeIdList = [];

        // let test = firestore.collection("timetrack").where("userUid", "==", userId).where("status", "==", "In Progress").get();

        await firestore
            .collection("timetrack")
            .where("userUid", "==", userId)
            .where("status", "==", "In Progress")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    if (doc.exists) {
                        timeIdList.push(doc.id);
                    }
                });
            })
            .catch(function (error) {
                console.log("Error getting timetrack: ", error);
            });

        if (timeIdList && timeIdList.length > 0) {
            let timetrack_loop = [];

            await firestore
                .collection("timetrackRow")
                .where("userUid", "==", userId)
                .where("timeId", "in", timeIdList)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            timetrack_loop.push({ ...doc.data(), id: doc.id });
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error getting time track rows: ", error);
                });

            //Now run through and create an array of data formatted for form
            let timetrack_initial = {};

            //First create a unique day array
            let days = [];
            let days2 = [];
            Object.keys(timetrack_loop).forEach(function (key) {
                if (!days[timetrack_loop[key].day]) {
                    days[timetrack_loop[key].day] = timetrack_loop[key].day;
                    days2.push(timetrack_loop[key].day);
                }
            });

            //Sort by date
            days2.sort();

            //Make a copy of the timetrack data
            let loop = timetrack_loop;

            //Now loop over the days array and only add initial time tracking per day
            var arrayLength = days2.length;
            for (var i = 0; i < arrayLength; i++) {
                let tt = {};
                tt.projects = {};

                Object.keys(loop).forEach(function (key2) {
                    let dt1 = format(days2[i].toDate(), "yyyy-MM-dd");
                    let dt2 = format(loop[key2].day.toDate(), "yyyy-MM-dd");

                    if (dt1 === dt2) {
                        let proj_name = loop[key2].projectId + "|" + loop[key2].projectName;

                        tt.projects[proj_name] = loop[key2].timeWorked + "|" + loop[key2].id;

                        if (loop[key2].comments) {
                            tt.comments = loop[key2].comments;
                        }

                        tt.day = loop[key2].day.toDate();
                        tt.hoursWorked = 0;
                        tt.hoursRequired = loop[key2].hoursRequired;
                        tt.timeId = loop[key2].timeId;
                    }
                });

                timetrack_initial[i] = tt;
            }

            let timetrack_initial2 = {};
            Object.assign(timetrack_initial2, timetrack_initial);

            return timetrack_initial2;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getTimeId(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("timetrack").where("userUid", "==", userId).where("status", "==", "In Progress").get();
}

export async function timeEntry(values) {
    const firestore = firebase.firestore();

    console.log(values);

    try {
        await firestore.collection("timeEntry").add(values);
        toast.success("Time saved");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getUserTime(userIdData) {
    const firestore = firebase.firestore();
    let user = {};
    try {
        await firestore
            .collection("timetrack")
            .where("userUid", "==", userIdData)
            .where("status", "==", "In Progress")
            .get()
            .then(
                (res) => {
                    if (res.docs.length > 0) {
                        let appObj = { ...res.docs[0].data(), id: res.docs[0].id };
                        user = appObj;
                    }
                },
                (err) => {
                    console.log(err);
                }
            );

        let userId = user.userUid;
        let timetrack_loop = [];

        if (user.hasOwnProperty("id")) {
            await firestore
                .collection("timetrackRow")
                .where("userUid", "==", userId)
                .where("timeId", "==", user.id)
                .get()
                .then(
                    (res) => {
                        let list = [];
                        res.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                list.push(appObj);
                            }
                        });
                        timetrack_loop = list;
                    },
                    (err) => {
                        console.log(err);
                    }
                );

            //Now run through and create an array of data formatted for form
            let timetrack_initial = {};

            //First create a unique day array
            let days = [];
            let days2 = [];
            Object.keys(timetrack_loop).forEach(function (key) {
                if (!days[timetrack_loop[key].day]) {
                    days[timetrack_loop[key].day] = timetrack_loop[key].day;
                    days2.push(timetrack_loop[key].day);
                }
            });

            //Sort by date
            days2.sort();

            //Make a copy of the timetrack data
            let loop = timetrack_loop;

            //Now loop over the days array and only add initial time tracking per day
            var arrayLength = days2.length;
            for (var i = 0; i < arrayLength; i++) {
                let tt = {};
                tt.projects = {};

                Object.keys(loop).forEach(function (key2) {
                    let dt1 = format(days2[i].toDate(), "yyyy-MM-dd");
                    let dt2 = format(loop[key2].day.toDate(), "yyyy-MM-dd");

                    if (dt1 === dt2) {
                        let proj_name = loop[key2].projectId + "|" + loop[key2].projectName;

                        tt.projects[proj_name] = loop[key2].timeWorked + "|" + loop[key2].id;
                        tt.userUid = user.userUid;
                        tt.companyId = user.companyId;
                        tt.displayName = user.displayName;
                        tt.departmentId = user.departmentId;

                        if (loop[key2].comments) {
                            tt.comments = loop[key2].comments;
                        }

                        tt.day = loop[key2].day.toDate();
                        tt.hoursWorked = 0;
                        tt.hoursRequired = loop[key2].hoursRequired;
                        tt.timeId = loop[key2].timeId;
                    }
                });

                timetrack_initial[i] = tt;
            }

            const timetrack_initial2 = Object.values(timetrack_initial);

            return timetrack_initial2;
        } else {
            return [];
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getThisTime(timeId) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let user = [];

    try {
        await firestore
            .collection("timetrack")
            .doc(timeId)
            .get()
            .then(
                (res) => {
                    if (res.exists) {
                        let appObj = { ...res.data(), id: res.id };
                        user = appObj;
                    }
                },
                (err) => {
                    console.log(err);
                }
            );

        let userId = user.userUid;
        let timetrack_loop = [];

        console.log(userId);

        await firestore
            .collection("timetrackRow")
            .where("userUid", "==", userId)
            .where("timeId", "==", timeId)
            .get()
            .then(
                (res) => {
                    let list = [];
                    res.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                            list.push(appObj);
                        }
                    });
                    timetrack_loop = list;
                },
                (err) => {
                    console.log(err);
                }
            );

        //Now run through and create an array of data formatted for form
        let timetrack_initial = {};

        //First create a unique day array
        let days = [];
        let days2 = [];
        Object.keys(timetrack_loop).forEach(function (key) {
            if (!days[timetrack_loop[key].day]) {
                days[timetrack_loop[key].day] = timetrack_loop[key].day;
                days2.push(timetrack_loop[key].day);
            }
        });

        //Sort by date
        days2.sort();

        //Make a copy of the timetrack data
        let loop = timetrack_loop;

        //Now loop over the days array and only add initial time tracking per day
        var arrayLength = days2.length;
        for (var i = 0; i < arrayLength; i++) {
            let tt = {};
            tt.projects = {};

            Object.keys(loop).forEach(function (key2) {
                let dt1 = format(days2[i].toDate(), "yyyy-MM-dd");
                let dt2 = format(loop[key2].day.toDate(), "yyyy-MM-dd");

                if (dt1 === dt2) {
                    let proj_name = loop[key2].projectId + "|" + loop[key2].projectName;

                    tt.projects[proj_name] = loop[key2].timeWorked + "|" + loop[key2].id;
                    tt.userUid = user.userUid;
                    tt.companyId = user.companyId;
                    tt.displayName = user.displayName;
                    tt.departmentId = user.departmentId;

                    if (loop[key2].comments) {
                        tt.comments = loop[key2].comments;
                    }

                    tt.day = loop[key2].day.toDate();
                    tt.hoursWorked = 0;
                    tt.hoursRequired = loop[key2].hoursRequired;
                    tt.timeId = loop[key2].timeId;
                }
            });

            timetrack_initial[i] = tt;
        }

        const timetrack_initial2 = Object.values(timetrack_initial);

        return timetrack_initial2;
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function timeTrackDelete(timeId) {
    const firestore = firebase.firestore();
    const user = firestore.auth().currentUser;

    try {
        let timeRef = firestore.collection("timetrack").where("userUid", "==", user.uid).where("status", "==", "In Progress");
        let timeSnap = await timeRef.get();

        await firestore.collection("timetrack").doc(timeId).delete();
    } catch (error) {
        console.log(error);
        toastr.error("Oops", "Something went wrong");
    }
}

export async function getBlockedDates() {
    const firestore = firebase.firestore();
    let allBlocked = [];
    await firestore
        .collection("blockedDates")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        allBlocked.push(addSeconds(new Date(0), doc.data().blockDate.seconds));
                    }
                });
            },
            (err) => {
                console.log(err);
            }
        );
    return allBlocked;
}

export async function getProjects(userId) {
    const firestore = firebase.firestore();
    if (userId == "landing" || userId == "home") {
        userId = firebase.auth().currentUser.uid;
    }

    let userInfo = [];
    await firestore
        .collection("timetrack")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), ["id"]: `${res.id}` };
                    userInfo.push(appObj);
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo[0].userUid !== undefined) {
        userId = userInfo[0].userUid;
    }

    return firestore.collection("projects").where("users", "array-contains", userId).get();
}

export async function getProjectsUser(userId) {
    const firestore = firebase.firestore();

    if (userId == firebase.auth().currentUser.uid) {
        userId = firebase.auth().currentUser.uid;
    } else {
        userId = userId;
    }

    let userInfo = [];
    await firestore
        .collection("timetrack")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), ["id"]: `${res.id}` };
                    userInfo.push(appObj);
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo[0] !== undefined) {
        userId = userInfo[0].userUid;
    }

    return firestore.collection("projects").where("users", "array-contains", userId).get();
}

export async function getTimeLists(userUid) {
    const firestore = firebase.firestore();
    let fromDateFilter = new Date(format(new Date(), "yyyy-MM-01 00:00"));
    return firestore.collection("timetrackRow").where("userUid", "==", userUid).where("status", "==", "Approved").where("day", ">=", fromDateFilter).get();
}

export async function getTimeCurrent(id) {
    const firestore = firebase.firestore();
    return firestore.collection("timetrack").doc(id).get();
}
export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export const getProfile = (userId) => {
    if (userId == "home") {
        const auth = firebase.auth().currentUser;
        userId = auth.uid;
    }
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userId).get();
};

export async function getProfileData(userUid) {
    const firestore = firebase.firestore();

    let allData = [];

    await firestore
        .collection("users")
        .doc(userUid)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    allData.push(appObj);
                }
            },
            (err) => {
                console.log(err);
            }
        );

    await firestore
        .collection("blockedDates")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        list.push(doc.data().blockDate.toDate());
                    }
                });

                allData.push(list);
            },
            (err) => {
                console.log(err);
            }
        );
    await firestore
        .collection("companies")
        .doc(allData[0].companyId)
        .get()
        .then(
            (result) => {
                if (result.exists) {
                    let appObj = { ...result.data(), id: result.id };
                    allData.push(appObj);
                }
            },
            (err) => {
                console.log(err);
            }
        );

    await firestore
        .collection("projects")
        .where("users", "array-contains", allData[0].id)
        .get()
        .then((result3) => {
            let projectData = result3.docs;
            let list = [];
            projectData.forEach((docData) => {
                if (docData.exists) {
                    list.push({ projectName: docData.data().projectName, projectId: docData.id });
                }
            });
            allData.push(list);
        });

    return allData;
}

export const getTimeTrackRow = (timeId) => {
    const firestore = firebase.firestore();
    return firestore.collection("timetrackRow").where("timeId", "==", timeId).get();
};

export async function getUserProjects(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("projects").where("users", "array-contains", userId).get();
}

export async function getComments(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("timetrack").doc(userId).collection("comments").orderBy("createDate", "desc").get();
}

export async function getAllProject() {
    const firestore = firebase.firestore();
    return firestore.collection("projects").orderBy("companyId", "asc").get();
}

export async function getAllUsers() {
    const firestore = firebase.firestore();
    return firestore.collection("users").where("isTerminated", "==", false).get();
}

export async function getTimeTrackRowList(userChosen, fromDate, toDate, projectChosen) {
    const firestore = firebase.firestore();
    if (fromDate == "") {
        fromDate = "None";
    }
    if (toDate == "") {
        toDate = "None";
    }
    if (userChosen == "None") {
        userChosen = firebase.auth().currentUser.uid;
    }

    if (projectChosen !== "0") {
        return firestore
            .collection("timetrackRow")
            .where("status", "==", "Approved")
            .where("projectId", "==", projectChosen)
            .where("userUid", "==", userChosen)
            .where("day", ">=", fromDate)
            .where("day", "<=", toDate)
            .get();
    } else {
        return firestore.collection("timetrackRow").where("status", "==", "Approved").where("userUid", "==", userChosen).where("day", ">=", fromDate).where("day", "<=", toDate).get();
    }
}

export async function getLeaveComments(id) {
    const firestore = firebase.firestore();
    return firestore.collection("leave").doc(id).collection("comments").orderBy("createDate", "desc").get();
}

export async function getCompany(companyId) {
    const firestore = firebase.firestore();
    return firestore.collection("companies").doc(companyId).get();
}

export async function getLeave() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    return firestore.collection("leave").where("userUid", "==", userData).get();
}

export async function totalYearlyHours(userId) {
    const firestore = firebase.firestore();
    let userInfo = [];
    let blockedDays = [];
    let finaltotal = [];

    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), ["id"]: `${res.id}` };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    await firestore
        .collection("blockedDates")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        list.push(doc.data().blockDate.toDate());
                    }
                });

                blockedDays = list;
            },
            (err) => {
                console.log(err);
            }
        );

    await firestore
        .collection("companies")
        .doc(userInfo.companyId)
        .get()
        .then(
            async (result) => {
                if (result.exists) {
                    let timetrackToCheck = [];
                    let projectlist = [];

                    await firestore
                        .collection("timetrack")
                        .where("userUid", "==", userInfo.id)
                        .get()
                        .then((result2) => {
                            let timeData = result2.docs;
                            let list = [];
                            timeData.forEach((docData) => {
                                if (docData.exists) {
                                    console.log();
                                    if (format(docData.data().minDate.toDate(), "yyyy") == format(new Date(), "yyyy")) {
                                        list.push(docData.id);
                                    }
                                }
                            });
                            timetrackToCheck = list;
                        });

                    await firestore
                        .collection("projects")
                        .where("users", "array-contains", userInfo.id)
                        .get()
                        .then((result3) => {
                            let projectData = result3.docs;
                            let list = [];
                            projectData.forEach((docData) => {
                                if (docData.exists) {
                                    list.push({ projectName: docData.data().projectName, projectId: docData.id });
                                }
                            });
                            projectlist = list;
                        });

                    let fullProjectsList = [];

                    Object.keys(timetrackToCheck).forEach(async function (key) {
                        let projLists = [];
                        await firestore
                            .collection("timetrackRow")
                            .where("timeId", "==", timetrackToCheck[key])
                            .get()
                            .then((result4) => {
                                let rowData = result4.docs;
                                let list = [];
                                let completeTime = 0;
                                rowData.forEach((docData) => {
                                    if (docData.exists) {
                                        if (format(docData.data().day.toDate(), "yyyy") == format(new Date(), "yyyy")) {
                                            list.push(docData.data());
                                        }
                                    }
                                });
                                projLists = list;
                            });
                        fullProjectsList.push(projLists);
                    });

                    setTimeout(function () {
                        const merge = fullProjectsList.flat(1);
                        let allTime = [];

                        Object.keys(merge).forEach(async function (key) {
                            if (format(merge[key].day.toDate(), "MMM") == "Jan") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "January" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Feb") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "February" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Mar") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "March" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Apr") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "April" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "May") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "May" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Jun") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "June" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Jul") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "July" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Aug") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "August" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Sept") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "September" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Oct") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "October" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Nov") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "November" });
                            }
                            if (format(merge[key].day.toDate(), "MMM") == "Dec") {
                                allTime.push({ projectId: merge[key].projectId, timeWorked: merge[key].timeWorked, month: "December" });
                            }
                        });

                        let yearTime = [];

                        Object.keys(projectlist).forEach(async function (key) {
                            //
                            let JanTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "January" && allTime[key2].projectId == projectlist[key].projectId) {
                                    JanTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let FebTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "February" && allTime[key2].projectId == projectlist[key].projectId) {
                                    FebTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let MarTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "March" && allTime[key2].projectId == projectlist[key].projectId) {
                                    MarTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let AprTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "April" && allTime[key2].projectId == projectlist[key].projectId) {
                                    AprTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let MayTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "May" && allTime[key2].projectId == projectlist[key].projectId) {
                                    MayTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let JunTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "June" && allTime[key2].projectId == projectlist[key].projectId) {
                                    JunTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let JulTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "July" && allTime[key2].projectId == projectlist[key].projectId) {
                                    JulTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let AugTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "August" && allTime[key2].projectId == projectlist[key].projectId) {
                                    AugTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let SeptTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "September" && allTime[key2].projectId == projectlist[key].projectId) {
                                    SeptTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let OctTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "October" && allTime[key2].projectId == projectlist[key].projectId) {
                                    OctTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let NovTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "November" && allTime[key2].projectId == projectlist[key].projectId) {
                                    NovTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            let DecTotal = 0;
                            Object.keys(allTime).forEach(async function (key2) {
                                if (allTime[key2].month == "December" && allTime[key2].projectId == projectlist[key].projectId) {
                                    OctTotal += parseFloat(allTime[key2].timeWorked);
                                }
                            });

                            yearTime.push({
                                projectId: projectlist[key].projectId,
                                projectName: projectlist[key].projectName,
                                JanTotal: JanTotal,
                                FebTotal: FebTotal,
                                MarTotal: MarTotal,
                                AprTotal: AprTotal,
                                MayTotal: MayTotal,
                                JunTotal: JunTotal,
                                JulTotal: JulTotal,
                                AugTotal: AugTotal,
                                SeptTotal: SeptTotal,
                                OctTotal: OctTotal,
                                NovTotal: NovTotal,
                                DecTotal: DecTotal,
                            });
                        });

                        let totalDaysPerYear = [];

                        let janHours = 0;
                        var janDaysStart = new Date(new Date().getFullYear() + "-01-01");
                        var janDaysEnd = new Date(new Date().getFullYear() + "-01-31");
                        while (janDaysStart <= janDaysEnd) {
                            if (blockedDays.includes(janDaysStart) == false) {
                                if (format(janDaysStart, "eee") == "Mon") {
                                    janHours += parseInt(result.data().mondayHours);
                                } else if (format(janDaysStart, "eee") == "Tue") {
                                    janHours += parseInt(result.data().tuesdayHours);
                                } else if (format(janDaysStart, "eee") == "Wed") {
                                    janHours += parseInt(result.data().wednesdayHours);
                                } else if (format(janDaysStart, "eee") == "Thu") {
                                    janHours += parseInt(result.data().thursdayHours);
                                } else if (format(janDaysStart, "eee") == "Fri") {
                                    janHours += parseInt(result.data().fridayHours);
                                }
                            }

                            janDaysStart = addDays(janDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "January", total: janHours });

                        let febHours = 0;
                        var febDaysStart = new Date(new Date().getFullYear() + "-02-01");
                        var febDaysEnd = new Date(new Date().getFullYear() + "-02-28");
                        while (febDaysStart <= febDaysEnd) {
                            if (blockedDays.includes(febDaysStart) == false) {
                                if (format(febDaysStart, "eee") == "Mon") {
                                    febHours += parseInt(result.data().mondayHours);
                                } else if (format(febDaysStart, "eee") == "Tue") {
                                    febHours += parseInt(result.data().tuesdayHours);
                                } else if (format(febDaysStart, "eee") == "Wed") {
                                    febHours += parseInt(result.data().wednesdayHours);
                                } else if (format(febDaysStart, "eee") == "Thu") {
                                    febHours += parseInt(result.data().thursdayHours);
                                } else if (format(febDaysStart, "eee") == "Fri") {
                                    febHours += parseInt(result.data().fridayHours);
                                }
                            }

                            febDaysStart = addDays(febDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "February", total: febHours });

                        let marHours = 0;
                        var marDaysStart = new Date(new Date().getFullYear() + "-03-01");
                        var marDaysEnd = new Date(new Date().getFullYear() + "-03-31");
                        while (marDaysStart <= marDaysEnd) {
                            if (blockedDays.includes(marDaysStart) == false) {
                                if (format(marDaysStart, "eee") == "Mon") {
                                    marHours += parseInt(result.data().mondayHours);
                                } else if (format(marDaysStart, "eee") == "Tue") {
                                    marHours += parseInt(result.data().tuesdayHours);
                                } else if (format(marDaysStart, "eee") == "Wed") {
                                    marHours += parseInt(result.data().wednesdayHours);
                                } else if (format(marDaysStart, "eee") == "Thu") {
                                    marHours += parseInt(result.data().thursdayHours);
                                } else if (format(marDaysStart, "eee") == "Fri") {
                                    marHours += parseInt(result.data().fridayHours);
                                }
                            }

                            marDaysStart = addDays(marDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "March", total: marHours });

                        let aprHours = 0;
                        var aprDaysStart = new Date(new Date().getFullYear() + "-04-01");
                        var aprDaysEnd = new Date(new Date().getFullYear() + "-04-30");
                        while (aprDaysStart <= aprDaysEnd) {
                            if (blockedDays.includes(aprDaysStart) == false) {
                                if (format(aprDaysStart, "eee") == "Mon") {
                                    aprHours += parseInt(result.data().mondayHours);
                                } else if (format(aprDaysStart, "eee") == "Tue") {
                                    aprHours += parseInt(result.data().tuesdayHours);
                                } else if (format(aprDaysStart, "eee") == "Wed") {
                                    aprHours += parseInt(result.data().wednesdayHours);
                                } else if (format(aprDaysStart, "eee") == "Thu") {
                                    aprHours += parseInt(result.data().thursdayHours);
                                } else if (format(aprDaysStart, "eee") == "Fri") {
                                    aprHours += parseInt(result.data().fridayHours);
                                }
                            }

                            aprDaysStart = addDays(aprDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "April", total: aprHours });

                        let mayHours = 0;
                        var mayDaysStart = new Date(new Date().getFullYear() + "-05-01");
                        var mayDaysEnd = new Date(new Date().getFullYear() + "-05-31");
                        while (mayDaysStart <= mayDaysEnd) {
                            if (blockedDays.includes(mayDaysStart) == false) {
                                if (format(mayDaysStart, "eee") == "Mon") {
                                    mayHours += parseInt(result.data().mondayHours);
                                } else if (format(mayDaysStart, "eee") == "Tue") {
                                    mayHours += parseInt(result.data().tuesdayHours);
                                } else if (format(mayDaysStart, "eee") == "Wed") {
                                    mayHours += parseInt(result.data().wednesdayHours);
                                } else if (format(mayDaysStart, "eee") == "Thu") {
                                    mayHours += parseInt(result.data().thursdayHours);
                                } else if (format(mayDaysStart, "eee") == "Fri") {
                                    mayHours += parseInt(result.data().fridayHours);
                                }
                            }

                            mayDaysStart = addDays(mayDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "May", total: mayHours });

                        let junHours = 0;
                        var junDaysStart = new Date(new Date().getFullYear() + "-06-01");
                        var junDaysEnd = new Date(new Date().getFullYear() + "-06-30");
                        while (junDaysStart <= junDaysEnd) {
                            if (blockedDays.includes(junDaysStart) == false) {
                                if (format(junDaysStart, "eee") == "Mon") {
                                    junHours += parseInt(result.data().mondayHours);
                                } else if (format(junDaysStart, "eee") == "Tue") {
                                    junHours += parseInt(result.data().tuesdayHours);
                                } else if (format(junDaysStart, "eee") == "Wed") {
                                    junHours += parseInt(result.data().wednesdayHours);
                                } else if (format(junDaysStart, "eee") == "Thu") {
                                    junHours += parseInt(result.data().thursdayHours);
                                } else if (format(junDaysStart, "eee") == "Fri") {
                                    junHours += parseInt(result.data().fridayHours);
                                }
                            }

                            junDaysStart = addDays(junDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "June", total: junHours });

                        let julHours = 0;
                        var julDaysStart = new Date(new Date().getFullYear() + "-07-01");
                        var julDaysEnd = new Date(new Date().getFullYear() + "-07-31");
                        while (julDaysStart <= julDaysEnd) {
                            if (blockedDays.includes(julDaysStart) == false) {
                                if (format(julDaysStart, "eee") == "Mon") {
                                    julHours += parseInt(result.data().mondayHours);
                                } else if (format(julDaysStart, "eee") == "Tue") {
                                    julHours += parseInt(result.data().tuesdayHours);
                                } else if (format(julDaysStart, "eee") == "Wed") {
                                    julHours += parseInt(result.data().wednesdayHours);
                                } else if (format(julDaysStart, "eee") == "Thu") {
                                    julHours += parseInt(result.data().thursdayHours);
                                } else if (format(julDaysStart, "eee") == "Fri") {
                                    julHours += parseInt(result.data().fridayHours);
                                }
                            }

                            julDaysStart = addDays(julDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "July", total: julHours });

                        let augHours = 0;
                        var augDaysStart = new Date(new Date().getFullYear() + "-08-01");
                        var augDaysEnd = new Date(new Date().getFullYear() + "-08-31");
                        while (augDaysStart <= augDaysEnd) {
                            if (blockedDays.includes(augDaysStart) == false) {
                                if (format(augDaysStart, "eee") == "Mon") {
                                    augHours += parseInt(result.data().mondayHours);
                                } else if (format(augDaysStart, "eee") == "Tue") {
                                    augHours += parseInt(result.data().tuesdayHours);
                                } else if (format(augDaysStart, "eee") == "Wed") {
                                    augHours += parseInt(result.data().wednesdayHours);
                                } else if (format(augDaysStart, "eee") == "Thu") {
                                    augHours += parseInt(result.data().thursdayHours);
                                } else if (format(augDaysStart, "eee") == "Fri") {
                                    augHours += parseInt(result.data().fridayHours);
                                }
                            }

                            augDaysStart = addDays(augDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "August", total: augHours });

                        let septHours = 0;
                        var septDaysStart = new Date(new Date().getFullYear() + "-09-01");
                        var septDaysEnd = new Date(new Date().getFullYear() + "-09-30");
                        while (septDaysStart <= septDaysEnd) {
                            if (blockedDays.includes(septDaysStart) == false) {
                                if (format(septDaysStart, "eee") == "Mon") {
                                    septHours += parseInt(result.data().mondayHours);
                                } else if (format(septDaysStart, "eee") == "Tue") {
                                    septHours += parseInt(result.data().tuesdayHours);
                                } else if (format(septDaysStart, "eee") == "Wed") {
                                    septHours += parseInt(result.data().wednesdayHours);
                                } else if (format(septDaysStart, "eee") == "Thu") {
                                    septHours += parseInt(result.data().thursdayHours);
                                } else if (format(septDaysStart, "eee") == "Fri") {
                                    septHours += parseInt(result.data().fridayHours);
                                }
                            }

                            septDaysStart = addDays(septDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "September", total: septHours });

                        let octHours = 0;
                        var octDaysStart = new Date(new Date().getFullYear() + "-10-01");
                        var octDaysEnd = new Date(new Date().getFullYear() + "-10-31");
                        while (octDaysStart <= octDaysEnd) {
                            if (blockedDays.includes(octDaysStart) == false) {
                                if (format(octDaysStart, "eee") == "Mon") {
                                    octHours += parseInt(result.data().mondayHours);
                                } else if (format(octDaysStart, "eee") == "Tue") {
                                    octHours += parseInt(result.data().tuesdayHours);
                                } else if (format(octDaysStart, "eee") == "Wed") {
                                    octHours += parseInt(result.data().wednesdayHours);
                                } else if (format(octDaysStart, "eee") == "Thu") {
                                    octHours += parseInt(result.data().thursdayHours);
                                } else if (format(octDaysStart, "eee") == "Fri") {
                                    octHours += parseInt(result.data().fridayHours);
                                }
                            }

                            octDaysStart = addDays(octDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "October", total: octHours });

                        let novHours = 0;
                        var novDaysStart = new Date(new Date().getFullYear() + "-11-01");
                        var novDaysEnd = new Date(new Date().getFullYear() + "-11-30");
                        while (novDaysStart <= novDaysEnd) {
                            if (blockedDays.includes(novDaysStart) == false) {
                                if (format(novDaysStart, "eee") == "Mon") {
                                    novHours += parseInt(result.data().mondayHours);
                                } else if (format(novDaysStart, "eee") == "Tue") {
                                    novHours += parseInt(result.data().tuesdayHours);
                                } else if (format(novDaysStart, "eee") == "Wed") {
                                    novHours += parseInt(result.data().wednesdayHours);
                                } else if (format(novDaysStart, "eee") == "Thu") {
                                    novHours += parseInt(result.data().thursdayHours);
                                } else if (format(novDaysStart, "eee") == "Fri") {
                                    novHours += parseInt(result.data().fridayHours);
                                }
                            }

                            novDaysStart = addDays(novDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "November", total: novHours });

                        let decHours = 0;
                        var decDaysStart = new Date(new Date().getFullYear() + "-12-01");
                        var decDaysEnd = new Date(new Date().getFullYear() + "-12-31");
                        while (decDaysStart <= decDaysEnd) {
                            if (blockedDays.includes(decDaysStart) == false) {
                                if (format(decDaysStart, "eee") == "Mon") {
                                    decHours += parseInt(result.data().mondayHours);
                                } else if (format(decDaysStart, "eee") == "Tue") {
                                    decHours += parseInt(result.data().tuesdayHours);
                                } else if (format(decDaysStart, "eee") == "Wed") {
                                    decHours += parseInt(result.data().wednesdayHours);
                                } else if (format(decDaysStart, "eee") == "Thu") {
                                    decHours += parseInt(result.data().thursdayHours);
                                } else if (format(decDaysStart, "eee") == "Fri") {
                                    decHours += parseInt(result.data().fridayHours);
                                }
                            }

                            decDaysStart = addDays(decDaysStart, 1);
                        }
                        totalDaysPerYear.push({ year: "December", total: decHours });

                        const merge2 = [totalDaysPerYear, yearTime];
                        const merge3 = merge2.flat(1);

                        finaltotal.push(merge2);

                        // return merge3;
                    }, 1000);
                }
            },
            (err) => {
                console.log(err);
            }
        );

    const someTimeoutAction = () => {
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve(finaltotal);
            }, 3000);
        });
    };

    const status = await someTimeoutAction();

    return status;
}

export async function addExpenseTracking(values) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;

    values.amount.replace("R", "");
    values.createDate = new Date(Date.now());

    try {
        let company = await firestore.collection("companies").doc(values.companyId).get();

        let runMonthTo = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            parseInt(values.expenseToDate ? values.expenseToDate : company.data().expenseToDate ? company.data().expenseToDate : 28)
        );
        let runMonthFrom = addDays(subMonths(runMonthTo, 1), 1);

        let expensePeriod = await firestore.collection("expenses").where("userUid", "==", values.userUid).where("fromDate", "==", runMonthFrom).where("toDate", "==", runMonthTo).get();

        if (expensePeriod.empty === true) {
            let newPeriod = {};
            newPeriod.fromDate = runMonthFrom;
            newPeriod.toDate = runMonthTo;
            newPeriod.userUid = values.userUid;
            newPeriod.companyId = values.companyId;
            newPeriod.status = "In Progress";
            newPeriod.total = parseFloat(values.amount);
            newPeriod.loaded = 1;
            newPeriod.createDate = values.createDate;

            let newExpense = await firestore.collection("expenses").add(newPeriod);

            if (values.uploadFile !== undefined && values.uploadFile !== "") {
                const path = `${auth.uid}/expense`;
                const options = {
                    name: cuid() + "-" + values.uploadFile.name,
                };
                const file = values.uploadFile;

                let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

                values.fileName = values.uploadFile.name;
                values.fileUrl = downloadURL;
            }
            delete values.uploadFile;

            console.log(newExpense.id);

            console.log(values);

            await firestore.collection("expenses").doc(newExpense.id).collection("loaded").add(values);
        } else {
            if (values.uploadFile !== undefined && values.uploadFile !== "") {
                const path = `${auth.uid}/expense`;
                const options = {
                    name: cuid() + "-" + values.uploadFile.name,
                };
                const file = values.uploadFile;

                let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

                values.fileName = values.uploadFile.name;
                values.fileUrl = downloadURL;
            }
            delete values.uploadFile;

            await firestore.collection("expenses").doc(expensePeriod.docs[0].id).collection("loaded").add(values);
            await firestore
                .collection("expenses")
                .doc(expensePeriod.docs[0].id)
                .update({ loaded: expensePeriod.docs[0].data().loaded + 1, total: expensePeriod.docs[0].data().total + parseFloat(values.amount) });
        }

        toast.success("Expense added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function updateRows(id) {
    const firestore = firebase.firestore();
    let user = await firestore.collection("users").doc(id).get();
    let company = await firestore.collection("companies").doc(user.data().companyId).get();

    let runMonthTo = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        parseInt(user.data().expenseToDate ? user.data().expenseToDate : company.data().expenseToDate ? company.data().expenseToDate : 28)
    );

    let runMonthFrom = addDays(subMonths(runMonthTo, 1), 1);

    let expensePeriod = await firestore.collection("expenses").where("userUid", "==", id).get();

    let docs = expensePeriod.docs;

    Object.keys(docs).forEach(async function (key) {
        if (
            docs[key].data().status == "In Progress" &&
            format(docs[key].data().fromDate.toDate(), "yyyy-MM-dd") !== format(runMonthFrom, "yyyy-MM-dd") &&
            format(docs[key].data().toDate.toDate(), "yyyy-MM-dd") !== format(runMonthTo, "yyyy-MM-dd")
        ) {
            await firestore.collection("expenses").doc(docs[key].id).update({ status: "Pending" });
        }
    });
}

export async function moveTime() {
    const firestore = firebase.firestore();

    let timeArr = [];

    let userArr = [];

    await firestore
        .collection("users")
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach(async function (doc) {
                if (doc.exists) {
                    userArr.push({ ...doc.data(), id: doc.id });
                }
            });
        })
        .catch(function (error) {
            console.log("Error getting line manager email: ", error);
        });

    // console.log(userArr);

    for (var i = 0; i < userArr.length; i++) {
        //

        let thisTime = [];

        await firestore
            .collection("timetrack")
            .where("userUid", "==", userArr[i].id)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    if (doc.exists) {
                        let obj = { ...doc.data(), firebaseId: doc.id };
                        obj.minDate = obj.minDate ? format(obj.minDate.toDate(), "yyyy-MM-dd") : "";
                        obj.maxDate = obj.maxDate ? format(obj.maxDate.toDate(), "yyyy-MM-dd") : "";

                        let time = [];

                        await firestore
                            .collection("timetrackRow")
                            .where("timeId", "==", obj.firebaseId)
                            .get()
                            .then(function (timeQuerySnapshot) {
                                timeQuerySnapshot.forEach(async function (res) {
                                    if (res.exists) {
                                        let timeObj = { ...res.data(), id: res.id };
                                        timeObj.day = timeObj.day ? format(timeObj.day.toDate(), "yyyy-MM-dd") : "";
                                        timeObj.timeWorked = timeObj.timeWorked > 0 ? timeObj.timeWorked : 0;
                                        time.push(timeObj);
                                    }
                                });
                            });

                        obj.time = time;
                        thisTime.push(obj);
                    }
                });
            });

        timeArr.push(thisTime);
    }

    console.log("Start Migration of " + timeArr.length + " records");

    await axios.post(apiURL + "/api/migrate_time", { timeArr: timeArr });

    // console.log(timeArr);

    // var data_snap_arr = [];

    // firestore
    //     .collection("users")
    //     .limit(10)
    //     .get(async (docs) => {
    //         docs.forEach((doc) => {
    //             let data = doc.data();
    //             data.id = doc.id;

    //             data_snap_arr.push(data);
    //         });
    //     });

    // await Promise.all(data_snap_arr.map(row)).then((result) => {
    //     const finalData = {};
    //     for (var i = 0; i < result.length; i++) {
    //         console.log(result[i]);
    //     }
    //     // return ref.child("/leaderboard").set(finalData);
    // });

    // firestore
    //     .collection("users")
    //     .limit(10)
    //     .onSnapshot(async (userSnap) => {
    //         await userSnap.docs.forEach((userResult) => {
    //             console.log("1");
    //             let userObj = { ...userResult.data(), userUid: userResult.id };

    //             if (userObj.id !== undefined) {
    //                 firestore
    //                     .collection("timetrack")
    //                     .where("userUid", "==", userObj.id)
    //                     .onSnapshot(async (snapshot) => {
    //                         let list = [];
    //                         snapshot.docs.forEach((result) => {
    //                             if (result.exists) {
    //                                 let obj = { ...result.data(), firebaseId: result.id };
    //                                 obj.minDate = format(obj.minDate.toDate(), "yyyy-MM-dd");
    //                                 obj.maxDate = format(obj.maxDate.toDate(), "yyyy-MM-dd");

    //                                 let time = [];

    //                                 firestore
    //                                     .collection("timetrackRow")
    //                                     .where("timeId", "==", obj.firebaseId)
    //                                     .onSnapshot((snap) => {
    //                                         snap.docs.forEach((res) => {
    //                                             if (res.exists) {
    //                                                 let timeObj = { ...res.data(), id: res.id };
    //                                                 timeObj.day = format(timeObj.day.toDate(), "yyyy-MM-dd");
    //                                                 time.push(timeObj);
    //                                             }
    //                                         });
    //                                     });

    //                                 obj.time = time;
    //                                 list.push(obj);
    //                             }
    //                         });

    //                         setTimeout(async function () {
    //                             await axios.post(apiURL + "/api/migrate_time", { profile: userObj, allTime: list });
    //                             console.log("2");
    //                             return true;
    //                         }, 3000);
    //                     });
    //             }
    //         });
    //     });
}

// export async function moveCompany() {
//     const firestore = firebase.firestore();

//     let companyArr = [];


//     await firestore
//         .collection("companies")
//         .get()
//         .then(function (querySnapshot) {
//             querySnapshot.forEach(async function (doc) {
//                 if (doc.exists) {
//                     companyArr.push({ ...doc.data(), id: doc.id });
//                 }
//             });
//         })
//         .catch(function (error) {
//             console.log("Error getting companies: ", error);
//         });


//     console.log("Start Migration of " + companyArr.length + " records");

//     await axios.post(apiURL + "/api/migrate_company", { companyArr: companyArr });

// }

// export async function moveLeavePeriods() {
//     const firestore = firebase.firestore();

//     let leavePeriodsArr = [];


//     await firestore
//         .collection("leaveStatus")
//         .get()
//         .then(function (querySnapshot) {
//             querySnapshot.forEach(async function (doc) {
//                 if (doc.exists) {
//                     leavePeriodsArr.push({ ...doc.data(), id: doc.id });
//                 }
//             });
//         })
//         .catch(function (error) {
//             console.log("Error getting leave periods: ", error);
//         });


//     console.log("Start Migration of " + leavePeriodsArr.length + " records");

//     await axios.post(apiURL + "/api/migrate_leave_periods", { leavePeriodsArr: leavePeriodsArr });

// }

// export async function moveLeaveComments() {
//     // const firestore = firebase.firestore();

//     // let leaveCommentsArr = [];


//     // await firestore
//     //     .collection("companies")
//     //     .get()
//     //     .then(function (querySnapshot) {
//     //         querySnapshot.forEach(async function (doc) {
//     //             if (doc.exists) {
//     //                 leaveCommentsArr.push({ ...doc.data(), id: doc.id });
//     //             }
//     //         });
//     //     })
//     //     .catch(function (error) {
//     //         console.log("Error getting leave comments: ", error);
//     //     });


//     // console.log("Start Migration of " + leaveCommentsArr.length + " records");

//     // await axios.post(apiURL + "/api/migrate_leave_comments", { leaveCommentsArr: leaveCommentsArr });

// }