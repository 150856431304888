import format from "date-fns/format";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import addYears from "date-fns/addYears";
import subDays from "date-fns/subDays";
import differenceInMonths from "date-fns/differenceInMonths";
import { toast } from "react-toastify";
//This is used if we want to access firebase directly without using getFirestore
import firebase from "../../app/config/firebase";
import { endOfMonth } from "date-fns";
import subYears from "date-fns/subYears";
import { addDays } from "date-fns/esm";

export async function leaveApprove(leave) {
    const firestore = firebase.firestore();

    try {
        leave.changeReason = "";

        if (leave.actionPerson) {
            leave.actionPerson = leave.actionPerson;
        }

        if (leave.annualFrom) {
            // eslint-disable-next-line no-self-assign
            leave.annualFrom = leave.annualFrom;
        }

        if (leave.annualTo) {
            // eslint-disable-next-line no-self-assign
            leave.annualTo = leave.annualTo;
        }

        if (leave.preApprove !== undefined && leave.preApprove === true && leave.status != "Denied") {
            leave.negativeEscalated = true;
            leave.escalateStatus = leave.status;
            leave.escalateNote = leave.denyReason;
            delete leave.denyReason;
            delete leave.status;
        } else if (leave.preApprove !== undefined && leave.preApprove === true && leave.status === "Denied") {
            leave.escalateStatus = leave.status;
            leave.escalateNote = leave.denyReason;
            leave.negativeEscalated = true;
        }

        //First make sure that leave exists
        var docRef = firestore.collection("leave").doc(leave.id);

        docRef
            .get()
            .then(async function (doc) {
                if (doc.exists) {
                    //Set staff leave as chosen
                    await firestore.collection("leave").doc(leave.id).update(leave);

                    if (doc.data().negativeRequested === undefined || doc.data().negativeEscalated === true) {
                        //Action Center - Notify Staff
                        let notification = {
                            createDate: new Date(Date.now()),
                            notificationType: "Notification",
                            rowModule: "Leave",
                            rowType: leave.leaveType,
                            rowId: leave.id,
                            description: "Leave " + leave.status,
                            read: false,
                            userUid: leave.userUid,
                        };

                        await firestore.collection("notifications").add(notification);
                    }

                    //Action Center - Mark notification as read
                    await firestore
                        .collection("notifications")
                        .where("rowId", "==", leave.id)
                        .where("rowModule", "==", "Leave")
                        .where("description", "==", "Leave requires approval")
                        .get()
                        .then(function (querySnapshot) {
                            if (!querySnapshot.empty) {
                                querySnapshot.forEach(function (doc) {
                                    if (!doc.empty) {
                                        let notif_update = {
                                            read: true,
                                            readOn: new Date(Date.now()),
                                        };
                                        firestore.collection("notifications").doc(doc.id).update(notif_update);
                                    }
                                });
                            }
                        });

                    if (doc.data().negativeRequested !== undefined && doc.data().negativeRequested === true && doc.data().negativeEscalated === undefined) {
                        await firestore
                            .collection("notifications")
                            .where("rowId", "==", leave.id)
                            .where("rowModule", "==", "Leave")
                            .where("description", "==", "Negative Leave requires pre - approval and escalation to HR")
                            .get()
                            .then(function (querySnapshot) {
                                if (!querySnapshot.empty) {
                                    querySnapshot.forEach(function (doc) {
                                        if (!doc.empty) {
                                            let notif_update = {
                                                read: true,
                                                readOn: new Date(Date.now()),
                                            };
                                            firestore.collection("notifications").doc(doc.id).update(notif_update);
                                        }
                                    });
                                }
                            });

                        let userData = await firestore.collection("users").doc(leave.userUid).get();
                        let user = userData.data();

                        if (user.approvalHRUser !== undefined && leave.status !== "Denied") {
                            await firestore.collection("notifications").add({
                                createDate: new Date(Date.now()),
                                notificationType: "Action",
                                rowModule: "Leave",
                                rowType: leave.leaveType,
                                rowId: doc.id,
                                description: "Negative Leave Escalated to HR for approval",
                                read: false,
                                userUid: user.approvalHRUser,
                                displayName: user.displayName,
                            });
                        } else if (leave.status === "Denied") {
                            let notification = {
                                createDate: new Date(Date.now()),
                                notificationType: "Notification",
                                rowModule: "Leave",
                                rowType: leave.leaveType,
                                rowId: leave.id,
                                description: "Negative Leave Denied",
                                read: false,
                                userUid: leave.userUid,
                            };

                            await firestore.collection("notifications").add(notification);
                        }
                    }

                    if (doc.data().negativeRequested !== undefined && doc.data().negativeRequested === true && doc.data().negativeEscalated === true) {
                        await firestore
                            .collection("notifications")
                            .where("rowId", "==", leave.id)
                            .where("rowModule", "==", "Leave")
                            .where("description", "==", "Negative Leave Escalated to HR for approval")
                            .get()
                            .then(function (querySnapshot) {
                                if (!querySnapshot.empty) {
                                    querySnapshot.forEach(function (doc) {
                                        if (!doc.empty) {
                                            let notif_update = {
                                                read: true,
                                                readOn: new Date(Date.now()),
                                            };
                                            firestore.collection("notifications").doc(doc.id).update(notif_update);
                                        }
                                    });
                                }
                            });
                    }
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function expenseApprove(values) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("expenses").doc(values.id).update(values);
        toast.success("Expense Updated");
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function expenseEdit(values) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("expenses").doc(values.id).update({ category: values.category, editUser: values.editUser });
        toast.success("Expense Updated");
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function expenseComment(values, expenseId) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("expenses").doc(expenseId).collection("comments").add(values);
        toast.success("Comment Added");
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function deleteExpense(values) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("expenses").doc(values.id).delete();
        toast.success("Expense Deleted");
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function timeApprove(time) {
    const firestore = firebase.firestore();

    try {
        time.changeReason = "";

        //First make sure that leave exists
        var docRef = firestore.collection("timetrack").doc(time.id);

        docRef
            .get()
            .then(async function (doc) {
                if (doc.exists) {
                    //Set staff leave as chosen
                    await firestore.collection("timetrack").doc(time.id).update(time);

                    //Update the status for all timetrack rows
                    await firestore
                        .collection("timetrackRow")
                        .where("timeId", "==", time.id)
                        .get()
                        .then(function (querySnapshot) {
                            if (!querySnapshot.empty) {
                                querySnapshot.forEach(function (doc) {
                                    if (!doc.empty) {
                                        firestore.collection("timetrackRow").doc(doc.id).update({ status: time.status });
                                    }
                                });
                            }
                        });

                    //Action Center - Notify Staff
                    let notification = {
                        createDate: new Date(Date.now()),
                        notificationType: "Notification",
                        rowModule: "Timetracking",
                        rowId: time.id,
                        description: "Timetracking " + time.status,
                        read: false,
                        userUid: time.userUid,
                    };

                    await firestore.collection("notifications").add(notification);

                    //Action Center - Mark notification as read
                    await firestore
                        .collection("notifications")
                        .where("rowId", "==", time.id)
                        .where("rowModule", "==", "Timetracking")
                        .where("description", "==", "Timetracking requires approval")
                        .get()
                        .then(function (querySnapshot) {
                            if (!querySnapshot.empty) {
                                querySnapshot.forEach(function (doc) {
                                    if (!doc.empty) {
                                        let notif_update = {
                                            read: true,
                                            readOn: new Date(Date.now()),
                                        };
                                        firestore.collection("notifications").doc(doc.id).update(notif_update);
                                    }
                                });
                            }
                        });
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function deleteLeave(leave) {
    const firestore = firebase.firestore();

    try {
        //Remove Leave Comments
        let commentsRef = firestore.collection("leave").doc(leave.id).collection("comments");
        let commentsSnap = await commentsRef.get();

        for (let i = 0; i < commentsSnap.docs.length; i++) {
            let id = commentsSnap.docs[i].id;
            await firestore.collection("leave").doc(leave.id).collection("comments").doc(id).delete();
        }

        //Remove Leave
        await firestore.collection("leave").doc(leave.id).delete();

        //Remove notifications
        await firestore
            .collection("notifications")
            .where("rowId", "==", leave.id)
            .where("rowModule", "==", "Leave")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    firestore.collection("notifications").doc(doc.id).delete();
                });
            });
    } catch (e) {
        console.log(e);
        throw new Error("Problem deleting the Leave");
    }
}

export async function deleteTimetrack(timetrack) {
    const firestore = firebase.firestore();

    try {
        //Remove Comments
        await firestore
            .collection("timetrack")
            .doc(timetrack.id)
            .collection("comments")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    await firestore.collection("timetrack").doc(timetrack.id).collection("comments").doc(doc.id).delete();
                });
            });

        //Remove timetrack
        await firestore.collection("timetrack").doc(timetrack.id).delete();

        //Remove rows
        await firestore
            .collection("timetrackRow")
            .where("timeId", "==", timetrack.id)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    await firestore.collection("timetrackRow").doc(doc.id).delete();
                });
            });
    } catch (e) {
        console.log(e);
        throw new Error("Problem deleting the Leave");
    }
}

export async function generateNewLeaveStats(userUid) {
    const firestore = firebase.firestore();

    //get userData
    let userQuery = await firestore.collection("users").doc(userUid).get();
    let userData = userQuery.data();

    //get users companyData
    let companyInfo = await firestore.collection("companies").doc(userData.companyId).get();
    let companyData = companyInfo.data();

    let timestamp1 = "";
    let currentEndDate = "";

    let startDate = "";

    //If take on date is set use Take on otherwise use start date otherwise use created date
    if (userData.takeOnStartDate !== undefined && userData.takeOnStartDate !== "") {
        startDate = userData.takeOnStartDate.toDate();
        startDate.setDate(1);
    } else if (userData.employmentStartDate !== undefined && userData.employmentStartDate !== "") {
        startDate = userData.employmentStartDate.toDate();
    } else {
        startDate = userData.createdAt.toDate();
    }

    //Check to what company leave is set to &get last day of period & assign data
    // set timestamp1 and currentEndDate based on company leave type
    if (companyData.leaveType === "Anniversary Date of Employee") {
        let monthNow = new Date().getMonth() + 1;
        let monthStart = parseInt(format(startDate, "MM"));

        if (monthNow >= monthStart) {
            currentEndDate = subDays(new Date(parseInt(new Date().getFullYear()) + 1, parseInt(format(startDate, "MM")) - 1, 1), 1);
        } else {
            currentEndDate = subDays(new Date(new Date().getFullYear(), parseInt(format(startDate, "MM")) - 1, 1), 1);
        }
        timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);
    } else if (companyData.leaveType === "Company Financial Year") {
        let monthNow = new Date().getMonth();
        let monthNewStart = parseInt(companyData.yearStart) - 1;
        if (monthNow >= monthNewStart) {
            currentEndDate = subDays(new Date(parseInt(new Date().getFullYear()) + 1, parseInt(companyData.yearStart) - 1, 1), 1);
        } else {
            currentEndDate = subDays(new Date(new Date().getFullYear(), parseInt(companyData.yearStart) - 1, 1), 1);
        }
        timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);
    } else if (companyData.leaveType === "January to December") {
        currentEndDate = new Date(new Date().getFullYear(), 11, 31);
        timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);

        if (companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date") {
            currentEndDate = new Date(new Date().getFullYear() + 1, 0, 31);
            timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);

            // console.log("HERE:", currentEndDate);
        }
    }

    // console.log("FROM TO DATE:", currentEndDate);
    // set timestamp1 and currentEndDate if the company uses a custom to and from date
    let timestamp1_new =
        companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
            ? new Date(timestamp1.toDate().getFullYear(), timestamp1.toDate().getMonth(), companyData.leaveToDay)
            : timestamp1;

    let currentEndDate_new =
        companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
            ? new Date(currentEndDate.getFullYear(), addMonths(currentEndDate, 1).getMonth(), companyData.leaveToDay)
            : currentEndDate;

    if (timestamp1.toDate() !== timestamp1_new) {
        timestamp1 = timestamp1_new;
    }

    if (currentEndDate !== currentEndDate_new) {
        currentEndDate = currentEndDate_new;
    }

    // console.log("FROM TO DATE 2:", timestamp1);

    //Create All from start date
    let AllLeaveStats = await firestore.collection("leaveStatus").where("userUid", "==", userData.id).where("periodType", "==", "Leave").get();

    if (AllLeaveStats.docs.length <= 0) {
        let currPeriodStart = addDays(subYears(currentEndDate, 1), 1);

        if (startDate < currPeriodStart) {
            //set date for previous section
            let prevEndDate = subYears(currentEndDate, 1);
            let timestampPrev = firebase.firestore.Timestamp.fromDate(prevEndDate);

            let month =
                companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date" ? subYears(currentEndDate, 2).getMonth() : addDays(subYears(currentEndDate, 2), 1).getMonth();
            if (addDays(subYears(currentEndDate, 2), 1).getMonth() == subYears(currentEndDate, 2).getMonth()) {
                var prevStartDate =
                    companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date" ? addDays(subYears(currentEndDate, 2), 1) : addDays(subYears(currentEndDate, 2), 2);
            } else {
                var prevStartDate = companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date" ? subYears(currentEndDate, 2) : addDays(subYears(currentEndDate, 2), 1);
            }

            let prevUserDetails = {};

            prevUserDetails.companyId = userData.companyId;
            prevUserDetails.companyname = userData.companyName;
            prevUserDetails.userDisplayName = userData.displayName;
            prevUserDetails.userUid = userUid;
            prevUserDetails.fromDate = prevStartDate;
            prevUserDetails.toDate = prevEndDate;

            let startCurrM = prevUserDetails.fromDate;

            let AllPrevLeaveStats = await firestore.collection("leaveStatus").where("userUid", "==", userData.id).where("periodType", "==", "Leave").where("toDate", "==", timestampPrev).get();

            if (AllPrevLeaveStats.empty === true) {
                let prevLeaveYearCreated = await firestore.collection("leaveStatus").add(prevUserDetails);
                // console.log(prevLeaveYearCreated.id);

                //set initial totals
                let prevAnnualTotal = 0;
                let prevAnnualUsed = 0;
                let prevUntrackUsed = 0;
                let prevFamilyUsed = 0;
                let prevMaternityUsed = 0;
                let prevPaternityUsed = 0;
                let prevIODUsed = 0;
                let prevParentalUsed = 0;
                let prevMovingUsed = 0;
                let prevCsrUsed = 0;
                let prevTakeOnStartBalance = 0;
                let prevReligiousUsed = 0;
                let prevStudyUsed = 0;
                let prevAccumUsed = 0;
                let prevAaccumUsed2 = 0;
                let prevUsedSpecial = 0;

                let prevTotalUsed = 0;
                let prevTotoal = 0;
                //Left

                let familyTotal = parseFloat(
                    userData.familyBalance ? userData.familyBalance : userData.leaveTotalFamily ? userData.leaveTotalFamily : companyData.leaveTotalFamily ? companyData.leaveTotalFamily : 0
                );
                let maternityTotal = parseFloat(
                    userData.maternityBalance
                        ? userData.maternityBalance
                        : userData.leaveTotalMaternity
                        ? userData.leaveTotalMaternity
                        : companyData.leaveTotalMaternity
                        ? companyData.leaveTotalMaternity
                        : 0
                );
                let paternityTotal = parseFloat(
                    userData.paternityBalance
                        ? userData.paternityBalance
                        : userData.leaveTotalPaternity
                        ? userData.leaveTotalPaternity
                        : companyData.leaveTotalPaternity
                        ? companyData.leaveTotalPaternity
                        : 0
                );
                let IODTotal = parseFloat(userData.IODBalance ? userData.IODBalance : userData.leaveTotalIOD ? userData.leaveTotalIOD : companyData.leaveTotalIOD ? companyData.leaveTotalIOD : 0);
                let parentalTotal = parseFloat(
                    userData.parentalBalance
                        ? userData.parentalBalance
                        : userData.leaveTotalParental
                        ? userData.leaveTotalParental
                        : companyData.leaveTotalParental
                        ? companyData.leaveTotalParental
                        : 0
                );
                let movingTotal = parseFloat(
                    userData.movingBalance ? userData.movingBalance : userData.leaveTotalMoving ? userData.leaveTotalMoving : companyData.leaveTotalMoving ? companyData.leaveTotalMoving : 0
                );
                let csrTotal = parseFloat(userData.CSRBalance ? userData.CSRBalance : userData.leaveTotalCSR ? userData.leaveTotalCSR : companyData.leaveTotalCSR ? companyData.leaveTotalCSR : 0);
                let religiousTotal = parseFloat(
                    userData.religiousBalance
                        ? userData.religiousBalance
                        : userData.leaveTotalReligious
                        ? userData.leaveTotalReligious
                        : companyData.leaveTotalReligious
                        ? companyData.leaveTotalReligious
                        : 0
                );
                let studyTotal = parseFloat(
                    userData.studyBalance ? userData.studyBalance : userData.leaveTotalStudy ? userData.leaveTotalStudy : companyData.leaveTotalStudy ? companyData.leaveTotalStudy : 0
                );
                let prevIncentiveTotal = 0;
                let accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                let accumTotal2 = 0;

                let startBalance = userData.takeOnBalance;

                //loop through months
                while (prevEndDate > prevStartDate) {
                    let monthlyTotals = {};

                    if (companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date") {
                        monthlyTotals.datePeriod = prevStartDate;
                        monthlyTotals.datePeriodTo = new Date(prevStartDate.getFullYear(), addMonths(prevStartDate, 1).getMonth(), parseFloat(companyData.leaveToDay));
                    } else {
                        monthlyTotals.datePeriod = prevStartDate;
                    }

                    //check if user has started yet - don't allow leave if not employed
                    if (prevStartDate >= startDate) {
                        if (startBalance == 0 || startBalance === undefined) {
                            prevAnnualTotal = prevAnnualTotal + parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0);
                        }

                        if (prevStartDate > startDate && prevStartDate < addYears(startDate, 1)) {
                            if (startBalance !== 0 && startBalance !== undefined) {
                                prevAnnualTotal = parseFloat(startBalance);
                                // console.log(prevAnnualTotal);
                                if (startBalance !== 0) {
                                    prevTakeOnStartBalance = startBalance;
                                }
                            }

                            startBalance = 0;
                        }
                    }

                    let dtFilterFrom = prevStartDate;
                    let dtFilterTo =
                        companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
                            ? new Date(dtFilterFrom.getFullYear(), addMonths(dtFilterFrom, 1).getMonth(), parseFloat(companyData.leaveToDay))
                            : endOfMonth(prevStartDate);

                    await firestore
                        .collection("leave")
                        .where("userUid", "==", userUid)
                        .where("createDate", ">=", dtFilterFrom)
                        .where("createDate", "<", dtFilterTo)
                        .where("status", "==", "Approved")
                        .get()
                        // eslint-disable-next-line no-loop-func
                        .then(function (querySnapshot) {
                            querySnapshot.forEach(async function (doc) {
                                let this_data = { ...doc.data(), id: doc.id };

                                //Skip unpaid leave -- untrack
                                if (!this_data.unpaidLeave || this_data.unpaidLeave === "No") {
                                    if (this_data.leaveType === "Annual") {
                                        if (this_data.dontTrackUsed && this_data.dontTrackUsed === true) {
                                            prevUntrackUsed = parseFloat(prevUntrackUsed) + parseFloat(this_data.annualDays);
                                            prevUntrackUsed = parseFloat(prevUntrackUsed.toFixed(2));
                                        } else {
                                            if (this_data.useAccumulative && this_data.useAccumulative === "Yes") {
                                                prevAccumUsed = parseFloat(prevAccumUsed) + parseFloat(this_data.annualDays);
                                                prevAccumUsed = parseFloat(prevAccumUsed.toFixed(2));
                                            } else if (this_data.useAccumulative2 && this_data.useAccumulative2 === "Yes") {
                                                prevAaccumUsed2 = parseFloat(prevAaccumUsed2) + parseFloat(this_data.annualDays);
                                                prevAaccumUsed2 = parseFloat(prevAaccumUsed2.toFixed(2));
                                            } else {
                                                prevAnnualUsed = parseFloat(prevAnnualUsed) + parseFloat(this_data.annualDays);
                                                prevAnnualUsed = parseFloat(prevAnnualUsed.toFixed(2));
                                            }
                                        }
                                    } else if (this_data.leaveType === "Family") {
                                        prevFamilyUsed = parseFloat(prevFamilyUsed) + parseFloat(this_data.annualDays);
                                        prevFamilyUsed = parseFloat(prevFamilyUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Maternity") {
                                        prevMaternityUsed = parseFloat(prevMaternityUsed) + parseFloat(this_data.annualDays);
                                        prevMaternityUsed = parseFloat(prevMaternityUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Paternity") {
                                        prevPaternityUsed = parseFloat(prevPaternityUsed) + parseFloat(this_data.annualDays);
                                        prevPaternityUsed = parseFloat(prevPaternityUsed.toFixed(2));
                                    } else if (this_data.leaveType === "IOD") {
                                        prevIODUsed = parseFloat(prevIODUsed) + parseFloat(this_data.annualDays);
                                        prevIODUsed = parseFloat(prevIODUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Parental") {
                                        prevParentalUsed = parseFloat(prevParentalUsed) + parseFloat(this_data.annualDays);
                                        prevParentalUsed = parseFloat(prevParentalUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Moving") {
                                        prevMovingUsed = parseFloat(prevMovingUsed) + parseFloat(this_data.annualDays);
                                        prevMovingUsed = parseFloat(prevMovingUsed.toFixed(2));
                                    } else if (this_data.leaveType === "CSR") {
                                        prevCsrUsed = parseFloat(prevCsrUsed) + parseFloat(this_data.annualDays);
                                        prevCsrUsed = parseFloat(prevCsrUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Religious") {
                                        prevReligiousUsed = parseFloat(prevReligiousUsed) + parseFloat(this_data.annualDays);
                                        prevReligiousUsed = parseFloat(prevReligiousUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Study") {
                                        prevStudyUsed = parseFloat(prevStudyUsed) + parseFloat(this_data.annualDays);
                                        prevStudyUsed = parseFloat(prevStudyUsed.toFixed(2));
                                    } else if (this_data.leaveType === "Special") {
                                        prevUsedSpecial = parseFloat(prevUsedSpecial) + parseFloat(this_data.annualDays);
                                        prevUsedSpecial = parseFloat(prevUsedSpecial.toFixed(2));
                                    } else if (this_data.leaveType === "Incentive") {
                                        prevIncentiveTotal = parseFloat(prevIncentiveTotal) + parseFloat(this_data.annualDays);
                                        prevIncentiveTotal = parseFloat(prevIncentiveTotal.toFixed(2));
                                        prevAnnualTotal = parseFloat(prevAnnualTotal) + parseFloat(this_data.annualDays);
                                        prevAnnualTotal = parseFloat(prevAnnualTotal).toFixed(2);
                                    }
                                }
                            });
                        });

                    //Save Totals
                    monthlyTotals.annualTotal = prevAnnualTotal;
                    monthlyTotals.incentiveTotal = prevIncentiveTotal;
                    //Save Total Used
                    monthlyTotals.annualUsed = prevAnnualUsed;
                    monthlyTotals.untrackUsed = prevUntrackUsed;
                    monthlyTotals.accumUsed = prevAccumUsed;
                    monthlyTotals.accumUsed2 = prevAaccumUsed2;

                    monthlyTotals.annualLeft = prevAnnualTotal - prevAnnualUsed;

                    if (accumTotal !== undefined) {
                        monthlyTotals.accumTotal = accumTotal;
                    } else {
                        monthlyTotals.accumTotal = 0;
                    }
                    if (accumTotal2 !== undefined) {
                        monthlyTotals.accumTotal2 = accumTotal2;
                    } else {
                        monthlyTotals.accumTotal2 = 0;
                    }

                    await firestore.collection("leaveStatus").doc(prevLeaveYearCreated.id).collection("Months").add(monthlyTotals);

                    prevStartDate = addMonths(prevStartDate, 1);
                }

                //Add nonGrowing back into main collection
                let prevLeaveStat = {};
                prevLeaveStat.familyUsed = prevFamilyUsed;
                prevLeaveStat.familyTotal = familyTotal;
                prevLeaveStat.familyLeft = familyTotal - prevFamilyUsed;

                prevLeaveStat.maternityUsed = prevMaternityUsed;
                prevLeaveStat.maternityTotal = maternityTotal;
                prevLeaveStat.maternityLeft = maternityTotal - prevMaternityUsed;

                prevLeaveStat.paternityUsed = prevPaternityUsed;
                prevLeaveStat.paternityTotal = paternityTotal;
                prevLeaveStat.paternityLeft = paternityTotal - prevPaternityUsed;

                prevLeaveStat.IODUsed = prevIODUsed;
                prevLeaveStat.IODTotal = IODTotal;
                prevLeaveStat.IODLeft = IODTotal - prevIODUsed;

                prevLeaveStat.parentalUsed = prevParentalUsed;
                prevLeaveStat.parentalTotal = parentalTotal;
                prevLeaveStat.parentalLeft = parentalTotal - prevParentalUsed;

                prevLeaveStat.movingUsed = prevMovingUsed;
                prevLeaveStat.movingTotal = movingTotal;
                prevLeaveStat.movingLeft = movingTotal - prevMovingUsed;

                prevLeaveStat.csrUsed = prevCsrUsed;
                prevLeaveStat.csrTotal = csrTotal;
                prevLeaveStat.csrLeft = csrTotal - prevCsrUsed;

                prevLeaveStat.religiousUsed = prevReligiousUsed;
                prevLeaveStat.religiousTotal = religiousTotal;
                prevLeaveStat.religiousLeft = religiousTotal - prevReligiousUsed;

                prevLeaveStat.studyUsed = prevStudyUsed;
                prevLeaveStat.studyTotal = studyTotal;
                prevLeaveStat.studyLeft = studyTotal - prevStudyUsed;

                prevLeaveStat.usedSpecial = prevUsedSpecial;

                if (new Date() > startDate && new Date() < addYears(startDate, 1)) {
                    if (prevTakeOnStartBalance !== undefined) {
                        prevLeaveStat.takeOnStartBalance = prevTakeOnStartBalance;
                    }
                }

                prevLeaveStat.periodType = "Leave";

                await firestore.collection("leaveStatus").doc(prevLeaveYearCreated.id).update(prevLeaveStat);
            }
        }
    }

    let leaveStatsNew = await firestore.collection("leaveStatus").where("userUid", "==", userData.id).where("toDate", "==", timestamp1).where("periodType", "==", "Leave").get();
    // console.log("EMPTY()", leaveStatsNew.empty);

    //if no entry exists with last day of period, create new entry
    if (leaveStatsNew.empty) {
        //create profile for current time period
        let userDetails = {};
        userDetails.companyId = userData.companyId;
        userDetails.companyname = userData.companyName;
        userDetails.userDisplayName = userData.displayName;
        userDetails.userUid = userUid;

        //set time period based on company leave type
        if (companyData.leaveType === "Anniversary Date of Employee") {
            let monthNow = new Date().getMonth() + 1;
            let monthStart = parseInt(format(startDate, "MM"));

            if (monthNow >= monthStart) {
                userDetails.fromDate = new Date(new Date().getFullYear(), parseInt(format(startDate, "MM")) - 1, 1);
                userDetails.toDate = subDays(new Date(parseInt(new Date().getFullYear()) + 1, parseInt(format(startDate, "MM")) - 1, 1), 1);
            } else {
                userDetails.fromDate = new Date(parseInt(new Date().getFullYear()) - 1, parseInt(format(startDate, "MM")) - 1, 1);
                userDetails.toDate = subDays(new Date(new Date().getFullYear(), parseInt(format(startDate, "MM")) - 1, 1), 1);
            }
        } else if (companyData.leaveType === "Company Financial Year") {
            let monthNow = new Date().getMonth();
            let monthNewStart = parseInt(companyData.yearStart) - 1;

            if (monthNow >= monthNewStart) {
                userDetails.fromDate = new Date(new Date().getFullYear(), parseInt(companyData.yearStart) - 1, 1);
                userDetails.toDate = subDays(new Date(parseInt(new Date().getFullYear()) + 1, parseInt(companyData.yearStart) - 1, 1), 1);
            } else {
                userDetails.fromDate = new Date(parseInt(new Date().getFullYear()) - 1, parseInt(companyData.yearStart) - 1, 1);
                userDetails.toDate = subDays(new Date(new Date().getFullYear(), parseInt(companyData.yearStart) - 1, 1), 1);
            }
        } else if (companyData.leaveType === "January to December") {
            userDetails.fromDate = new Date(new Date().getFullYear(), 0, 1);
            userDetails.toDate = new Date(new Date().getFullYear(), 11, 31);

            if (companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date") {
                userDetails.toDate = new Date(new Date().getFullYear() + 1, 0, 31);
            }
        }

        let userFromDateNew =
            companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
                ? new Date(userDetails.fromDate.getFullYear(), userDetails.fromDate.getMonth(), companyData.leaveFromDay)
                : userDetails.fromDate;

        let userToDateNew =
            companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
                ? new Date(userDetails.toDate.getFullYear(), userDetails.toDate.getMonth(), companyData.leaveToDay)
                : userDetails.toDate;

        if (userFromDateNew !== userDetails.fromDate) {
            userDetails.fromDate = userFromDateNew;
        }

        if (userToDateNew !== userDetails.toDate) {
            userDetails.toDate = userToDateNew;
        }

        let newLeaveYearCreated = await firestore.collection("leaveStatus").add(userDetails);
        //set current month to loop through (using start date)
        let startCurrM = userDetails.fromDate;
        //set initial totals
        let annualTotal = 0;
        let annualUsed = 0;
        let allUsed = 0;
        let untrackUsed = 0;
        let familyUsed = 0;
        let maternityUsed = 0;
        let paternityUsed = 0;
        let IODUsed = 0;
        let parentalUsed = 0;
        let movingUsed = 0;
        let csrUsed = 0;
        let religiousUsed = 0;
        let studyUsed = 0;
        let accumUsed = 0;
        let accumUsed2 = 0;
        let takeOnStartBalance = 0;
        let usedSpecial = 0;
        //Left

        if (startDate > userDetails.fromDate) {
            var familyTotal = parseFloat(
                userData.familyBalance ? userData.familyBalance : userData.leaveTotalFamily ? userData.leaveTotalFamily : companyData.leaveTotalFamily ? companyData.leaveTotalFamily : 0
            );
            var maternityTotal = parseFloat(
                userData.maternityBalance
                    ? userData.maternityBalance
                    : userData.leaveTotalMaternity
                    ? userData.leaveTotalMaternity
                    : companyData.leaveTotalMaternity
                    ? companyData.leaveTotalMaternity
                    : 0
            );
            var paternityTotal = parseFloat(
                userData.paternityBalance
                    ? userData.paternityBalance
                    : userData.leaveTotalPaternity
                    ? userData.leaveTotalPaternity
                    : companyData.leaveTotalPaternity
                    ? companyData.leaveTotalPaternity
                    : 0
            );
            var IODTotal = parseFloat(userData.IODBalance ? userData.IODBalance : userData.leaveTotalIOD ? userData.leaveTotalIOD : companyData.leaveTotalIOD ? companyData.leaveTotalIOD : 0);
            var parentalTotal = parseFloat(
                userData.parentalBalance ? userData.parentalBalance : userData.leaveTotalParental ? userData.leaveTotalParental : companyData.leaveTotalParental ? companyData.leaveTotalParental : 0
            );
            var movingTotal = parseFloat(
                userData.movingBalance ? userData.movingBalance : userData.leaveTotalMoving ? userData.leaveTotalMoving : companyData.leaveTotalMoving ? companyData.leaveTotalMoving : 0
            );
            var csrTotal = parseFloat(userData.CSRBalance ? userData.CSRBalance : userData.leaveTotalCSR ? userData.leaveTotalCSR : companyData.leaveTotalCSR ? companyData.leaveTotalCSR : 0);
            var religiousTotal = parseFloat(
                userData.religiousBalance
                    ? userData.religiousBalance
                    : userData.leaveTotalReligious
                    ? userData.leaveTotalReligious
                    : companyData.leaveTotalReligious
                    ? companyData.leaveTotalReligious
                    : 0
            );
            var studyTotal = parseFloat(
                userData.studyBalance ? userData.studyBalance : userData.leaveTotalStudy ? userData.leaveTotalStudy : companyData.leaveTotalStudy ? companyData.leaveTotalStudy : 0
            );

            var incentiveTotal = 0;
            var accumTotal = userData.accumBalance ? userData.accumBalance : 0;
            var accumTotal2 = 0;
        } else {
            var familyTotal = parseFloat(userData.leaveTotalFamily ? userData.leaveTotalFamily : companyData.leaveTotalFamily ? companyData.leaveTotalFamily : 0);
            var maternityTotal = parseFloat(userData.leaveTotalMaternity ? userData.leaveTotalMaternity : companyData.leaveTotalMaternity ? companyData.leaveTotalMaternity : 0);
            var paternityTotal = parseFloat(userData.leaveTotalPaternity ? userData.leaveTotalPaternity : companyData.leaveTotalPaternity ? companyData.leaveTotalPaternity : 0);
            var IODTotal = parseFloat(userData.leaveTotalIOD ? userData.leaveTotalIOD : companyData.leaveTotalIOD ? companyData.leaveTotalIOD : 0);
            var parentalTotal = parseFloat(userData.leaveTotalParental ? userData.leaveTotalParental : companyData.leaveTotalParental ? companyData.leaveTotalParental : 0);
            var movingTotal = parseFloat(userData.leaveTotalMoving ? userData.leaveTotalMoving : companyData.leaveTotalMoving ? companyData.leaveTotalMoving : 0);
            var csrTotal = parseFloat(userData.leaveTotalCSR ? userData.leaveTotalCSR : companyData.leaveTotalCSR ? companyData.leaveTotalCSR : 0);
            var religiousTotal = parseFloat(userData.leaveTotalReligious ? userData.leaveTotalReligious : companyData.leaveTotalReligious ? companyData.leaveTotalReligious : 0);
            var studyTotal = parseFloat(userData.leaveTotalStudy ? userData.leaveTotalStudy : companyData.leaveTotalStudy ? companyData.leaveTotalStudy : 0);

            var incentiveTotal = 0;
            var accumTotal = 0;
            var accumTotal2 = 0;
        }

        // this is the one that's causing our issue
        // write a check to see if

        let startBalance = userData.takeOnBalance;
        // let startBalance = 0;
        let totalToStart = 12;

        // but this one produces the same result when changed
        let allStartTotal = userData.takeOnBalance;
        // let allStartTotal = 0;

        //loop through months to add and create totals per month
        while (userDetails.toDate > startCurrM) {
            let monthlyTotals = {};

            if (companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date") {
                monthlyTotals.datePeriod = startCurrM;
                monthlyTotals.datePeriodTo = new Date(startCurrM.getFullYear(), addMonths(startCurrM, 1).getMonth(), parseFloat(companyData.leaveToDay));
            } else {
                monthlyTotals.datePeriod = startCurrM;
            }

            //check if user has started yet - don't allow leave if not employed
            if (startCurrM >= startDate) {
                // console.log(startBalance);
                if (startBalance == 0 || startBalance === undefined) {
                    annualTotal = annualTotal + parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0);
                }

                if (new Date() > userDetails.fromDate && new Date() < userDetails.toDate) {
                    if (startBalance !== 0 && startBalance !== undefined) {
                        annualTotal = parseFloat(startBalance);
                        if (startBalance !== 0) {
                            takeOnStartBalance = startBalance;
                        }
                    }
                    startBalance = 0;
                }
            } else {
                totalToStart = totalToStart - 1;
            }

            if ((companyData.leaveRolloverType && companyData.leaveRolloverType !== "None") || (userData.leaveRolloverType && userData.leaveRolloverType !== "None")) {
                if (
                    companyData.leaveRolloverType === "Accumulative" ||
                    companyData.leaveRolloverType === "Annual Rollover" ||
                    userData.leaveRolloverType === "Accumulative" ||
                    userData.leaveRolloverType === "Annual Rollover"
                ) {
                    let prevLeaveStats = await firestore
                        .collection("leaveStatus")
                        .where("userUid", "==", userData.id)
                        .where("toDate", "==", subYears(currentEndDate, 1))
                        .where("periodType", "==", "Leave")
                        .get();
                    // console.log(subYears(currentEndDate, 1));

                    let rollOverType = "";
                    let type = "";

                    if (companyData.leaveRolloverType === "Accumulative" || userData.leaveRolloverType === "Accumulative") {
                        rollOverType = "Accumulative";
                        type = userData.leaveRolloverType && userData.leaveRolloverType !== "" ? "User" : "Company";
                    } else if (companyData.leaveRolloverType === "Annual Rollover" || userData.leaveRolloverType === "Annual Rollover") {
                        rollOverType = "Annual Rollover";
                        type = userData.leaveRolloverType && userData.leaveRolloverType !== "" ? "User" : "Company";
                    }

                    let accumMax;
                    // console.log(type, companyData, userData);
                    if (type == "User") {
                        if (userData.leaveAccumulativeMaximum && userData.leaveAccumulativeMaximum !== "") {
                            accumMax = userData.leaveAccumulativeMaximum;
                        } else if (companyData.leaveAccumulativeMaximum && companyData.leaveAccumulativeMaximum !== "") {
                            accumMax = companyData.leaveAccumulativeMaximum;
                        }
                    } else if (type == "Company") {
                        if (companyData.leaveAccumulativeMaximum && companyData.leaveAccumulativeMaximum !== "") {
                            accumMax = companyData.leaveAccumulativeMaximum;
                        }
                    }

                    if (rollOverType === "Accumulative") {
                        let startMonth = userDetails.fromDate;
                        let cutOffDate = type == "Company" ? addMonths(startMonth, parseInt(companyData.leaveAccumulativeForfeit)) : addMonths(startMonth, parseInt(userData.leaveAccumulativeForfeit));

                        if (startCurrM < cutOffDate) {
                            if (prevLeaveStats.empty) {
                                if (accumMax) {
                                    accumTotal = userData.accumBalance && userData.accumBalance > accumMax ? accumMax : userData.accumBalance <= accumMax ? userData.accumBalance : 0;
                                } else {
                                    accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                                }
                            } else {
                                let prevLeaveStatsThisMonth = await firestore.collection("leaveStatus").doc(prevLeaveStats.docs[0].id).collection("Months").get();
                                let months = [];

                                // eslint-disable-next-line no-loop-func
                                Object.keys(prevLeaveStatsThisMonth.docs).forEach(function (key) {
                                    months.push({ annualLeft: prevLeaveStatsThisMonth.docs[key].data().annualLeft, datePeriod: prevLeaveStatsThisMonth.docs[key].data().datePeriod.toDate() });
                                });

                                // eslint-disable-next-line no-loop-func
                                Object.keys(months).forEach(function (key) {
                                    if (months[key].datePeriod.getMonth() === startCurrM.getMonth()) {
                                        if (accumMax) {
                                            accumTotal = months[key].annualLeft > accumMax ? accumMax : months[key].annualLeft <= accumMax ? months[key].annualLeft : 0;
                                        } else {
                                            accumTotal = months[key].annualLeft ? months[key].annualLeft : 0;
                                        }
                                    }
                                });
                            }
                        } else {
                            if (prevLeaveStats.empty) {
                                let totalAccumTotal;
                                if (accumMax) {
                                    totalAccumTotal = userData.accumBalance > accumMax ? accumMax : userData.accumBalance <= accumMax ? userData.accumBalance : 0;
                                } else {
                                    totalAccumTotal = userData.accumBalance ? userData.accumBalance : 0;
                                }

                                if (type === "Company") {
                                    if (totalAccumTotal > companyData.leaveAccumulativeAfterForfeit) {
                                        if (accumMax) {
                                            accumTotal2 =
                                                companyData.leaveAccumulativeAfterForfeit > accumMax
                                                    ? accumMax
                                                    : companyData.leaveAccumulativeAfterForfeit <= accumMax
                                                    ? companyData.leaveAccumulativeAfterForfeit
                                                    : 0;
                                        } else {
                                            accumTotal2 = companyData.leaveAccumulativeAfterForfeit ? companyData.leaveAccumulativeAfterForfeit : 0;
                                        }
                                    } else {
                                        if (accumMax) {
                                            accumTotal2 =
                                                companyData.leaveAccumulativeAfterForfeit > accumMax
                                                    ? accumMax
                                                    : companyData.leaveAccumulativeAfterForfeit <= accumMax
                                                    ? companyData.leaveAccumulativeAfterForfeit
                                                    : 0;
                                        } else {
                                            accumTotal2 = companyData.leaveAccumulativeAfterForfeit ? companyData.leaveAccumulativeAfterForfeit : 0;
                                        }
                                    }
                                } else {
                                    if (totalAccumTotal > userData.leaveAccumulativeAfterForfeit) {
                                        if (accumMax) {
                                            accumTotal2 =
                                                userData.leaveAccumulativeAfterForfeit > accumMax
                                                    ? accumMax
                                                    : userData.leaveAccumulativeAfterForfeit <= accumMax
                                                    ? userData.leaveAccumulativeAfterForfeit
                                                    : 0;
                                        } else {
                                            accumTotal2 = userData.leaveAccumulativeAfterForfeit ? userData.leaveAccumulativeAfterForfeit : 0;
                                        }
                                    } else {
                                        if (accumMax) {
                                            accumTotal2 =
                                                userData.leaveAccumulativeAfterForfeit > accumMax
                                                    ? accumMax
                                                    : userData.leaveAccumulativeAfterForfeit <= accumMax
                                                    ? userData.leaveAccumulativeAfterForfeit
                                                    : 0;
                                        } else {
                                            accumTotal2 = userData.leaveAccumulativeAfterForfeit ? userData.leaveAccumulativeAfterForfeit : 0;
                                        }
                                    }
                                }

                                if (accumMax) {
                                    accumTotal = userData.accumBalance > accumMax ? accumMax : userData.accumBalance <= accumMax ? userData.accumBalance : 0;
                                } else {
                                    accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                                }
                            } else {
                                let prevLeaveStatsThisMonth = await firestore.collection("leaveStatus").doc(prevLeaveStats.docs[0].id).collection("Months").get();
                                let months = [];

                                // eslint-disable-next-line no-loop-func
                                Object.keys(prevLeaveStatsThisMonth.docs).forEach(function (key) {
                                    months.push({ annualLeft: prevLeaveStatsThisMonth.docs[key].data().annualLeft, datePeriod: prevLeaveStatsThisMonth.docs[key].data().datePeriod.toDate() });
                                });

                                // eslint-disable-next-line no-loop-func
                                Object.keys(months).forEach(function (key) {
                                    if (months[key].datePeriod.getMonth() === startCurrM.getMonth()) {
                                        if (type === "Company") {
                                            if (parseFloat(months[key].annualLeft) >= parseFloat(companyData.leaveAccumulativeAfterForfeit)) {
                                                if (accumMax) {
                                                    accumTotal =
                                                        companyData.leaveAccumulativeAfterForfeit > accumMax
                                                            ? accumMax
                                                            : companyData.leaveAccumulativeAfterForfeit <= accumMax
                                                            ? companyData.leaveAccumulativeAfterForfeit
                                                            : 0;
                                                } else {
                                                    accumTotal = companyData.leaveAccumulativeAfterForfeit ? companyData.leaveAccumulativeAfterForfeit : 0;
                                                }
                                            } else {
                                                if (accumMax) {
                                                    accumTotal = months[key].annualLeft > accumMax ? accumMax : months[key].annualLeft <= accumMax ? months[key].annualLeft : 0;
                                                } else {
                                                    accumTotal = months[key].annualLeft ? months[key].annualLeft : 0;
                                                }
                                            }
                                        } else {
                                            if (parseFloat(months[key].annualLeft) >= parseFloat(userData.leaveAccumulativeAfterForfeit)) {
                                                if (accumMax) {
                                                    accumTotal =
                                                        userData.leaveAccumulativeAfterForfeit > accumMax
                                                            ? accumMax
                                                            : userData.leaveAccumulativeAfterForfeit <= accumMax
                                                            ? userData.leaveAccumulativeAfterForfeit
                                                            : 0;
                                                } else {
                                                    accumTotal = userData.leaveAccumulativeAfterForfeit ? userData.leaveAccumulativeAfterForfeit : 0;
                                                }
                                                accumTotal = userData.leaveAccumulativeAfterForfeit;
                                            } else {
                                                if (accumMax) {
                                                    accumTotal = months[key].annualLeft > accumMax ? accumMax : months[key].annualLeft <= accumMax ? months[key].annualLeft : 0;
                                                } else {
                                                    accumTotal = months[key].annualLeft ? months[key].annualLeft : 0;
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    } else if (rollOverType === "Annual Rollover") {
                        if (prevLeaveStats.empty) {
                            accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                        } else {
                            let prevLeaveStatsThisMonth = await firestore.collection("leaveStatus").doc(prevLeaveStats.docs[0].id).collection("Months").get();
                            let months = [];

                            // eslint-disable-next-line no-loop-func
                            Object.keys(prevLeaveStatsThisMonth.docs).forEach(function (key) {
                                months.push({
                                    annualLeft: prevLeaveStatsThisMonth.docs[key].data().annualLeft,
                                    datePeriod: prevLeaveStatsThisMonth.docs[key].data().datePeriod.toDate(),
                                    accumTotal: prevLeaveStatsThisMonth.docs[key].data().accumTotal,
                                });
                            });

                            months.sort((a, b) => (a.datePeriod < b.datePeriod ? 1 : -1));
                            let lastAmount = months[0];
                            accumTotal = parseFloat(lastAmount.annualLeft) + parseFloat(lastAmount.accumTotal);
                        }
                    }
                }
            }

            let dtFilterFrom = startCurrM;
            let dtFilterTo =
                companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
                    ? new Date(startCurrM.getFullYear(), addMonths(startCurrM, 1).getMonth(), parseFloat(companyData.leaveToDay))
                    : endOfMonth(startCurrM);

            let filtertype = "createDate";

            if (companyData.leaveTrackingType === "Leave Month") {
                await firestore
                    .collection("leave")
                    .where("userUid", "==", userUid)
                    .where("createDate", ">=", dtFilterFrom)
                    .where("createDate", "<", dtFilterTo)
                    .where("status", "==", "Approved")
                    .get()
                    // eslint-disable-next-line no-loop-func
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(async function (doc) {
                            let this_data = { ...doc.data(), id: doc.id };

                            if (!this_data.unpaidLeave || this_data.unpaidLeave === "No") {
                                if (this_data.leaveType === "Annual") {
                                    if (this_data.dontTrackUsed === false || this_data.dontTrackUsed === undefined) {
                                        if (
                                            (this_data.useAccumulative !== "Yes" || this_data.useAccumulative == undefined) &&
                                            (this_data.useAccumulative2 !== "Yes" || this_data.useAccumulative2 === undefined)
                                        ) {
                                            allUsed = parseFloat(allUsed) + parseFloat(this_data.annualDays);
                                            allUsed = parseFloat(allUsed.toFixed(2));
                                        }
                                    }
                                }
                            }
                        });
                    });

                filtertype = "annualFrom";
            }

            await firestore
                .collection("leave")
                .where("userUid", "==", userUid)
                .where(filtertype, ">=", dtFilterFrom)
                .where(filtertype, "<", dtFilterTo)
                .where("status", "==", "Approved")
                .get()
                // eslint-disable-next-line no-loop-func
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        let this_data = { ...doc.data(), id: doc.id };

                        //Skip unpaid leave -- untrack
                        if (!this_data.unpaidLeave || this_data.unpaidLeave === "No") {
                            if (this_data.leaveType === "Annual") {
                                if (this_data.dontTrackUsed && this_data.dontTrackUsed === true) {
                                    untrackUsed = parseFloat(untrackUsed) + parseFloat(this_data.annualDays);
                                    untrackUsed = parseFloat(untrackUsed.toFixed(2));
                                } else {
                                    if (this_data.useAccumulative && this_data.useAccumulative === "Yes") {
                                        accumUsed = parseFloat(accumUsed) + parseFloat(this_data.annualDays);
                                        accumUsed = parseFloat(accumUsed.toFixed(2));
                                    } else if (this_data.useAccumulative2 && this_data.useAccumulative2 === "Yes") {
                                        accumUsed2 = parseFloat(accumUsed2) + parseFloat(this_data.annualDays);
                                        accumUsed2 = parseFloat(accumUsed2.toFixed(2));
                                    } else {
                                        annualUsed = parseFloat(annualUsed) + parseFloat(this_data.annualDays);
                                        annualUsed = parseFloat(annualUsed.toFixed(2));
                                    }
                                }
                            } else if (this_data.leaveType === "Family") {
                                familyUsed = parseFloat(familyUsed) + parseFloat(this_data.annualDays);
                                familyUsed = parseFloat(familyUsed.toFixed(2));
                            } else if (this_data.leaveType === "Maternity") {
                                maternityUsed = parseFloat(maternityUsed) + parseFloat(this_data.annualDays);
                                maternityUsed = parseFloat(maternityUsed.toFixed(2));
                            } else if (this_data.leaveType === "Paternity") {
                                paternityUsed = parseFloat(paternityUsed) + parseFloat(this_data.annualDays);
                                paternityUsed = parseFloat(paternityUsed.toFixed(2));
                            } else if (this_data.leaveType === "IOD") {
                                IODUsed = parseFloat(IODUsed) + parseFloat(this_data.annualDays);
                                IODUsed = parseFloat(IODUsed.toFixed(2));
                            } else if (this_data.leaveType === "Parental") {
                                parentalUsed = parseFloat(parentalUsed) + parseFloat(this_data.annualDays);
                                parentalUsed = parseFloat(parentalUsed.toFixed(2));
                            } else if (this_data.leaveType === "Moving") {
                                movingUsed = parseFloat(movingUsed) + parseFloat(this_data.annualDays);
                                movingUsed = parseFloat(movingUsed.toFixed(2));
                            } else if (this_data.leaveType === "CSR") {
                                csrUsed = parseFloat(csrUsed) + parseFloat(this_data.annualDays);
                                csrUsed = parseFloat(csrUsed.toFixed(2));
                            } else if (this_data.leaveType === "Religious") {
                                religiousUsed = parseFloat(religiousUsed) + parseFloat(this_data.annualDays);
                                religiousUsed = parseFloat(religiousUsed.toFixed(2));
                            } else if (this_data.leaveType === "Study") {
                                studyUsed = parseFloat(studyUsed) + parseFloat(this_data.annualDays);
                                studyUsed = parseFloat(studyUsed.toFixed(2));
                            } else if (this_data.leaveType === "Special") {
                                usedSpecial = parseFloat(usedSpecial) + parseFloat(this_data.annualDays);
                                usedSpecial = parseFloat(usedSpecial.toFixed(2));
                            } else if (this_data.leaveType === "Incentive") {
                                incentiveTotal = parseFloat(incentiveTotal) + parseFloat(this_data.annualDays);
                                incentiveTotal = parseFloat(incentiveTotal.toFixed(2));
                                annualTotal = parseFloat(annualTotal) + parseFloat(this_data.annualDays);
                                annualTotal = parseFloat(annualTotal).toFixed(2);
                            }
                        }

                        // await firestore.collection("leave").doc(this_data.id).update({ tracked: true });
                    });
                });

            //Save Totals
            monthlyTotals.annualTotal = annualTotal;
            monthlyTotals.incentiveTotal = incentiveTotal;

            let total1 = parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0) * totalToStart;
            // let total2 = total1 - parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0);
            let total3 = total1 + parseFloat(allStartTotal !== undefined ? allStartTotal : "0");

            if (startCurrM >= startDate) {
                monthlyTotals.allTotal = total3;
                monthlyTotals.allUsed = allUsed;
                monthlyTotals.allLeft = monthlyTotals.allTotal - allUsed;
            } else {
                monthlyTotals.allTotal = 0;
                monthlyTotals.allUsed = 0;
                monthlyTotals.allLeft = 0;
            }

            //Save Total Used
            monthlyTotals.annualUsed = annualUsed;
            monthlyTotals.untrackUsed = untrackUsed;
            monthlyTotals.accumUsed = accumUsed;
            monthlyTotals.accumUsed2 = accumUsed2;

            monthlyTotals.annualLeft = annualTotal - annualUsed;

            if (accumTotal !== undefined) {
                monthlyTotals.accumTotal = accumTotal;
            } else {
                monthlyTotals.accumTotal = 0;
            }
            if (accumTotal2 !== undefined) {
                monthlyTotals.accumTotal2 = accumTotal2;
            } else {
                monthlyTotals.accumTotal2 = 0;
            }

            await firestore.collection("leaveStatus").doc(newLeaveYearCreated.id).collection("Months").add(monthlyTotals);

            startCurrM = addMonths(startCurrM, 1);
        }

        //Add nonGrowing back into main collection
        let leaveStat = {};
        leaveStat.familyUsed = familyUsed;
        leaveStat.familyTotal = familyTotal;
        leaveStat.familyLeft = familyTotal - familyUsed;

        leaveStat.maternityUsed = maternityUsed;
        leaveStat.maternityTotal = maternityTotal;
        leaveStat.maternityLeft = maternityTotal - maternityUsed;

        leaveStat.paternityUsed = paternityUsed;
        leaveStat.paternityTotal = paternityTotal;
        leaveStat.paternityLeft = paternityTotal - paternityUsed;

        leaveStat.IODUsed = IODUsed;
        leaveStat.IODTotal = IODTotal;
        leaveStat.IODLeft = IODTotal - IODUsed;

        leaveStat.parentalUsed = parentalUsed;
        leaveStat.parentalTotal = parentalTotal;
        leaveStat.parentalLeft = parentalTotal - parentalUsed;

        leaveStat.movingUsed = movingUsed;
        leaveStat.movingTotal = movingTotal;
        leaveStat.movingLeft = movingTotal - movingUsed;

        leaveStat.csrUsed = csrUsed;
        leaveStat.csrTotal = csrTotal;
        leaveStat.csrLeft = csrTotal - csrUsed;

        leaveStat.religiousUsed = religiousUsed;
        leaveStat.religiousTotal = religiousTotal;
        leaveStat.religiousLeft = religiousTotal - religiousUsed;

        leaveStat.studyUsed = studyUsed;
        leaveStat.studyTotal = studyTotal;
        leaveStat.studyLeft = studyTotal - studyUsed;

        leaveStat.usedSpecial = usedSpecial;

        if (new Date() > startDate && new Date() < addYears(startDate, 1)) {
            if (takeOnStartBalance !== undefined) {
                leaveStat.takeOnStartBalance = takeOnStartBalance;
            }
        }

        leaveStat.periodType = "Leave";

        await firestore.collection("leaveStatus").doc(newLeaveYearCreated.id).update(leaveStat);
    } else {
        await generateNew(userUid);
    }
    // }
}

export async function genSickLeave(userUid) {
    const firestore = firebase.firestore();

    //get user info
    let userQuery = await firestore.collection("users").doc(userUid).get();
    let userData = userQuery.data();

    //get users company info
    let companyInfo = await firestore.collection("companies").doc(userData.companyId).get();
    let companyData = companyInfo.data();

    // CREATE SICK LEAVE PERIOD
    let dateNow = new Date();

    let sickLeave = firestore.collection("leaveStatus").where("userUid", "==", userUid).where("periodType", "==", "Sick").where("toDate", ">", dateNow).get();
    let sickLeaveNow = {};

    await sickLeave.then(function (querySnapshot) {
        if (querySnapshot.empty === false) {
            sickLeaveNow = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
        }
    });

    let sickStartDate = "";

    if (userData.takeOnStartDate !== undefined && userData.takeOnStartDate !== "") {
        sickStartDate = userData.takeOnStartDate.toDate();
    } else if (userData.employmentStartDate !== undefined && userData.employmentStartDate !== "") {
        sickStartDate = userData.employmentStartDate.toDate();
    } else {
        sickStartDate = userData.createdAt.toDate();
    }

    //See if sick leave exists for current period
    if (sickLeaveNow.hasOwnProperty("id")) {
        let sickUsed = 0;
        // console.log("1");

        //add new sick leave
        await firestore
            .collection("leave")
            .where("userUid", "==", userUid)
            .where("createDate", ">=", sickLeaveNow.fromDate.toDate())
            .where("createDate", "<", sickLeaveNow.toDate.toDate())
            .where("leaveType", "==", "Sick")
            .where("status", "==", "Approved")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    let this_data = { ...doc.data(), id: doc.id };
                    sickUsed = parseFloat(sickUsed) + parseFloat(this_data.annualDays);
                });
            });

        let allSickLeave = firestore
            .collection("leaveStatus")
            .where("userUid", "==", userUid)
            .where("periodType", "==", "Sick")
            .where("toDate", "<=", subDays(sickLeaveNow.fromDate.toDate(), 1))
            .get();

        await allSickLeave.then(function (querySnapshot) {
            if (querySnapshot.empty === true) {
                sickLeaveNow.sickTotal = parseFloat(userData.sickbalance ? userData.sickbalance : companyData.leaveTotalSick);
                sickLeaveNow.sickLeft = parseFloat(userData.sickbalance ? userData.sickbalance : companyData.leaveTotalSick) - sickUsed;
            } else {
                sickLeaveNow.sickTotal = parseFloat(companyData.leaveTotalSick);
                sickLeaveNow.sickLeft = parseFloat(companyData.leaveTotalSick) - sickUsed;
            }
        });

        //update sick leave
        sickLeaveNow.sickUsed = sickUsed;
        sickLeaveNow.sickLeft = sickLeaveNow.sickTotal - sickUsed;

        await firestore.collection("leaveStatus").doc(sickLeaveNow.id).update(sickLeaveNow);
    } else {
        //add 3 years to start date to get first period
        let startUserDate = subDays(addYears(sickStartDate, 3), 1);

        //if first 3 year period falls in current period
        if (startUserDate > dateNow) {
            // console.log("2");
            let sickUsed = 0;

            //Add leave
            await firestore
                .collection("leave")
                .where("userUid", "==", userUid)
                .where("createDate", ">=", sickStartDate)
                .where("createDate", "<", dateNow)
                .where("leaveType", "==", "Sick")
                .where("status", "==", "Approved")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        let this_data = { ...doc.data(), id: doc.id };
                        sickUsed = parseFloat(sickUsed) + parseFloat(this_data.annualDays);
                    });
                });

            //new entry for leave for list period
            let newEntry = {
                periodType: "Sick",
                fromDate: sickStartDate,
                toDate: subDays(addYears(sickStartDate, 3), 1),
                userUid: userData.id,
                userDisplayName: userData.displayName,
                companyId: userData.companyId,
                companyName: userData.companyName,
                sickUsed: sickUsed,
            };

            let allSickLeave = firestore.collection("leaveStatus").where("userUid", "==", userUid).where("periodType", "==", "Sick").get();

            allSickLeave.then(function (querySnapshot) {
                if (querySnapshot.empty === true) {
                    newEntry.sickTotal = parseFloat(userData.sickbalance ? userData.sickbalance : companyData.leaveTotalSick);
                    newEntry.sickLeft = parseFloat(userData.sickbalance ? userData.sickbalance : companyData.leaveTotalSick) - sickUsed;
                } else {
                    newEntry.sickTotal = parseFloat(companyData.leaveTotalSick);
                    newEntry.sickLeft = parseFloat(companyData.leaveTotalSick) - sickUsed;
                }
            });

            let sickExists = firestore
                .collection("leaveStatus")
                .where("userUid", "==", userUid)
                .where("periodType", "==", "Sick")
                .where("toDate", ">=", subDays(addYears(sickStartDate, 3), 1))
                .get();
            let sick = {};

            await sickExists.then(function (querySnapshot) {
                if (querySnapshot.empty === false) {
                    sick = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
                }
            });

            if (sick.hasOwnProperty("id")) {
                await firestore.collection("leaveStatus").doc(sick.id).update(newEntry);
            } else {
                await firestore.collection("leaveStatus").add(newEntry);
            }
        } else {
            // console.log("3");
            //start date doesn't fall in first period
            let startUserDate = sickStartDate;
            let userNewDate = addYears(startUserDate, 3);

            //add 3 years to start date to find current period
            while (userNewDate <= dateNow) {
                userNewDate = addYears(userNewDate, 3);
            }

            //get start of current period
            let startNewDate = subYears(userNewDate, 3);

            let sickUsed = 0;

            //add leave
            await firestore
                .collection("leave")
                .where("userUid", "==", userUid)
                .where("createDate", ">=", startNewDate)
                .where("createDate", "<", userNewDate)
                .where("leaveType", "==", "Sick")
                .where("status", "==", "Approved")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        let this_data = { ...doc.data(), id: doc.id };
                        sickUsed = parseFloat(sickUsed) + parseFloat(this_data.annualDays);
                    });
                });

            //create leave for current period
            let newEntry = {
                periodType: "Sick",
                fromDate: startNewDate,
                toDate: subDays(userNewDate, 1),
                userUid: userData.id,
                userDisplayName: userData.displayName,
                companyId: userData.companyId,
                companyName: userData.companyName,
                sickUsed: sickUsed,
            };

            let allSickLeave = firestore.collection("leaveStatus").where("userUid", "==", userUid).where("periodType", "==", "Sick").get();

            allSickLeave.then(function (querySnapshot) {
                if (querySnapshot.empty === true) {
                    newEntry.sickTotal = parseFloat(userData.sickbalance ? userData.sickbalance : companyData.leaveTotalSick);
                    newEntry.sickLeft = parseFloat(userData.sickbalance ? userData.sickbalance : companyData.leaveTotalSick) - sickUsed;
                } else {
                    newEntry.sickTotal = parseFloat(companyData.leaveTotalSick);
                    newEntry.sickLeft = parseFloat(companyData.leaveTotalSick) - sickUsed;
                }
            });

            let sickExists = firestore.collection("leaveStatus").where("userUid", "==", userUid).where("periodType", "==", "Sick").where("toDate", ">=", subDays(userNewDate, 1)).get();
            let sick = {};

            await sickExists.then(function (querySnapshot) {
                if (querySnapshot.empty === false) {
                    sick = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
                }
            });

            if (sick.hasOwnProperty("id")) {
                await firestore.collection("leaveStatus").doc(sick.id).update(newEntry);
            } else {
                await firestore.collection("leaveStatus").add(newEntry);
            }
        }
    }
}

export async function generateNew(userUid) {
    const firestore = firebase.firestore();

    //get user info
    let userQuery = await firestore.collection("users").doc(userUid).get();
    let userData = userQuery.data();

    //get users company info
    let companyInfo = await firestore.collection("companies").doc(userData.companyId).get();
    let companyData = companyInfo.data();

    let currentEndDate = "";
    let timestamp1 = "";
    let fromDate = "";

    let startDate = "";

    //If take on date is set use Take on otherwise use start date otherwise use created date
    if (userData.takeOnStartDate !== undefined && userData.takeOnStartDate !== "") {
        startDate = userData.takeOnStartDate.toDate();
        startDate.setDate(1);
    } else if (userData.employmentStartDate !== undefined && userData.employmentStartDate !== "") {
        startDate = userData.employmentStartDate.toDate();
    } else {
        startDate = userData.createdAt.toDate();
    }

    //Check to what company leave is set to &get last day of period & assign data
    // set timestamp1 and currentEndDate based on company leave type
    if (companyData.leaveType === "Anniversary Date of Employee") {
        let monthNow = new Date().getMonth() + 1;
        let monthStart = parseInt(format(startDate, "MM"));
        if (monthNow >= monthStart) {
            fromDate = new Date(new Date().getFullYear(), parseInt(format(startDate, "MM")) - 1, 1);
            currentEndDate = subDays(new Date(parseInt(new Date().getFullYear()) + 1, parseInt(format(startDate, "MM")) - 1, 1), 1);
            // currentEndDate = subDays(new Date(parseInt(new Date().getFullYear()), parseInt(format(startDate, "MM")) - 1, 1), 1);
        } else {
            fromDate = new Date(parseInt(new Date().getFullYear()) - 1, parseInt(format(startDate, "MM")) - 1, 1);
            // currentEndDate = subDays(new Date(new Date().getFullYear() + 1, parseInt(format(startDate, "MM")) - 1, 1), 1);
            currentEndDate = subDays(new Date(new Date().getFullYear(), parseInt(format(startDate, "MM")) - 1, 1), 1);
        }
        timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);
    } else if (companyData.leaveType === "Company Financial Year") {
        let monthNow = new Date().getMonth();
        let monthNewStart = parseInt(companyData.yearStart) - 1;
        if (monthNow >= monthNewStart) {
            fromDate = new Date(new Date().getFullYear(), parseInt(companyData.yearStart) - 1, 1);
            currentEndDate = subDays(new Date(parseInt(new Date().getFullYear()) + 1, parseInt(companyData.yearStart) - 1, 1), 1);
        } else {
            fromDate = new Date(parseInt(new Date().getFullYear()) - 1, parseInt(companyData.yearStart) - 1, 1);
            currentEndDate = subDays(new Date(new Date().getFullYear(), parseInt(companyData.yearStart) - 1, 1), 1);
        }
        timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);
    } else if (companyData.leaveType === "January to December") {
        fromDate = new Date(new Date().getFullYear(), 0, 1);
        currentEndDate = new Date(new Date().getFullYear(), 11, 31);
        timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);

        if (companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date") {
            currentEndDate = new Date(new Date().getFullYear() + 1, 0, 31);
            timestamp1 = firebase.firestore.Timestamp.fromDate(currentEndDate);
        }
    }

    let startCurrM_Old = timestamp1.toDate();
    let toDate_Old = currentEndDate;

    let startCurrM_new =
        companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
            ? new Date(timestamp1.toDate().getFullYear(), timestamp1.toDate().getMonth(), companyData.leaveToDay)
            : timestamp1.toDate();
    let toDate_old =
        companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
            ? new Date(currentEndDate.getFullYear(), currentEndDate.getMonth(), companyData.leaveToDay)
            : currentEndDate;

    if (startCurrM_Old !== startCurrM_new) {
        timestamp1 = startCurrM_new;
    }

    if (toDate_Old !== toDate_old) {
        currentEndDate = toDate_old;
    }

    let leaveStats = await firestore.collection("leaveStatus").where("userUid", "==", userData.id).where("toDate", "==", timestamp1).where("periodType", "==", "Leave").get();

    let startCurrM = leaveStats.docs[0].data().fromDate.toDate();
    let toDate = leaveStats.docs[0].data().toDate.toDate();

    //set initial totals
    let annualTotal = 0;
    let annualUsed = 0;
    let allUsed = 0;
    let untrackUsed = 0;
    let familyUsed = 0;
    let maternityUsed = 0;
    let paternityUsed = 0;
    let IODUsed = 0;
    let parentalUsed = 0;
    let movingUsed = 0;
    let csrUsed = 0;
    let religiousUsed = 0;
    let studyUsed = 0;
    let accumUsed = 0;
    let accumUsed2 = 0;
    let takeOnStartBalance = 0;
    let usedSpecial = 0;

    let usedTotal = 0;

    //Left

    if (startDate > startCurrM) {
        var familyTotal = parseFloat(
            userData.familyBalance ? userData.familyBalance : userData.leaveTotalFamily ? userData.leaveTotalFamily : companyData.leaveTotalFamily ? companyData.leaveTotalFamily : 0
        );
        var maternityTotal = parseFloat(
            userData.maternityBalance ? userData.maternityBalance : userData.leaveTotalMaternity ? userData.leaveTotalMaternity : companyData.leaveTotalMaternity ? companyData.leaveTotalMaternity : 0
        );
        var paternityTotal = parseFloat(
            userData.paternityBalance ? userData.paternityBalance : userData.leaveTotalPaternity ? userData.leaveTotalPaternity : companyData.leaveTotalPaternity ? companyData.leaveTotalPaternity : 0
        );
        var IODTotal = parseFloat(userData.IODBalance ? userData.IODBalance : userData.leaveTotalIOD ? userData.leaveTotalIOD : companyData.leaveTotalIOD ? companyData.leaveTotalIOD : 0);
        var parentalTotal = parseFloat(
            userData.parentalBalance ? userData.parentalBalance : userData.leaveTotalParental ? userData.leaveTotalParental : companyData.leaveTotalParental ? companyData.leaveTotalParental : 0
        );
        var movingTotal = parseFloat(
            userData.movingBalance ? userData.movingBalance : userData.leaveTotalMoving ? userData.leaveTotalMoving : companyData.leaveTotalMoving ? companyData.leaveTotalMoving : 0
        );
        var csrTotal = parseFloat(userData.CSRBalance ? userData.CSRBalance : userData.leaveTotalCSR ? userData.leaveTotalCSR : companyData.leaveTotalCSR ? companyData.leaveTotalCSR : 0);
        var religiousTotal = parseFloat(
            userData.religiousBalance ? userData.religiousBalance : userData.leaveTotalReligious ? userData.leaveTotalReligious : companyData.leaveTotalReligious ? companyData.leaveTotalReligious : 0
        );
        var studyTotal = parseFloat(
            userData.studyBalance ? userData.studyBalance : userData.leaveTotalStudy ? userData.leaveTotalStudy : companyData.leaveTotalStudy ? companyData.leaveTotalStudy : 0
        );
    } else {
        var familyTotal = parseFloat(userData.leaveTotalFamily ? userData.leaveTotalFamily : companyData.leaveTotalFamily ? companyData.leaveTotalFamily : 0);
        var maternityTotal = parseFloat(userData.leaveTotalMaternity ? userData.leaveTotalMaternity : companyData.leaveTotalMaternity ? companyData.leaveTotalMaternity : 0);
        var paternityTotal = parseFloat(userData.leaveTotalPaternity ? userData.leaveTotalPaternity : companyData.leaveTotalPaternity ? companyData.leaveTotalPaternity : 0);
        var IODTotal = parseFloat(userData.leaveTotalIOD ? userData.leaveTotalIOD : companyData.leaveTotalIOD ? companyData.leaveTotalIOD : 0);
        var parentalTotal = parseFloat(userData.leaveTotalParental ? userData.leaveTotalParental : companyData.leaveTotalParental ? companyData.leaveTotalParental : 0);
        var movingTotal = parseFloat(userData.leaveTotalMoving ? userData.leaveTotalMoving : companyData.leaveTotalMoving ? companyData.leaveTotalMoving : 0);
        var csrTotal = parseFloat(userData.leaveTotalCSR ? userData.leaveTotalCSR : companyData.leaveTotalCSR ? companyData.leaveTotalCSR : 0);
        var religiousTotal = parseFloat(userData.leaveTotalReligious ? userData.leaveTotalReligious : companyData.leaveTotalReligious ? companyData.leaveTotalReligious : 0);
        var studyTotal = parseFloat(userData.leaveTotalStudy ? userData.leaveTotalStudy : companyData.leaveTotalStudy ? companyData.leaveTotalStudy : 0);
    }

    let incentiveTotal = 0;
    let accumTotal = 0;
    let accumTotal2 = 0;
    let totalToStart = 12;
    let startBalance = userData.takeOnBalance;
    let allStartTotal = userData.takeOnBalance;

    let BeforeThis = await firestore.collection("leaveStatus").where("userUid", "==", userData.id).where("periodType", "==", "Leave").get();

    if (BeforeThis.docs.length > 1) {
        startBalance = 0;
        allStartTotal = 0;
    } else if (BeforeThis.docs.length === 1) {
        // if (BeforeThis.docs[0].data().toDate.toDate() != timestamp1.toDate()) {
        if (new Date(BeforeThis.docs[0].data().toDate.toDate()).toString() != new Date(timestamp1).toString()) {
            startBalance = 0;
            allStartTotal = 0;
        }
    }

    while (toDate > startCurrM) {
        let monthlyTotals = {};
        monthlyTotals.datePeriod = startCurrM;

        if (companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date") {
            monthlyTotals.datePeriod = startCurrM;
            monthlyTotals.datePeriodTo = new Date(startCurrM.getFullYear(), addMonths(startCurrM, 1).getMonth(), parseFloat(companyData.leaveToDay));
        } else {
            monthlyTotals.datePeriod = startCurrM;
        }

        //check if user has started yet - don't allow leave if not employed based on take on date

        if (startCurrM >= startDate) {
            if (startBalance == 0 || startBalance === undefined) {
                annualTotal = parseFloat(annualTotal) + parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0);
            }

            if (new Date() > startDate && new Date() < currentEndDate) {
                if (startBalance !== 0 && startBalance !== undefined) {
                    if (startBalance !== "") {
                        annualTotal = parseFloat(startBalance);
                    }
                    if (startBalance !== 0) {
                        takeOnStartBalance = startBalance;
                    }
                }
                startBalance = 0;
            }
        } else {
            totalToStart = totalToStart - 1;
        }

        if ((companyData.leaveRolloverType && companyData.leaveRolloverType !== "None") || (userData.leaveRolloverType && userData.leaveRolloverType !== "None")) {
            if (
                companyData.leaveRolloverType === "Accumulative" ||
                companyData.leaveRolloverType === "Annual Rollover" ||
                userData.leaveRolloverType === "Accumulative" ||
                userData.leaveRolloverType === "Annual Rollover"
            ) {
                let prevLeaveStats = await firestore
                    .collection("leaveStatus")
                    .where("userUid", "==", userData.id)
                    .where("toDate", "==", subYears(currentEndDate, 1))
                    .where("periodType", "==", "Leave")
                    .get();

                let rollOverType = "";
                let type = "";

                if (companyData.leaveRolloverType === "Accumulative" || userData.leaveRolloverType === "Accumulative") {
                    rollOverType = "Accumulative";
                    type = userData.leaveRolloverType && userData.leaveRolloverType !== "" ? "User" : "Company";
                } else if (companyData.leaveRolloverType === "Annual Rollover" || userData.leaveRolloverType === "Annual Rollover") {
                    rollOverType = "Annual Rollover";
                    type = userData.leaveRolloverType && userData.leaveRolloverType !== "" ? "User" : "Company";
                }

                let accumMax;
                if (type == "User") {
                    if (userData.leaveAccumulativeMaximum && userData.leaveAccumulativeMaximum !== "") {
                        // if type is user and maximum carryover is set, it'll use the user's value
                        accumMax = userData.leaveAccumulativeMaximum;
                    } else if (companyData.leaveAccumulativeMaximum && companyData.leaveAccumulativeMaximum !== "") {
                        // if type is user and no maximum carryover is set at a user level, it'll use the company maximum value
                        accumMax = companyData.leaveAccumulativeMaximum;
                    }
                } else if (type == "Company") {
                    if (companyData.leaveAccumulativeMaximum && companyData.leaveAccumulativeMaximum !== "") {
                        // if type is company it'll use the company maximum value
                        accumMax = companyData.leaveAccumulativeMaximum;
                    }
                }

                if (rollOverType === "Accumulative") {
                    let startMonth = fromDate;
                    let cutOffDate = type == "Company" ? addMonths(startMonth, parseInt(companyData.leaveAccumulativeForfeit)) : addMonths(startMonth, parseInt(userData.leaveAccumulativeForfeit));

                    if (startCurrM < cutOffDate) {
                        // console.log(prevLeaveStats);
                        if (prevLeaveStats.empty) {
                            if (accumMax) {
                                accumTotal = userData.accumBalance > accumMax ? accumMax : userData.accumBalance <= accumMax ? userData.accumBalance : 0;
                            } else {
                                accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                            }
                        } else {
                            let prevLeaveStatsThisMonth = await firestore.collection("leaveStatus").doc(prevLeaveStats.docs[0].id).collection("Months").get();
                            let months = [];

                            // eslint-disable-next-line no-loop-func
                            Object.keys(prevLeaveStatsThisMonth.docs).forEach(function (key) {
                                months.push({ annualLeft: prevLeaveStatsThisMonth.docs[key].data().annualLeft, datePeriod: prevLeaveStatsThisMonth.docs[key].data().datePeriod.toDate() });
                            });

                            let thisTot = 0;

                            // eslint-disable-next-line no-loop-func
                            Object.keys(months).forEach(function (key) {
                                if (months[key].datePeriod.getMonth() === 1) {
                                    if (accumMax) {
                                        accumTotal = months[key].annualLeft > accumMax ? accumMax : months[key].annualLeft <= accumMax ? months[key].annualLeft : 0;
                                    } else {
                                        accumTotal = months[key].annualLeft ? months[key].annualLeft : 0;
                                    }
                                    thisTot = months[key].annualLeft;
                                }
                            });
                        }
                    } else {
                        if (prevLeaveStats.empty) {
                            let totalAccumTotal;
                            if (accumMax) {
                                accumTotal = userData.accumBalance > accumMax ? accumMax : userData.accumBalance <= accumMax ? userData.accumBalance : 0;
                            } else {
                                accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                            }

                            if (type === "Company") {
                                if (totalAccumTotal > companyData.leaveAccumulativeAfterForfeit) {
                                    if (accumMax) {
                                        accumTotal2 =
                                            companyData.leaveAccumulativeAfterForfeit > accumMax
                                                ? accumMax
                                                : companyData.leaveAccumulativeAfterForfeit <= accumMax
                                                ? companyData.leaveAccumulativeAfterForfeit
                                                : 0;
                                    } else {
                                        accumTotal2 = companyData.leaveAccumulativeAfterForfeit ? companyData.leaveAccumulativeAfterForfeit : 0;
                                    }
                                } else {
                                    if (accumMax) {
                                        accumTotal2 =
                                            companyData.leaveAccumulativeAfterForfeit > accumMax
                                                ? accumMax
                                                : companyData.leaveAccumulativeAfterForfeit <= accumMax
                                                ? companyData.leaveAccumulativeAfterForfeit
                                                : 0;
                                    } else {
                                        accumTotal2 = companyData.leaveAccumulativeAfterForfeit ? companyData.leaveAccumulativeAfterForfeit : 0;
                                    }
                                    // accumTotal2 = companyData.leaveAccumulativeAfterForfeit;
                                }
                            } else {
                                if (totalAccumTotal > userData.leaveAccumulativeAfterForfeit) {
                                    if (accumMax) {
                                        accumTotal2 =
                                            userData.leaveAccumulativeAfterForfeit > accumMax
                                                ? accumMax
                                                : userData.leaveAccumulativeAfterForfeit <= accumMax
                                                ? userData.leaveAccumulativeAfterForfeit
                                                : 0;
                                    } else {
                                        accumTotal2 = userData.leaveAccumulativeAfterForfeit ? userData.leaveAccumulativeAfterForfeit : 0;
                                    }
                                } else {
                                    if (accumMax) {
                                        accumTotal2 =
                                            userData.leaveAccumulativeAfterForfeit > accumMax
                                                ? accumMax
                                                : userData.leaveAccumulativeAfterForfeit <= accumMax
                                                ? userData.leaveAccumulativeAfterForfeit
                                                : 0;
                                    } else {
                                        accumTotal2 = userData.leaveAccumulativeAfterForfeit ? userData.leaveAccumulativeAfterForfeit : 0;
                                    }
                                }
                            }

                            if (accumMax) {
                                accumTotal = userData.accumBalance > accumMax ? accumMax : userData.accumBalance <= accumMax ? userData.accumBalance : 0;
                            } else {
                                accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                            }
                        } else {
                            let prevLeaveStatsThisMonth = await firestore.collection("leaveStatus").doc(prevLeaveStats.docs[0].id).collection("Months").get();
                            let months = [];

                            // eslint-disable-next-line no-loop-func
                            Object.keys(prevLeaveStatsThisMonth.docs).forEach(function (key) {
                                months.push({ annualLeft: prevLeaveStatsThisMonth.docs[key].data().annualLeft, datePeriod: prevLeaveStatsThisMonth.docs[key].data().datePeriod.toDate() });
                            });

                            // eslint-disable-next-line no-loop-func
                            Object.keys(months).forEach(function (key) {
                                if (months[key].datePeriod.getMonth() === startCurrM.getMonth()) {
                                    if (type === "Company") {
                                        if (parseFloat(months[key].annualLeft) >= parseFloat(companyData.leaveAccumulativeAfterForfeit)) {
                                            if (accumMax) {
                                                accumTotal =
                                                    companyData.leaveAccumulativeAfterForfeit > accumMax
                                                        ? accumMax
                                                        : companyData.leaveAccumulativeAfterForfeit <= accumMax
                                                        ? companyData.leaveAccumulativeAfterForfeit
                                                        : 0;
                                            } else {
                                                accumTotal = companyData.leaveAccumulativeAfterForfeit ? companyData.leaveAccumulativeAfterForfeit : 0;
                                            }
                                        } else {
                                            if (accumMax) {
                                                accumTotal = months[key].annualLeft > accumMax ? accumMax : months[key].annualLeft <= accumMax ? months[key].annualLeft : 0;
                                            } else {
                                                accumTotal = months[key].annualLeft ? months[key].annualLeft : 0;
                                            }
                                        }
                                    } else {
                                        if (parseFloat(months[key].annualLeft) >= parseFloat(userData.leaveAccumulativeAfterForfeit)) {
                                            if (accumMax) {
                                                accumTotal =
                                                    userData.leaveAccumulativeAfterForfeit > accumMax
                                                        ? accumMax
                                                        : userData.leaveAccumulativeAfterForfeit <= accumMax
                                                        ? userData.leaveAccumulativeAfterForfeit
                                                        : 0;
                                            } else {
                                                accumTotal = userData.leaveAccumulativeAfterForfeit ? userData.leaveAccumulativeAfterForfeit : 0;
                                            }
                                        } else {
                                            if (accumMax) {
                                                accumTotal = months[key].annualLeft > accumMax ? accumMax : months[key].annualLeft <= accumMax ? months[key].annualLeft : 0;
                                            } else {
                                                accumTotal = months[key].annualLeft ? months[key].annualLeft : 0;
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    }
                } else if (rollOverType === "Annual Rollover") {
                    if (prevLeaveStats.empty) {
                        accumTotal = userData.accumBalance ? userData.accumBalance : 0;
                    } else {
                        let prevLeaveStatsThisMonth = await firestore.collection("leaveStatus").doc(prevLeaveStats.docs[0].id).collection("Months").get();
                        let months = [];

                        // eslint-disable-next-line no-loop-func
                        Object.keys(prevLeaveStatsThisMonth.docs).forEach(function (key) {
                            months.push({
                                annualLeft: prevLeaveStatsThisMonth.docs[key].data().annualLeft,
                                datePeriod: prevLeaveStatsThisMonth.docs[key].data().datePeriod.toDate(),
                                accumTotal: prevLeaveStatsThisMonth.docs[key].data().accumTotal,
                            });
                        });

                        months.sort((a, b) => (a.datePeriod < b.datePeriod ? 1 : -1));
                        let lastAmount = months[0];
                        accumTotal = parseFloat(lastAmount.annualLeft) + parseFloat(lastAmount.accumTotal);
                    }
                }
            }
        }

        let currentMonth = await firestore.collection("leaveStatus").doc(leaveStats.docs[0].id).collection("Months").where("datePeriod", "==", startCurrM).get();
        //over here

        let dtFilterFrom = startCurrM;
        // let dtFilterTo = endOfMonth(startCurrM);
        let dtFilterTo =
            companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
                ? new Date(startCurrM.getFullYear(), addMonths(startCurrM, 1).getMonth(), parseFloat(companyData.leaveToDay))
                : endOfMonth(startCurrM);

        let filterType = "createDate";

        if (companyData.leaveTrackingType === "Leave Month") {
            await firestore
                .collection("leave")
                .where("userUid", "==", userUid)
                .where("createDate", ">=", dtFilterFrom)
                .where("createDate", "<", dtFilterTo)
                .where("status", "==", "Approved")
                .get()
                // eslint-disable-next-line no-loop-func
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        let this_data = { ...doc.data(), id: doc.id };

                        if (!this_data.unpaidLeave || (this_data.unpaidLeave === "No" && this_data.skip !== "yes")) {
                            if (this_data.leaveType === "Annual") {
                                if (this_data.dontTrackUsed === false || this_data.dontTrackUsed === undefined) {
                                    if (
                                        (this_data.useAccumulative !== "Yes" || this_data.useAccumulative == undefined) &&
                                        (this_data.useAccumulative2 !== "Yes" || this_data.useAccumulative2 === undefined)
                                    ) {
                                        allUsed = parseFloat(allUsed) + parseFloat(this_data.annualDays);
                                        allUsed = parseFloat(allUsed.toFixed(2));
                                    }
                                }
                            }
                        }
                    });
                });

            filterType = "annualFrom";
        }

        await firestore
            .collection("leave")
            .where("userUid", "==", userUid)
            .where(filterType, ">=", dtFilterFrom)
            .where(filterType, "<", dtFilterTo)
            .where("status", "==", "Approved")
            .get()
            // eslint-disable-next-line no-loop-func
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    let this_data = { ...doc.data(), id: doc.id };

                    // console.log(this_data.skip !== "yes");

                    //Skip unpaid leave -- untrack
                    if (!this_data.unpaidLeave || (this_data.unpaidLeave === "No" && this_data.skip !== "yes")) {
                        // console.log(this_data.leaveType);
                        if (this_data.leaveType === "Annual") {
                            if (this_data.dontTrackUsed && this_data.dontTrackUsed === true) {
                                untrackUsed = parseFloat(untrackUsed) + parseFloat(this_data.annualDays);
                                untrackUsed = parseFloat(untrackUsed.toFixed(2));
                            } else {
                                if (this_data.useAccumulative && this_data.useAccumulative === "Yes") {
                                    accumUsed = parseFloat(accumUsed) + parseFloat(this_data.annualDays);
                                    accumUsed = parseFloat(accumUsed.toFixed(2));
                                } else if (this_data.useAccumulative2 && this_data.useAccumulative2 === "Yes") {
                                    accumUsed2 = parseFloat(accumUsed2) + parseFloat(this_data.annualDays);
                                    accumUsed2 = parseFloat(accumUsed2.toFixed(2));
                                } else {
                                    annualUsed = parseFloat(annualUsed) + parseFloat(this_data.annualDays);
                                    annualUsed = parseFloat(annualUsed.toFixed(2));
                                }
                            }
                        } else if (this_data.leaveType === "Family") {
                            familyUsed = parseFloat(familyUsed) + parseFloat(this_data.annualDays);
                            familyUsed = parseFloat(familyUsed.toFixed(2));
                        } else if (this_data.leaveType === "Maternity") {
                            maternityUsed = parseFloat(maternityUsed) + parseFloat(this_data.annualDays);
                            maternityUsed = parseFloat(maternityUsed.toFixed(2));
                        } else if (this_data.leaveType === "Paternity") {
                            paternityUsed = parseFloat(paternityUsed) + parseFloat(this_data.annualDays);
                            paternityUsed = parseFloat(paternityUsed.toFixed(2));
                        } else if (this_data.leaveType === "IOD") {
                            IODUsed = parseFloat(IODUsed) + parseFloat(this_data.annualDays);
                            IODUsed = parseFloat(IODUsed.toFixed(2));
                        } else if (this_data.leaveType === "Parental") {
                            parentalUsed = parseFloat(parentalUsed) + parseFloat(this_data.annualDays);
                            parentalUsed = parseFloat(parentalUsed.toFixed(2));
                        } else if (this_data.leaveType === "Moving") {
                            movingUsed = parseFloat(movingUsed) + parseFloat(this_data.annualDays);
                            movingUsed = parseFloat(movingUsed.toFixed(2));
                        } else if (this_data.leaveType === "CSR") {
                            csrUsed = parseFloat(csrUsed) + parseFloat(this_data.annualDays);
                            csrUsed = parseFloat(csrUsed.toFixed(2));
                        } else if (this_data.leaveType === "Religious") {
                            religiousUsed = parseFloat(religiousUsed) + parseFloat(this_data.annualDays);
                            religiousUsed = parseFloat(religiousUsed.toFixed(2));
                        } else if (this_data.leaveType === "Study") {
                            studyUsed = parseFloat(studyUsed) + parseFloat(this_data.annualDays);
                            studyUsed = parseFloat(studyUsed.toFixed(2));
                        } else if (this_data.leaveType === "Special") {
                            usedSpecial = parseFloat(usedSpecial) + parseFloat(this_data.annualDays);
                            usedSpecial = parseFloat(usedSpecial.toFixed(2));
                        } else if (this_data.leaveType === "Incentive") {
                            incentiveTotal = parseFloat(incentiveTotal) + parseFloat(this_data.annualDays);
                            incentiveTotal = parseFloat(incentiveTotal.toFixed(2));
                            annualTotal = parseFloat(annualTotal) + parseFloat(this_data.annualDays);
                            annualTotal = parseFloat(annualTotal).toFixed(2);
                        }
                    }
                });
            });

        //Save Totals
        monthlyTotals.annualTotal = annualTotal.toFixed(2);
        monthlyTotals.incentiveTotal = incentiveTotal;

        if (companyData.leaveTrackingType === "Leave Month") {
            let total1 = parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0) * totalToStart;
            // let total2 = total1 - parseFloat(userData.leavePerMonth ? userData.leavePerMonth : companyData.leavePerMonth ? companyData.leavePerMonth : 0);
            let total3 = total1 + parseFloat(allStartTotal !== undefined && allStartTotal !== "" ? allStartTotal : "0");

            if (startCurrM >= startDate) {
                monthlyTotals.allTotal = total3.toFixed(2);
                monthlyTotals.allUsed = allUsed.toFixed(2);
                monthlyTotals.allLeft = (monthlyTotals.allTotal - allUsed).toFixed(2);
            } else {
                monthlyTotals.allTotal = 0;
                monthlyTotals.allUsed = 0;
                monthlyTotals.allLeft = 0;
            }
        }

        //Save Total Used
        monthlyTotals.annualUsed = annualUsed.toFixed(2);
        monthlyTotals.untrackUsed = untrackUsed;
        monthlyTotals.accumUsed = accumUsed;
        monthlyTotals.accumUsed2 = accumUsed2;

        monthlyTotals.annualLeft = (annualTotal - annualUsed).toFixed(2);

        if (accumTotal !== undefined) {
            monthlyTotals.accumTotal = accumTotal;
        } else {
            monthlyTotals.accumTotal = 0;
        }
        if (accumTotal2 !== undefined) {
            monthlyTotals.accumTotal2 = accumTotal2;
        } else {
            monthlyTotals.accumTotal2 = 0;
        }

        await firestore.collection("leaveStatus").doc(leaveStats.docs[0].id).collection("Months").doc(currentMonth.docs[0].id).update(monthlyTotals);

        startCurrM = addMonths(startCurrM, 1);
    }

    //Add nonGrowing back into main collection
    let leaveStat = {};
    leaveStat.familyUsed = familyUsed;
    leaveStat.familyTotal = familyTotal;
    leaveStat.familyLeft = familyTotal - familyUsed;

    leaveStat.maternityUsed = maternityUsed;
    leaveStat.maternityTotal = maternityTotal;
    leaveStat.maternityLeft = maternityTotal - maternityUsed;

    leaveStat.paternityUsed = paternityUsed;
    leaveStat.paternityTotal = paternityTotal;
    leaveStat.paternityLeft = paternityTotal - paternityUsed;

    leaveStat.IODUsed = IODUsed;
    leaveStat.IODTotal = IODTotal;
    leaveStat.IODLeft = IODTotal - IODUsed;

    leaveStat.parentalUsed = parentalUsed;
    leaveStat.parentalTotal = parentalTotal;
    leaveStat.parentalLeft = parentalTotal - parentalUsed;

    leaveStat.movingUsed = movingUsed;
    leaveStat.movingTotal = movingTotal;
    leaveStat.movingLeft = movingTotal - movingUsed;

    leaveStat.csrUsed = csrUsed;
    leaveStat.csrTotal = csrTotal;
    leaveStat.csrLeft = csrTotal - csrUsed;

    leaveStat.religiousUsed = religiousUsed;
    leaveStat.religiousTotal = religiousTotal;
    leaveStat.religiousLeft = religiousTotal - religiousUsed;

    leaveStat.studyUsed = studyUsed;
    leaveStat.studyTotal = studyTotal;
    leaveStat.studyLeft = studyTotal - studyUsed;

    leaveStat.usedSpecial = usedSpecial;

    if (new Date() > startDate && new Date() < addYears(startDate, 1) && takeOnStartBalance !== undefined) {
        leaveStat.takeOnStartBalance = takeOnStartBalance;
    }

    leaveStat.periodType = "Leave";

    await firestore.collection("leaveStatus").doc(leaveStats.docs[0].id).update(leaveStat);

    //Update Sick Leave Period
    let dateNow = new Date();

    let sickLeave = firestore.collection("leaveStatus").where("userUid", "==", userUid).where("periodType", "==", "Sick").where("toDate", ">", dateNow).get();
    let sickLeaveNow = {};

    await sickLeave.then(function (querySnapshot) {
        if (querySnapshot.empty === false) {
            sickLeaveNow = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
        }
    });

    let sickStartDate = "";

    if (userData.employmentStartDate !== undefined) {
        sickStartDate = userData.employmentStartDate.toDate();
    } else {
        sickStartDate = userData.createdAt.toDate();
    }

    //See if sick leave exists for current period
    if (sickLeaveNow.hasOwnProperty("id")) {
        let sickUsed = 0;

        //add new sick leave
        await firestore
            .collection("leave")
            .where("userUid", "==", userUid)
            .where("createDate", ">=", sickLeaveNow.fromDate.toDate())
            .where("createDate", "<", sickLeaveNow.toDate.toDate())
            .where("leaveType", "==", "Sick")
            .where("status", "==", "Approved")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    let this_data = { ...doc.data(), id: doc.id };
                    sickUsed = parseFloat(sickUsed) + parseFloat(this_data.annualDays);
                });
            });

        //update sick leave
        sickLeaveNow.sickUsed = sickUsed;
        sickLeaveNow.sickLeft = sickLeaveNow.sickTotal - sickUsed;

        await firestore.collection("leaveStatus").doc(sickLeaveNow.id).update(sickLeaveNow);

        toast.success("Leave Updated");
    } else {
        //add 3 years to start date to get first period
        let startUserDate = subDays(addYears(sickStartDate, 3), 1);

        //if first 3 year period falls in current period
        if (startUserDate > dateNow) {
            let sickUsed = 0;

            //Add leave
            await firestore
                .collection("leave")
                .where("userUid", "==", userUid)
                .where("createDate", ">=", sickStartDate)
                .where("createDate", "<", dateNow)
                .where("leaveType", "==", "Sick")
                .where("status", "==", "Approved")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        let this_data = { ...doc.data(), id: doc.id };
                        sickUsed = parseFloat(sickUsed) + parseFloat(this_data.annualDays);
                    });
                });

            //new entry for leave for list period
            let newEntry = {
                periodType: "Sick",
                fromDate: sickStartDate,
                toDate: subDays(addYears(sickStartDate, 3), 1),
                userUid: userData.id,
                userDisplayName: userData.displayName,
                companyId: userData.companyId,
                companyName: userData.companyName,
                sickTotal: parseFloat(companyData.leaveTotalSick),
                sickLeft: parseFloat(companyData.leaveTotalSick) - sickUsed,
                sickUsed: sickUsed,
            };

            let sickExists = firestore
                .collection("leaveStatus")
                .where("userUid", "==", userUid)
                .where("periodType", "==", "Sick")
                .where("toDate", ">=", subDays(addYears(sickStartDate, 3), 1))
                .get();
            let sick = {};

            await sickExists.then(function (querySnapshot) {
                if (querySnapshot.empty === false) {
                    sick = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
                }
            });

            if (sick.hasOwnProperty("id")) {
                await firestore.collection("leaveStatus").doc(sick.id).update(newEntry);
            } else {
                await firestore.collection("leaveStatus").add(newEntry);
            }
        } else {
            //start date doesn't fall in first period
            let startUserDate = sickStartDate;
            let userNewDate = addYears(startUserDate, 3);

            //add 3 years to start date to find current period
            while (userNewDate <= dateNow) {
                userNewDate = addYears(userNewDate, 3);
            }

            //get start of current period
            let startNewDate = subYears(userNewDate, 3);

            let sickUsed = 0;

            //add leave
            await firestore
                .collection("leave")
                .where("userUid", "==", userUid)
                .where("createDate", ">=", startNewDate)
                .where("createDate", "<", userNewDate)
                .where("leaveType", "==", "Sick")
                .where("status", "==", "Approved")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        let this_data = { ...doc.data(), id: doc.id };
                        sickUsed = parseFloat(sickUsed) + parseFloat(this_data.annualDays);
                    });
                });

            //create leave for current period
            let newEntry = {
                periodType: "Sick",
                fromDate: startNewDate,
                toDate: subDays(userNewDate, 1),
                userUid: userData.id,
                userDisplayName: userData.displayName,
                companyId: userData.companyId,
                companyName: userData.companyName,
                sickTotal: parseFloat(companyData.leaveTotalSick),
                sickLeft: parseFloat(companyData.leaveTotalSick) - sickUsed,
                sickUsed: sickUsed,
            };

            let sickExists = firestore.collection("leaveStatus").where("userUid", "==", userUid).where("periodType", "==", "Sick").where("toDate", ">=", subDays(userNewDate, 1)).get();
            let sick = {};

            await sickExists.then(function (querySnapshot) {
                if (querySnapshot.empty === false) {
                    sick = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
                }
            });

            if (sick.hasOwnProperty("id")) {
                await firestore.collection("leaveStatus").doc(sick.id).update(newEntry);
            } else {
                await firestore.collection("leaveStatus").add(newEntry);
            }
        }

        toast.success("Leave Updated");
    }
}

export async function generateLeaveStats(userUid, message, changeYear) {
    const firestore = firebase.firestore();

    let debug = 0;

    if (debug === 1) {
        //
    }

    try {
        //Get all users leave data
        //OR - have 2 buttons, generate all + generate this year.
        //maybe generate all can auto run if a year prior to current year is loaded but otherwise just normal one is run.
        //so long run for all is an option but default for leave approval etc is just current year.

        let userQuery = await firestore.collection("users").doc(userUid).get();
        let userData = userQuery.data();

        let takeOnStartDate = "";

        if (userData.takeOnStartDate !== undefined && userData.takeOnStartDate !== "") {
            takeOnStartDate = userData.takeOnStartDate.toDate();
            takeOnStartDate.setDate(1);
        }

        //Get company data
        let company = await firestore.collection("companies").doc(userData.companyId).get();
        company = company.data();

        if (company.hasLeave == true) {
            let yearNow = format(Date.now(), "yyyy");
            let monthNow = format(Date.now(), "M");

            //Set up starter vars
            //let curYear = format(new Date(), "yyyy");

            //Default to Company Financial Year
            let curFinancialYear = parseInt(yearNow);

            //Set the default leaveYearStart value which we re-use a few times
            let leaveYearStart = 0;

            if (takeOnStartDate !== "" && format(takeOnStartDate, "yyyy") === format(new Date(), "yyyy") && parseInt(format(takeOnStartDate, "MM")) > parseInt(company.yearStart)) {
                leaveYearStart = parseInt(format(takeOnStartDate, "MM"));
            } else {
                leaveYearStart = parseInt(company.yearStart);
            }

            let MonthInc = leaveYearStart;

            //Now check if the company has a different Leave Type set
            if (company.leaveType && company.leaveType === "Anniversary Date of Employee") {
                if (userData.employmentStartDate) {
                    MonthInc = format(userData.employmentStartDate.toDate(), "M");
                    leaveYearStart = MonthInc;
                }
            }

            let leavePerMonth = company.leavePerMonth;

            //If the user has custom Leave Per Months values set update accordingly
            if (userData.leavePerMonth && userData.leavePerMonth > 0) {
                leavePerMonth = userData.leavePerMonth;

                if (debug === 1) {
                    //
                }
            }

            //If the users employment start date falls within this financial year update the start dates
            // if (userData.employmentStartDate && userData.employmentStartDate !== "") {
            //     let startFinancialYear = parseInt(format(userData.employmentStartDate.toDate(), "yyyy"));
            //     let startMonthInc = parseInt(format(userData.employmentStartDate.toDate(), "M"));

            //     if (startFinancialYear === curFinancialYear && startMonthInc > MonthInc) {
            //         MonthInc = startMonthInc;
            //     }

            // }

            if (debug === 1) {
                // console.log("monthNow", monthNow, "leaveYearStart", leaveYearStart);
            }

            //Work out the financial year
            if (parseInt(monthNow) < parseInt(leaveYearStart)) {
                //Because the month is smaller than the companies starting month it means we are in a new year so switch back a year
                curFinancialYear = parseInt(yearNow) - 1;
                if (debug === 1) {
                    // console.log("year switch back happened");
                }
            }

            //Check if we need to adjust the year
            if (typeof changeYear !== "undefined") {
                let changeYear_y = parseInt(format(changeYear, "yyyy"));
                let changeYear_m = parseInt(format(changeYear, "M"));

                if (debug === 1) {
                    // console.log("changeYear has been passed");
                    // console.log("changeYear_y", changeYear_y);
                    // console.log("changeYear_m", changeYear_m);
                    // console.log("leaveYearStart", leaveYearStart);
                }

                if (changeYear_y < curFinancialYear || (changeYear_y === curFinancialYear && changeYear_m < leaveYearStart)) {
                    if (changeYear_m < leaveYearStart) {
                        curFinancialYear = parseInt(changeYear_y) - 1;
                    } else {
                        curFinancialYear = changeYear_y;
                    }

                    if (debug === 1) {
                        // console.log("Changed year because of changeYear var", curFinancialYear);
                    }
                }
            }

            if (debug === 1) {
                // console.log("current financial year = ", curFinancialYear);
            }

            let curYear = curFinancialYear;
            let total = parseFloat(0);

            if (!userData["leaveMonths"]) {
                userData["leaveMonths"] = {};
            }

            //Step 1: Set up blank User Data
            for (let i = 1; i <= 12; i++) {
                if (MonthInc === 13) {
                    MonthInc = 1;
                    curYear = parseInt(curYear) + 1;
                }

                total = i * leavePerMonth;
                total = total.toFixed(2);

                if (!userData["leaveMonths"][curYear]) {
                    userData["leaveMonths"][curYear] = {};
                }

                //Does this user have Accumulative Leave?
                let accumForfeitDate = "";
                if (company.leaveRolloverType && company.leaveRolloverType === "Accumulative" && company.leaveAccumulativeForfeit && company.leaveAccumulativeForfeit > 0) {
                    //If they do, does the leave get forfeited?
                    accumForfeitDate = new Date(yearNow, leaveYearStart - 1, 1);
                    accumForfeitDate = addMonths(accumForfeitDate, company.leaveAccumulativeForfeit);
                    accumForfeitDate = subDays(accumForfeitDate, 1);
                }

                //MIRICHEA
                //Get all Incentive leave for the current period (this depends on company types etc, you can look at how we get it further down)
                //Add this as a seperate total for us to track but also add it to the main total so that we don't have do re-calculate totals all over the system

                userData["leaveMonths"][curYear][MonthInc] = {
                    used: 0,
                    usedUntracked: 0,
                    left: 0,
                    total: total, //Also add the incentiveTotal onto here so that rest of the system functions as normal
                    // incentiveTotal: 0, //This will be what you get from above
                    runningTotal: 0,
                };
                userData["leaveMonths"][curYear]["nonGrowing"] = {
                    familyUsed: 0,
                    familyTotal: parseFloat(userData.leaveTotalFamily ? userData.leaveTotalFamily : company.leaveTotalFamily ? company.leaveTotalFamily : 0),
                    maternityUsed: 0,
                    maternityTotal: parseFloat(userData.leaveTotalMaternity ? userData.leaveTotalMaternity : company.leaveTotalMaternity ? company.leaveTotalMaternity : 0),
                    paternityUsed: 0,
                    paternityTotal: parseFloat(userData.leaveTotalPaternity ? userData.leaveTotalPaternity : company.leaveTotalPaternity ? company.leaveTotalPaternity : 0),
                    IODUsed: 0,
                    IODTotal: parseFloat(userData.leaveTotalIOD ? userData.leaveTotalIOD : company.leaveTotalIOD ? company.leaveTotalIOD : 0),
                    parentalUsed: 0,
                    parentalTotal: parseFloat(userData.leaveTotalParental ? userData.leaveTotalParental : company.leaveTotalParental ? company.leaveTotalParental : 0),
                    movingUsed: 0,
                    movingTotal: parseFloat(userData.leaveTotalMoving ? userData.leaveTotalMoving : company.leaveTotalMoving ? company.leaveTotalMoving : 0),
                    csrUsed: 0,
                    csrTotal: parseFloat(userData.leaveTotalCSR ? userData.leaveTotalCSR : company.leaveTotalCSR ? company.leaveTotalCSR : 0),
                    religiousUsed: 0,
                    religiousTotal: parseFloat(userData.leaveTotalReligious ? userData.leaveTotalReligious : company.leaveTotalReligious ? company.leaveTotalReligious : 0),
                    studyUsed: 0,
                    studyTotal: parseFloat(userData.leaveTotalStudy ? userData.leaveTotalStudy : company.leaveTotalStudy ? company.leaveTotalStudy : 0),

                    incentiveTotal: 0,

                    accumUsed: 0,
                    accumTotal: 0,
                    accumForfeitDate,

                    accumUsed2: 0,
                    accumTotal2: company.leaveAccumulativeAfterForfeit && company.leaveAccumulativeAfterForfeit > 0 ? parseFloat(company.leaveAccumulativeAfterForfeit) : 0,

                    usedSpecial: 0,
                };

                userData["leaveMonths"]["sick"] = {
                    sickUsed: 0,
                    sickTotal: parseFloat(userData.leaveTotalSick ? userData.leaveTotalSick : company.leaveTotalSick ? company.leaveTotalSick : 0),
                };

                //TODO: [DO-26] Sort out how to store sick leave every 3 years

                MonthInc++;
            }

            //Reset values
            curYear = curFinancialYear;

            if (debug === 1) {
                // console.log("blank data: ", userData["leaveMonths"]);
            }

            try {
                //Companies financial start year/month
                let dtFilterFrom = new Date(curYear + "-" + leaveYearStart + "-01");

                //Date To = dateFrom + 1 year
                let dtFilterTo = addYears(dtFilterFrom, 1);

                //Remove 1 day so that we are the last day of previous month
                //dtFilterTo = subDays(dtFilterTo, 1);

                if (debug === 1) {
                    // console.log("Filter", dtFilterFrom, dtFilterTo);
                }

                //We have switched from AnnualFrom to createDate because leave taken in future needs to come off current leave stats
                await firestore
                    .collection("leave")
                    .where("userUid", "==", userUid)
                    .where("createDate", ">=", dtFilterFrom)
                    .where("createDate", "<", dtFilterTo)
                    .where("status", "==", "Approved")
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            let this_data = doc.data();

                            //Skip unpaid leave
                            if (!this_data.unpaidLeave || this_data.unpaidLeave === "No") {
                                //First lets set the createDate
                                let this_month = parseInt(format(this_data.createDate.toDate(), "M"));
                                let this_year = parseInt(format(this_data.createDate.toDate(), "yyyy"));

                                //Now get Annual From to compare
                                //First lets set the createDate
                                let this_month_annualFrom = parseInt(format(this_data.annualFrom.toDate(), "M"));
                                let this_year_annualFrom = parseInt(format(this_data.annualFrom.toDate(), "yyyy"));

                                if (debug === 1) {
                                    // console.log("loop dates: this_month ", this_month, " this_year ", this_year, " ", this_month_annualFrom, " ", this_year_annualFrom);
                                }

                                //If its before now then update to annualFrom date
                                if (this_year > this_year_annualFrom || (this_year === this_year_annualFrom && this_month > this_month_annualFrom)) {
                                    // console.log("This leave is taken before load date, swap dates!");
                                    this_month = this_month_annualFrom;
                                    this_year = this_year_annualFrom;
                                }

                                if (debug === 1) {
                                    // console.log(this_data.leaveType + " - " + this_data.annualDays);
                                }

                                let annualDays = parseFloat(this_data.annualDays).toFixed(2);

                                if (this_data.leaveType === "Annual") {
                                    if (this_data.dontTrackUsed && this_data.dontTrackUsed === true) {
                                        userData["leaveMonths"][this_year][this_month]["usedUntracked"] =
                                            parseFloat(userData["leaveMonths"][this_year][this_month]["usedUntracked"]) + parseFloat(annualDays);
                                        userData["leaveMonths"][this_year][this_month]["usedUntracked"] = userData["leaveMonths"][this_year][this_month]["usedUntracked"].toFixed(2);
                                    } else {
                                        if (this_data.useAccumulative && this_data.useAccumulative === "Yes") {
                                            userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed"] =
                                                parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed"]) + parseFloat(annualDays);
                                            userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed"].toFixed(2);
                                        } else if (this_data.useAccumulative2 && this_data.useAccumulative2 === "Yes") {
                                            userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed2"] =
                                                parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed2"]) + parseFloat(annualDays);
                                            userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed2"] = userData["leaveMonths"][curYear]["nonGrowing"]["accumUsed2"].toFixed(2);
                                        } else {
                                            userData["leaveMonths"][this_year][this_month]["used"] = parseFloat(userData["leaveMonths"][this_year][this_month]["used"]) + parseFloat(annualDays);
                                            userData["leaveMonths"][this_year][this_month]["used"] = parseFloat(userData["leaveMonths"][this_year][this_month]["used"].toFixed(2));
                                        }
                                    }
                                } else if (this_data.leaveType === "Family") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["familyUsed"] = parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["familyUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["familyUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["familyUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Maternity") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["maternityUsed"] =
                                        parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["maternityUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["maternityUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["maternityUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Paternity") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["paternityUsed"] =
                                        parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["paternityUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["paternityUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["paternityUsed"].toFixed(2);
                                } else if (this_data.leaveType === "IOD") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["IODUsed"] = parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["IODUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["IODUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["IODUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Parental") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["parentalUsed"] =
                                        parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["parentalUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["parentalUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["parentalUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Moving") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["movingUsed"] = parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["movingUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["movingUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["movingUsed"].toFixed(2);
                                } else if (this_data.leaveType === "CSR") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["csrUsed"] = parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["csrUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["csrUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["csrUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Religious") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["religiousUsed"] =
                                        parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["religiousUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["religiousUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["religiousUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Study") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["studyUsed"] = parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["studyUsed"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["studyUsed"] = userData["leaveMonths"][curYear]["nonGrowing"]["studyUsed"].toFixed(2);
                                } else if (this_data.leaveType === "Incentive") {
                                    userData["leaveMonths"][curYear]["nonGrowing"]["incentiveTotal"] =
                                        parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["incentiveTotal"]) + parseFloat(annualDays);
                                    userData["leaveMonths"][curYear]["nonGrowing"]["incentiveTotal"] = userData["leaveMonths"][curYear]["nonGrowing"]["incentiveTotal"].toFixed(2);
                                }
                            }
                        });
                    })
                    .catch(function (error) {
                        console.log("Error getting documents: ", error);
                    });

                //Now update Sick leave, different query because it is a 3 year cycle
                //Firstly get the difference between now and the employment start date, default to created date
                let sickFromDt = Date.now();
                let dif = 0;

                if (userData.createdAt) {
                    dif = differenceInMonths(Date.now(), userData.createdAt);
                    sickFromDt = userData.createdAt;

                    if (debug === 1) {
                        // console.log("sick date from (createdAt): ", format(sickFromDt.toDate(), "yyyy-MM-dd"));
                    }
                }

                if (userData.employmentStartDate) {
                    dif = differenceInMonths(Date.now(), userData.employmentStartDate.toDate());
                    sickFromDt = userData.employmentStartDate;

                    if (debug === 1) {
                        // console.log("sick date from (employmentStartDate): ", format(sickFromDt.toDate(), "yyyy-MM-dd"));
                    }
                }

                //If sick leave is within 3 years then set date
                if (dif > 36) {
                    // let difYears = Math.floor(dif / 36);
                    let difMonths = dif % 36;

                    if (debug === 1) {
                        // console.log("difYears", difYears, "difMonths", difMonths);
                    }

                    sickFromDt = subMonths(Date.now(), difMonths);
                }

                if (debug === 1) {
                    // console.log("sick date from (final): ", sickFromDt);
                    // console.log("dif", dif);
                }

                await firestore
                    .collection("leave")
                    .where("userUid", "==", userUid)
                    .where("leaveType", "==", "Sick")
                    .where("status", "==", "Approved")
                    .where("createDate", ">=", sickFromDt)
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            let this_data = doc.data();
                            let annualDays = parseFloat(this_data.annualDays).toFixed(2);

                            userData["leaveMonths"]["sick"]["sickUsed"] = parseFloat(userData["leaveMonths"]["sick"]["sickUsed"]) + parseFloat(annualDays);
                            userData["leaveMonths"]["sick"]["sickUsed"] = userData["leaveMonths"]["sick"]["sickUsed"].toFixed(2);
                        });
                    })
                    .catch(function (error) {
                        console.log("Error getting documents: ", error);
                    });

                if (debug === 1) {
                    // console.log("userData before actual tots loop", userData["leaveMonths"]);
                }

                //Now run through leave data and set up the actual running totals
                MonthInc = parseInt(leaveYearStart);
                curYear = curFinancialYear;
                let runningTotal = 0;
                let runningTotalUntracked = 0;
                // let startMonth = 12;
                // if (format(takeOnStartDate, "yyyy") == curYear) {
                //     //
                // }
                for (let i = 1; i <= 12; i++) {
                    if (MonthInc === 13) {
                        MonthInc = 1;
                        curYear = parseInt(curYear) + 1;
                    }

                    total = i * leavePerMonth;

                    if (debug === 1) {
                        // console.log("curYear", curYear, "MonthInc", MonthInc, "leaveYearStart", leaveYearStart);
                    }

                    let RolloverAmount = 0;

                    //Does this company allow Annual Rollover?
                    if (company.leaveRolloverType && company.leaveRolloverType === "Annual Rollover") {
                        //If we are on first month work out rollover
                        if (MonthInc === parseInt(leaveYearStart)) {
                            //Get previous periods amount for rollover
                            if (userData["leaveMonths"][curYear] && userData["leaveMonths"][curYear][leaveYearStart - 1] && userData["leaveMonths"][curYear][leaveYearStart - 1]["left"]) {
                                RolloverAmount = userData["leaveMonths"][curYear][leaveYearStart - 1]["left"];
                                RolloverAmount = parseFloat(RolloverAmount);
                            }

                            //Otherwise just get it
                        } else {
                            if (MonthInc < parseInt(leaveYearStart)) {
                                RolloverAmount = userData["leaveMonths"][curYear - 1][parseInt(leaveYearStart)]["RolloverAmount"];
                            } else {
                                RolloverAmount = userData["leaveMonths"][curYear][parseInt(leaveYearStart)]["RolloverAmount"];
                            }
                        }
                    }

                    /*Questions:
                        1. should we block adding of leave prior to a staff members start date?
                        2. In my test case it goes back to 2018 because i added test data for that
                        */

                    //Set the Rollover Amount
                    if (RolloverAmount > 0) {
                        RolloverAmount = parseFloat(RolloverAmount);
                        RolloverAmount = RolloverAmount.toFixed(2);
                        userData["leaveMonths"][curYear][MonthInc]["RolloverAmount"] = RolloverAmount;

                        //Add Rollover Amount to the total
                        userData["leaveMonths"][curYear][MonthInc]["total"] =
                            parseFloat(userData["leaveMonths"][curYear][MonthInc]["total"]) + parseFloat(userData["leaveMonths"][curYear][MonthInc]["RolloverAmount"]);
                        userData["leaveMonths"][curYear][MonthInc]["total"] = parseFloat(userData["leaveMonths"][curYear][MonthInc]["total"]).toFixed(2);
                        //userData["leaveMonths"][curYear][MonthInc]["total"] = userData["leaveMonths"][curYear][MonthInc]["total"].toFixed(2);
                    }

                    //Add Incentive to total
                    userData["leaveMonths"][curYear][MonthInc]["total"] =
                        parseFloat(userData["leaveMonths"][curYear][MonthInc]["total"]) + parseFloat(userData["leaveMonths"][curYear]["nonGrowing"]["incentiveTotal"]);
                    userData["leaveMonths"][curYear][MonthInc]["total"] = parseFloat(userData["leaveMonths"][curYear][MonthInc]["total"]).toFixed(2);

                    runningTotal += parseFloat(userData["leaveMonths"][curYear][MonthInc]["used"]);
                    runningTotalUntracked += parseFloat(userData["leaveMonths"][curYear][MonthInc]["usedUntracked"]);

                    if (debug === 1) {
                        // console.log("runningTotal", runningTotal);
                        // console.log("used " + curYear + "-" + MonthInc + ":", userData["leaveMonths"][curYear][MonthInc]);
                    }

                    userData["leaveMonths"][curYear][MonthInc]["runningTotal"] = parseFloat(runningTotal.toFixed(2));
                    userData["leaveMonths"][curYear][MonthInc]["left"] = userData["leaveMonths"][curYear][MonthInc]["total"] - (runningTotal + runningTotalUntracked);
                    userData["leaveMonths"][curYear][MonthInc]["left"] = parseFloat(userData["leaveMonths"][curYear][MonthInc]["left"].toFixed(2));

                    MonthInc++;
                }

                if (debug === 1) {
                    // console.log("userData before firstore update", userData["leaveMonths"]);
                }

                //Now try to update Accumulative Leave
                if (company.leaveRolloverType && company.leaveRolloverType === "Accumulative") {
                    curFinancialYear = parseInt(yearNow);
                    MonthInc = parseInt(leaveYearStart);

                    if (debug === 1) {
                        // console.log("accumutalive:");
                        // console.log("curFinancialYear:", curFinancialYear);
                        // console.log("MonthInc:", MonthInc);
                    }

                    //Check if we have leave for previous year
                    let foundPrev = false;
                    if (MonthInc > 1) {
                        if (userData["leaveMonths"][curFinancialYear] && userData["leaveMonths"][curFinancialYear][MonthInc - 1] && userData["leaveMonths"][curFinancialYear][MonthInc - 1]["left"]) {
                            let accum = userData["leaveMonths"][curFinancialYear][MonthInc - 1]["left"];

                            if (debug === 1) {
                                // console.log(curFinancialYear, MonthInc, accum);
                            }

                            if (accum > 0) {
                                userData["leaveMonths"][curFinancialYear]["nonGrowing"]["accumTotal"] = parseFloat(accum);
                                foundPrev = true;
                            }

                            //Work out the expiry date
                            //userData["leaveMonths"][curFinancialYear]["nonGrowing"]["totalAccumExpiry"] = "?";
                        }
                    }

                    if (debug === 1) {
                        // console.log("foundPrev", foundPrev);
                    }

                    if (foundPrev === false && userData["accumTakeOn"] && userData["accumTakeOnYear"] === curFinancialYear) {
                        userData["leaveMonths"][curFinancialYear]["nonGrowing"]["accumTotal"] = parseFloat(userData["accumTakeOn"]);
                    }
                }

                //And finally count Special Leave Hours
                // await firestore2
                //     .collection("leave")
                //     .where("userUid", "==", userUid)
                //     .where("leaveType", "==", "Sick")
                //     .where("status", "==", "Approved")
                //     .where("annualFrom", ">=", sickFromDt)
                //     .get()
                //     .then(function (querySnapshot) {
                //         querySnapshot.forEach(function (doc) {
                //             let this_data = doc.data();
                //             let annualDays = parseFloat(this_data.annualDays).toFixed(2);

                //             userData["leaveMonths"]["sick"]["sickUsed"] = parseFloat(userData["leaveMonths"]["sick"]["sickUsed"]) + parseFloat(annualDays);
                //             userData["leaveMonths"]["sick"]["sickUsed"] = userData["leaveMonths"]["sick"]["sickUsed"].toFixed(2);
                //         });
                //     })
                //     .catch(function (error) {
                //         console.log("Error getting documents: ", error);
                //     });

                await firestore.collection("users").doc(userUid).update(userData);

                if (debug === 1) {
                    // console.log("generate stats done");
                }

                if (message) {
                    toast.success(message);
                }
            } catch (error) {
                console.log(error);
                toast.error("Something went wrong");
            }
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function clearProfileLeave(values, returnProfile = false) {
    const firestore = firebase.firestore();

    try {
        let userRef = firestore.collection("users").doc(values.userId);

        await userRef.update({
            leaveMonths: firebase.firestore.FieldValue.delete(),
        });

        //Add Accumulative leave
        if (values.accumulativeDays && values.accumulativeDays !== "" && values.takeOnDate) {
            let year = format(values.takeOnDate, "yyyy");

            await firestore
                .collection("users")
                .doc(values.userId)
                .update({
                    accumTakeOn: parseFloat(values.accumulativeDays),
                    accumTakeOnYear: parseInt(year),
                });
        }

        if (returnProfile === true) {
            let userData = await userRef.get().then(async function (doc) {
                if (doc.exists) {
                    return doc.data();
                } else {
                    return false;
                }
            });

            return userData;
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getCurrentLeave(leaveId) {
    const firestore = firebase.firestore();
    return firestore.collection("leave").doc(leaveId).get();
}

export async function getUserLeave(id) {
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(id).get();
}

export async function getLeave(id) {
    const firestore = firebase.firestore();
    return firestore.collection("leave").where("userUid", "==", id).get();
}

export async function getClientTime() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userId = auth.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    let allUserTime = [];
    await firestore
        .collection("timetrack")
        .where("companyId", "==", userInfo.companyId)
        .where("departmentId", "in", userInfo.departments ? userInfo.departments : ["None"])
        .where("status", "==", "Pending")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                allUserTime.push(list);
            },
            (err) => {
                console.log(err);
            }
        );
    await firestore
        .collection("timetrack")
        .where("companyId", "==", userInfo.companyId)
        .where("approvalUser", "==", userInfo.id ? userInfo.id : ["None"])
        .where("status", "==", "Pending")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                allUserTime.push(list);
            },
            (err) => {
                console.log(err);
            }
        );

    let mergedTime = allUserTime.flat();

    return mergedTime;
}

export async function getClientLeave() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userId = auth.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    let allUserLeave = [];
    await firestore
        .collection("leave")
        .where("companyId", "==", userInfo.companyId)
        .where("approvalType", "==", "Line Manager/s in Department")
        .where("departmentId", "in", userInfo.departments.length > 0 ? userInfo.departments : ["None"])
        .where("status", "==", "Pending")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        if (appObj.negativeEscalated === undefined) {
                            list.push(appObj);
                        }
                    }
                });
                allUserLeave.push(list);
            },
            (err) => {
                console.log(err);
            }
        );
    await firestore
        .collection("leave")
        .where("companyId", "==", userInfo.companyId)
        .where("approvalType", "==", "Specific User")
        .where("approvalUser", "==", userInfo.id ? userInfo.id : ["None"])
        .where("status", "==", "Pending")
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        if (appObj.negativeEscalated === undefined) {
                            list.push(appObj);
                        }
                    }
                });
                allUserLeave.push(list);
            },
            (err) => {
                console.log(err);
            }
        );

    await firestore
        .collection("leave")
        .where("companyId", "==", userInfo.companyId)
        .where("specificApprovalHRUser", "==", userInfo.id ? userInfo.id : ["None"])
        .where("negativeRequested", "==", true)
        .where("status", "==", "Pending")
        .where("negativeEscalated", "==", true)
        .get()
        .then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                allUserLeave.push(list);
            },
            (err) => {
                console.log(err);
            }
        );

    let mergedLeave = allUserLeave.flat();

    return mergedLeave;
}

export async function multiExpenseApprove(values) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;

    try {
        let updates = values.check;

        if (values.edit === false) {
            if (updates !== undefined) {
                Object.keys(updates).forEach(async function (key) {
                    await firestore.collection("expenses").doc(values.row).collection("loaded").doc(key).update({ status: "Approved", appovedBy: auth.uid });
                });
            }

            await firestore.collection("expenses").doc(values.row).update({ status: "Approved", appovedBy: auth.uid });
        } else {
            let allEntries = await firestore.collection("expenses").doc(values.row).collection("loaded").get();
            let entry = allEntries.docs;
            let updates = Object.keys(values.check);
            if (updates !== undefined) {
                Object.keys(entry).forEach(async function (key) {
                    if (updates === undefined) {
                        await firestore.collection("expenses").doc(values.row).collection("loaded").doc(entry[key].id).update({ status: "Denied", editedBy: auth.uid });
                    } else if (updates.includes(entry[key].id)) {
                        await firestore.collection("expenses").doc(values.row).collection("loaded").doc(entry[key].id).update({ status: "Approved", editedBy: auth.uid });
                    } else {
                        await firestore.collection("expenses").doc(values.row).collection("loaded").doc(entry[key].id).update({ status: "Denied", editedBy: auth.uid });
                    }
                });
            }
            let deny = values.deny;
            if (deny !== undefined) {
                Object.keys(deny).forEach(async function (key) {
                    await firestore.collection("expenses").doc(values.row).collection("loaded").doc(deny[key]).update({ status: "Denied", editedBy: auth.uid });
                });
            }
        }

        toast.success("Expense added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}
