import React, { useState, useEffect } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getProjects, getAuthUser } from "../superActions";
import { useSelector } from "react-redux";
import firebase from "../../../app/config/firebase";

const firestore = firebase.firestore();

export default function ProjectsPage(props) {
    const [projects, setProjects] = useState([]);
    const [profile, setProfile] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("projects")
            .orderBy("companyId", "asc")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let appObj = { ...result.data(), id: result.id };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (b.companyName < a.companyName ? 1 : -1));

                setProjects(list);
            });

        return () => {
            unsubscribe();
        };
    }, []);

    let projectsNew = [];

    if (!currentUserProfile.isSuper && projects && projects.length > 0) {
        projectsNew = projects.filter((proj) => {
            return proj.companyId === currentUserProfile.companyId;
        });
    } else {
        projectsNew = projects;
    }

    return (
        <Container>
            <Button as={Link} to={`/super/project/0`}>
                Add Project
            </Button>
            <Button as={Link} to={`/super/landing`}>
                Back to Super
            </Button>

            <table className="ui celled table selectable">
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Project</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {projectsNew &&
                        projectsNew.length > 0 &&
                        projectsNew.map((projects) => (
                            <tr key={projects.id}>
                                <td data-label="CompanyName">{projects.companyName}</td>
                                <td data-label="ProjectName">
                                    {projects.projectName} {projects.projectPrefix && projects.projectPrefix !== undefined ? "(" + projects.projectPrefix + ")" : null}
                                </td>
                                <td data-label="Action">
                                    <Button as={Link} to={`/super/project/${projects.id}`}>
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Container>
    );
}
