import React, { useState, useEffect } from "react";
import { Form, Button, Comment, Grid } from "semantic-ui-react";
import format from "date-fns/format";
import { leaveComment } from "../staffActions";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Formik } from "formik";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeaveComments(props) {
    const [leave_comments, setLeave_comments] = useState([]);
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leave")
            .doc(id)
            .collection("comments")
            .orderBy("createDate", "desc")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setLeave_comments(list);
            });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            <h2>Comments</h2>

            {leave_comments && leave_comments.length > 0 && (
                <Comment.Group>
                    {leave_comments.map((sr) => (
                        <Comment key={sr.id}>
                            <Comment.Content>
                                <Comment.Author as="a">{sr.displayName}</Comment.Author>
                                <Comment.Metadata>{format(sr.createDate.toDate(), "d MMMM yyyy HH:mm:ss")}</Comment.Metadata>
                                <Comment.Text>{sr.comment}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    ))}
                </Comment.Group>
            )}

            <Formik
                initialValues={{ comment: "" }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let leaveId = id;
                    leaveComment(values, leaveId);
                    resetForm();
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid stackable>
                            <Grid.Row columns={3}>
                                <Grid.Column width={2}>
                                    <label>Comment</label>
                                </Grid.Column>

                                <Grid.Column width={10}>
                                    <Form.Field>
                                        <MyTextInput name="comment" />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <Button disabled={!dirty || isSubmitting} loading={isSubmitting} type="submit" fluid size="large" color="blue">
                                        COMMENT
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </div>
    );
}
