import React, { useState, useEffect } from "react";
import { Form, Button, Divider } from "semantic-ui-react";
import { supportReply, getSupportRec } from "../supportActions";
import { Formik } from "formik";
import MyTextArea from "../../../app/common/form/MyTextArea";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function SupportReqReplyForm(props) {
    const [support_req, setSupport_req] = useState([]);
    let parts = window.location.href.split("/");
    let supportId = parts.pop() || parts.pop();

    useEffect(() => {
        const unsubscribe = firestore
            .collection("support")
            .doc(supportId)
            .onSnapshot((snapshot) => {
                setSupport_req(snapshot.data());
            });

        return () => unsubscribe();
    }, []);

    return (
        <Formik
            initialValues={{ response: "" }}
            enableReinitialize={true}
            validate={(values) => {
                const errors = {};
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                support_req.id = supportId;
                await supportReply(values, support_req);
                resetForm();
                setSubmitting(false);
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Form.Field>
                        <label>Query</label>
                        <MyTextArea name="response" />
                    </Form.Field>

                    <Divider />

                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" fluid size="large" color="blue">
                        REPLY
                    </Button>
                </form>
            )}
        </Formik>
    );
}
