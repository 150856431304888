import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Grid, Segment, Button, Table, Label } from "semantic-ui-react";
import NavLogo from "../nav/NavBar/NavLogo";
import LeaveHomeForm from "../manager/ManagerLeaveList/LeaveHomeForm";
import format from "date-fns/format";
import OnboardingPage from "./OnboardingPage";
import ActionsBlock from "./ActionsBlock/ActionsBlock";
import { getProfileClient } from "./homeActions";
import { openModal } from "../../app/common/modals/modalReducer";
import ErrorBoundary from "../../app/layout/ErrorBoundary";
import addSeconds from "date-fns/addSeconds";
// import firebase from "../../app/config/firebase";
import axios from "axios";
// import { getTime } from "../clients/clientActions";
// const firestore = firebase.firestore();
import apiURL from "../../app/config/apiURL";

import firebase from "../../app/config/firebase";
import { generateNewLeaveStats, genSickLeave } from "../manager/managerActions";
import { startOfMonth } from "date-fns/esm";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { getDay } from "date-fns";
// import { moveCompany, moveLeavePeriods } from "../staff/staffActions";
const firestore = firebase.firestore();

export default function HomePage(props) {
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();
    const [clientView, setClientView] = useState(false);
    const [profile, setProfile] = useState([]);
    const [timetrack, setTimetrack] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [company, setCompany] = useState([]);
    const dispatch = useDispatch();
    let credValues = {};
    const [leaveMonthly, setLeaveMonthly] = useState([]);
    const [leave, setLeave] = useState([]);
    const [sickLeave, setSickLeave] = useState([]);

    const [leaveLoading, setLeaveLoading] = useState(false);

    if (props.history.location.state !== undefined) {
        credValues = props.history.location.state.values;
    }

    if (id === "home") {
        window.location.replace("/dashboard/landing");
    }

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        if (currentUserProfile.id !== id && id !== "home" && id !== "landing") {
            setClientView(true);
        } else {
            setClientView(false);
        }

        let usr = "";

        if (id === "home" || id === "landing") {
            usr = firebase.auth().currentUser.uid;
        } else {
            usr = id;
        }

        getProfileClient(usr).then(
            async (res) => {
                if (res && res.exists) {
                    let profileUser = { ...res.data(), id: res.id };
                    setProfile({ ...res.data(), id: res.id });

                    await firestore
                        .collection("companies")
                        .doc(profileUser.companyId)
                        .onSnapshot(async (snapshot) => {
                            let company = snapshot.data();
                            setCompany(company);

                            if (company.leaveMethodType && company.leaveMethodType === "New Method") {
                                setLeaveLoading(true);

                                await firestore
                                    .collection("leaveStatus")
                                    .where("userUid", "==", profileUser.id)
                                    // .where("toDate", ">=", new Date())
                                    .where("periodType", "==", "Leave")
                                    .onSnapshot(async (snapshot) => {
                                        let docs = snapshot.docs;
                                        let dataExists = false;
                                        let KeyId = 0;

                                        Object.keys(docs).forEach((key) => {
                                            let dateData = docs[key].data().toDate.toDate();
                                            if (company.leaveMonthType && company.leaveMonthType == "Custom From and To Date") {
                                                if (new Date(dateData.toDateString()) >= new Date(new Date().toDateString()) && new Date(dateData.toDateString()).getDate() == company.leaveToDay) {
                                                    dataExists = true;
                                                    KeyId = key;
                                                }
                                            } else {
                                                if (new Date(dateData.toDateString()) >= new Date(new Date().toDateString())) {
                                                    dataExists = true;
                                                    KeyId = key;
                                                }
                                            }
                                        });

                                        if (dataExists === true) {
                                            let startDate =
                                                company.leaveMonthType && company.leaveMonthType == "Custom From and To Date"
                                                    ? new Date(new Date().getFullYear(), new Date().getMonth(), company.leaveFromDay)
                                                    : startOfMonth(new Date());

                                            setLeave(snapshot.docs[KeyId].data());

                                            // console.log(snapshot.docs[KeyId].id);
                                            // console.log(startDate);
                                            // console.log(new Date());
                                            // console.log(new Date() > startDate);

                                            if (new Date() > startDate == false) {
                                                startDate =
                                                    company.leaveMonthType && company.leaveMonthType == "Custom From and To Date"
                                                        ? new Date(new Date().getFullYear(), new Date().getMonth() - 1, company.leaveFromDay)
                                                        : startOfMonth(new Date());
                                            }

                                            // console.log(snapshot.docs[KeyId].id)
                                            // console.log(snapshot.docs[KeyId].data())
                                            if (
                                                (company.leaveMonthType &&
                                                    company.leaveMonthType == "Custom From and To Date" &&
                                                    snapshot.docs[KeyId].data().toDate.toDate().getDate() == company.leaveToDay) ||
                                                company.leaveMonthType == undefined ||
                                                company.leaveMonthType !== "Custom From and To Date"
                                            ) {
                                                // TODO: temporary fix - need to figure out why this sometimes returns no data.
                                                await firestore
                                                    .collection("leaveStatus")
                                                    .doc(snapshot.docs[KeyId].id)
                                                    .collection("Months")
                                                    .where("datePeriod", "==", startDate)
                                                    .onSnapshot((snapshot2) => {
                                                        if (snapshot2.docs.length > 0) {
                                                            // console.log(snapshot2.docs[0]);
                                                            // console.log("Data", snapshot2.docs[0].data());
                                                            setLeaveMonthly(snapshot2.docs[0].data());
                                                            setLeaveLoading(false);
                                                        } else {
                                                            setLeaveLoading(false);
                                                        }
                                                    });
                                            } else {
                                                await generateNewLeaveStats(profileUser.id);
                                            }
                                        } else {
                                            await generateNewLeaveStats(profileUser.id);
                                        }
                                    });

                                await firestore
                                    .collection("leaveStatus")
                                    .where("userUid", "==", profileUser.id)
                                    .where("toDate", ">", new Date())
                                    .where("periodType", "==", "Sick")
                                    .onSnapshot(async (snapshot) => {
                                        if (snapshot.empty === false) {
                                            setSickLeave(snapshot.docs[0].data());
                                        } else {
                                            await genSickLeave(profileUser.id);
                                        }
                                    });
                            }
                        });
                    const response = await axios.post(apiURL + "/api/get_time_home", { profileId: res.id });
                    setTimetrack(response.data);

                    // const unsubscribe = firestore
                    //     .collection("timetrack")
                    //     .where("userUid", "==", res.data().id)
                    //     .where("status", "in", ["In Progress"])
                    //     .onSnapshot((snapshot) => {
                    //         let list = [];
                    //         snapshot.docs.forEach((result) => {
                    //             if (result.exists) {
                    //                 let obj = { ...result.data(), id: result.id };
                    //                 list.push(obj);
                    //             }
                    //         });
                    //         setTimetrack(list[0]);
                    //     });
                } else {
                    setProfile(false);
                    const response = await axios.post(apiURL + "/api/get_time_home", { profileId: currentUserProfile.id });
                    setTimetrack(response.data);
                }
            },
            (err) => {
                console.log(err);
            }
        );
        // let usr = "";

        if (id == "home") {
            usr = firebase.auth().currentUser.uid;
        } else {
            usr = id;
        }

        const unsubscribe = firestore
            .collection("timetrack")
            .where("userUid", "==", usr)
            .where("status", "in", ["In Progress"])
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setTimetrack(list[0]);
            });

        const unsubscribe2 = firestore
            .collection("expenses")
            .where("userUid", "==", usr)
            .where("status", "==", "In Progress")
            .onSnapshot((snapshot) => {
                if (snapshot.empty === false) {
                    setExpenses(snapshot.docs[0].data());
                }
            });

        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, [id]);

    if (profile === false) {
        return (
            <>
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            </>
        );
    }

    let yearNow = format(Date.now(), "yyyy");
    let monthNow = format(Date.now(), "M");

    let { left, total, used, leftSick, totalSick, usedSick, leftFamily, totalFamily, usedFamily } = 0;

    let curFinancialYear = parseInt(profile.companyYearStart);
    let nonGrowingYear = parseInt(yearNow);

    if (monthNow < curFinancialYear) {
        nonGrowingYear = parseInt(yearNow) - 1;
    }

    if (profile["leaveMonths"] && !profile["leaveMonths"][nonGrowingYear]) {
        nonGrowingYear = parseInt(yearNow);
    }

    if (leaveMonthly.hasOwnProperty("annualLeft") && leaveMonthly.hasOwnProperty("annualTotal") && leaveMonthly.hasOwnProperty("annualUsed")) {
        left = Number.isInteger(leaveMonthly.annualLeft) ? leaveMonthly.annualLeft : parseFloat(leaveMonthly.annualLeft).toFixed(2);
        total = Number.isInteger(leaveMonthly.annualTotal) ? leaveMonthly.annualTotal : parseFloat(leaveMonthly.annualTotal).toFixed(2);
        used = leaveMonthly.annualUsed;
    } else {
        if (
            profile &&
            profile["leaveMonths"] &&
            profile["leaveMonths"][yearNow] &&
            profile["leaveMonths"][yearNow][monthNow] &&
            profile["leaveMonths"][nonGrowingYear] &&
            profile["leaveMonths"][nonGrowingYear]["nonGrowing"]
        ) {
            left = parseFloat(profile["leaveMonths"][yearNow][monthNow]["left"]);
            total = parseFloat(profile["leaveMonths"][yearNow][monthNow]["total"]);
            used = parseFloat(profile["leaveMonths"][yearNow][monthNow]["runningTotal"]);
        }
    }

    if (sickLeave.hasOwnProperty("sickUsed") && sickLeave.hasOwnProperty("sickTotal") && sickLeave.hasOwnProperty("sickLeft")) {
        usedSick = sickLeave.sickUsed;
        totalSick = sickLeave.sickTotal;
        leftSick = sickLeave.sickLeft;
    } else {
        if (
            profile &&
            profile["leaveMonths"] &&
            profile["leaveMonths"][yearNow] &&
            profile["leaveMonths"][yearNow][monthNow] &&
            profile["leaveMonths"][nonGrowingYear] &&
            profile["leaveMonths"][nonGrowingYear]["nonGrowing"]
        ) {
            usedSick = parseFloat(profile["leaveMonths"]["sick"]["sickUsed"]);
            totalSick = parseFloat(profile["leaveMonths"]["sick"]["sickTotal"]);
            leftSick = totalSick - usedSick;
        }
    }

    if (leave.hasOwnProperty("familyUsed") && leave.hasOwnProperty("familyTotal") && leave.hasOwnProperty("familyLeft")) {
        usedFamily = leave.familyUsed;
        totalFamily = leave.familyTotal;
        leftFamily = leave.familyLeft;
    } else {
        if (
            profile &&
            profile["leaveMonths"] &&
            profile["leaveMonths"][yearNow] &&
            profile["leaveMonths"][yearNow][monthNow] &&
            profile["leaveMonths"][nonGrowingYear] &&
            profile["leaveMonths"][nonGrowingYear]["nonGrowing"]
        ) {
            usedFamily = parseFloat(profile["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyUsed"]);
            totalFamily = parseFloat(profile["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"]);
            leftFamily = totalFamily - usedFamily;
        }
    }

    let cal_day = format(Date.now(), "d");
    let cal_m_y = format(Date.now(), "MMM-yyyy");

    // let migrationHasRun = false;
    // if (!migrationHasRun) {
    //     moveLeavePeriods();
    //     migrationHasRun = true;
    // }

    return (
        <>
            {clientView === true && (
                <ErrorBoundary>
                    <Container>
                        <Segment textAlign="center" style={{ marginBottom: "20px" }}>
                            <h1>Staff Member: {profile.displayName ? profile.displayName : profile.email}</h1>
                            {profile && profile.onboardingDone === true && (currentUserProfile.isAdmin || currentUserProfile.isSuper || currentUserProfile.isHR) ? (
                                <Link as="a" to={`/settings/basic/${profile.id}`}>
                                    <Button color="blue" style={{ float: "right", marginTop: -50 }}>
                                        Edit Profile
                                    </Button>
                                </Link>
                            ) : null}
                        </Segment>
                    </Container>
                </ErrorBoundary>
            )}

            {(currentUserProfile.isHR === true || currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) &&
                (profile.onboardingDone === false || !profile.onboardingDone) &&
                clientView === true && (
                    <ErrorBoundary>
                        <Container>
                            <Segment textAlign="center" style={{ marginBottom: "20px", backgroundColor: "transparent" }}>
                                <h1>Onboarding not completed</h1>
                            </Segment>
                        </Container>
                    </ErrorBoundary>
                )}

            {profile && clientView === false && (!profile.onboardingDone || profile.onboardingDone === false) ? (
                <ErrorBoundary>
                    <OnboardingPage profile={profile} creds={credValues} />
                </ErrorBoundary>
            ) : null}

            {(clientView === true && (currentUserProfile.isAdmin || currentUserProfile.isSuper || currentUserProfile.isLineManager)) || (clientView === false && currentUserProfile.onboardingDone) ? (
                <>
                    {profile && ((clientView === false && profile.onboardingDone) || clientView === true) && (
                        <div>
                            <ErrorBoundary>
                                <NavLogo
                                    profile={profile}
                                    h1={clientView === true ? "Staff Member Dashboard" : "Your Dashboard"}
                                    p="Here you will find all documents and links you require, from time-keeping and leave to shared calendars, personal settings and reporting."
                                />
                            </ErrorBoundary>

                            <Container>
                                <br />

                                <div className="home_grid">
                                    {clientView === false && (
                                        <ErrorBoundary>
                                            <ActionsBlock />
                                        </ErrorBoundary>
                                    )}

                                    {profile && profile.isLineManager === true && clientView === false && (
                                        <ErrorBoundary>
                                            <LeaveHomeForm />
                                        </ErrorBoundary>
                                    )}

                                    {((profile && profile.isLineManager === true) || (profile && profile.isAdmin === true) || (profile && profile.isHR === true)) && clientView === false && (
                                        <ErrorBoundary>
                                            <Segment>
                                                <h2>Employees</h2>
                                                <p>View your staff, all their details and requests.</p>

                                                <div className="img">
                                                    <img src="/assets/employees.png" alt="Employees" />
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View all employees{" "}
                                                        <Link as="a" to={`/clients/staff/${profile.companyId}`}>
                                                            here.
                                                        </Link>
                                                        <br />
                                                        <Link as="a" to="/home">
                                                            Export list now in Excel.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </Segment>
                                        </ErrorBoundary>
                                    )}

                                    {company && company.hasExpenseTracking && company.hasExpenseTracking === true && (
                                        <ErrorBoundary>
                                            <Segment>
                                                <h2>Expense Tracking</h2>
                                                <p>Your Expense Tracking per month.</p>

                                                <div className="img">
                                                    <Grid columns={2}>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <h3>Logged</h3>
                                                                <p className="number greentxt">{<span>{expenses && expenses.loaded ? expenses.loaded : "0"}</span>}</p>
                                                            </Grid.Column>

                                                            <Grid.Column>
                                                                <h3>Total</h3>
                                                                <p className="number">R {<span>{expenses && expenses.total ? expenses.total : "0"}</span>}</p>
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                {clientView === false ? (
                                                                    <Link as="a" to={`/expense/add`}>
                                                                        <Button color="blue">Add Expense</Button>
                                                                    </Link>
                                                                ) : (
                                                                    <Link as="a" to={`/expense/add/${profile.id}`}>
                                                                        <Button color="blue">Add Expense</Button>
                                                                    </Link>
                                                                )}
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View all expense tracking requests{" "}
                                                        {clientView === false ? (
                                                            <Link as="a" to={`/expenses/view/all`}>
                                                                here
                                                            </Link>
                                                        ) : (
                                                            <Link as="a" to={`/expenses/view/all/${profile.id}`}>
                                                                here
                                                            </Link>
                                                        )}
                                                    </p>
                                                </div>
                                            </Segment>
                                        </ErrorBoundary>
                                    )}

                                    {(profile.isStaff === true || currentUserProfile.isLineManager === true || currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) === true ? (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Your Leave</h2>
                                                <p>Your annual leave and absences.</p>

                                                <div className="spacer">
                                                    {leaveLoading === true ? (
                                                        <LoadingComponent />
                                                    ) : (
                                                        <Table basic="very" celled collapsing unstackable style={{ width: "100%" }}>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                                                    <Table.HeaderCell>Used</Table.HeaderCell>
                                                                    <Table.HeaderCell>Left</Table.HeaderCell>
                                                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell>Annual</Table.Cell>
                                                                    <Table.Cell>{used}</Table.Cell>
                                                                    <Table.Cell>{left}</Table.Cell>
                                                                    <Table.Cell>{total}</Table.Cell>
                                                                </Table.Row>

                                                                <Table.Row>
                                                                    <Table.Cell>Family</Table.Cell>
                                                                    <Table.Cell>{usedFamily}</Table.Cell>
                                                                    <Table.Cell>{leftFamily}</Table.Cell>
                                                                    <Table.Cell>{totalFamily}</Table.Cell>
                                                                </Table.Row>

                                                                <Table.Row>
                                                                    <Table.Cell>Sick</Table.Cell>
                                                                    <Table.Cell>{usedSick}</Table.Cell>
                                                                    <Table.Cell>{leftSick}</Table.Cell>
                                                                    <Table.Cell>{totalSick}</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    )}

                                                    {clientView === false && (
                                                        <Button
                                                            color="blue"
                                                            onClick={() =>
                                                                dispatch(
                                                                    openModal({
                                                                        modalType: "LeaveTrackModal",
                                                                        modalProps: { profile: profile, profileClient: profile, clientView: clientView, type: "add", company: company },
                                                                    })
                                                                )
                                                            }
                                                        >
                                                            REQUEST LEAVE
                                                        </Button>
                                                    )}
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View all leave requests{" "}
                                                        <Link as="a" to={`/clients/leaveview/${profile.id}`}>
                                                            here.
                                                        </Link>
                                                        {/*<br />
                                                    Next leave date coming up:<br /><span className='bluetxt'>(6-Jun-2019: Public Holiday)</span>*/}
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    ) : null}

                                    {profile && profile.isStaff === true && profile.hasTimeTracking && profile.hasTimeTracking === true && (
                                        <ErrorBoundary>
                                            <Segment>
                                                <h2>Your Time Tracking</h2>
                                                <p>Your Time Tracking per project.</p>

                                                <div className="img">
                                                    <Grid columns={2}>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <h3>Logged</h3>
                                                                <p className="number greentxt">{<span>{timetrack && timetrack.daysCount ? timetrack.daysCount : "0"}</span>}</p>
                                                                <h3>Days</h3>
                                                            </Grid.Column>

                                                            <Grid.Column>
                                                                <h3>Logged</h3>
                                                                <p className="number">{<span>{timetrack && timetrack.hoursTotal ? timetrack.hoursTotal : "0"}</span>}</p>
                                                                <h3>Hours</h3>
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <Link as="a" to={`/clients/time/${profile.id}`}>
                                                                    <Button color="blue">VIEW TIME</Button>
                                                                </Link>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View all time tracking requests{" "}
                                                        <Link as="a" to={`/clients/time/${profile.id}`}>
                                                            here.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </Segment>
                                        </ErrorBoundary>
                                    )}

                                    {(profile && profile.isStaff === true && clientView === false) ||
                                        (currentUserProfile && (currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true)) ? (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Work From Home</h2>
                                                <p>Track the days you work from home.</p>

                                                <div className="img" style={{ marginTop: "-20px" }}>
                                                    <img src="/assets/your_documents.png" alt="Your Documents" />
                                                </div>

                                                <div className="center">
                                                    <Link as="a" to={`/staff/home/${profile.id}`}>
                                                        <Button color="blue">TRACK DAYS</Button>
                                                    </Link>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    ) : null}

                                    {profile && profile.isStaff === true && clientView === false && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Calendar</h2>
                                                <p>View absences, holidays and notable dates.</p>

                                                <div className="img">
                                                    <div>
                                                        <h3>Today’s date</h3>
                                                        <p className="number">{cal_day}</p>
                                                        <h3>{cal_m_y}</h3>
                                                    </div>
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View all calendar entries{" "}
                                                        <Link as="a" to="/staffcalendar">
                                                            here.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}

                                    {((profile && profile.isStaff === true && clientView === false) ||
                                        (currentUserProfile && currentUserProfile.isAdmin === true && currentUserProfile.viewDocuments === true)) && (
                                            <Segment>
                                                <ErrorBoundary>
                                                    <h2>Your Documents</h2>
                                                    <p>Manage all your documents.</p>

                                                    <div className="img">
                                                        <img src="/assets/your_documents.png" alt="Your Documents" />
                                                    </div>

                                                    <div className="center">
                                                        <p style={{ marginBottom: 0 }}>
                                                            View your documents{" "}
                                                            <Link as="a" to={clientView === true ? `/resources/${profile.id}` : "/resources/"}>
                                                                here.
                                                            </Link>
                                                        </p>

                                                        {profile.isLineManager && (
                                                            <p>
                                                                <Link as="a" to="/resources">
                                                                    Upload document
                                                                </Link>
                                                            </p>
                                                        )}
                                                    </div>
                                                </ErrorBoundary>
                                            </Segment>
                                        )}

                                    {/* {currentUserProfile && currentUserProfile.isAdmin === true && currentUserProfile.viewDocuments === true && ( */}
                                    {currentUserProfile && currentUserProfile.isStaff === true && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Company Documents</h2>
                                                <p>Manage company documents.</p>

                                                <div className="img">
                                                    <img src="/assets/your_documents.png" alt="Your Documents" />
                                                </div>

                                                <div className="center">
                                                    <p style={{ marginBottom: 0 }}>
                                                        View documents{" "}
                                                        <Link as="a" to={clientView === true ? `/company/resources/${profile.id}` : "/company/resources/"}>
                                                            here.
                                                        </Link>
                                                    </p>

                                                    {profile.isLineManager && (
                                                        <p>
                                                            <Link as="a" to="/resources/company/">
                                                                Upload document
                                                            </Link>
                                                        </p>
                                                    )}
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}

                                    {profile.isSuper === true && clientView === false && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Clients</h2>
                                                <p>View your entire client list.</p>

                                                <div className="img">
                                                    <img src="/assets/clients.png" alt="Clients" />
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View all your clients{" "}
                                                        <Link as="a" to="/clients/landing">
                                                            here.
                                                        </Link>
                                                        <br />
                                                        <Link as="a" to="/home">
                                                            Export list now in Excel.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}
                                    {profile.isSuper === true && clientView === false && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Admin</h2>
                                                <p>Various Settings for Admin Users</p>

                                                <div className="img">
                                                    <img src="/assets/settings.png" alt="Settings" />
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        See more{" "}
                                                        <Link as="a" to="/admin/landing">
                                                            here.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}
                                    {((profile && profile.isStaff === true && clientView === false) ||
                                        (currentUserProfile && currentUserProfile.isAdmin === true && currentUserProfile.viewPayslips === true) ||
                                        currentUserProfile.isSuper === true) && (
                                            <Segment>
                                                <ErrorBoundary>
                                                    <h2>Your Payslips</h2>
                                                    <p>View and download all your payslips.</p>

                                                    <div className="img">
                                                        <img src="/assets/your_payslips.png" alt="Your Payslips" />
                                                    </div>

                                                    <div className="center">
                                                        <p style={{ marginBottom: 0 }}>
                                                            View your documents{" "}
                                                            <Link as="a" to={clientView === true ? `/payslips/${profile.id}` : "/payslips/"}>
                                                                here.
                                                            </Link>
                                                        </p>

                                                        {(profile.isSuper || profile.isAdmin) && (
                                                            <p>
                                                                <Link as="a" to="/payslips_bulk">
                                                                    Bulk Payslip Uploads
                                                                </Link>
                                                            </p>
                                                        )}
                                                    </div>
                                                </ErrorBoundary>
                                            </Segment>
                                        )}

                                    {((profile && profile.isStaff === true && clientView === false) ||
                                        (currentUserProfile && currentUserProfile.isAdmin === true && currentUserProfile.viewIrp5 === true) ||
                                        currentUserProfile.isSuper === true) && (
                                            <Segment>
                                                <ErrorBoundary>
                                                    <h2>IRP5 Documents</h2>
                                                    <p>View and download your documents for SARS.</p>

                                                    <div className="img">
                                                        <img src="/assets/irp5_docs.png" alt="IRP5 Documents" />
                                                    </div>

                                                    <div className="center">
                                                        <p style={{ marginBottom: 0 }}>
                                                            View your documents{" "}
                                                            <Link as="a" to={clientView === true ? `/irp5/${profile.id}` : "/irp5/"}>
                                                                here.
                                                            </Link>
                                                        </p>
                                                        {profile.isLineManager && (
                                                            <p style={{ marginBottom: 0 }}>
                                                                <Link as="a" to="/irp5">
                                                                    Upload document
                                                                </Link>
                                                            </p>
                                                        )}
                                                        {profile.isLineManager && (
                                                            <p>
                                                                <Link as="a" to="/irp5_bulk">
                                                                    Bulk IRP5 Uploads
                                                                </Link>
                                                            </p>
                                                        )}
                                                    </div>
                                                </ErrorBoundary>
                                            </Segment>
                                        )}
                                    {profile && profile.isLineManager && profile.isLineManager === true && clientView === false && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Reports</h2>
                                                <p>Create and review reports on your data.</p>

                                                <div className="img">
                                                    <img src="/assets/reports.png" alt="Reports" />
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View your reports{" "}
                                                        <Link as="a" to="/reports/landing">
                                                            here.
                                                        </Link>
                                                        <br />
                                                        <Link as="a" to="/reports/landing">
                                                            Start a new report
                                                        </Link>
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}
                                    {clientView === false && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Queries & Info</h2>
                                                <p>Not sure how to use this tool? Looking for your employment terms and contract details, see them here, or open a ticket and ask.</p>

                                                <div className="img">
                                                    <img src="/assets/queries.png" alt="Queries & Info" />
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        View your queries{" "}
                                                        <Link as="a" to="/support/landing">
                                                            here.
                                                        </Link>
                                                        <br />
                                                        <Link as="a" to="/support/landing">
                                                            Open a ticket now.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}
                                    {clientView === false && (
                                        <Segment>
                                            <ErrorBoundary>
                                                <h2>Settings</h2>
                                                <p>Personalise your colour theme, upload your photo and company logo, change your background.</p>

                                                <div className="img">
                                                    <img src="/assets/settings.png" alt="Settings" />
                                                </div>

                                                <div className="center">
                                                    <p>
                                                        See more{" "}
                                                        <Link as="a" to="/settings">
                                                            here.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </ErrorBoundary>
                                        </Segment>
                                    )}
                                </div>
                            </Container>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <NavLogo
                        profile={profile}
                        h1={clientView === true ? "Staff Member Dashboard" : "Your Dashboard"}
                        p="Here you will find all documents and links you require, from time-keeping and leave to shared calendars, personal settings and reporting."
                    />
                </div>
            )}
        </>
    );
}
