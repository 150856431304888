import React, { useState, useEffect } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getAuthUser, getCompanies, getLeaveAction, getSiteName } from "../reportsAction";
import { useSelector } from "react-redux";

export default function LeaveActivity(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [profile, setProfile] = useState([]);
    const [leave, setLeave] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companyFilterId, setCompanyFilterId] = useState("0");

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());

                    getCompanies().then(
                        (resData) => {
                            let list = [];
                            resData.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    list.push(appObj);
                                }
                            });

                            let companiesList = [
                                {
                                    key: "",
                                    text: "Choose a Company",
                                    value: "",
                                },
                            ];

                            Object.keys(list).forEach(function (key) {
                                if (
                                    res.data().isSuper === true ||
                                    (res.data().isAdmin === true && res.data().companyId == list[key].id) ||
                                    (res.data().isHR === true && res.data().companyId == list[key].id) ||
                                    (res.data().isLineManager === true && res.data().companyId == list[key].id)
                                ) {
                                    companiesList.push({
                                        key: list[key].id,
                                        text: list[key].companyName,
                                        value: list[key].id,
                                    });
                                }
                            });

                            setCompaniesList(companiesList);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );

        getLeaveAction(companyFilterId).then(
            async (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };

                        let allowedDeps = currentUserProfile.departments;

                        if (
                            currentUserProfile.isSuper === true ||
                            currentUserProfile.isAdmin === true ||
                            currentUserProfile.isHR === true ||
                            (currentUserProfile.isLineManager === true && allowedDeps.includes(appObj.departmentId))
                        ) {

                            if (startDate !== "" && doc.data().annualFrom) {
                                if (format(new Date(doc.data().annualFrom.toDate()), "yyyy-MM-dd") >= format(startDate, "yyyy-MM-dd")) {
                                    if (format(new Date(doc.data().annualFrom.toDate()), "yyyy-MM-dd") <= format(endDate, "yyyy-MM-dd")) {
                                        list.push(appObj);
                                    }
                                }
                            }

                            if (startDate == "") {
                                list.push(appObj);
                            }
                        }
                    }
                });

                if (list && list.length > 0) {
                    for (var key in list) {
                        list[key].dateRequested = format(list[key].createDate.toDate(), "yyyy-MM-dd HH:mm:Ss");
                        list[key].fromDate = list[key].annualFrom ? format(list[key].annualFrom.toDate(), "yyyy-MM-dd") : "";
                        list[key].toDate = list[key].annualTo ? format(list[key].annualTo.toDate(), "yyyy-MM-dd") : "";
                        if (currentUserProfile.companyId == "tSCqYFwyczd0DXOUi18F") {
                            list[key].siteName = await getSiteName(list[key].userUid);
                        }
                    }
                }

                list.sort((a, b) => (a.dateRequested < b.dateRequested ? 1 : -1));

                setLeave(list);
            },
            (err) => {
                console.log(err);
            }
        );
    }, [companyFilterId, startDate, endDate]);

    function filterData(value) {
        setStartDate(value);
    }
    function changeId(value) {
        setCompanyFilterId(value);
    }
    function filterDataTo(value) {
        setEndDate(value);
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Leave Activity Report</h1>
                        </Grid.Column>

                        <Grid.Column textAlign="right">{console.log(leave)}
                            {leave && leave.length > 0 && (
                                <>
                                    {currentUserProfile.companyId == "tSCqYFwyczd0DXOUi18F" ? (
                                        <ExcelFile element={<Button color="blue">Download</Button>}>
                                            <ExcelSheet data={leave} name="Leave">
                                                <ExcelColumn label="Display Name" value="displayName" />
                                                <ExcelColumn label="From Date" value="fromDate" />
                                                <ExcelColumn label="To Date" value="toDate" />
                                                <ExcelColumn label="Leave Type" value="leaveType" />
                                                <ExcelColumn label="Leave Requested" value="annualDays" />
                                                <ExcelColumn label="Requested on Date" value="dateRequested" />
                                                <ExcelColumn label="Leave Status" value="status" />
                                                <ExcelColumn label="Site" value="siteName" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    ) : (
                                        <ExcelFile element={<Button color="blue">Download</Button>}>
                                            <ExcelSheet data={leave} name="Leave">
                                                <ExcelColumn label="Display Name" value="displayName" />
                                                <ExcelColumn label="From Date" value="fromDate" />
                                                <ExcelColumn label="To Date" value="toDate" />
                                                <ExcelColumn label="Leave Type" value="leaveType" />
                                                <ExcelColumn label="Leave Requested" value="annualDays" />
                                                <ExcelColumn label="Requested on Date" value="dateRequested" />
                                                <ExcelColumn label="Leave Status" value="status" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    )}
                                </>
                            )}

                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={6}>
                                    {/* <Grid.Column> */}
                                    {/* <label>Choose a period</label> */}
                                    {/* <DatePicker
                                            name="startDate"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            dropdownMode="select"
                                            value={values.startDate}
                                            selected={startDate}
                                            onChange={(value) => {
                                                filterData(value);
                                            }}
                                            disabledKeyboardNavigation
                                        /> */}
                                    {/* <input type="month" selected={startDate} onChange={(value) => filterData(value.target.value)} /> */}

                                    {/* </Grid.Column> */}

                                    <Grid.Column>
                                        <label>From:</label>
                                        <DatePicker selected={startDate} onChange={(value) => filterData(value)} showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy-MM-dd" />
                                        {/* <input type="month" selected={startDate} onChange={(value) => filterData(value.target.value)} /> */}
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label>To:</label>
                                        <DatePicker selected={endDate} onChange={(value) => filterDataTo(value)} showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy-MM-dd" />
                                        {/* <input type="month" selected={endDate} onChange={(value) => filterData(value.target.value)} /> */}
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="companyId"
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            value={values.companyId}
                                            onChange={(field, value) => {
                                                changeId(value.value);
                                                setFieldValue("companyId", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            <table className="ui celled table selectable">
                <thead>
                    <tr style={{ fontSize: 11 }}>
                        <th>Display Name</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Leave Type</th>
                        <th>Days Leave Requested</th>
                        <th>Requested on Date</th>
                        <th>Status</th>
                        {currentUserProfile.companyId == "tSCqYFwyczd0DXOUi18F" && <th>Site</th>}
                        <th>Actions</th>
                    </tr>
                </thead>

                <tbody style={{ fontSize: 11 }}>
                    {leave &&
                        leave.length > 0 &&
                        leave.map((leaveRow) => (
                            <tr key={leaveRow.id}>
                                <td>
                                    <span>
                                        <strong>{leaveRow.displayName}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.fromDate}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.toDate}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.leaveType}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.annualDays}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.dateRequested}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{leaveRow.status}</strong>
                                    </span>
                                </td>
                                {currentUserProfile.companyId == "tSCqYFwyczd0DXOUi18F" &&
                                    <td>
                                        <span>
                                            <strong>{leaveRow.siteName}</strong>
                                        </span>
                                    </td>
                                }

                                <td width={150} data-label="Action">
                                    <Button color="blue" as={Link} to={`/staff/view/${leaveRow.id}`}>
                                        View Leave
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Container>
    );
}
