import React, { useState, useEffect, useCallback } from "react";
import { Container, Button, Grid, Segment } from "semantic-ui-react";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getAuthUser, getCompanies } from "../reportsAction";
import axios from "axios";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import apiURL from "../../../app/config/apiURL";
import firebase from "../../../app/config/firebase";

const firestore = firebase.firestore();

export default function ReportLeaveBulkTimePage() {
    const [companyIdFilter, setCompanyIdFilter] = useState(""); // Correct state variable used here
    const [companiesList, setCompaniesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [profiles, setProfiles] = useState([]); // This will store the profiles fetched from Firebase
    const [downloadURL, setDownloadURL] = useState("");
    const [comments, setComments] = useState("0");

    // Fetch companies list
    useEffect(() => {
        setLoading(true);
        getAuthUser().then((res) => {
            if (res.exists) {
                getCompanies().then((resData) => {
                    let list = [];
                    resData.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), id: doc.id };
                            list.push(appObj);
                        }
                    });

                    let companiesOptions = [
                        { key: "", text: "Choose a Company", value: "" },
                        ...list.map((company) => ({
                            key: company.id,
                            text: company.companyName,
                            value: company.id,
                        })),
                    ];

                    setCompaniesList(companiesOptions);
                    setLoading(false);
                });
            }
        });
    }, []);

    // Fetch users for the selected company from Firebase
    const fetchUsersForCompany = async (companyIdFilter) => {
        // Use companyIdFilter here
        setLoading(true); // Indicate loading when fetching users
        const usersRef = firestore.collection("users").where("companyId", "==", companyIdFilter);
        const usersSnapshot = await usersRef.get();
        const users = usersSnapshot.docs.map((doc) => ({
            id: doc.id,
            displayName: doc.data().displayName,
            employeeNr: doc.data().employeeNr,
        }));
        setProfiles(users); // Set the profiles (users) in state
        setLoading(false); // Stop loading once users are fetched
    };

    const fetchProjectList = async (userId) => {
        const projectRef = firestore.collection("projects").where("users", "array-contains", userId);
        const projectSnapshot = await projectRef.get();
        const projectList = projectSnapshot.docs.map((doc) => ({
            text: doc.data().projectPrefix || doc.data().projectName,
            value: `${doc.id}|${doc.data().projectName}`,
            hover: doc.data().projectPrefix || "",
        }));
        return projectList;
    };

    const fetchUserDataAndTime = async (userId, startDate, endDate) => {
        // Fetch user profile data
        const userRef = firestore.collection("users").doc(userId);
        const userSnapshot = await userRef.get();
        const userProfile = userSnapshot.data();

        // Fetch time tracking data
        const timeRef = firestore.collection("timetrack").where("userUid", "==", userId).where("createdAt", ">=", startDate).where("createdAt", "<=", endDate);
        const timeSnapshot = await timeRef.get();
        const timeList = timeSnapshot.docs.map((doc) => doc.data());

        return {
            profileClient: userProfile,
            timeList,
        };
    };

    const updateCompanySelection = useCallback((companyIdFilter) => {
        // Pass companyIdFilter here
        setCompanyIdFilter(companyIdFilter);
        if (companyIdFilter) {
            fetchUsersForCompany(companyIdFilter); // Fetch users when companyIdFilter is set
        } else {
            setProfiles([]); // Clear profiles if no company is selected
        }
    }, []);

    const filterData = (value) => {
        setStartDate(value);
    };

    const filterDataTo = (value) => {
        setEndDate(value);
    };

    const handleBulkDownload = async () => {
        setLoading(true);

        try {
            // Fetch project list, time data, and profiles for each user
            const userDataPromises = profiles.map(async (user) => {
                const projectList = await fetchProjectList(user.id);
                const { profileClient, timeList } = await fetchUserDataAndTime(user.id, startDate, endDate);
                return { profileClient, projectList, timeList };
            });

            const userData = await Promise.all(userDataPromises);

            // Send bulk request to generate PDFs
            const response = await axios.post(`${apiURL}/api/generate_bulk_pdf`, {
                startDate: startDate.toISOString().split("T")[0],
                endDate: endDate.toISOString().split("T")[0],
                users: userData,
                comments: comments,
            });

            setDownloadURL(apiURL + "/" + response.data);
        } catch (error) {
            console.error("Error generating bulk PDFs:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Bulk Timesheets</h1>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            {!downloadURL ? (
                                <Button color="blue" onClick={handleBulkDownload} disabled={profiles.length === 0 || loading}>
                                    Generate PDFs
                                </Button>
                            ) : (
                                <a href={downloadURL} target="_blank">
                                    Download PDFs
                                </a>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik initialValues={{ email: "" }} validate={(values) => {}} onSubmit={(values, { setSubmitting }) => {}}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <label>From:</label>
                                        <DatePicker selected={startDate} onChange={filterData} showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy-MM-dd" />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>To:</label>
                                        <DatePicker selected={endDate} onChange={filterDataTo} showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy-MM-dd" />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <MySelectInput
                                            name="comments"
                                            value={values.comments}
                                            options={[
                                                { key: "Yes", text: "Yes", value: "1" },
                                                { key: "No", text: "No", value: "0" },
                                            ]}
                                            placeholder="Hide Comments"
                                            onChange={(field, value) => setComments(value.value)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <MySelectInput
                                            name="companyId"
                                            value={values.companyId}
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            onChange={(field, value) => updateCompanySelection(value.value)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            {loading && <LoadingComponent />}
        </Container>
    );
}
