import React from "react";
import { Menu, Container, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { logout } from "../../user/userActions";
import { useSelector } from "react-redux";

export default function NavBar() {
    const { currentUserProfile } = useSelector((state) => state.profile);

    if (currentUserProfile === null) {
        window.location.replace("/");
    }

    async function handleSignOut() {
        logout();
        window.location.replace("/");
    }

    return (
        <div>
            {currentUserProfile && (
                <Menu inverted fixed="top" text>
                    <Container>
                        <Menu.Item>Hi, {currentUserProfile.displayName}!</Menu.Item>

                        <Menu.Menu position="right">
                            {/*<Menu.Item>Search</Menu.Item>*/}

                            {(currentUserProfile.isSuper || currentUserProfile.isAdmin) && (
                                <Menu.Item as={Link} to="/super/landing">
                                    DRG Admin
                                </Menu.Item>
                            )}

                            <Menu.Item as={Link} to="/dashboard/landing">
                                Dashboard
                            </Menu.Item>

                            <Menu.Item onClick={handleSignOut}>Logout</Menu.Item>

                            <Menu.Item as={Link} to="/settings">
                                <Image style={{ maxHeight: "80px", width: "auto" }} spaced="right" size="tiny" circular src={currentUserProfile.photoURL || "/assets/user.png"} />
                            </Menu.Item>
                        </Menu.Menu>
                    </Container>
                </Menu>
            )}
        </div>
    );
}
