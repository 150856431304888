import format from "date-fns/format";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import firebase from "../../app/config/firebase";

export async function leaveManualTrack(values) {
    const firestore = firebase.firestore();

    //We can use this to turn errors on and off easily for this function
    const debug = 1;

    //Get User Details
    let userQuery = await firestore.collect("users").doc(values.userId).get();
    let userData = userQuery.data();

    //Get company details
    let company = await firestore.collection("companies").doc(userData.companyId).get();
    company = company.data();

    if (debug === 1) {
        //
    }


    //Set up the year/month vars
    let yearNow = format(Date.now(), "yyyy");
    let monthNow = format(Date.now(), "M");
    let monthStart = parseInt(company.yearStart);

    //Default annualFrom to today
    let annualFrom = new Date();

    if (company.yearStart > 0) {
        monthStart = monthStart - 1;
    }

    let totLeave = parseFloat(company.leavePerMonth) * 12;

    if (values.financialYear !== yearNow) {
        //Set the date to start of financial year + 1
        let year = parseInt(values.financialYear) + 1;

        //Set the month to start first day of starting month
        annualFrom = new Date(year, monthStart, 1);

        //Remove 1 day to get the last day of the Financial year
        annualFrom = subDays(annualFrom, 2);
    } else {
        //Update the totLeave to be based on current month and not entire year
        totLeave = parseFloat(company.leavePerMonth) * parseInt(monthNow);
    }

    let leave = {};

    leave.annualFrom = annualFrom;
    leave.annualTo = annualFrom;
    leave.createDate = leave.createDate?leave.createDate:new Date(Date.now());
    leave.editDate = new Date(Date.now());
    leave.companyId = userData.companyId;
    leave.departmentId = userData.departmentId;

    if (userData.displayName) {
        leave.displayName = userData.displayName;
    }

    leave.userUid = userData.id;
    leave.status = values.status;
    leave.leaveType = values.leaveType;

    if (leave.leaveType === "Annual" && values.daysType === "Days Left") {
        leave.annualDays = parseFloat(totLeave) - parseFloat(values.annualDays);
    } else {
        leave.annualDays = values.annualDays;
    }

    leave.annualDays = parseFloat(leave.annualDays).toFixed(2);

    //Check if this is a Half Day
    if (values.halfDay && leave.annualDays < 1) {
        leave.halfDay = values.halfDay;
    } else {
        leave.halfDay = "no";
    }

    //Add Accumulative Leave
    if (values.useAccumulative) {
        leave.useAccumulative = values.useAccumulative;
    }

    try {
        await firestore.collection("leave").add(leave);

        return annualFrom;
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function leaveManualTrackV2(values, profile) {
    const firestore = firebase.firestore();

    try {
        //We can use this to turn errors on and off easily for this function
        const debug = 0;

        //Get User Details
        let userQuery = await firestore.collection("users").doc(values.userId).get();
        let userData = userQuery.data();

        //Get company details
        // let company = await firestore.collection("companies").doc(userData.companyId).get();
        // company = company.data();

        if (debug === 1) {
            //
        }

        //Default annualFrom to chosen date
        let takeOnDate = values.takeOnDate;
        let takeOnYear = format(takeOnDate, "yyyy");
        let takeOnMonth = format(takeOnDate, "M");

        if (values.annualDays && values.annualDays !== "") {
            let leave = {};

            leave.annualFrom = takeOnDate;
            leave.annualTo = takeOnDate;
            leave.createDate = leave.createDate?leave.createDate:new Date(Date.now());
            leave.editDate = new Date(Date.now());
            leave.companyId = userData.companyId;
            leave.departmentId = userData.departmentId;

            if (userData.displayName) {
                leave.displayName = userData.displayName;
            }

            leave.userUid = userData.id;
            leave.status = "Approved";
            leave.leaveType = "Annual";

            let currentUserTotal = 0;

            if (userData["leaveMonths"] && userData["leaveMonths"][takeOnYear] && userData["leaveMonths"][takeOnYear][takeOnMonth] && userData["leaveMonths"][takeOnYear][takeOnMonth]["left"]) {
                currentUserTotal = userData["leaveMonths"][takeOnYear][takeOnMonth]["left"];
            }

            if (debug === 1) {
                //
            }

            leave.annualDays = parseFloat(currentUserTotal) - parseFloat(values.annualDays);

            leave.annualDays = parseFloat(leave.annualDays).toFixed(2);
            leave.addedBy = profile.id;
            leave.addedByDisplayName = profile.displayName ? profile.displayName : profile.email;

            leave.addedTakeOn = true;
            leave.dontSendEmails = true;

            if (values.description) {
                leave.description = values.description;
            }

            if (debug === 1) {
                //
            }

            await firestore.collection("leave").add(leave);
        }

        if (values.sickDays && values.sickDays !== "") {
            let leaveSick = {
                annualFrom: takeOnDate,
                annualTo: takeOnDate,
                createDate: new Date(Date.now()),
                companyId: userData.companyId,
                departmentId: userData.departmentId,
                status: "Approved",
                leaveType: "Sick",
                addedBy: profile.id,
                addedByDisplayName: profile.displayName ? profile.displayName : profile.email,
                addedTakeOn: true,
                userUid: userData.id,
            };

            if (userData.displayName) {
                leaveSick.displayName = userData.displayName;
            }

            let curSickTotal = userData["leaveMonths"]["sick"]["sickTotal"];
            let curSickUsed = userData["leaveMonths"]["sick"]["sickUsed"];
            leaveSick.annualDays = curSickTotal - curSickUsed - values.sickDays;
            leaveSick.annualDays = parseFloat(leaveSick.annualDays).toFixed(2);
            leaveSick.dontSendEmails = true;

            if (debug === 1) {
                //
            }

            await firestore.collection("leave").add(leaveSick);
        }

        if (values.accumulativeDays && values.accumulativeDays !== "") {
            let leaveAccum = {};

            leaveAccum.annualFrom = takeOnDate;
            leaveAccum.annualTo = takeOnDate;
            leaveAccum.createDate = new Date(Date.now());
            leaveAccum.companyId = userData.companyId;
            leaveAccum.departmentId = userData.departmentId;

            if (userData.displayName) {
                leaveAccum.displayName = userData.displayName;
            }

            leaveAccum.userUid = userData.id;
            leaveAccum.status = "Approved";
            leaveAccum.leaveType = "Annual";
            leaveAccum.useAccumulative = "Yes";

            let currentUserTotal = 0;

            if (
                userData["leaveMonths"] &&
                userData["leaveMonths"][takeOnYear] &&
                userData["leaveMonths"][takeOnYear]["nonGrowing"] &&
                userData["leaveMonths"][takeOnYear]["nonGrowing"]["accumTotal"]
            ) {
                currentUserTotal = userData["leaveMonths"][takeOnYear]["nonGrowing"]["accumTotal"];
            }

            if (debug === 1) {
                //
            }

            leaveAccum.annualDays = parseFloat(currentUserTotal) - parseFloat(values.accumulativeDays);

            leaveAccum.annualDays = parseFloat(leaveAccum.annualDays).toFixed(2);
            leaveAccum.addedBy = profile.id;
            leaveAccum.addedByDisplayName = profile.displayName ? profile.displayName : profile.email;

            leaveAccum.addedTakeOn = true;
            leaveAccum.dontSendEmails = true;

            if (values.description) {
                leaveAccum.description = values.description;
            }

            if (debug === 1) {
                //
            }

            await firestore.collection("leave").add(leaveAccum);
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getUsers() {
    const firestore = firebase.firestore();
    return firestore.collection("users").where("isTerminated", "==", false).get();
}
export async function getCompanies() {
    const firestore = firebase.firestore();
    return firestore.collection("companies").get();
}
export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};
