import React, { useState, useEffect } from "react";
import { Container, Button, Segment, Header, Comment, Grid, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SupportReqReplyForm from "./SupportReqReplyForm";
import format from "date-fns/format";
import { closeticket, deleteticket, getAuthUser, getSupportRec, getSupportReplies } from "../supportActions";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function SupportViewPage(props) {
    // const [deleting, setDeleting] = useState(false);
    const [profile, setProfile] = useState([]);
    const [support_req, setSupport_req] = useState([]);
    let parts = window.location.href.split("/");
    let supportId = parts.pop() || parts.pop();
    const [support_replies, setSupport_replies] = useState([]);
    const [typecls, setTypecls] = useState("grey");

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getSupportRec(supportId).then(
            (res) => {
                if (res.exists) {
                    setSupport_req(res.data());

                    if (res.data().status === "Complete") {
                        setTypecls("green");
                    } else if (res.data().status === "New Request") {
                        setTypecls("red");
                    } else if (res.data().status === "Reply Sent") {
                        setTypecls("orange");
                    } else if (res.data().status === "Admin Reply Sent") {
                        setTypecls("yellow");
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );

        const unsubscribe = firestore
            .collection("support")
            .doc(supportId)
            .collection("support_replies")
            .orderBy("createDate", "desc")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setSupport_replies(list);
            });

        return () => unsubscribe();
    }, []);

    function closeticketData(support_req) {
        support_req.id = supportId;
        closeticket(support_req);
    }
    async function deleteticketData(support_req) {
        if (window.confirm("Are you sure?")) {
            support_req.id = supportId;
            await deleteticket(support_req);
            window.location.replace("/support/landing");
        }
    }
    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Column width={6}>
                        <Header as="h3">Support Request</Header>
                    </Grid.Column>

                    <Grid.Column width={10} textAlign="right">
                        <Button /*disabled={deleting}*/ as={Link} to="/support/landing" color="blue" size="small">
                            BACK TO SUPPORT REQUESTS
                        </Button>

                        {profile && (profile.isLineManager || profile.isAdmin || profile.isSuper) && (
                            <Button /*disabled={deleting}*/ color="green" onClick={() => closeticketData(support_req)}>
                                CLOSE TICKET
                            </Button>
                        )}

                        {profile && profile.isSuper && (
                            <Button /*loading={deleting} disabled={deleting}*/ color="red" onClick={() => deleteticketData(support_req)}>
                                DELETE TICKET
                            </Button>
                        )}
                    </Grid.Column>
                </Grid>

                <Divider />

                <Grid>
                    <Grid.Column width={10}>
                        <p>
                            <strong>Request Type:</strong>
                            <br />
                            {support_req.requestType}
                        </p>
                        <p>
                            <strong>Request:</strong>
                            <br />
                            {support_req.requestDescription}
                        </p>
                    </Grid.Column>

                    <Grid.Column width={6} textAlign="right">
                        <Label color={typecls}>{support_req.status}</Label>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Segment>
                <Header as="h3" dividing>
                    Replies
                </Header>

                {support_replies && support_replies.length === 0 && <p>No Replies</p>}

                {support_replies && support_replies.length > 0 && (
                    <Comment.Group>
                        {support_replies.map((sr) => (
                            <Comment key={sr.id}>
                                <Comment.Content>
                                    <Comment.Author as="a">{sr.displayName}</Comment.Author>
                                    <Comment.Metadata>{format(sr.createDate.toDate(), "d MMMM yyyy HH:mm:ss")}</Comment.Metadata>
                                    <Comment.Text>{sr.response}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                        ))}
                    </Comment.Group>
                )}
            </Segment>

            <Segment>
                <Header as="h3" dividing>
                    New Reply
                </Header>

                <SupportReqReplyForm support_req={support_req} />
            </Segment>
        </Container>
    );
}
