import React, { useState, useEffect } from "react";
import { Button, Container, Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";
import CompanyResourcesUploadForm from "./CompanyResourcesUploadForm";
import format from "date-fns/format";
import { deleteResource } from "../resourceActions";
import { Link } from "react-router-dom";
import { getAuthUser } from "../resourceActions";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function CompanyResourcesDashboardPage(props) {
    const [clientView, setClientView] = useState(false);
    const [profile, setProfile] = useState([]);
    const [resourcesUser, setResourcesUser] = useState([]);
    const [resourcesCompany, setResourcesCompany] = useState([]);
    const [resourcesAll, setResourcesAll] = useState([]);
    const [auth, setAuth] = useState([]);

    let id = "";
    if (props.match.params.id) {
        id = props.match.params.id;
    } else {
        id = firebase.auth().currentUser.uid;
    }

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setAuth(res.data());
                    if (id !== res.data().id && id !== "") {
                        setClientView(true);
                    } else {
                        setClientView(false);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );

        const unsubscribe1 = firestore
            .collection("resources")
            .where("viewedBy", "==", "All")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let resourcesAllData = [];

                Object.keys(list).forEach(function (key) {
                    resourcesAllData.push(list[key]);
                });

                setResourcesAll(resourcesAllData);
            });

        const unsubscribe2 = firestore
            .collection("resources")
            .where("userUid", "==", id)
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let resourcesUserData = [];
                Object.keys(list).forEach(function (key) {
                    resourcesUserData.push(list[key]);
                });

                setResourcesUser(resourcesUserData);
            });

        const unsubscribe3 = firestore
            .collection("users")
            .doc(id)
            .onSnapshot((res) => {
                if (res.exists) {
                    setProfile(res.data());

                    firestore
                        .collection("resources")
                        .where("companyId", "==", res.data().companyId)
                        .where("userUid", "==", "None")
                        .onSnapshot((snapshot) => {
                            let list = [];
                            snapshot.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    list.push(appObj);
                                }
                            });
                            let resourcesCompanyData = [];

                            Object.keys(list).forEach(function (key) {
                                resourcesCompanyData.push(list[key]);
                            });

                            setResourcesCompany(resourcesCompanyData);
                        });
                }
            });

        return () => {
            unsubscribe1();
            unsubscribe2();
            unsubscribe3();
        };
    }, []);

    return (
        <Container>
            {profile.isSuper === true || profile.isAdmin === true /*|| profile.isHR === true*/ ? <CompanyResourcesUploadForm /> : null}

            {clientView === true && (
                <Segment style={{ marginBottom: "20px" }}>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <h1>Staff Member: {profile.displayName ? profile.displayName : profile.email}</h1>
                            </Grid.Column>

                            <Grid.Column textAlign="right">
                                <Link className="ui button" as="a" to={"/home/" + profile.id}>
                                    Back to Dashboard
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}

            {resourcesUser && resourcesUser.length === 0 && resourcesCompany && resourcesCompany.length === 0 && resourcesAll && resourcesAll.length === 0 && <p>No Documents Uploaded</p>}

            {resourcesUser &&
                resourcesUser.length > 0 &&
                resourcesUser.map((resource) => (
                    <Segment secondary key={resource.id}>
                        <Grid>
                            <GridRow>
                                <GridColumn width="four" verticalAlign="middle">
                                    {resource.documentName}
                                </GridColumn>

                                <GridColumn width="twelve">
                                    <Segment>
                                        <Grid>
                                            <GridRow>
                                                <GridColumn width="eight" verticalAlign="middle">
                                                    {format(resource.date.toDate(), "d MMMM yyyy")}
                                                    <br />
                                                    Version {resource.version}
                                                </GridColumn>

                                                <GridColumn width="eight" textAlign="right">
                                                    <Button color="blue" as="a" target="_blank" href={resource.fileUrl}>
                                                        Download File
                                                    </Button>

                                                    {(auth.isSuper || auth.isAdmin) && (
                                                        <Button color="red" onClick={() => window.confirm("Are you sure you wish to delete this item?") && deleteResource(resource)}>
                                                            Delete File
                                                        </Button>
                                                    )}
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                ))}

            {resourcesCompany &&
                resourcesCompany.length > 0 &&
                resourcesCompany.map((resource) => (
                    <Segment secondary key={resource.id}>
                        <Grid>
                            <GridRow>
                                <GridColumn width="four" verticalAlign="middle">
                                    {resource.documentName}
                                </GridColumn>

                                <GridColumn width="twelve">
                                    <Segment>
                                        <Grid>
                                            <GridRow>
                                                <GridColumn width="eight" verticalAlign="middle">
                                                    {format(resource.date.toDate(), "d MMMM yyyy")}
                                                    <br />
                                                    Version {resource.version}
                                                </GridColumn>

                                                <GridColumn width="eight" textAlign="right">
                                                    <Button color="blue" as="a" target="_blank" href={resource.fileUrl}>
                                                        Download File
                                                    </Button>

                                                    {(auth.isSuper || auth.isAdmin) && (
                                                        <Button color="red" onClick={() => window.confirm("Are you sure you wish to delete this item?") && deleteResource(resource)}>
                                                            Delete File
                                                        </Button>
                                                    )}
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                ))}

            {resourcesAll &&
                resourcesAll.length > 0 &&
                resourcesAll.map((resource) => (
                    <Segment secondary key={resource.id}>
                        <Grid>
                            <GridRow>
                                <GridColumn width="four" verticalAlign="middle">
                                    {resource.documentName}
                                </GridColumn>

                                <GridColumn width="twelve">
                                    <Segment>
                                        <Grid>
                                            <GridRow>
                                                <GridColumn width="eight" verticalAlign="middle">
                                                    {format(resource.date.toDate(), "d MMMM yyyy")}
                                                    <br />
                                                    Version {resource.version}
                                                </GridColumn>

                                                <GridColumn width="eight" textAlign="right">
                                                    <Button color="blue" as="a" target="_blank" href={resource.fileUrl}>
                                                        Download File
                                                    </Button>

                                                    {auth.isSuper && (
                                                        <Button color="red" onClick={() => window.confirm("Are you sure you wish to delete this item?") && deleteResource(resource)}>
                                                            Delete File
                                                        </Button>
                                                    )}
                                                </GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </Segment>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                ))}
        </Container>
    );
}
