import React from "react";
import { Modal, Button, Image, Divider, Form, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import TimeAppForm from "../manager/ManagerTimeList/TimeAppForm";
import { Formik } from "formik";
import MyRadioButton from "../../app/common/form/MyRadioButton";
import MyTextArea from "../../app/common/form/MyTextArea";
import firebase from "../../app/config/firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { add, format } from "date-fns";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useEffect } from "react";

const firestore = firebase.firestore();

export default function RemoteWorkModal(props) {
    const dispatch = useDispatch();
    let day = props.days;

    const [fromDate, setFromDate] = useState("");
    const [errorsFrom, setErrorsFrom] = useState("");
    const [toDate, setToDate] = useState("");
    const { currentUserProfile } = useSelector((state) => state.profile);

    if (day.status === "Approved") {
        var header_cls = "green_header";
    } else {
        var header_cls = "red_header";
    }

    useEffect(() => {
        if (day && day.dateFrom) {
            setFromDate(day.dateFrom.toDate());
        }
        if (day && day.dateTo) {
            setToDate(day.dateTo.toDate());
        }
    }, []);

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>
                <Image style={{ zIndex: 2, maxHeight: "130px", maxWidth: "130px", width: "auto" }} avatar spaced="right" size="small" src={"/assets/user.png"} />

                <div className={header_cls}>
                    <div className="ui right aligned header" style={{ marginRight: 40, marginTop: 5, color: "white" }}>
                        <h3>{day.status}</h3>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                        <h2 className="ui center aligned header">EDIT REMOTE WORKING DAYS</h2>
                    </div>

                    <Divider />

                    <Formik
                        initialValues={{
                            dateFrom: day.dateFrom ? day.dateFrom.toDate() : "",
                            dateTo: day.dateTo ? day.dateTo.toDate() : "",
                            halfDay: day.halfDay ? day.halfDay : "",
                            reason: day.reason ? day.reason : "",
                        }}
                        enableReinitialize={true}
                        validate={(values) => {
                            const error = {};

                            if (!values.dateFrom) {
                                error.dateFrom = "required";
                                setErrorsFrom("Date is Required");
                            }

                            return error;
                        }}
                        onSubmit={async (values, { resetForm, setSubmitting }) => {
                            let totalDays = 0;
                            console.log(values);

                            if (values.dateTo === "") {
                                totalDays = "1";
                                delete values.dateTo;
                            } else {
                                let from = values.dateFrom;
                                let to = values.dateTo;
                                let compare_dates = function (date1, date2) {
                                    if (date1 > date2) return "<";
                                    else if (date1 < date2) return ">";
                                    else return "=";
                                };

                                let compared = compare_dates(new Date(format(new Date(from), "yyyy/MM/dd")), new Date(format(new Date(to), "yyyy/MM/dd")));

                                console.log(compared);

                                while (compared === ">" || compared === "=") {
                                    if (format(from, "EEE") !== "Sat" && format(from, "EEE") !== "Sun") {
                                        totalDays++;
                                    }

                                    from = add(new Date(format(new Date(from), "yyyy"), format(new Date(from), "MM"), format(new Date(from), "dd")), {
                                        years: 0,
                                        months: -1,
                                        days: 1,
                                    });
                                    compared = compare_dates(new Date(format(new Date(from), "yyyy/MM/dd")), new Date(format(new Date(to), "yyyy/MM/dd")));
                                }

                                console.log(totalDays);
                            }

                            values.totalDays = totalDays;
                            values.editUser = currentUserProfile;

                            setTimeout(async function () {
                                console.log(values);
                                setFromDate("");
                                setToDate("");
                                await firestore.collection("remoteWork").doc(day.id).update(values);
                                setSubmitting(false);
                                dispatch(closeModal());
                            }, 1000);
                        }}
                    >
                        {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid>
                                    <Grid.Row columns={4}>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Date From:</label>
                                                <DatePicker
                                                    name="dateFrom"
                                                    dateFormat="yyyy-MM-dd"
                                                    showYearDropdown={true}
                                                    showMonthDropdown={true}
                                                    dropdownMode="select"
                                                    value={values.fromDate}
                                                    selected={fromDate}
                                                    onChange={(value) => {
                                                        setFromDate(value);
                                                        setFieldValue("dateFrom", value);
                                                        setErrorsFrom("");
                                                    }}
                                                    disabledKeyboardNavigation
                                                    inline
                                                />
                                                {errorsFrom && errorsFrom !== "" && <p style={{ color: "red", border: "1px solid red", padding: "5px", borderRadius: "10px" }}>{errorsFrom}</p>}
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Date To:</label>
                                                <DatePicker
                                                    name="dateTo"
                                                    dateFormat="yyyy-MM-dd"
                                                    showYearDropdown={true}
                                                    showMonthDropdown={true}
                                                    dropdownMode="select"
                                                    value={values.dateTo}
                                                    selected={toDate}
                                                    onChange={(value) => {
                                                        setToDate(value);
                                                        setFieldValue("dateTo", value);
                                                    }}
                                                    disabledKeyboardNavigation
                                                    inline
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Half Day</label>
                                                <div style={{ display: "inline-flex" }}>
                                                    <MyRadioButton name="halfDay" type="radio" value="No" label="" labelcb="No" />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MyRadioButton name="halfDay" type="radio" value="Yes" label="" labelcb="Yes" />
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Reason for working from home</label>
                                                <MyTextInput name="reason" placeholder="Reason for working from home" />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" type="submit" color="blue">
                                        SUBMIT
                                    </Button>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
