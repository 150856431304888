import React, { useState, useEffect } from "react";
import { Container, Segment, Divider, Label, Button, Grid } from "semantic-ui-react";
import { leaveManualTrack, getUsers, getAuthUser } from "../adminActions";
import { generateLeaveStats } from "../../manager/managerActions";
import format from "date-fns/format";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyRadioButton from "../../../app/common/form/MyRadioButton";

export default function AdminLoadLeavePage(props) {
    const [assignUsers, setAssignUsers] = useState([]);
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getUsers().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let assignUsers = [
                    {
                        key: "",
                        text: "Choose a User",
                        value: "",
                    },
                ];

                Object.keys(list).forEach(function (key) {
                    assignUsers.push({
                        key: list[key].id,
                        text: list[key].displayName && list[key].displayName.length > 0 ? list[key].companyName + " - " + list[key].displayName : list[key].companyName + " - " + list[key].email,
                        value: list[key].id,
                    });
                });

                setAssignUsers(assignUsers);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    const leaveType = [
        { key: "Annual", text: "Annual Leave", value: "Annual" },
        { key: "Sick", text: "Sick Leave", value: "Sick" },
        { key: "Study", text: "Study Leave", value: "Study" },
        { key: "Unpaid", text: "Unpaid Leave", value: "Unpaid" },
        { key: "Maternity", text: "Maternity Leave", value: "Maternity" },
    ];

    const accumulativeChoices = [
        { key: "Yes", text: "Yes", value: "Yes" },
        { key: "No", text: "No", value: "No" },
    ];

    const leaveStatus = [
        { key: "Approved", text: "Approved", value: "Approved" },
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Denied", text: "Denied", value: "Denied" },
    ];

    const daysType = [
        { key: "Days Taken", text: "Days Taken", value: "Days Taken" },
        { key: "Days Left", text: "Days Left", value: "Days Left" },
    ];

    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={{ financialYear: "", annualDays: "" }}
                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        values.addedBy = profile.id;

                        let monthNow = format(Date.now(), "M");
                        let yearNow = format(Date.now(), "yyyy");

                        let annualFrom = await leaveManualTrack(values);

                        if (values.financialYear !== yearNow) {
                            let month = format(annualFrom, "MM");
                            let year = format(annualFrom, "yyyy");

                            //Check if this is a previous years leave, if it is update previous years leave too
                            if ((month < monthNow && year === yearNow) || year < yearNow) {
                                await generateLeaveStats(values.userId, "", annualFrom);
                            }
                        }

                        //Generate user stats
                        await generateLeaveStats(values.userId, "Leave has been added");

                        setSubmitting(false);
                    }}
                >
                    {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <label>Staff Member</label>
                                        <MySelectInput
                                            name="userId"
                                            options={assignUsers}
                                            value={values.userId}
                                            onChange={(field, value) => setFieldValue("userId", value.value)}
                                            placeholder="Choose a User"
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Type</label>
                                        <MySelectInput
                                            name="leaveType"
                                            options={leaveType}
                                            value={values.leaveType}
                                            onChange={(field, value) => setFieldValue("leaveType", value.value)}
                                            placeholder="Select your Leave Type"
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Status</label>
                                        <MySelectInput
                                            name="status"
                                            options={leaveStatus}
                                            value={values.status}
                                            onChange={(field, value) => setFieldValue("status", value.value)}
                                            placeholder="Select your Leave Status"
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <label>Financial Year</label>
                                        <MyTextInput name="financialYear" placeholder="e.g. 2019 or 2020" />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Type of Days</label>
                                        <MySelectInput
                                            name="daysType"
                                            options={daysType}
                                            value={values.daysType}
                                            onChange={(field, value) => setFieldValue("daysType", value.value)}
                                            placeholder="Select Type of Days"
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>No. of Days</label>
                                        <MyTextInput name="annualDays" />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <label>Use Accumulative Leave</label>
                                        <MySelectInput
                                            name="useAccumulative"
                                            options={accumulativeChoices}
                                            value={values.useAccumulative}
                                            onChange={(field, value) => setFieldValue("useAccumulative", value.value)}
                                            placeholder="Use Accumulative Leave"
                                        />
                                    </Grid.Column>

                                    <Grid.Column></Grid.Column>

                                    <Grid.Column>
                                        <div>
                                            <label>Half Day</label>
                                            <MyRadioButton name="halfDay" type="radio" value="morning" label="" labelcb="Yes - Morning" />
                                            <MyRadioButton name="halfDay" type="radio" value="afternoon" label="" labelcb="Yes - Afternoon" />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />

                            {error && (
                                <Label basic color="red">
                                    {error}
                                </Label>
                            )}
                            <Button disabled={isSubmitting || !dirty} loading={isSubmitting} fluid size="large" type="submit" color="blue">
                                ADD LEAVE
                            </Button>
                        </form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}
