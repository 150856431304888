import React from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button, Divider } from "semantic-ui-react";

import { closeModal } from "../../app/common/modals/modalReducer";
import SupportReqForm from "../support/SupportView/SupportReqForm";

export default function SupportForm(props) {
    const dispatch = useDispatch();

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>&nbsp;</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/queries.png" alt="Open a Support Ticket" />

                        <h2 className="ui center aligned header">Open a Support Ticket</h2>
                        <p>Submit your query and we will get back to you.</p>
                    </div>

                    <Divider />

                    <SupportReqForm />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
