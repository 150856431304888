import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Segment, Grid, Button, Label, Table } from "semantic-ui-react";
import { format } from "date-fns/esm";
import { deleteTimetrack } from "../../manager/managerActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Link } from "react-router-dom";
import TimeComments from "./TimeComments";
import { getAuthUser, getTimeTrackRow } from "../staffActions";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ReactExport from "react-data-export";
import Pdf from "react-to-pdf";
import firebase from "../../../app/config/firebase";
import axios from "axios";

import apiURL from "../../../app/config/apiURL";
import TimePDF from "./TimePDF";
const firestore = firebase.firestore();

export default function ViewTimePage(props) {
    let parts = window.location.href.split("/");
    let timeId = parts.pop() || parts.pop();
    const [timeCurrent, setTimeCurrent] = useState([]);
    const [profile, setProfile] = useState([]);
    const [profileClient, setProfileClient] = useState([]);
    const [projectList, setProjectList] = useState([]);
    // const [dailyProjects, setDailyProjects] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [exportData, setExportData] = useState([]);
    // const [totalWorked, setTotalWorked] = useState([]);
    // const [submittedBy, setSubmittedBy] = useState("");
    // const [approvedBy, setApprovedBy] = useState("");
    const dispatch = useDispatch();

    const [progress, setProgress] = useState(false);

    const [timeList, setTimeList] = useState([]);
    const [timeData, setTimeData] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("timetrack")
            .doc(timeId)
            .onSnapshot((result) => {
                let timeObj = { ...result.data(), id: result.id };
                setTimeCurrent(timeObj);
                // setSubmittedBy(timeObj.submitted_by);
                // setApprovedBy(timeObj.approved_by);
            });
        getAuthUser().then(
            async (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    const response = await axios.post(apiURL + "/api/view_time", { timeId: timeId });
                    setTimeData(response.data[0]);
                    setTimeList(response.data[1].sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0)));

                    let timeDetails = response.data[1].sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

                    let totalHoursWorked = 0;
                    let totalHoursRequired = 0;
                    let projectIdArr = [];
                    Object.keys(timeDetails).forEach(function (key) {
                        Object.keys(timeDetails[key]).forEach(function (index) {
                            if (index.split("_")[1] !== undefined) {
                                projectIdArr.push(index.split("_")[1]);
                            }
                        });
                    });
                    const uniqueIds = [];
                    let projNames = [
                        { value: "Date", style: { font: { bold: true } } },
                        { value: "Day", style: { font: { bold: true } } },
                        { value: "Total Required", style: { font: { bold: true } } },
                        { value: "Total Worked", style: { font: { bold: true } } },
                    ];
                    projectIdArr.map(async (ids) => {
                        if (uniqueIds.indexOf(ids) === -1) {
                            uniqueIds.push(ids);
                            //
                            await firestore
                                .collection("projects")
                                .where("id", "==", ids)
                                .onSnapshot((snapshot) => {
                                    projNames.push({ value: snapshot.docs[0].data().projectName, style: { font: { bold: true } }, width: { wch: 15 } });
                                });
                        }
                    });

                    const someTimeoutAction = () => {
                        return new Promise((resolve) => {
                            setTimeout(function () {
                                resolve(projNames);
                            }, 3000);
                        });
                    };

                    const projectListAarrNames = await someTimeoutAction();

                    projectListAarrNames.push({ value: "Comments", style: { font: { bold: true } } });

                    let totalsPerProject = [];
                    // let required = { date: "Proportion Required", day: "", totalRequired: "" };
                    let worked = { date: "Proportion Worked", day: "", totalRequired: "" };
                    let hoursInWorked = { date: "Total Hours Worked", day: "" };

                    Object.keys(timeDetails).forEach(function (key) {
                        totalHoursWorked += parseFloat(timeDetails[key].totalWorked);
                        totalHoursRequired += parseFloat(timeDetails[key].totalRequired);

                        Object.keys(timeDetails[key]).forEach(function (index) {
                            if (index.split("_")[1] !== undefined) {
                                if (totalsPerProject.some((e) => e.projectId === index.split("_")[1])) {
                                    Object.keys(totalsPerProject).forEach(function (key2) {
                                        if (totalsPerProject[key2].projectId == index.split("_")[1]) {
                                            let totalOne = isNaN(parseFloat(timeDetails[key][index])) ? 0 : parseFloat(timeDetails[key][index]);
                                            let totalTwo = isNaN(parseFloat(totalsPerProject[key2].total)) ? 0 : parseFloat(totalsPerProject[key2].total);
                                            let newTotal = totalOne + totalTwo;
                                            totalsPerProject[key2].total = newTotal;

                                            worked["totalRequired"] =
                                                totalHoursWorked !== 0
                                                    ? Math.round(((Math.round(totalHoursWorked * 10) / 10 / (Math.round(totalHoursRequired * 10) / 10)) * 100 * 10) / 10) + " %"
                                                    : "0 %";

                                            worked[index] = totalHoursRequired !== 0 ? Math.round(((newTotal / totalHoursRequired) * 100 * 10) / 10) + "%" : "0 %";
                                            hoursInWorked[index] = newTotal;
                                        }
                                    });
                                } else {
                                    totalsPerProject.push({
                                        projectId: index.split("_")[1],
                                        total: isNaN(parseFloat(timeDetails[key][index])) ? 0 : parseFloat(timeDetails[key][index]),
                                        required: res.data()["Required|" + index.split("_")[1]],
                                    });
                                }
                            }
                        });
                    });

                    hoursInWorked["totalWorked"] = totalHoursWorked;
                    hoursInWorked["totalRequired"] = totalHoursRequired;

                    let exportInfo = [
                        {
                            columns: [
                                { title: res.data().companyName, width: { wch: 30 }, style: { font: { sz: "34", bold: true } } },
                                { title: "" },
                                { title: "", width: { wch: 20 } },
                                { title: "", width: { wch: 20 } },
                            ],
                            data: [
                                [{ value: "" }, { value: "" }],
                                [
                                    { value: res.data().displayName, style: { font: { bold: true } } },
                                    {
                                        value:
                                            response && response.data && response.data[1] && response.data[1][0] && response.data[1][0].date
                                                ? new Date(response.data[1][0].date).toLocaleString("default", { month: "long" })
                                                : null,
                                        style: { font: { bold: true } },
                                    },
                                ],
                                [{ value: "" }, { value: "" }],
                                projectListAarrNames,
                            ],
                        },
                    ];

                    let exportTimeDetails = [];

                    Object.keys(timeDetails).forEach(function (key) {
                        let info = [{ value: timeDetails[key].date }, { value: timeDetails[key].day }, { value: timeDetails[key].totalRequired }, { value: timeDetails[key].totalWorked }];
                        Object.keys(timeDetails[key]).forEach(function (index) {
                            if (index.split("_")[0] === "project") {
                                info.push({ value: timeDetails[key][index] });
                            }
                        });
                        info.push({ value: timeDetails[key].comments });
                        let newArr = exportInfo[0].data;
                        newArr.push(info);
                        exportTimeDetails.push(info);
                    });

                    let newArrForData = exportInfo[0].data;
                    newArrForData.push([{ value: "" }]);
                    let HoursIntotal = [{ value: "Total Hours Worked", style: { font: { bold: true } } }, { value: "" }, { value: totalHoursRequired }, { value: totalHoursWorked }];
                    let required = [{ value: "Proportion Required", style: { font: { bold: true } } }, { value: "" }, { value: "" }, { value: "" }];
                    let propWorked = [{ value: "Proportion Worked", style: { font: { bold: true } } }, { value: "" }, { value: worked.totalRequired }, { value: "" }];

                    Object.keys(hoursInWorked).forEach(function (key) {
                        if (key.split("_")[0] === "project") {
                            HoursIntotal.push({ value: hoursInWorked[key] });
                            required.push({ value: res.data()["Required|" + key.split("_")[1]] + " %" });
                        }
                    });

                    Object.keys(worked).forEach(function (key) {
                        if (key.split("_")[0] === "project") {
                            propWorked.push({ value: worked[key] });
                        }
                    });

                    newArrForData.push(HoursIntotal);
                    newArrForData.push(required);
                    newArrForData.push(propWorked);
                    newArrForData.push([{ value: "" }]);
                    newArrForData.push([{ value: "Submitted by:", style: { font: { bold: true } } }, { value: response.data[0].submitted_by }]);

                    newArrForData.push([
                        { value: "First Submitted on:", style: { font: { bold: true } } },
                        { value: response.data[0].first_submitted_timestamp != null ? response.data[0].first_submitted_timestamp : "No data" },
                    ]);
                    if (response.data[0].submitted_by_time !== null) {
                        newArrForData.push([{ value: "Latest submission:", style: { font: { bold: true } } }, { value: response.data[0].submitted_by_time }]);
                    }

                    newArrForData.push([{ value: "" }]);
                    if (response.data[0].approved_by !== null) {
                        newArrForData.push([
                            { value: response.data[0].status === "Approved" ? "Approved by:" : response.data[0].status === "Denied" ? "Denied by:" : "Cancelled By:", style: { font: { bold: true } } },
                            { value: response.data[0].approved_by },
                        ]);
                    }
                    if (response.data[0].approved_by_time !== null) {
                        newArrForData.push([{ value: "Approved at:", style: { font: { bold: true } } }, { value: response.data[0].approved_by_time }]);
                    }

                    setExportData(exportInfo);

                    if (response.data[0].timeUserUid !== undefined && response.data[0].timeUserUid !== res.id) {
                        firestore
                            .collection("users")
                            .doc(response.data[0].timeUserUid)
                            .onSnapshot((result2) => {
                                let profileObj = { ...result2.data(), id: result2.id };
                                setProfileClient(profileObj);
                            });
                    }

                    firestore
                        .collection("projects")
                        // .where("users", "array-contains", response.data[0]["timeUserUid"])
                        .onSnapshot((snapshot) => {
                            let list = [];
                            snapshot.docs.forEach((doc) => {
                                if (doc.exists) {
                                    let obj = { ...doc.data(), id: doc.id };
                                    list.push(obj);
                                }
                            });
                            // let projectListData = [
                            //     {
                            //         key: "",
                            //         text: "None",
                            //         value: "",
                            //     },
                            // ];
                            // let cnt = -1;
                            // projectListData = list.map(function (project) {
                            //     cnt++;
                            //     return {
                            //         key: cnt,
                            //         text: project.projectPrefix ? project.projectPrefix : project.projectName,
                            //         value: project.id + "|" + project.projectName,
                            //         hover: project.projectPrefix ? project.projectName : "",
                            //     };
                            // });

                            let reqProjListData = response.data[1][0];
                            let reqProjList = [];

                            if (reqProjListData) {
                                Object.keys(reqProjListData).forEach(function (key) {
                                    if (key.split("_") && key.split("_")[0] === "project") {
                                        reqProjList.push(key.split("_")[1]);
                                    }
                                });
                            }

                            let projectListData = [];

                            Object.keys(list).forEach(async function (key) {
                                if ((list[key].users !== undefined && list[key].users.includes(response.data[0]["timeUserUid"])) || reqProjList.includes(list[key].id)) {
                                    if (
                                        Number.isNaN(new Date(list[key].projectFromDate).getMonth()) === true ||
                                        (parseFloat(response.data[2]) >= new Date(list[key].projectFromDate).getMonth() + 1 &&
                                            parseFloat(response.data[2]) <= new Date(list[key].projectToDate).getMonth() + 1)
                                    ) {
                                        projectListData.push({
                                            text: list[key].projectPrefix ? list[key].projectPrefix : list[key].projectName,
                                            value: list[key].id + "|" + list[key].projectName,
                                            hover: list[key].projectPrefix ? list[key].projectName : "",
                                        });
                                    }
                                }
                            });

                            // console.log("projectListData");
                            setProjectList(projectListData);
                        });
                }
            },
            (err) => {
                console.log(err);
            }
        );
        // getTimeTrackRow(timeId).then(
        //     (res) => {
        //         let list = [];
        //         res.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), id: doc.id };
        //                 list.push(appObj);
        //             }
        //         });

        //         let timetrackRow = list;

        //         let timetrack_loop = [];

        //         timetrackRow.forEach(function (doc) {
        //             timetrack_loop.push(doc);
        //         });

        //         let timetrack_initial = {};

        //         //First create a unique day array
        //         let days = [];
        //         let days2 = [];
        //         Object.keys(timetrack_loop).forEach(function (key) {
        //             if (!days[timetrack_loop[key].day]) {
        //                 days[timetrack_loop[key].day] = timetrack_loop[key].day;
        //                 days2.push(timetrack_loop[key].day);
        //             }
        //         });

        //         //Sort by date
        //         days2.sort();

        //         //Make a copy of the timetrack data
        //         let loop = timetrack_loop;
        //         let totalHoursWorked = 0;

        //         //Now loop over the days array and only add initial time tracking per day
        //         var arrayLength = days2.length;
        //         for (var i = 0; i < arrayLength; i++) {
        //             let tt = {};
        //             tt.projects = {};
        //             tt.hoursWorked = 0;

        //             // eslint-disable-next-line no-loop-func
        //             Object.keys(loop).forEach(function (key2) {
        //                 let dt1 = format(days2[i].toDate(), "yyyy-MM-dd");
        //                 let dt2 = format(loop[key2].day.toDate(), "yyyy-MM-dd");

        //                 if (dt1 === dt2) {
        //                     let proj_name = loop[key2].projectId + "|" + loop[key2].projectName;

        //                     tt.projects[proj_name] = loop[key2].timeWorked;
        //                     tt.hoursWorked += parseInt(loop[key2].timeWorked);
        //                     totalHoursWorked += parseInt(loop[key2].timeWorked);

        //                     if (loop[key2].comments) {
        //                         tt.comments = loop[key2].comments;
        //                     }

        //                     tt.day = format(loop[key2].day.toDate(), "dd MMMM yyyy");

        //                     tt.hoursRequired = loop[key2].hoursRequired;
        //                 }
        //             });

        //             timetrack_initial[i] = tt;
        //         }

        //         // setTotalWorked(totalHoursWorked);

        //         let timetrack_initial2 = [];
        //         Object.assign(timetrack_initial2, timetrack_initial);
        //         // setDailyProjects(timetrack_initial2);
        //         let exportInfo = [
        //             timetrack_initial2,
        //             { day: "", hoursRequired: "", hoursWorked: "", projects: "" },
        //             { day: "Total Hours Worked", hoursRequired: "", hoursWorked: totalHoursWorked, projects: "" },
        //         ];
        //         setExportData(exportInfo.flat());
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );

        return () => unsubscribe();
    }, []);

    async function removeTimetrack(timeCurrent, profileClient) {
        setDeleting(true);

        await axios.post(apiURL + "/api/time_delete", { timeId: timeId, profileClient: profileClient });

        setDeleting(false);

        if (profileClient.hasOwnProperty("id")) {
            window.location.replace("/clients/time/" + profileClient.id);
        } else {
            window.location.replace("/clients/time/" + profile.id);
        }
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const ref = React.createRef();
    const options = {
        orientation: "landscape",
    };

    async function changeToOnProgress(profileClient) {
        setProgress(true);
        await axios.post(apiURL + "/api/status_update", { timeId: timeId });

        setProgress(false);

        if (profileClient.hasOwnProperty("id")) {
            window.location.replace("/clients/time/" + profileClient.id);
        } else {
            window.location.replace("/clients/time/" + profile.id);
        }
    }

    async function modifyPdf(timeList, projectList, timeData, profileClient, profile, type) {
        const response = await axios.post(apiURL + "/api/generate_pdf", {
            timeList: timeList,
            projectList: projectList,
            timeData: timeData,
            profileClient: profileClient,
            profile: profile,
            type: type,
        });
        window.open(apiURL + response.data, "_blank");
    }

    return (
        <>
            {(profile.id !== profileClient.id && (profile.isAdmin !== false || profile.isLineManager !== false)) || !profileClient.hasOwnProperty("id") ? (
                <Container>
                    {profileClient && profileClient.hasOwnProperty("email") ? (
                        <Segment textAlign="center" style={{ marginBottom: "20px" }}>
                            <h1>Staff Member: {profileClient.displayName ? profileClient.displayName : profileClient.email}</h1>
                        </Segment>
                    ) : null}

                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Segment>
                                    {timeData.status === "Denied" && (
                                        <Label color="red" style={{ fontSize: 15 }}>
                                            {timeData.status.toUpperCase()}
                                        </Label>
                                    )}
                                    {timeData.status === "Approved" && (
                                        <Label color="green" style={{ fontSize: 15 }}>
                                            {timeData.status.toUpperCase()}
                                        </Label>
                                    )}
                                    {(timeData.status === "Pending Change" || timeData.status === "Pending") && (
                                        <Label color="orange" style={{ fontSize: 15 }}>
                                            {timeData.status.toUpperCase()}
                                        </Label>
                                    )}

                                    {profile && profile.isLineManager && (timeData.status === "Pending Change" || timeData.status === "Pending") && (
                                        <Button onClick={() => dispatch(openModal({ modalType: "TimeAppModal", modalProps: { timeData: timeData, profileClient: profileClient } }))} color="blue">
                                            APPROVE/DENY
                                        </Button>
                                    )}

                                    {profile.isSuper && (
                                        <Button
                                            color="red"
                                            loading={deleting}
                                            disabled={deleting}
                                            onClick={() => window.confirm("Are you sure you wish to delete this item?") && removeTimetrack(timeData, profileClient)}
                                        >
                                            DELETE
                                        </Button>
                                    )}
                                    {profile.isAdmin ? (
                                        <>
                                            {timeData.status === "Pending" || timeData.status === "Approved" ? (
                                                profileClient && profileClient.length > 0 ? (
                                                    <Button size="medium" color="yellow" content="EDIT" as={Link} to={"/timetracking/edit/" + profileClient.id + "/" + timeData.id} />
                                                ) : (
                                                    <Button size="medium" color="yellow" content="EDIT" as={Link} to={"/timetracking/edit/" + profile.id + "/" + timeData.id} />
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ) : null}

                                    {profile && profile.isLineManager && (
                                        <Button size="medium" color="yellow" content="BACK TO IN PROGRESS" onClick={() => changeToOnProgress(profileClient)} loading={progress} style={{ margin: 5 }} />
                                    )}

                                    {timeList && timeList.length > 0 && (profile.isLineManager === true || profile.isAdmin === true || profile.isSuper === true) && (
                                        <>
                                            {/* <ExcelFile element={<Button color="blue">DOWNLOAD EXCEL</Button>}>
                                                <ExcelSheet data={exportData} name="Time Track">
                                                    <ExcelColumn label="Date" value="date" />
                                                    <ExcelColumn label="Date" value="day" />
                                                    <ExcelColumn label="Total Required" value="totalRequired" />
                                                    <ExcelColumn label="Hours Worked" value="totalWorked" />

                                                    {projectList &&
                                                        projectList.map((proj) => (
                                                            <ExcelColumn
                                                                label={proj.value.split("|").slice(-1).pop()}
                                                                value={(col) => (col["project_" + proj.value.split("|")[0]] ? col["project_" + proj.value.split("|")[0]] : "")}
                                                            />
                                                        ))}
                                                    <ExcelColumn label="Comments" value="comments" />
                                                </ExcelSheet>
                                            </ExcelFile> */}

                                            <ExcelFile element={<Button color="blue">DOWNLOAD EXCEL</Button>}>
                                                <ExcelSheet dataSet={exportData} name="Organization" />
                                            </ExcelFile>

                                            {/* {profile.companyId == "UGkck8ATK3LJfjGvfwYV" || profile.companyId == "tSCqYFwyczd0DXOUi18F" ? ( */}
                                            <Button color="blue" onClick={() => modifyPdf(timeList, projectList, timeData, profileClient, profile, 0)}>
                                                GENERATE PDF
                                            </Button>

                                            <Button color="blue" onClick={() => modifyPdf(timeList, projectList, timeData, profileClient, profile, 1)}>
                                                PDF NO COMMENTS
                                            </Button>
                                            {/* ) : (
                                                ""
                                            )} */}
                                        </>
                                    )}
                                </Segment>
                            </Grid.Column>

                            <Grid.Column>
                                <Segment textAlign="right">
                                    {profile && profile.isLineManager && (
                                        <Button color="grey" as={Link} to={`/manager`}>
                                            BACK TO OUTSTANDING
                                        </Button>
                                    )}

                                    {profileClient && profileClient.hasOwnProperty("id") ? (
                                        <Button color="grey" as={Link} to={`/clients/time/${profileClient.id}`}>
                                            TIMETRACKING REQUESTS
                                        </Button>
                                    ) : (
                                        <Button color="grey" as={Link} to={`/clients/time/${profile.id}`}>
                                            TIMETRACKING REQUESTS
                                        </Button>
                                    )}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {timeList && timeList.length > 0 ? (
                        <>
                            <div ref={ref}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Table striped celled structured>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell rowSpan={2}>Date</Table.HeaderCell>
                                                        <Table.HeaderCell rowSpan={2}>Day</Table.HeaderCell>
                                                        <Table.HeaderCell rowSpan={2}>Total Required</Table.HeaderCell>
                                                        <Table.HeaderCell rowSpan={2}>Total Worked</Table.HeaderCell>

                                                        <Table.HeaderCell colSpan={projectList.length} textAlign="center">
                                                            Projects
                                                        </Table.HeaderCell>

                                                        <Table.HeaderCell rowSpan={2}>Comments</Table.HeaderCell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        {projectList &&
                                                            projectList.map((proj) => (
                                                                <Table.HeaderCell textAlign="center" key={proj.value} style={{ width: "130px" }} data-tip={proj.hover}>
                                                                    {proj.hover !== "" ? (
                                                                        <Popup trigger={<h5>{proj.text}</h5>} position={"top"} on={["hover", "focus"]} arrow="center top">
                                                                            <p style={{ textAlign: "center" }}> {proj.hover} </p>
                                                                        </Popup>
                                                                    ) : (
                                                                        <h5>{proj.text}</h5>
                                                                    )}
                                                                </Table.HeaderCell>
                                                            ))}
                                                    </Table.Row>
                                                </Table.Header>

                                                {timeList && timeList.length > 0 && (
                                                    <Table.Body>
                                                        {timeList.map((row, index) => (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>{row.date}</Table.Cell>

                                                                <Table.Cell>{row.day}</Table.Cell>

                                                                <Table.Cell>{row.totalRequired}</Table.Cell>

                                                                <Table.Cell>{row.totalWorked}</Table.Cell>

                                                                {projectList &&
                                                                    projectList.map((proj) => (
                                                                        <>
                                                                            {row["project_" + proj.value.split("|")[0]] ? (
                                                                                <Table.Cell>{row["project_" + proj.value.split("|")[0]]}</Table.Cell>
                                                                            ) : (
                                                                                <Table.Cell></Table.Cell>
                                                                            )}
                                                                        </>
                                                                    ))}

                                                                <Table.Cell>{row.comments}</Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                        <Table.Row key="lastRow">
                                                            <Table.Cell>
                                                                <h4>
                                                                    <strong>Total Hours Worked</strong>
                                                                </h4>
                                                            </Table.Cell>

                                                            <Table.Cell></Table.Cell>
                                                            <Table.Cell>
                                                                <strong>
                                                                    {Math.round(
                                                                        timeList
                                                                            .map((item) => (item.totalRequired !== "" && item.totalRequired !== null ? parseFloat(item.totalRequired) : 0))
                                                                            .reduce((prev, next) => prev + next) * 10
                                                                    ) / 10}
                                                                </strong>
                                                            </Table.Cell>

                                                            <Table.Cell>
                                                                <strong>
                                                                    {Math.round(
                                                                        timeList
                                                                            .map((item) => (item.totalWorked !== "" && item.totalWorked !== null ? parseFloat(item.totalWorked) : 0))
                                                                            .reduce((prev, next) => prev + next) * 10
                                                                    ) / 10}
                                                                </strong>
                                                            </Table.Cell>

                                                            {projectList && projectList.map((proj) => <Table.Cell></Table.Cell>)}

                                                            <Table.Cell></Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                )}
                                            </Table>
                                            {timeData["first_submitted_timestamp"] !== "" && timeData["first_submitted_timestamp"] !== null ? (
                                                <h4>First submitted on {timeData["first_submitted_timestamp"]}</h4>
                                            ) : null}
                                            {timeData["submitted_by"] !== "" && timeData["submitted_by"] !== null ? (
                                                <h4>
                                                    Latest submitted by: {timeData["submitted_by"]} on {timeData["submitted_by_time"]} {timeData["submitted_by_timestamp"]}
                                                </h4>
                                            ) : null}
                                            {timeData.status === "Approved" && timeData["approved_by"] !== "" && timeData["approved_by"] !== null ? (
                                                <h4>
                                                    Approved by: {timeData["approved_by"]} on {timeData["approved_by_time"]} at {timeData["approved_by_timestamp"]}
                                                </h4>
                                            ) : null}
                                            {timeData.status === "Denied" && timeData["approved_by"] !== "" && timeData["approved_by"] !== null ? (
                                                <h4>
                                                    Denied by: {timeData["approved_by"]} on {timeData["approved_by_time"]} at {timeData["approved_by_timestamp"]}
                                                </h4>
                                            ) : null}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                            <Segment>
                                <TimeComments time={timeData} profile={profile} />
                            </Segment>
                        </>
                    ) : (
                        <div className="ui segment" style={{ height: 100 }}>
                            <div className="ui active inverted dimmer">
                                <div className="ui text loader">Loading</div>
                            </div>
                            <p></p>
                        </div>
                    )}
                </Container>
            ) : (
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            )}
        </>
    );
}
