import React, { useEffect, useState, useCallback } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserList, getAuthUser, getCompanies, getLeaveAction, getLeaveInfo } from "../reportsAction";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { Formik } from "formik";
import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
import { date } from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { app } from "firebase";

const firestore = firebase.firestore();

export default function ReportLeaveDuePage(props) {
    const [users, setUsers] = useState([]);
    const [companyIdFilter, setCompanyIdFilter] = useState("0");
    const [profile, setProfile] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [profile_company, setProfile_company] = useState("");
    const [loading, setLoading] = useState(false);
    let leave = [];

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

    const { currentUserProfile } = useSelector((state) => state.profile);

    const [yearNow, setYearNow] = useState([]);
    const [monthNow, setMonthNow] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    setProfile_company(res.data().companyId);

                    getCompanies().then(
                        (resData) => {
                            let list = [];
                            resData.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    if (appObj.hasLeave == true) {
                                        list.push(appObj);
                                    }
                                }
                            });

                            let companiesList = [
                                {
                                    key: "",
                                    text: "Choose a Company",
                                    value: "",
                                },
                            ];

                            Object.keys(list).forEach(function (key) {
                                if (
                                    res.data().isSuper === true ||
                                    (res.data().isAdmin === true && res.data().companyId == list[key].id) ||
                                    (res.data().isHR === true && res.data().companyId == list[key].id) ||
                                    (res.data().isLineManager === true && res.data().companyId == list[key].id)
                                ) {
                                    companiesList.push({
                                        key: list[key].id,
                                        text: list[key].companyName,
                                        value: list[key].id,
                                    });
                                }
                            });

                            setCompaniesList(companiesList);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );

        getLeaveInfo(companyIdFilter).then(async (doc) => {
            if (doc.exists == true) {
                setLoading(true);
                let companyObj = { ...doc.data(), id: doc.id };

                if (companyObj.leaveMethodType !== undefined && companyObj.leaveMethodType == "New Method") {
                    let AllowedUsers = [];
                    await firestore
                        .collection("users")
                        .where("companyId", "==", companyIdFilter)
                        .get()
                        .then(async (res) => {
                            let adminDepartments = currentUserProfile.departments;
                            res.docs.forEach((user) => {
                                if (adminDepartments.includes(user.data().departmentId)) {
                                    AllowedUsers.push(user.id);
                                }
                            });
                        });

                    // console.log(AllowedUsers);

                    let leaveModule = [];
                    await firestore
                        .collection("leaveStatus")
                        // .orderBy("toDate", "asc")
                        .where("periodType", "==", "Leave")
                        .where("companyId", "==", companyObj.id)
                        // .where("toDate", ">=", startDate)
                        .get()
                        // eslint-disable-next-line no-loop-func
                        .then(async (res) => {
                            res.docs.forEach(async (data) => {
                                const docData = data.data();

                                const docFromDate = docData.fromDate.toDate(); // Ensure to convert Firestore timestamp to JavaScript Date
                                const docToDate = docData.toDate.toDate(); // Ensure to convert Firestore timestamp to JavaScript Date

                                // Check if the document's fromDate and toDate range contains the selected date range
                                if (docFromDate <= startDate && docToDate >= endDate) {
                                    if (
                                        (currentUserProfile.isLineManager === true && AllowedUsers.includes(data.data().userUid)) ||
                                        currentUserProfile.isAdmin === true ||
                                        currentUserProfile.isHR === true ||
                                        currentUserProfile.isSuper === true
                                    ) {
                                        let list = {};

                                        await firestore
                                            .collection("leaveStatus")
                                            .doc(data.id)
                                            .collection("Months")
                                            .orderBy("datePeriod", "asc")
                                            .get()
                                            .then((result) => {
                                                result.docs.forEach((month) => {
                                                    list["leaveTotal"] = month.data().annualTotal;

                                                    var d1 = new Date(startDate);
                                                    d1.setMonth(d1.getMonth() - 1);

                                                    if (format(d1, "yyy-MM-dd") == format(month.data().datePeriod.toDate(), "yyy-MM-dd")) {
                                                        list["prevTotal"] = month.data().annualUsed;
                                                    }

                                                    if (format(startDate, "yyy-MM-dd") == format(month.data().datePeriod.toDate(), "yyyy-MM-dd")) {
                                                        list["annualUsedYtd"] = parseFloat(month.data().annualUsed);
                                                        list["annualBalanceYtd"] = parseFloat(month.data().annualLeft);
                                                        list["annualTotalYtd"] = parseFloat(month.data().annualTotal);
                                                        // console.log(list["prevTotal"]);
                                                        // console.log(month.data().annualUsed);
                                                        // console.log(parseFloat(month.data().annualUsed) - parseFloat(list["prevTotal"]));

                                                        list["currUsed"] = parseFloat(month.data().annualUsed ? month.data().annualUsed : 0) - parseFloat(list["prevTotal"] ? list["prevTotal"] : 0);
                                                        // console.log(list["currUsed"]);
                                                        // console.log(" - ! - ");
                                                    }
                                                    list["annualLeft"] = month.data().annualLeft;

                                                    list["accumUsed"] = month.data().accumUsed;
                                                    list["accumLeft"] = month.data().accumTotal - month.data().accumUsed;
                                                    list["accumTotal"] = month.data().accumTotal;
                                                });
                                            });

                                        list["familyUsed"] = data.data().familyUsed;
                                        list["familyLeft"] = data.data().familyLeft;
                                        list["familyTotal"] = data.data().familyTotal;

                                        list["leaveTotal"] = parseFloat(list["leaveTotal"] ? list["leaveTotal"] : 0).toFixed(2);
                                        list["annualUsedYtd"] = parseFloat(list["annualUsedYtd"] ? list["annualUsedYtd"] : 0).toFixed(2);
                                        list["annualBalanceYtd"] = parseFloat(list["annualBalanceYtd"] ? list["annualBalanceYtd"] : 0).toFixed(2);
                                        list["annualTotalYtd"] = parseFloat(list["annualTotalYtd"] ? list["annualTotalYtd"] : 0).toFixed(2);
                                        list["annualLeft"] = parseFloat(list["annualLeft"] ? list["annualLeft"] : 0).toFixed(2);

                                        list["accumUsed"] = parseFloat(list["accumUsed"] ? list["accumUsed"] : 0).toFixed(2);
                                        list["accumLeft"] = parseFloat(list["accumLeft"] ? list["accumLeft"] : 0).toFixed(2);
                                        list["accumTotal"] = parseFloat(list["accumTotal"] ? list["accumTotal"] : 0).toFixed(2);
                                        list["familyUsed"] = parseFloat(list["familyUsed"] ? list["familyUsed"] : 0).toFixed(2);
                                        list["familyLeft"] = parseFloat(list["familyLeft"] ? list["familyLeft"] : 0).toFixed(2);
                                        list["familyTotal"] = parseFloat(list["familyTotal"] ? list["familyTotal"] : 0).toFixed(2);
                                        list["currUsed"] = parseFloat(list["currUsed"] ? list["currUsed"] : 0).toFixed(2);

                                        // await firestore
                                        //     .collection("leaveStatus")
                                        //     .where("periodType", "==", "Sick")
                                        //     .where("userUid", "==", data.data().userUid)
                                        //     .where("toDate", ">=", new Date())
                                        //     .get()
                                        //     // eslint-disable-next-line no-loop-func
                                        //     .then(async (res) => {
                                        //         if (res.empty == false) {
                                        //             list["sickUsed"] = res.docs[0].data().sickUsed;
                                        //             list["sickUsedYtd"] = res.docs[0].data().sickUsed;
                                        //             list["sickLeft"] = res.docs[0].data().sickLeft;
                                        //             list["sickTotal"] = res.docs[0].data().sickTotal;
                                        //         }
                                        //     });

                                        // list["sickUsed"] = parseFloat(list["sickUsed"]).toFixed(2);
                                        // list["sickUsedYtd"] = parseFloat(list["sickUsedYtd"]).toFixed(2);
                                        // list["sickLeft"] = parseFloat(list["sickLeft"]).toFixed(2);
                                        // list["sickTotal"] = parseFloat(list["sickTotal"]).toFixed(2);

                                        await firestore
                                            .collection("users")
                                            .doc(data.data().userUid)
                                            .get()
                                            .then(
                                                async (res) => {
                                                    if (res.exists) {
                                                        list["id"] = res.id;
                                                        list["displayName"] = res.data().displayName;
                                                        list["employee_full_first_names"] = res.data().employee_full_first_names;
                                                        list["employee_surname"] = res.data().employee_surname;
                                                        list["employeeNr"] = res.data().employeeNr;
                                                        list["email"] = res.data().email;
                                                        list["departmentName"] = res.data().departmentName ? res.data().departmentName : "";

                                                        leaveModule.push(list);

                                                        // if (res.data().departmentId) {
                                                        //     await firestore
                                                        //         .collection("departments")
                                                        //         .doc(res.data().departmentId)
                                                        //         .get()
                                                        //         .then((result) => {
                                                        //             if (result.exists) {
                                                        //                 list["departmentName"] = result.data().departmentName;
                                                        //             }
                                                        //         });
                                                        // } else {
                                                        //     list["departmentName"] = "";
                                                        // }
                                                    }
                                                },
                                                (err) => {
                                                    console.log(err);
                                                }
                                            );

                                        //For some reason we have a bunch of blank data so this just skips those records
                                        // if (list["email"]) {
                                        //     leaveModule.push(list);
                                        // }
                                    }
                                }
                            });
                        });

                    const someTimeoutAction = () => {
                        return new Promise((resolve) => {
                            setTimeout(function () {
                                resolve(leaveModule);
                            }, 2000);
                        });
                    };
                    const listLeave = await someTimeoutAction();
                    setUsers(listLeave);

                    setLoading(false);
                } else {
                    let leaveModule = [];
                    await firestore
                        .collection("users")
                        .where("companyId", "==", companyIdFilter)
                        .get()
                        .then(
                            async (res) => {
                                res.docs.forEach((user) => {
                                    let adminDepartments = currentUserProfile.departments;
                                    if (
                                        (currentUserProfile.isLineManager === true && adminDepartments.includes(user.data().departmentId)) ||
                                        currentUserProfile.isSuper === true ||
                                        currentUserProfile.isAdmin === true ||
                                        currentUserProfile.isHR === true
                                    ) {
                                        let list = {};

                                        list["displayName"] = user.data().displayName;
                                        list["employee_full_first_names"] = user.data().employee_full_first_names;
                                        list["employee_surname"] = user.data().employee_surname;
                                        list["employeeNr"] = user.data().employeeNr;
                                        list["email"] = user.data().email;

                                        let leaveTotal = 0;
                                        list["annualUsed"] = 0;
                                        list["annualUsedYtd"] = 0;
                                        list["annualLeft"] = 0;
                                        list["annualTotal"] = 0;

                                        list["accumUsed"] = 0;
                                        list["accumLeft"] = 0;
                                        list["accumTotal"] = 0;
                                        list["leaveTotal"] = 0;

                                        if (user.data().companyLeavePerMonth !== undefined) {
                                            list["leaveTotal"] = (parseFloat(user.data().companyLeavePerMonth) * 12).toFixed(2);
                                        }

                                        let year = "";

                                        if (yearNow.length == 0) {
                                            year = format(new Date(), "yyyy");
                                        } else {
                                            year = yearNow;
                                        }

                                        let month = "";

                                        if (monthNow.length == 0) {
                                            month = parseInt(format(new Date(), "MM"));
                                        } else {
                                            month = parseInt(monthNow);
                                        }

                                        if (user.data().leaveMonths && user.data().leaveMonths[year] && user.data().leaveMonths[year][month]) {
                                            list["annualUsed"] = parseFloat(user.data().leaveMonths[year][month]["used"]);
                                            list["annualLeft"] = parseFloat(user.data().leaveMonths[year][month]["left"]);
                                            if (list["leavePerMonth"] && user.data().leavePerMonth > 0) {
                                                list["annualTotal"] = parseFloat(user.data().leavePerMonth * 12);
                                            } else {
                                                list["annualTotal"] = parseFloat(leaveTotal);
                                            }

                                            list["annualUsedYtd"] = list["annualTotal"] - list["annualLeft"];
                                        }

                                        list["annualUsed"] = list["annualUsed"].toFixed(2);
                                        list["annualUsedYtd"] = list["annualUsedYtd"].toFixed(2);
                                        list["annualLeft"] = list["annualLeft"].toFixed(2);
                                        list["annualTotal"] = list["annualTotal"].toFixed(2);

                                        //Sick Leave
                                        list["sickUsed"] = 0;
                                        list["sickUsedCur"] = 0;
                                        list["sickUsedYtd"] = 0;
                                        list["sickLeft"] = 0;
                                        list["sickTotal"] = 0;

                                        if (user.data().leaveMonths && user.data().leaveMonths["sick"]) {
                                            //Loop over leave and get current months leave
                                            if (leave && leave.length > 0) {
                                                for (var l in leave) {
                                                    if (leave[l].userUid === user.id) {
                                                        let this_y = format(leave[l].createDate.toDate(), "yyyy");
                                                        let this_m = format(leave[l].createDate.toDate(), "M");

                                                        if (this_y === year && this_m === month) {
                                                            list["sickUsedCur"] += parseFloat(leave[l].annualDays);
                                                        }
                                                    }
                                                }
                                            }

                                            list["sickUsed"] = parseFloat(user.data().leaveMonths["sick"]["sickUsed"]);
                                            list["sickTotal"] = parseFloat(user.data().leaveMonths["sick"]["sickTotal"]);

                                            list["sickLeft"] = list["sickTotal"] - list["sickUsed"];
                                            list["sickUsedYtd"] = list["sickTotal"] - list["sickLeft"];
                                        }

                                        list["sickUsed"] = list["sickUsed"].toFixed(2);
                                        list["sickUsedCur"] = list["sickUsedCur"].toFixed(2);
                                        list["sickLeft"] = list["sickLeft"].toFixed(2);
                                        list["sickTotal"] = list["sickTotal"].toFixed(2);
                                        list["sickUsedYtd"] = list["sickUsedYtd"].toFixed(2);

                                        //Family Leave
                                        list["familyUsed"] = 0;
                                        list["familyLeft"] = 0;
                                        list["familyTotal"] = 0;

                                        if (user.data().leaveMonths && user.data().leaveMonths[year] && user.data().leaveMonths[year]["nonGrowing"]) {
                                            list["familyUsed"] = parseFloat(user.data().leaveMonths[year]["nonGrowing"]["familyUsed"]);
                                            list["familyTotal"] = parseFloat(user.data().leaveMonths[year]["nonGrowing"]["familyTotal"]);

                                            list["familyLeft"] = list["familyTotal"] - list["familyUsed"];
                                        }

                                        list["familyUsed"] = list["familyUsed"].toFixed(2);
                                        list["familyLeft"] = list["familyLeft"].toFixed(2);
                                        list["familyTotal"] = list["familyTotal"].toFixed(2);

                                        //Accumulative Leave
                                        list["accumUsed"] = 0;
                                        list["accumLeft"] = 0;
                                        list["accumTotal"] = 0;

                                        if (user.data().leaveMonths && user.data().leaveMonths[year] && user.data().leaveMonths[year]["nonGrowing"]) {
                                            list["accumUsed"] = parseFloat(user.data().leaveMonths[year]["nonGrowing"]["accumUsed"]);
                                            list["accumTotal"] = parseFloat(user.data().leaveMonths[year]["nonGrowing"]["accumTotal"]);

                                            list["accumLeft"] = list["accumTotal"] - list["accumUsed"];
                                        }

                                        list["accumUsed"] = list["accumUsed"].toFixed(2);
                                        list["accumLeft"] = list["accumLeft"].toFixed(2);
                                        list["accumTotal"] = list["accumTotal"].toFixed(2);

                                        leaveModule.push(list);
                                    }
                                });
                            },
                            (err) => {
                                console.log(err);
                            }
                        );

                    const someTimeoutAction2 = () => {
                        return new Promise((resolve) => {
                            setTimeout(function () {
                                resolve(leaveModule);
                            }, 2000);
                        });
                    };
                    const listLeave = await someTimeoutAction2();
                    setUsers(listLeave);

                    setLoading(false);
                }
            }
        });
    }, [companyIdFilter, yearNow, monthNow, profile_company, endDate, startDate]);

    const updateUserData = useCallback((value) => {
        setCompanyIdFilter(value);
    }, []);

    function changeCompanyId(value) {
        updateUserData(value);
    }

    function filterData(value) {
        setYearNow(format(new Date(value), "yyyy"));
        setMonthNow(format(new Date(value), "M"));

        setStartDate(value);
    }
    function filterDataTo(value) {
        setEndDate(value);
    }

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Leave Balance Report</h1>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            {users && users.length > 0 && (
                                <ExcelFile element={<Button color="blue">Download</Button>}>
                                    <ExcelSheet data={users} name="Users">
                                        <ExcelColumn label="Department" value="departmentName" />
                                        <ExcelColumn label="Employee Number" value="employeeNr" />
                                        <ExcelColumn label="First Name" value="employee_full_first_names" />
                                        <ExcelColumn label="Known As" value="displayName" />
                                        <ExcelColumn label="Last Name" value="employee_surname" />

                                        <ExcelColumn label="Email" value="email" />

                                        <ExcelColumn label="Balance Available" value="annualBalanceYtd" />
                                        <ExcelColumn label="Leave Taken In Current Period" value="currUsed" />
                                        <ExcelColumn label="Accumulative Leave Balance" value="accumLeft" />
                                        <ExcelColumn label="Annual Leave Left" value="annualLeft" />

                                        <ExcelColumn label="Annual Leave Allocated (per annum)" value="leaveTotal" />

                                        <ExcelColumn label="Annual Leave Taken (YTD)" value="annualUsedYtd" />

                                        <ExcelColumn label="Accumulative Leave Balance (as at 31 Jan)" value="accumTotal" />
                                        <ExcelColumn label="Accumulated Leave Taken (during 01 Feb - 30 April)" value="accumUsed" />
                                        <ExcelColumn label="Accumulative Leave Balance (was not taken during the 3 mths)" value="accumLeft" />

                                        <ExcelColumn label="Sick Leave Allocated (3 year cycle)" value="sickTotal" />
                                        <ExcelColumn label="Sick Leave Taken (Current Month)" value="sickUsedCur" />
                                        <ExcelColumn label="Sick Leave Taken (from start of cycle)" value="sickUsedYtd" />
                                        <ExcelColumn label="Sick Leave Left" value="sickLeft" />

                                        <ExcelColumn label="Family Leave Allocated" value="familyTotal" />
                                        <ExcelColumn label="Family Leave Taken" value="familyUsed" />
                                        <ExcelColumn label="Family Leave Left" value="familyLeft" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )}

                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik initialValues={{ email: "" }} validate={(values) => {}} onSubmit={(values, { setSubmitting }) => {}}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={6}>
                                    <Grid.Column>
                                        <label>From:</label>
                                        <DatePicker selected={startDate} onChange={(value) => filterData(value)} showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy-MM-dd" />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label>To:</label>
                                        <DatePicker selected={endDate} onChange={(value) => filterDataTo(value)} showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy-MM-dd" />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="companyId"
                                            value={values.companyId}
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            onChange={(field, value) => changeCompanyId(value.value)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            {loading === true ? (
                <LoadingComponent />
            ) : (
                <table className="ui celled table selectable">
                    <thead>
                        <tr style={{ fontSize: 11 }}>
                            <th>Department</th>
                            <th>Employee Number</th>
                            <th>First Name</th>
                            <th>Known As</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            {/* <th>Annual Leave Allocated (P.A)</th>
                             */}

                            <th>Balance Available</th>
                            <th>Leave Taken In Current Period</th>
                            <th>Accumulative Leave Balance</th>
                            <th>Balance Available (P.A)</th>

                            {/* <th>Annual Total (YTD)</th> */}
                            {/* <th>Leave Taken (YTD)</th> */}

                            {/* <th>Accumulated Leave Taken</th> */}
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody style={{ fontSize: 11 }}>
                        {console.log(users)}
                        {users &&
                            users.length > 0 &&
                            users.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        <strong>{user.departmentName}</strong>
                                    </td>
                                    <td>
                                        <strong>{user.employeeNr}</strong>
                                    </td>
                                    <td data-label="Name">
                                        {user.displayName && user.displayName.length > 0 && (
                                            <span>
                                                <strong>{user.employee_full_first_names}</strong>
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{user.displayName}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{user.employee_surname}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        {user.email && user.email.length > 0 && (
                                            <span>
                                                <strong>{user.email}</strong>
                                            </span>
                                        )}
                                    </td>

                                    {/* <td>{user.leaveTotal}</td>

                                     */}

                                    <td>{user.annualBalanceYtd}</td>
                                    <td>{user.currUsed}</td>
                                    <td>{user.accumLeft}</td>
                                    <td>{user.annualLeft}</td>

                                    {/* <td>{user.annualTotalYtd}</td> */}
                                    {/* <td>{user.annualUsedYtd}</td> */}

                                    {/* <td>{user.accumUsed}</td> */}

                                    <td width={150} data-label="Action">
                                        <Button color="blue" as={Link} to={`/clients/leaveview/${user.id}`}>
                                            View Profile
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </Container>
    );
}
