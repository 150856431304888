import React from "react";
import { Table } from "semantic-ui-react";
import { useState } from "react";
import { useEffect } from "react";

import firebase from "../../../app/config/firebase";
import { startOfMonth } from "date-fns/esm";
import { addMonths } from "date-fns";
const firestore = firebase.firestore();

export default function LeaveLeftTbl(values) {
    let profileClient = values["profileClient"];
    let selectedYear = values.year;

    if (!values.id) {
        profileClient = values.profileClient;
    }

    let layout = "vertical";
    if (values.layout) {
        layout = values.layout;
    }

    const [leaveMonthly, setLeaveMonthly] = useState([]);
    const [leave, setLeave] = useState([]);
    const [sickLeave, setSickLeave] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [annualLeftDays, setAnnualLeftDays] = useState([]);
    const [annualTotalDays, setAnnualTotalDays] = useState([]);

    let showAccumExpired = false;
    let showAccum = false;
    let showAccum2 = false;

    useEffect(() => {
        if (selectedYear === "" || selectedYear === undefined) {
            firestore
                .collection("leaveStatus")
                .where("userUid", "==", profileClient.id)
                .where("toDate", ">", new Date())
                .where("periodType", "==", "Leave")
                .onSnapshot((snapshot) => {
                    setLeave(snapshot.docs[0].data());
                    firestore
                        .collection("leaveStatus")
                        .doc(snapshot.docs[0].id)
                        .collection("Months")
                        .where("datePeriod", "==", startOfMonth(new Date()))
                        .onSnapshot((snapshot2) => {
                            setLeaveMonthly(snapshot2.docs[0].data());
                            setAnnualLeftDays(Number.isInteger(snapshot2.docs[0].data().annualLeft) ? snapshot2.docs[0].data().annualLeft : parseFloat(snapshot2.docs[0].data().annualLeft).toFixed(2));
                            setAnnualTotalDays(
                                Number.isInteger(snapshot2.docs[0].data().annualTotal) ? snapshot2.docs[0].data().annualTotal : parseFloat(snapshot2.docs[0].data().annualTotal).toFixed(2)
                            );
                        });
                });
        } else {
            firestore
                .collection("leaveStatus")
                .doc(selectedYear)
                .onSnapshot((snapshot) => {
                    setLeave(snapshot.data());
                    firestore
                        .collection("leaveStatus")
                        .doc(snapshot.id)
                        .collection("Months")
                        .where("datePeriod", "==", startOfMonth(new Date()))
                        .onSnapshot((snapshot2) => {
                            setLeaveMonthly(snapshot2.docs[0].data());
                            setAnnualLeftDays(Number.isInteger(snapshot2.docs[0].data().annualLeft) ? snapshot2.docs[0].data().annualLeft : parseFloat(snapshot2.docs[0].data().annualLeft).toFixed(2));
                            setAnnualTotalDays(
                                Number.isInteger(snapshot2.docs[0].data().annualTotal) ? snapshot2.docs[0].data().annualTotal : parseFloat(snapshot2.docs[0].data().annualTotal).toFixed(2)
                            );
                        });
                });
        }

        const unsubscribe2 = firestore
            .collection("leaveStatus")
            .where("userUid", "==", profileClient.id)
            .where("toDate", ">", new Date())
            .where("periodType", "==", "Sick")
            .onSnapshot((snapshot) => {
                setSickLeave(snapshot.docs[0].data());
            });

        const unsubscribe3 = firestore
            .collection("companies")
            .doc(profileClient.companyId)
            .onSnapshot((snapshot) => {
                if (snapshot.exists) {
                    setCompanyData(snapshot.data());
                }
            });

        return () => {
            // unsubscribe();
            unsubscribe2();
            unsubscribe3();
        };
    }, [selectedYear]);

    if (companyData.leaveRolloverType === "Accumulative") {
        showAccum = true;
        if (addMonths(leave.fromDate.toDate(), companyData.leaveAccumulativeForfeit) < new Date()) {
            showAccumExpired = true;
            showAccum2 = true;
        }
    } else if (companyData.leaveRolloverType === "Annual Rollover") {
        showAccum = true;
    }

    return (
        <>
            {layout && layout === "horizontal" ? (
                <>
                    <Table basic="very" celled collapsing unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Annual</Table.HeaderCell>
                                {companyData.leaveTrackingType && companyData.leaveTrackingType === "Leave Month" && <Table.HeaderCell>Annual Totals</Table.HeaderCell>}
                                {showAccum && showAccumExpired === false && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                                {showAccum && !showAccum2 && showAccumExpired === true && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                                {showAccum && showAccum2 && showAccumExpired === true && <Table.HeaderCell>Expired Accumulative</Table.HeaderCell>}
                                {showAccum2 && showAccumExpired === true && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                                {sickLeave.sickTotal > 0 && <Table.HeaderCell>Sick</Table.HeaderCell>}
                                {leave.familyTotal > 0 && <Table.HeaderCell>Family</Table.HeaderCell>}
                                {leave.maternityTotal > 0 && <Table.HeaderCell>Maternity</Table.HeaderCell>}
                                {leave.paternityTotal > 0 && <Table.HeaderCell>Paternity</Table.HeaderCell>}
                                {leave.IODTotal > 0 && <Table.HeaderCell>IOD</Table.HeaderCell>}
                                {leave.parentalTotal > 0 && <Table.HeaderCell>Parental</Table.HeaderCell>}
                                {leave.movingTotal > 0 && <Table.HeaderCell>Moving</Table.HeaderCell>}
                                {leave.csrTotal > 0 && <Table.HeaderCell>CSR</Table.HeaderCell>}
                                {leave.religiousTotal > 0 && <Table.HeaderCell>Religious</Table.HeaderCell>}
                                {leave.studyTotal > 0 && <Table.HeaderCell>Study</Table.HeaderCell>}
                                <Table.HeaderCell>Special</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Days Used</Table.Cell>
                                <Table.Cell>{leaveMonthly.annualUsed}</Table.Cell>
                                {companyData.leaveTrackingType && companyData.leaveTrackingType === "Leave Month" && <Table.Cell>{leaveMonthly.allUsed}</Table.Cell>}
                                {showAccum && <Table.Cell>{leaveMonthly.accumUsed}</Table.Cell>}
                                {showAccum2 && showAccumExpired === true && <Table.Cell>{leaveMonthly.accumUsed2}</Table.Cell>}
                                {sickLeave.sickTotal > 0 && <Table.Cell>{sickLeave.sickUsed}</Table.Cell>}
                                {leave.familyTotal > 0 && <Table.Cell>{leave.familyUsed}</Table.Cell>}
                                {leave.maternityTotal > 0 && <Table.Cell>{leave.maternityUsed}</Table.Cell>}
                                {leave.paternityTotal > 0 && <Table.Cell>{leave.paternityUsed}</Table.Cell>}
                                {leave.IODTotal > 0 && <Table.Cell>{leave.IODUsed}</Table.Cell>}
                                {leave.parentalTotal > 0 && <Table.Cell>{leave.parentalUsed}</Table.Cell>}
                                {leave.movingTotal > 0 && <Table.Cell>{leave.movingUsed}</Table.Cell>}
                                {leave.csrTotal > 0 && <Table.Cell>{leave.csrUsed}</Table.Cell>}
                                {leave.religiousTotal > 0 && <Table.Cell>{leave.religiousUsed}</Table.Cell>}
                                {leave.studyTotal > 0 && <Table.Cell>{leave.studyUsed}</Table.Cell>}
                                <Table.Cell>{leave.usedSpecial}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Days Left</Table.Cell>
                                <Table.Cell>{annualLeftDays}</Table.Cell>
                                {companyData.leaveTrackingType && companyData.leaveTrackingType === "Leave Month" && <Table.Cell>{leaveMonthly.allLeft}</Table.Cell>}
                                {showAccum && <Table.Cell>{leaveMonthly.accumTotal - leaveMonthly.accumUsed}</Table.Cell>}
                                {showAccum2 && showAccumExpired === true && <Table.Cell>{leaveMonthly.accumTotal2 - leaveMonthly.accumUsed2}</Table.Cell>}
                                {sickLeave.sickTotal > 0 && <Table.Cell>{sickLeave.sickLeft}</Table.Cell>}
                                {leave.familyTotal > 0 && <Table.Cell>{leave.familyLeft}</Table.Cell>}
                                {leave.maternityTotal > 0 && <Table.Cell>{leave.maternityLeft}</Table.Cell>}
                                {leave.paternityTotal > 0 && <Table.Cell>{leave.paternityLeft}</Table.Cell>}
                                {leave.IODTotal > 0 && <Table.Cell>{leave.IODLeft}</Table.Cell>}
                                {leave.parentalTotal > 0 && <Table.Cell>{leave.parentalLeft}</Table.Cell>}
                                {leave.movingTotal > 0 && <Table.Cell>{leave.movingLeft}</Table.Cell>}
                                {leave.csrTotal > 0 && <Table.Cell>{leave.csrLeft}</Table.Cell>}
                                {leave.religiousTotal > 0 && <Table.Cell>{leave.religiousLeft}</Table.Cell>}
                                {leave.studyTotal > 0 && <Table.Cell>{leave.studyLeft}</Table.Cell>}
                                <Table.Cell>N/A</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Days Total</Table.Cell>
                                <Table.Cell>{leaveMonthly.annualTotal}</Table.Cell>
                                {companyData.leaveTrackingType && companyData.leaveTrackingType === "Leave Month" && <Table.Cell>{leaveMonthly.allTotal}</Table.Cell>}
                                {showAccum && <Table.Cell>{leaveMonthly.accumTotal}</Table.Cell>}
                                {showAccum2 && showAccumExpired === true && <Table.Cell>{leaveMonthly.accumTotal2}</Table.Cell>}
                                {sickLeave.sickTotal > 0 && <Table.Cell>{sickLeave.sickTotal}</Table.Cell>}
                                {leave.familyTotal > 0 && <Table.Cell>{leave.familyTotal}</Table.Cell>}
                                {leave.maternityTotal > 0 && <Table.Cell>{leave.maternityTotal}</Table.Cell>}
                                {leave.paternityTotal > 0 && <Table.Cell>{leave.paternityTotal}</Table.Cell>}
                                {leave.IODTotal > 0 && <Table.Cell>{leave.IODTotal}</Table.Cell>}
                                {leave.parentalTotal > 0 && <Table.Cell>{leave.parentalTotal}</Table.Cell>}
                                {leave.movingTotal > 0 && <Table.Cell>{leave.movingTotal}</Table.Cell>}
                                {leave.csrTotal > 0 && <Table.Cell>{leave.csrTotal}</Table.Cell>}
                                {leave.religiousTotal > 0 && <Table.Cell>{leave.religiousTotal}</Table.Cell>}
                                {leave.studyTotal > 0 && <Table.Cell>{leave.studyTotal}</Table.Cell>}
                                <Table.Cell>N/A</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </>
            ) : (
                <>
                    <Table basic="very" celled collapsing unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Days Used</Table.HeaderCell>
                                <Table.HeaderCell>Days Left</Table.HeaderCell>
                                <Table.HeaderCell>Days Total</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Annual</Table.Cell>
                                <Table.Cell>{leaveMonthly.annualUsed}</Table.Cell>
                                <Table.Cell>{annualLeftDays}</Table.Cell>
                                <Table.Cell>{annualTotalDays}</Table.Cell>
                            </Table.Row>

                            {companyData.leaveTrackingType && companyData.leaveTrackingType === "Leave Month" && (
                                <Table.Row>
                                    <Table.Cell>Annual Totals</Table.Cell>
                                    <Table.Cell>{leaveMonthly.allUsed}</Table.Cell>
                                    <Table.Cell>{leaveMonthly.allLeft}</Table.Cell>
                                    <Table.Cell>{leaveMonthly.allTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {showAccum && (
                                <Table.Row>
                                    <Table.Cell>{showAccumExpired === true && "Expired "}Accumulative</Table.Cell>
                                    <Table.Cell>{leaveMonthly.accumUsed}</Table.Cell>
                                    <Table.Cell>{leaveMonthly.accumTotal - leaveMonthly.accumUsed}</Table.Cell>
                                    <Table.Cell>{leaveMonthly.accumTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {showAccum2 && showAccumExpired === true && (
                                <Table.Row>
                                    <Table.Cell>Accumulative</Table.Cell>
                                    <Table.Cell>{leaveMonthly.accumUsed2}</Table.Cell>
                                    <Table.Cell>{leaveMonthly.accumTotal2 - leaveMonthly.accumUsed2}</Table.Cell>
                                    <Table.Cell>{leaveMonthly.accumTotal2}</Table.Cell>
                                </Table.Row>
                            )}

                            {sickLeave.sickTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Sick</Table.Cell>
                                    <Table.Cell>{sickLeave.sickUsed}</Table.Cell>
                                    <Table.Cell>{sickLeave.sickLeft}</Table.Cell>
                                    <Table.Cell>{sickLeave.sickTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.familyTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Family</Table.Cell>
                                    <Table.Cell>{leave.familyUsed}</Table.Cell>
                                    <Table.Cell>{leave.familyLeft}</Table.Cell>
                                    <Table.Cell>{leave.familyTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.maternityTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Maternity</Table.Cell>
                                    <Table.Cell>{leave.maternityUsed}</Table.Cell>
                                    <Table.Cell>{leave.maternityLeft}</Table.Cell>
                                    <Table.Cell>{leave.maternityTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.paternityTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Paternity</Table.Cell>
                                    <Table.Cell>{leave.paternityLeft}</Table.Cell>
                                    <Table.Cell>{leave.paternityUsed}</Table.Cell>
                                    <Table.Cell>{leave.paternityTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.IODTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>IOD</Table.Cell>
                                    <Table.Cell>{leave.IODLeft}</Table.Cell>
                                    <Table.Cell>{leave.IODUsed}</Table.Cell>
                                    <Table.Cell>{leave.IODTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.parentalTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Parental</Table.Cell>
                                    <Table.Cell>{leave.parentalLeft}</Table.Cell>
                                    <Table.Cell>{leave.parentalUsed}</Table.Cell>
                                    <Table.Cell>{leave.parentalTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.movingTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Moving</Table.Cell>
                                    <Table.Cell>{leave.movingUsed}</Table.Cell>
                                    <Table.Cell>{leave.movingLeft}</Table.Cell>
                                    <Table.Cell>{leave.movingTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.csrTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>CSR</Table.Cell>
                                    <Table.Cell>{leave.csrUsed}</Table.Cell>
                                    <Table.Cell>{leave.csrLeft}</Table.Cell>
                                    <Table.Cell>{leave.csrTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.religiousTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Religious</Table.Cell>
                                    <Table.Cell>{leave.religiousUsed}</Table.Cell>
                                    <Table.Cell>{leave.religiousLeft}</Table.Cell>
                                    <Table.Cell>{leave.religiousTotal}</Table.Cell>
                                </Table.Row>
                            )}

                            {leave.studyTotal > 0 && (
                                <Table.Row>
                                    <Table.Cell>Study</Table.Cell>
                                    <Table.Cell>{leave.studyUsed}</Table.Cell>
                                    <Table.Cell>{leave.studyLeft}</Table.Cell>
                                    <Table.Cell>{leave.studyTotal}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </>
            )}
        </>
    );
}
