import React, { Component } from "react";
import { Container, Button, Segment } from "semantic-ui-react";
import {
    updateTerminatedUsers,
    updateOldLeaveDates,
    updateDepName,
    terminateThink,
    unterminateThink,
    terminateNonThink,
    removeNonThink,
    updateSpesificUserId,
    updateThinkLeaveUsers,
    testPDFAction,
    checkLeaveOutstandingAction,
    testIRP5Actions,
} from "./sandboxActions";
import { connect } from "react-redux";

const actions = {
    updateTerminatedUsers,
    updateOldLeaveDates,
    updateDepName,
    terminateThink,
    unterminateThink,
    terminateNonThink,
    removeNonThink,
    updateSpesificUserId,
    updateThinkLeaveUsers,
    testPDFAction,
    checkLeaveOutstandingAction,
    testIRP5Actions,
};

class sandbox extends Component {
    updateTerminated = async () => {
        await this.props.updateTerminatedUsers();
    };

    updateOldLeaveDates = async () => {
        await this.props.updateOldLeaveDates();
    };

    updateUserDepName = async () => {
        updateDepName();
    };

    terminateThinkUsers = async () => {
        terminateThink();
    };

    unterminateThinkUsers = async () => {
        unterminateThink();
    };

    terminateNonThinkUsers = async () => {
        terminateNonThink();
    };

    removeNonThinkData = async () => {
        removeNonThink();
    };

    updateUserIDEverywhere = async () => {
        updateSpesificUserId();
    };

    updateThinkLeave = async () => {
        await updateThinkLeaveUsers();
    };

    testPDF = async () => {
        await testPDFAction();
    };

    checkLeaveOutstanding = async () => {
        await checkLeaveOutstandingAction();
    };

    testIRP5 = async () => {
        await testIRP5Actions();
    };

    render() {
        return (
            <Container>
                <Segment>
                    <Button onClick={() => this.updateTerminated()}>Add Terminated to users</Button>
                    <Button onClick={() => this.updateOldLeaveDates()}>Update Leave Dates for old Leave</Button>
                    <Button onClick={() => this.updateUserDepName()}>Update Users Department Name</Button>
                    <br />
                    <br />
                    <Button onClick={() => this.terminateThinkUsers()}>Terminate Think Users</Button>
                    <Button onClick={() => this.unterminateThinkUsers()}>Unterminate Think Users</Button>
                    <Button onClick={() => this.removeNonThinkData()}>Remove Non Think Data</Button>
                    <br />
                    <br />
                    <Button onClick={() => this.updateUserIDEverywhere()}>Update Spesific user ID everywhere</Button>
                    <Button onClick={() => this.updateThinkLeave()}>Update THINK leave</Button>
                    <br />
                    <br />
                    <Button onClick={() => this.testPDF()}>PDF Test</Button>
                    <Button onClick={() => this.checkLeaveOutstanding()}>Check Leave Outstanding</Button>
                    <Button onClick={() => this.testIRP5()}>IRP5 Checklist</Button>
                </Segment>
            </Container>
        );
    }
}

export default connect(null, actions)(sandbox);
