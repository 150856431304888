import React from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import { customBulkpayslip, uploadBulkPayslips } from "../payslipsActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { useSelector } from "react-redux";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";

export default function PayslipsBulkUploadForm(props) {
    const { currentUserProfile } = useSelector((state) => state.profile);

    let companies = props.companies;

    let assignCompanies = [
        {
            key: "",
            text: "Choose a Company",
            value: "",
        },
    ];

    if (companies) {
        Object.keys(companies).forEach(function (key) {
            if (currentUserProfile.isSuper || (currentUserProfile.isAdmin && currentUserProfile.companyId === companies[key].id)) {
                assignCompanies.push({
                    key: companies[key].id,
                    text: companies[key].companyName,
                    value: companies[key].id,
                });
            }
        });
    }

    const mapPayslipsBy = [
        { key: "Employee Number", text: "Employee Number", value: "Employee Number" },
        { key: "User Initials", text: "User Initials and Surname", value: "User Initials" },
    ];

    return (
        <Formik
            initialValues={{ documentName: "", mapBy: "Employee Number", encrypt: true }}
            validate={(values) => {
                const errors = {};
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                let files = values.uploadFile;

                var i;
                for (i = 0; i < values.uploadFile.length; i++) {
                    if (values.encrypt === false) {
                        await uploadBulkPayslips(values.uploadFile[i], values.documentName, values.companyId, values.mapBy, values.encrypt);
                    } else {
                        var formData = new FormData();
                        var imagefile = document.querySelector("#uploadFile");
                        formData.append("file", imagefile.files[i]);
                        formData.append("documentName", imagefile.files[i].name);
                        formData.append("filename", values.documentName);
                        formData.append("encrypt", values.encrypt);

                        let FileUpload = await axios.post(apiURL + "/api/upload_bulk_pdf", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                        // delete values.uploadFile;

                        values.downloadURL = apiURL + "/users/" + FileUpload.data;
                        values.documentFullName = imagefile.files[i].name;

                        await customBulkpayslip(values);
                    }
                }

                setSubmitting(false);
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label>Payslip Date</label>
                                    <MyTextInput name="documentName" placeholder="Enter a date for the payslip" />
                                </Form.Field>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label>
                                        <strong>Drag or click to upload</strong>
                                    </label>
                                    <input
                                        id="uploadFile"
                                        name="uploadFile"
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue("uploadFile", event.currentTarget.files);
                                        }}
                                        className="form-control"
                                        multiple
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <Form.Field>
                                    <label>Company</label>
                                    <MySelectInput
                                        name="companyId"
                                        options={assignCompanies}
                                        value={values.companyId}
                                        onChange={(field, value) => setFieldValue("companyId", value.value)}
                                        placeholder="Choose a Company"
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Form.Field>
                                    <label>Map By</label>
                                    <MySelectInput
                                        name="mapBy"
                                        options={mapPayslipsBy}
                                        value={values.mapBy || "Employee Number"}
                                        onChange={(field, value) => setFieldValue("mapBy", value.value)}
                                        placeholder="Map By"
                                        clearable={false}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Form.Field>
                                    <label style={{ marginTop: "30px" }}>
                                        <input
                                            type="checkbox"
                                            name={"encrypt"}
                                            id="encrypt"
                                            defaultChecked="true"
                                            value="true"
                                            className="ui checkbox"
                                            onChange={(value) => {
                                                setFieldValue("encrypt", value.target.checked);
                                            }}
                                        />
                                        &nbsp; Add Password
                                    </label>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column width={16}>
                                <Button type="submit" disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue">
                                    UPLOAD
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}
