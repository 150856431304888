import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Divider, Form, Header, Image, Grid, Segment } from "semantic-ui-react";
import { createCompany, updateCompany, getCompanyData } from "../superActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { useSelector } from "react-redux";

export default function CompaniesDetailedPage(props) {
    let parts = window.location.href.split("/");
    let companyId = parts.pop() || parts.pop();
    const [company, setCompany] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    let leaveRolloverType = "";

    const [hasLeave, setHasLeave] = useState(false);
    const [hasTimeTracking, setHasTimeTracking] = useState(false);
    const [hasExpenseTracking, setHasExpenseTracking] = useState(false);
    const [hrCompany, setHrCompany] = useState(false);
    const [hrDRG, setHrDRG] = useState(false);
    const [trackAlways, setTrackAlways] = useState(true);
    const [autoSubmit, setAutoSubmit] = useState(true);
    const [months, setMonths] = useState("");
    const [workSaturday, setWorkSaturday] = useState(false);
    const [workSunday, setWorkSunday] = useState(false);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        getCompanyData(companyId).then(
            (res) => {
                if (res.exists) {
                    setCompany(res.data());
                    setInitialValues(res.data());

                    setHasLeave(res.data().hasLeave);
                    setHasTimeTracking(res.data().hasTimeTracking);
                    setHasExpenseTracking(res.data().hasExpenseTracking);
                    setHrCompany(res.data().hrCompany);
                    setHrDRG(res.data().hrDRG);
                    setTrackAlways(res.data().trackAlways);
                    setMonths(res.data().leaveMonthType);
                    setWorkSaturday(res.data().saturdayWorkDay);
                    setWorkSunday(res.data().sundayWorkDay);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    if (initialValues && initialValues.leaveRolloverType !== undefined) {
        leaveRolloverType = initialValues.leaveRolloverType;
    }

    const monthList = [
        { key: "1", text: "January", value: "1" },
        { key: "2", text: "February", value: "2" },
        { key: "3", text: "March", value: "3" },
        { key: "4", text: "April", value: "4" },
        { key: "5", text: "May", value: "5" },
        { key: "6", text: "June", value: "6" },
        { key: "7", text: "July", value: "7" },
        { key: "8", text: "August", value: "8" },
        { key: "9", text: "September", value: "9" },
        { key: "10", text: "October", value: "10" },
        { key: "11", text: "November", value: "11" },
        { key: "12", text: "December", value: "12" },
    ];

    const sendPayslip = [
        { key: "Email", text: "Email", value: "Email" },
        { key: "Print", text: "Print", value: "Print" },
        { key: "Nothing", text: "Nothing", value: "Nothing" },
    ];

    const hours = [
        { key: "1", text: "1", value: "1" },
        { key: "2", text: "2", value: "2" },
        { key: "3", text: "3", value: "3" },
        { key: "4", text: "4", value: "4" },
        { key: "5", text: "5", value: "5" },
        { key: "6", text: "6", value: "6" },
        { key: "7", text: "7", value: "7" },
        { key: "8", text: "8", value: "8" },
        { key: "9", text: "9", value: "9" },
        { key: "10", text: "10", value: "10" },
        { key: "11", text: "11", value: "11" },
        { key: "12", text: "12", value: "12" },
    ];

    const days = [
        { key: "01", text: "01", value: "01" },
        { key: "02", text: "02", value: "02" },
        { key: "03", text: "03", value: "03" },
        { key: "04", text: "04", value: "04" },
        { key: "05", text: "05", value: "05" },
        { key: "06", text: "06", value: "06" },
        { key: "07", text: "07", value: "07" },
        { key: "08", text: "08", value: "08" },
        { key: "09", text: "09", value: "09" },
        { key: "10", text: "10", value: "10" },
        { key: "11", text: "11", value: "11" },
        { key: "12", text: "12", value: "12" },
        { key: "13", text: "13", value: "13" },
        { key: "14", text: "14", value: "14" },
        { key: "15", text: "15", value: "15" },
        { key: "16", text: "16", value: "16" },
        { key: "17", text: "17", value: "17" },
        { key: "18", text: "18", value: "18" },
        { key: "19", text: "19", value: "19" },
        { key: "20", text: "20", value: "20" },
        { key: "21", text: "21", value: "21" },
        { key: "22", text: "22", value: "22" },
        { key: "23", text: "23", value: "23" },
        { key: "24", text: "24", value: "24" },
        { key: "25", text: "25", value: "25" },
        { key: "26", text: "26", value: "26" },
        { key: "27", text: "27", value: "27" },
        { key: "28", text: "28", value: "28" },
        { key: "29", text: "29", value: "29" },
        { key: "30", text: "30", value: "30" },
        { key: "31", text: "31", value: "31" },
    ];

    const leaveType = [
        { key: "Company Financial Year", text: "Company Financial Year", value: "Company Financial Year" },
        { key: "Anniversary Date of Employee", text: "Anniversary Date of Employee", value: "Anniversary Date of Employee" },
        { key: "January to December", text: "January to December", value: "January to December" },
    ];

    const leaveMonthType = [
        { key: "Start to End of Month", text: "Start to End of Month", value: "Start to End of Month" },
        { key: "Custom From and To Date", text: "Custom From and To Date", value: "Custom From and To Date" },
    ];

    const RolloverType = [
        { key: "None", text: "None", value: "None" },
        { key: "Accumulative", text: "Accumulative", value: "Accumulative" },
        { key: "Annual Rollover", text: "Annual Rollover", value: "Annual Rollover" },
    ];

    const leaveTrackingType = [
        { key: "Current Month", text: "Current Month", value: "Current Month" },
        { key: "Leave Month", text: "Leave Month", value: "Leave Month" },
    ];

    const leaveMethodType = [
        { key: "Old Method", text: "Old Method", value: "Old Method" },
        { key: "New Method", text: "New Method", value: "New Method" },
    ];

    const negLeaveApproval = [
        { key: "Line Manager", text: "Line Manager", value: "Line Manager" },
        { key: "HR User", text: "HR User", value: "HR User" },
    ];

    function hasLeaveCompany(value) {
        setHasLeave(value.target.checked);
    }
    function hasTimeTrackingCompany(value) {
        setHasTimeTracking(value.target.checked);
    }
    function hasExpenseTrackingCompany(value) {
        setHasExpenseTracking(value.target.checked);
    }
    function hrCompanyCompany(value) {
        setHrCompany(value.target.checked);
    }
    function hrDrgCompany(value) {
        setHrDRG(value.target.checked);
    }
    function trackAlwaysCompany(value) {
        setTrackAlways(value.target.checked);
    }
    function autoSubmitCompany(value) {
        setAutoSubmit(value.target.checked);
    }

    function changeRollType(value) {
        leaveRolloverType = value;
    }

    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize="true"
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (companyId !== "0") {
                            values.id = companyId;
                            await updateCompany(values);
                        } else {
                            await createCompany(values);
                        }

                        setSubmitting(false);
                        props.history.push("/super/companies/");
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid stackable>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={8}>
                                        <Header>Company Details</Header>

                                        <Form.Field>
                                            <label>Company Name</label>
                                            <MyTextInput name="companyName" type="text" placeholder="Company Name" />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Leave per Month</label>
                                            <MyTextInput name="leavePerMonth" type="text" placeholder="Leave per Month" />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Starting Month</label>
                                            <MySelectInput
                                                name="yearStart"
                                                options={monthList}
                                                value={values.yearStart}
                                                onChange={(field, value) => setFieldValue("yearStart", value.value)}
                                                placeholder="Starting Month"
                                            />
                                            <em>This is when a new leave cycle starts</em>
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Current Financial Year</label>
                                            <MyTextInput name="curFinancialYear" type="text" placeholder="e.g. 2020" />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>Send Payslip</label>
                                            <MySelectInput
                                                name="sendPayslip"
                                                options={sendPayslip}
                                                onChange={(field, value) => setFieldValue("sendPayslip", value.value)}
                                                value={values.sendPayslip}
                                                placeholder="Send Payslip"
                                            />
                                        </Form.Field>

                                        <Form.Group inline>
                                            <label>Allowed Modules:</label>
                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="hasLeave"
                                                    value="true"
                                                    checked={hasLeave}
                                                    onChange={(value) => {
                                                        hasLeaveCompany(value);
                                                        setFieldValue("hasLeave", value.target.checked);
                                                    }}
                                                    className="ui checkbox"
                                                />
                                                &nbsp; Leave &nbsp;
                                            </label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="hasTimeTracking"
                                                    value="true"
                                                    checked={hasTimeTracking}
                                                    onChange={(value) => {
                                                        hasTimeTrackingCompany(value);
                                                        setFieldValue("hasTimeTracking", value.target.checked);
                                                    }}
                                                    className="ui checkbox"
                                                />
                                                &nbsp; Time Tracking &nbsp;
                                            </label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="hasExpenseTracking"
                                                    value="true"
                                                    checked={hasExpenseTracking}
                                                    onChange={(value) => {
                                                        hasExpenseTrackingCompany(value);
                                                        setFieldValue("hasExpenseTracking", value.target.checked);
                                                    }}
                                                    className="ui checkbox"
                                                />
                                                &nbsp; Expense Tracking &nbsp;
                                            </label>
                                        </Form.Group>

                                        <Form.Group inline>
                                            <label>HR:</label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="hrCompany"
                                                    value="true"
                                                    checked={hrCompany}
                                                    onChange={(value) => {
                                                        hrCompanyCompany(value);
                                                        setFieldValue("hrCompany", value.target.checked);
                                                    }}
                                                    className="ui checkbox"
                                                />
                                                &nbsp; HR Department within Company &nbsp;
                                            </label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="hrDRG"
                                                    value="true"
                                                    checked={hrDRG}
                                                    onChange={(value) => {
                                                        hrDrgCompany(value);
                                                        setFieldValue("hrDRG", value.target.checked);
                                                    }}
                                                    className="ui checkbox"
                                                />
                                                &nbsp; DRG &nbsp;
                                            </label>
                                        </Form.Group>

                                        <Grid stackable>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Form.Field>
                                                        <label>New Company Logo</label>
                                                        <input
                                                            id="uploadFile"
                                                            name="uploadFile"
                                                            type="file"
                                                            onChange={(event) => {
                                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                                            }}
                                                            className="form-control"
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={8}>
                                                    <label>Current Company Logo</label>
                                                    {company.logoUrl && company.logoUrl.length > 0 && <Image src={company.logoUrl} size="medium" />}
                                                </Grid.Column>
                                            </Grid.Row>
                                            {hasExpenseTracking === true && (
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <h4>Expense tracking per month</h4>
                                                    </Grid.Column>

                                                    <Grid.Column width={8}>
                                                        <Form.Field>
                                                            <MySelectInput
                                                                name="expenseToDate"
                                                                labelTop="To:"
                                                                options={days}
                                                                onChange={(field, value) => setFieldValue("expenseToDate", value.value)}
                                                                placeholder="To day of month"
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}

                                            {hasLeave === true && (
                                                <Grid.Row columns={2}>
                                                    <Grid.Column width={16}>
                                                        <Form.Field>
                                                            <label>Leave Tracking Type</label>
                                                            <MySelectInput
                                                                name="leaveTrackingType"
                                                                options={leaveTrackingType}
                                                                value={values.leaveTrackingType || "Current Month"}
                                                                onChange={(field, value) => {
                                                                    changeRollType(value.value);
                                                                    setFieldValue("leaveTrackingType", value.value);
                                                                }}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}

                                            {currentUserProfile && currentUserProfile.isSuper && (
                                                <>
                                                    {hasLeave === true && (
                                                        <Grid.Row columns={2}>
                                                            <Grid.Column width={16}>
                                                                <Form.Field>
                                                                    <label>Leave Method Type</label>
                                                                    <MySelectInput
                                                                        name="leaveMethodType"
                                                                        options={leaveMethodType}
                                                                        value={values.leaveMethodType || "Old Method"}
                                                                        onChange={(field, value) => {
                                                                            setFieldValue("leaveMethodType", value.value);
                                                                        }}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}

                                                    <Grid.Row columns={2}></Grid.Row>
                                                </>
                                            )}
                                            {hasLeave === true && (
                                                <Grid.Row columns={2}>
                                                    <Grid.Column width={16}>
                                                        <Form.Field>
                                                            <label>Negative Leave Approval Person</label>
                                                            <MySelectInput
                                                                name="negLeaveApproval"
                                                                options={negLeaveApproval}
                                                                value={values.negLeaveApproval}
                                                                onChange={(field, value) => {
                                                                    changeRollType(value.value);
                                                                    setFieldValue("negLeaveApproval", value.value);
                                                                }}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <label>
                                                        <b>Work Days Over Weekend: </b>
                                                    </label>
                                                    &nbsp;
                                                    <label className="ui">
                                                        <input
                                                            type="checkbox"
                                                            name="saturdayWorkDay"
                                                            value="true"
                                                            checked={workSaturday}
                                                            onChange={(value) => {
                                                                setFieldValue("saturdayWorkDay", value.target.checked);
                                                                setWorkSaturday(value.target.checked);
                                                            }}
                                                            className="ui checkbox"
                                                        />
                                                        &nbsp; Saturday &nbsp;
                                                    </label>
                                                    <label className="ui">
                                                        <input
                                                            type="checkbox"
                                                            name="sundayWorkDay"
                                                            value="true"
                                                            checked={workSunday}
                                                            onChange={(value) => {
                                                                setFieldValue("sundayWorkDay", value.target.checked);
                                                                setWorkSunday(value.target.checked);
                                                            }}
                                                            className="ui checkbox"
                                                        />
                                                        &nbsp; Sunday &nbsp;
                                                    </label>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>

                                    {hasLeave === true && (
                                        <Grid.Column width={8}>
                                            <Header>Leave Allowance</Header>

                                            <Form.Field>
                                                <label>Leave Type</label>
                                                <MySelectInput
                                                    name="leaveType"
                                                    options={leaveType}
                                                    value={values.leaveType}
                                                    onChange={(field, value) => setFieldValue("leaveType", value.value)}
                                                    placeholder="Leave Type"
                                                />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Leave Months</label>
                                                <MySelectInput
                                                    name="leaveMonthType"
                                                    options={leaveMonthType}
                                                    value={values.leaveMonthType}
                                                    onChange={(field, value) => {
                                                        setFieldValue("leaveMonthType", value.value);
                                                        setMonths(value.value);
                                                    }}
                                                    placeholder="Leave Month Type"
                                                />
                                            </Form.Field>

                                            {months && months === "Custom From and To Date" ? (
                                                <>
                                                    <Form.Field>
                                                        <label>Leave From Day</label>
                                                        <MySelectInput
                                                            name="leaveFromDay"
                                                            options={days}
                                                            value={values.leaveFromDay}
                                                            onChange={(field, value) => {
                                                                setFieldValue("leaveFromDay", value.value);
                                                            }}
                                                            placeholder="Leave From Day"
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>Leave To Day</label>
                                                        <MySelectInput
                                                            name="leaveToDay"
                                                            options={days}
                                                            value={values.leaveToDay}
                                                            onChange={(field, value) => {
                                                                setFieldValue("leaveToDay", value.value);
                                                            }}
                                                            placeholder="Leave To Day"
                                                        />
                                                    </Form.Field>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            <Form.Field>
                                                <label>Family (Days)</label>
                                                <MyTextInput name="leaveTotalFamily" type="text" placeholder="Total Family Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Maternity (Days)</label>
                                                <MyTextInput name="leaveTotalMaternity" type="text" placeholder="Total Maternity Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Paternity (Days)</label>
                                                <MyTextInput name="leaveTotalPaternity" type="text" placeholder="Total Paternity Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>IOD (Days)</label>
                                                <MyTextInput name="leaveTotalIOD" type="text" placeholder="Total IOD Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Parental (Days)</label>
                                                <MyTextInput name="leaveTotalParental" type="text" placeholder="Total Parental Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Moving (Days)</label>
                                                <MyTextInput name="leaveTotalMoving" type="text" placeholder="Total Moving Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>CSR (Days)</label>
                                                <MyTextInput name="leaveTotalCSR" type="text" placeholder="Total CSR Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Sick (Days)</label>
                                                <MyTextInput name="leaveTotalSick" type="text" placeholder="Total Sick Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Religious (Days)</label>
                                                <MyTextInput name="leaveTotalReligious" type="text" placeholder="Total Religious Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Study (Days)</label>
                                                <MyTextInput name="leaveTotalStudy" type="text" placeholder="Total Study Leave" />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Leave Rollover Type</label>
                                                <MySelectInput
                                                    name="leaveRolloverType"
                                                    options={RolloverType}
                                                    value={values.leaveRolloverType}
                                                    onChange={(field, value) => {
                                                        changeRollType(value.value);
                                                        setFieldValue("leaveRolloverType", value.value);
                                                    }}
                                                    placeholder="Leave Rollover Type"
                                                />
                                            </Form.Field>

                                            {leaveRolloverType && leaveRolloverType === "Accumulative" && (
                                                <Form.Field>
                                                    <label>Maximum Accumulated Leave</label>
                                                    <MyTextInput name="leaveAccumulativeMaximum" type="text" placeholder="How much leave can accumulate?" />
                                                </Form.Field>
                                            )}

                                            {leaveRolloverType && leaveRolloverType === "Accumulative" && (
                                                <Form.Field>
                                                    <label>Accumulative Leave Forfeit Months</label>
                                                    <MyTextInput name="leaveAccumulativeForfeit" type="text" placeholder="Does Accumulative Leave Forfeit?" />
                                                </Form.Field>
                                            )}

                                            {leaveRolloverType && leaveRolloverType === "Accumulative" && (
                                                <Form.Field>
                                                    <label>Accumulative Leave after Forfeit</label>
                                                    <MyTextInput name="leaveAccumulativeAfterForfeit" type="text" placeholder="Leave after Forfeit?" />
                                                </Form.Field>
                                            )}
                                        </Grid.Column>
                                    )}

                                    {hasTimeTracking === true && (
                                        <>
                                            <Grid.Column width={16}>
                                                <Header>Company Hours:</Header>
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <Form.Field style={{ width: "150px" }}>
                                                    <label>Monday:</label>
                                                    <MySelectInput
                                                        name="mondayHours"
                                                        options={hours}
                                                        value={values.mondayHours}
                                                        onChange={(field, value) => setFieldValue("mondayHours", value.value)}
                                                        placeholder="Monday"
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <Form.Field style={{ width: "150px" }}>
                                                    <label>Tuesday:</label>
                                                    <MySelectInput
                                                        name="tuesdayHours"
                                                        options={hours}
                                                        value={values.tuesdayHours}
                                                        onChange={(field, value) => setFieldValue("tuesdayHours", value.value)}
                                                        placeholder="Tuesday"
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <Form.Field style={{ width: "150px" }}>
                                                    <label>Wednesday:</label>
                                                    <MySelectInput
                                                        name="wednesdayHours"
                                                        options={hours}
                                                        value={values.wednesdayHours}
                                                        onChange={(field, value) => setFieldValue("wednesdayHours", value.value)}
                                                        placeholder="Wednesday"
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <Form.Field style={{ width: "150px" }}>
                                                    <label>Thursday:</label>
                                                    <MySelectInput
                                                        name="thursdayHours"
                                                        options={hours}
                                                        value={values.thursdayHours}
                                                        onChange={(field, value) => setFieldValue("thursdayHours", value.value)}
                                                        placeholder="Thursday"
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <Form.Field style={{ width: "150px" }}>
                                                    <label>Friday:</label>
                                                    <MySelectInput
                                                        name="fridayHours"
                                                        options={hours}
                                                        value={values.fridayHours}
                                                        onChange={(field, value) => setFieldValue("fridayHours", value.value)}
                                                        placeholder="Friday"
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </>
                                    )}
                                </Grid.Row>

                                {hasTimeTracking === true && (
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Header>Time Tracking Submissions Allowed:</Header>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <br />
                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="autoSubmit"
                                                    value="true"
                                                    checked={autoSubmit}
                                                    onChange={(value) => {
                                                        autoSubmitCompany(value);
                                                        setFieldValue("autoSubmit", value.target.checked);
                                                    }}
                                                    className="ui checkbox"
                                                />
                                                &nbsp; Auto submit time &nbsp;
                                            </label>
                                            <br />
                                            {autoSubmit === false || autoSubmit === undefined ? (
                                                <>
                                                    <br />
                                                    <label className="ui">
                                                        <input
                                                            type="checkbox"
                                                            name="trackAlways"
                                                            value="true"
                                                            checked={trackAlways}
                                                            onChange={(value) => {
                                                                trackAlwaysCompany(value);
                                                                setFieldValue("trackAlways", value.target.checked);
                                                            }}
                                                            className="ui checkbox"
                                                        />
                                                        &nbsp; Always allow submissions &nbsp;
                                                    </label>
                                                </>
                                            ) : null}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                <Grid.Row>
                                    {(trackAlways === false && autoSubmit === false) || (trackAlways === undefined && autoSubmit === undefined) ? (
                                        <>
                                            <Grid.Column width={3}>
                                                <label>
                                                    <b>From:</b>
                                                </label>
                                                <MySelectInput name="fromoDate" options={days} onChange={(field, value) => setFieldValue("fromoDate", value.value)} placeholder="From day of month" />
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <label>
                                                    <b>To:</b>
                                                </label>
                                                <MySelectInput name="toDate" options={days} onChange={(field, value) => setFieldValue("toDate", value.value)} placeholder="To day of month" />
                                            </Grid.Column>
                                        </>
                                    ) : null}
                                </Grid.Row>
                            </Grid>

                            <Divider />

                            <Button disabled={!dirty || isSubmitting} loading={isSubmitting} size="large" positive content={companyId.length > 1 ? "Update Company" : "Create Company"} />
                            <Button size="large" as={Link} to={`/super/companies/`}>
                                Cancel
                            </Button>
                        </form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}
