import { SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";
import { APP_LOADED } from "../../app/async/asyncReducer";
import firebase from "../../app/config/firebase";
import { getUserProfile, dataFromSnapshot } from "../../app/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../profiles/profileActions";
import { toast } from "react-toastify";

export function signInUser(user) {
    return {
        type: SIGN_IN_USER,
        payload: user,
    };
}

export function verifyAuth() {
    return function (dispatch) {
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(signInUser(user));
                const profileRef = getUserProfile(user.uid);
                profileRef.onSnapshot((snapshot) => {
                    dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
                    dispatch({ type: APP_LOADED });
                });
            } else {
                dispatch(signOutUser());
                dispatch({ type: APP_LOADED });
            }
        });
    };
}

export function signOutUser() {
    return {
        type: SIGN_OUT_USER,
    };
}

export async function registerClientUser(values, client) {
    const firestore = firebase.firestore();

    try {
        let config = {
            apiKey: "AIzaSyClU8nmq7vaVG8PKhzW-zuTbquvQc7ns9U",
            authDomain: "igo-travel-leads-f4ef5.firebaseapp.com",
            databaseURL: "https://igo-travel-leads-f4ef5.firebaseio.com",
        };

        var secondaryApp = firebase.initializeApp(config, "Secondary");

        secondaryApp
            .auth()
            .createUserWithEmailAndPassword(client.client_email, values.password)
            .then(function (firebaseUser) {
                let updatedUser = {
                    displayName: client.client_name,
                    email: client.client_email,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    clientId: client.id,
                    id: firebaseUser.user.uid,
                };

                firestore
                    .collection("users")
                    .doc(updatedUser.id)
                    .set(updatedUser, { merge: true })
                    .then(function () {
                        //Link user to client
                        firestore
                            .collection("clients")
                            .doc(client.id)
                            .set({ appUserUid: firebaseUser.user.uid, appPassword: values.password }, { merge: true })
                            .then(function () {
                                secondaryApp.auth().signOut();
                            });
                    });
            });

        toast.success("User created");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function updatePassword(creds) {
    const user = firebase.auth().currentUser;
    try {
        await user.updatePassword(creds.newPassword1);
        toast.success("Your password has been updated");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function resetPassword(creds) {
    try {
        await firebase.auth().sendPasswordResetEmail(creds);

        toast.success("A password reset email has been sent, check your inbox!");
    } catch (e) {
        console.log(e);
        toast.error(e.message);
    }
}
