import React from "react";
import format from "date-fns/format";
import { Table } from "semantic-ui-react";

export default function LeaveLeftTbl(values) {
    let profileClient = values["profileClient"];

    if (!values.id) {
        profileClient = values.profileClient;
    }

    let layout = "vertical";
    if (values.layout) {
        layout = values.layout;
    }

    let yearNow = format(Date.now(), "yyyy");
    let monthNow = format(Date.now(), "M");

    // if (values.year !== undefined) {
    //     yearNow = values.year;
    // }

    // let curFinancialYear = parseInt(profileClient.companyYearStart);
    let nonGrowingYear = values.leaveYearStart;

    // if (monthNow < curFinancialYear) {
    //     nonGrowingYear = parseInt(yearNow) - 1;
    // }

    if (!profileClient["leaveMonths"]) {
        nonGrowingYear = parseInt(yearNow);
    }

    //let used = parseFloat(profileClient['leaveMonths'][yearNow][monthNow]['used']);

    if (profileClient && profileClient["leaveMonths"] && profileClient["leaveMonths"][yearNow] && profileClient["leaveMonths"][yearNow][monthNow]) {
        var left = parseFloat(profileClient["leaveMonths"][yearNow][monthNow]["left"]);
        var total = parseFloat(profileClient["leaveMonths"][yearNow][monthNow]["total"]);
        var used = parseFloat(profileClient["leaveMonths"][yearNow][monthNow]["runningTotal"]);
    } else {
        var left = 0;
        var total = 0;
        var used = 0;
    }

    let showAccum = false;
    let accumUsed = 0;
    let accumTotal = 0;
    let leftAccum = 0;
    let accumForfeit = "";
    let showAccumExpired = false;

    nonGrowingYear = parseInt(yearNow);

    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] > 0
    ) {
        accumUsed = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed"]);
        accumTotal = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"]);
        leftAccum = accumTotal - accumUsed;
        showAccum = true;

        if (profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumForfeitDate"]) {
            accumForfeit = profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumForfeitDate"];

            if (new Date() > accumForfeit.toDate()) {
                showAccumExpired = true;
            }
        }
    }

    let showAccum2 = false;
    let accumUsed2 = 0;
    let accumTotal2 = 0;
    let leftAccum2 = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"] > 0
    ) {
        accumUsed2 = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed2"]);
        accumTotal2 = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"]);
        leftAccum2 = accumTotal2 - accumUsed2;
        showAccum2 = true;
    }

    let showSick = false;
    let leftSick = 0;
    let totalSick = 0;
    let usedSick = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"]["sick"] &&
        profileClient["leaveMonths"]["sick"]["sickTotal"] &&
        profileClient["leaveMonths"]["sick"]["sickTotal"] > 0
    ) {
        usedSick = parseFloat(profileClient["leaveMonths"]["sick"]["sickUsed"]);
        totalSick = parseFloat(profileClient["leaveMonths"]["sick"]["sickTotal"]);
        leftSick = totalSick - usedSick;
        showSick = true;
    }

    let showFamily = false;
    let usedFamily = 0;
    let totalFamily = 0;
    let leftFamily = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"] > 0
    ) {
        usedFamily = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyUsed"]);
        totalFamily = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"]);
        leftFamily = totalFamily - usedFamily;
        showFamily = true;
    }

    let showMaternity = false;
    let usedMaternity = 0;
    let totalMaternity = 0;
    let leftMaternity = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"] > 0
    ) {
        usedMaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityUsed"]);
        totalMaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"]);
        leftMaternity = totalMaternity - usedMaternity;
        showMaternity = true;
    }

    let showPaternity = false;
    let usedPaternity = 0;
    let totalPaternity = 0;
    let leftPaternity = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"] > 0
    ) {
        usedPaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityUsed"]);
        totalPaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"]);
        leftPaternity = totalPaternity - usedPaternity;
        showPaternity = true;
    }

    let showIOD = false;
    let usedIOD = 0;
    let totalIOD = 0;
    let leftIOD = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"] > 0
    ) {
        usedIOD = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODUsed"]);
        totalIOD = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"]);
        leftIOD = totalIOD - usedIOD;
        showIOD = true;
    }

    let showParental = false;
    let usedParental = 0;
    let totalParental = 0;
    let leftParental = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalTotal"] > 0
    ) {
        usedParental = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalUsed"]);
        totalParental = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["parentalTotal"]);
        leftParental = totalParental - usedParental;
        showParental = true;
    }

    let showMoving = false;
    let usedMoving = 0;
    let totalMoving = 0;
    let leftMoving = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"] > 0
    ) {
        usedMoving = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingUsed"]);
        totalMoving = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"]);
        leftMoving = totalMoving - usedMoving;
        showMoving = true;
    }

    let showCSR = false;
    let usedCSR = 0;
    let totalCSR = 0;
    let leftCSR = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"] > 0
    ) {
        usedCSR = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrUsed"]);
        totalCSR = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"]);
        leftCSR = totalCSR - usedCSR;
        showCSR = true;
    }

    let showReligious = false;
    let usedReligious = 0;
    let totalReligious = 0;
    let leftReligious = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"] > 0
    ) {
        usedReligious = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousUsed"]);
        totalReligious = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"]);
        leftReligious = totalReligious - usedReligious;
        showReligious = true;
    }

    let showStudy = false;
    let usedStudy = 0;
    let totalStudy = 0;
    let leftStudy = 0;
    if (
        profileClient &&
        profileClient["leaveMonths"] &&
        profileClient["leaveMonths"][nonGrowingYear] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"] &&
        profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"] > 0
    ) {
        usedStudy = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyUsed"]);
        totalStudy = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"]);
        leftStudy = totalStudy - usedStudy;
        showStudy = true;
    }

    let usedSpecial = 0;

    return (
        <div>
            {layout && layout === "horizontal" ? (
                <Table basic="very" celled collapsing unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Annual</Table.HeaderCell>
                            {showAccum && showAccumExpired === false && <Table.HeaderCell>Accumulative</Table.HeaderCell>}

                            {showAccum && !showAccum2 && showAccumExpired === true && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                            {showAccum && showAccum2 && showAccumExpired === true && <Table.HeaderCell>Expired Accumulative</Table.HeaderCell>}

                            {showAccum2 && showAccumExpired === true && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                            {showSick && <Table.HeaderCell>Sick</Table.HeaderCell>}
                            {showFamily && <Table.HeaderCell>Family</Table.HeaderCell>}
                            {showMaternity && <Table.HeaderCell>Maternity</Table.HeaderCell>}
                            {showPaternity && <Table.HeaderCell>Paternity</Table.HeaderCell>}
                            {showIOD && <Table.HeaderCell>IOD</Table.HeaderCell>}
                            {showParental && <Table.HeaderCell>Parental</Table.HeaderCell>}
                            {showMoving && <Table.HeaderCell>Moving</Table.HeaderCell>}
                            {showCSR && <Table.HeaderCell>CSR</Table.HeaderCell>}
                            {showReligious && <Table.HeaderCell>Religious</Table.HeaderCell>}
                            {showStudy && <Table.HeaderCell>Study</Table.HeaderCell>}
                            <Table.HeaderCell>Special</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Days Used</Table.Cell>
                            <Table.Cell>{used}</Table.Cell>
                            {showAccum && <Table.Cell>{accumUsed}</Table.Cell>}
                            {showAccum2 && showAccumExpired === true && <Table.Cell>{accumUsed2}</Table.Cell>}
                            {showSick && <Table.Cell>{usedSick}</Table.Cell>}
                            {showFamily && <Table.Cell>{usedFamily}</Table.Cell>}
                            {showMaternity && <Table.Cell>{usedMaternity}</Table.Cell>}
                            {showIOD && <Table.Cell>{usedIOD}</Table.Cell>}
                            {showParental && <Table.Cell>{usedParental}</Table.Cell>}
                            {showMoving && <Table.Cell>{usedMoving}</Table.Cell>}
                            {showCSR && <Table.Cell>{usedCSR}</Table.Cell>}
                            {showReligious && <Table.Cell>{usedReligious}</Table.Cell>}
                            {showStudy && <Table.Cell>{usedStudy}</Table.Cell>}
                            <Table.Cell>{usedSpecial}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Days Left</Table.Cell>
                            <Table.Cell>{left}</Table.Cell>
                            {showAccum && <Table.Cell>{leftAccum}</Table.Cell>}
                            {showAccum2 && showAccumExpired === true && <Table.Cell>{leftAccum2}</Table.Cell>}
                            {showSick && <Table.Cell>{leftSick}</Table.Cell>}
                            {showFamily && <Table.Cell>{leftFamily}</Table.Cell>}
                            {showMaternity && <Table.Cell>{leftMaternity}</Table.Cell>}
                            {showPaternity && <Table.Cell>{leftPaternity}</Table.Cell>}
                            {showIOD && <Table.Cell>{leftIOD}</Table.Cell>}
                            {showParental && <Table.Cell>{leftParental}</Table.Cell>}
                            {showMoving && <Table.Cell>{leftMoving}</Table.Cell>}
                            {showCSR && <Table.Cell>{leftCSR}</Table.Cell>}
                            {showReligious && <Table.Cell>{leftReligious}</Table.Cell>}
                            {showStudy && <Table.Cell>{leftStudy}</Table.Cell>}
                            <Table.Cell>N/A</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Days Total</Table.Cell>
                            <Table.Cell>{total}</Table.Cell>
                            {showAccum && <Table.Cell>{accumTotal}</Table.Cell>}
                            {showAccum2 && showAccumExpired === true && <Table.Cell>{accumTotal2}</Table.Cell>}
                            {showSick && <Table.Cell>{totalSick}</Table.Cell>}
                            {showFamily && <Table.Cell>{totalFamily}</Table.Cell>}
                            {showMaternity && <Table.Cell>{totalMaternity}</Table.Cell>}
                            {showPaternity && <Table.Cell>{totalPaternity}</Table.Cell>}
                            {showIOD && <Table.Cell>{totalIOD}</Table.Cell>}
                            {showParental && <Table.Cell>{totalParental}</Table.Cell>}
                            {showMoving && <Table.Cell>{totalMoving}</Table.Cell>}
                            {showCSR && <Table.Cell>{totalCSR}</Table.Cell>}
                            {showReligious && <Table.Cell>{totalReligious}</Table.Cell>}
                            {showStudy && <Table.Cell>{totalStudy}</Table.Cell>}
                            <Table.Cell>N/A</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ) : (
                <Table basic="very" celled collapsing unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Days Used</Table.HeaderCell>
                            <Table.HeaderCell>Days Left</Table.HeaderCell>
                            <Table.HeaderCell>Days Total</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Annual</Table.Cell>
                            <Table.Cell>{used}</Table.Cell>
                            <Table.Cell>{left}</Table.Cell>
                            <Table.Cell>{total}</Table.Cell>
                        </Table.Row>

                        {showAccum && (
                            <Table.Row>
                                <Table.Cell>{showAccumExpired === true && "Expired "}Accumulative</Table.Cell>
                                <Table.Cell>{accumUsed}</Table.Cell>
                                <Table.Cell>{leftAccum}</Table.Cell>
                                <Table.Cell>{accumTotal}</Table.Cell>
                            </Table.Row>
                        )}

                        {showAccum2 && showAccumExpired === true && (
                            <Table.Row>
                                <Table.Cell>Accumulative</Table.Cell>
                                <Table.Cell>{accumUsed2}</Table.Cell>
                                <Table.Cell>{leftAccum2}</Table.Cell>
                                <Table.Cell>{accumTotal2}</Table.Cell>
                            </Table.Row>
                        )}

                        {showSick && (
                            <Table.Row>
                                <Table.Cell>Sick</Table.Cell>
                                <Table.Cell>{usedSick}</Table.Cell>
                                <Table.Cell>{leftSick}</Table.Cell>
                                <Table.Cell>{totalSick}</Table.Cell>
                            </Table.Row>
                        )}

                        {showFamily && (
                            <Table.Row>
                                <Table.Cell>Family</Table.Cell>
                                <Table.Cell>{usedFamily}</Table.Cell>
                                <Table.Cell>{leftFamily}</Table.Cell>
                                <Table.Cell>{totalFamily}</Table.Cell>
                            </Table.Row>
                        )}

                        {showMaternity && (
                            <Table.Row>
                                <Table.Cell>Maternity</Table.Cell>
                                <Table.Cell>{usedMaternity}</Table.Cell>
                                <Table.Cell>{leftMaternity}</Table.Cell>
                                <Table.Cell>{totalMaternity}</Table.Cell>
                            </Table.Row>
                        )}

                        {showPaternity && (
                            <Table.Row>
                                <Table.Cell>Paternity</Table.Cell>
                                <Table.Cell>{usedPaternity}</Table.Cell>
                                <Table.Cell>{leftPaternity}</Table.Cell>
                                <Table.Cell>{totalPaternity}</Table.Cell>
                            </Table.Row>
                        )}

                        {showIOD && (
                            <Table.Row>
                                <Table.Cell>IOD</Table.Cell>
                                <Table.Cell>{usedIOD}</Table.Cell>
                                <Table.Cell>{leftIOD}</Table.Cell>
                                <Table.Cell>{totalIOD}</Table.Cell>
                            </Table.Row>
                        )}

                        {showParental && (
                            <Table.Row>
                                <Table.Cell>Parental</Table.Cell>
                                <Table.Cell>{usedParental}</Table.Cell>
                                <Table.Cell>{leftParental}</Table.Cell>
                                <Table.Cell>{totalParental}</Table.Cell>
                            </Table.Row>
                        )}

                        {showMoving && (
                            <Table.Row>
                                <Table.Cell>Moving</Table.Cell>
                                <Table.Cell>{usedMoving}</Table.Cell>
                                <Table.Cell>{leftMoving}</Table.Cell>
                                <Table.Cell>{totalMoving}</Table.Cell>
                            </Table.Row>
                        )}

                        {showCSR && (
                            <Table.Row>
                                <Table.Cell>CSR</Table.Cell>
                                <Table.Cell>{usedCSR}</Table.Cell>
                                <Table.Cell>{leftCSR}</Table.Cell>
                                <Table.Cell>{totalCSR}</Table.Cell>
                            </Table.Row>
                        )}

                        {showReligious && (
                            <Table.Row>
                                <Table.Cell>Religious</Table.Cell>
                                <Table.Cell>{usedReligious}</Table.Cell>
                                <Table.Cell>{leftReligious}</Table.Cell>
                                <Table.Cell>{totalReligious}</Table.Cell>
                            </Table.Row>
                        )}

                        {showStudy && (
                            <Table.Row>
                                <Table.Cell>Study</Table.Cell>
                                <Table.Cell>{usedStudy}</Table.Cell>
                                <Table.Cell>{leftStudy}</Table.Cell>
                                <Table.Cell>{totalStudy}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
}
