import React, { useState, useEffect } from "react";
import { Container, Segment, Grid, Table, Button, Form } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getProfile, getAllProject, getAllUsers, getTimeTrackRowList } from "../staffActions";
import axios from "axios";
import { useSelector } from "react-redux";
import apiURL from "../../../app/config/apiURL";

export default function TimeReportPage(props) {
    const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    const [profile, setProfile] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [timeList, setTimeList] = useState([]);
    const [userChosen, setUserChosen] = useState("None");
    const [projectChosen, setProjectChosen] = useState("0");
    const [hoursRequiredTotal, setHoursRequiredTotal] = useState("0");
    const [hoursWorkedTotal, setHoursWorkedTotal] = useState("0");
    const { currentUserProfile } = useSelector((state) => state.profile);
    const [timeRow, setTimeRow] = useState([]);

    useEffect(() => {
        getProfile(userId).then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getAllProject().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                const projectOptionsData = [{ key: "All", text: "All", value: "0" }];

                Object.keys(list).forEach(function (key) {
                    projectOptionsData.push({ key: list[key].projectName, text: list[key].projectName, value: list[key].id });
                });

                setProjectOptions(projectOptionsData);
            },
            (err) => {
                console.log(err);
            }
        );
        getAllUsers().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                const userOptionsData = [];

                Object.keys(list).forEach(function (key) {
                    userOptionsData.push({ key: list[key].is, text: list[key].displayName, value: list[key].id });
                });

                setUserOptions(userOptionsData);
            },
            (err) => {
                console.log(err);
            }
        );
        getTimeList(userChosen, fromDate, toDate, projectChosen);

        async function getTimeList(userChosen, fromDate, toDate) {
            const response = await axios.post(apiURL + "/api/get_time_report_monthly", {
                userChosen: userChosen === "None" ? currentUserProfile.id : userChosen,
                fromDate: fromDate && fromDate !== "" ? format(fromDate, "yyyy-MM-dd") : "None",
                toDate: toDate && toDate !== "" ? format(toDate, "yyyy-MM-dd") : "None",
                projectChosen: projectChosen,
            });

            setTimeRow(response.data);

            let reportTime = response.data;
            const key = "date";

            const arrayUniqueByKey = [...new Map(reportTime.map((item) => [item[key], item])).values()];

            setHoursRequiredTotal(
                arrayUniqueByKey && arrayUniqueByKey.length > 0 && arrayUniqueByKey.map((item) => (item.hoursRequired !== "" ? parseFloat(item.hoursRequired) : 0)).reduce((prev, next) => prev + next)
            );
        }
        // getTimeTrackRowList(userChosen, fromDate, toDate, projectChosen).then(
        //     (res) => {
        //         let list = [];
        //         res.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
        //                 list.push(appObj);
        //             }
        //         });

        //         let updatedTimeList = [];

        //         if (projectChosen !== "0") {
        //             Object.keys(list).forEach(function (key) {
        //                 if (list[key].projectId == projectChosen) {
        //                     updatedTimeList.push(list[key]);
        //                 }
        //             });
        //         } else {
        //             updatedTimeList = list;
        //         }

        //         let hoursRequiredTotalData = 0;
        //         let hoursWorkedTotalData = 0;

        //         Object.keys(list).forEach(function (key) {
        //             hoursRequiredTotalData += parseInt(list[key].hoursRequired);
        //             hoursWorkedTotalData += parseInt(list[key].timeWorked);
        //         });

        //         setHoursRequiredTotal(hoursRequiredTotalData);
        //         setHoursWorkedTotal(hoursWorkedTotalData);

        //         setTimeList(updatedTimeList);
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );
    }, [fromDate, toDate, projectChosen, userChosen]);

    function updateFrom(value) {
        setFromDate(value);
    }
    function updateTo(value) {
        setToDate(value);
    }
    function updateUser(value) {
        setUserChosen(value);
    }
    function updateProject(value) {
        setProjectChosen(value);
    }

    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={{ email: "" }}
                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid stackable>
                                <Grid.Row columns={3}>
                                    <Grid.Column width={3}>
                                        <Form.Field>
                                            <label>From</label>
                                            <DatePicker
                                                name="fromDate"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.fromDate}
                                                selected={fromDate}
                                                onChange={(value) => {
                                                    updateFrom(value);
                                                    setFieldValue("fromDate", value);
                                                }}
                                                disabledKeyboardNavigation
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Form.Field>
                                            <label>To</label>
                                            <DatePicker
                                                name="toDate"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.toDate}
                                                selected={toDate}
                                                onChange={(value) => {
                                                    updateTo(value);
                                                    setFieldValue("toDate", value);
                                                }}
                                                disabledKeyboardNavigation
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={4}>
                                        <Form.Field>
                                            <label>Project</label>
                                            <MySelectInput
                                                name="project"
                                                value={values.project}
                                                options={projectOptions}
                                                onChange={(field, value) => {
                                                    updateProject(value.value);
                                                    setFieldValue("project", value.value);
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Form.Field>
                                            <label>User</label>
                                            <MySelectInput
                                                name="user"
                                                options={userOptions}
                                                value={values.user}
                                                placeholder={profile.displayName}
                                                onChange={(field, value) => {
                                                    updateUser(value.value);
                                                    setFieldValue("user", value.value);
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column textAlign="right" width={3}>
                                        <Button color="grey" as={Link} to={`/clients/time/${userId}`}>
                                            BACK
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Segment>
            <Table striped celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Day</Table.HeaderCell>
                        <Table.HeaderCell>Total Required</Table.HeaderCell>
                        <Table.HeaderCell>Total Worked</Table.HeaderCell>
                        <Table.HeaderCell>Project</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {timeRow && timeRow.length > 0 && (
                    <Table.Body>
                        {timeRow.map((row, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>{format(new Date(row.date), "dd MMMM yyyy")}</Table.Cell>

                                <Table.Cell>{row.hoursRequired}</Table.Cell>

                                <Table.Cell>{row.timeWorked}</Table.Cell>

                                {projectOptions && projectOptions.length > 0 && projectOptions.map((project) => <>{row.projectId == project["value"] && <Table.Cell>{project["key"]}</Table.Cell>}</>)}
                            </Table.Row>
                        ))}
                    </Table.Body>
                )}

                <Table.Footer>
                    <Table.Row>
                        <Table.Cell>Total</Table.Cell>

                        <Table.Cell>{hoursRequiredTotal}</Table.Cell>

                        <Table.Cell>
                            {timeRow && timeRow.length > 0 && timeRow.map((item) => (item.timeWorked !== "" ? parseFloat(item.timeWorked) : 0)).reduce((prev, next) => prev + next)}
                        </Table.Cell>

                        <Table.Cell>-</Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Container>
    );
}
