import React from "react";
import { Modal, Button, Image, Divider, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import TimeAppForm from "../manager/ManagerTimeList/TimeAppForm";
import { Formik } from "formik";
import MyRadioButton from "../../app/common/form/MyRadioButton";
import MyTextArea from "../../app/common/form/MyTextArea";
import firebase from "../../app/config/firebase";

const firestore = firebase.firestore();

export default function RemoteWorkModal(props) {
    const dispatch = useDispatch();
    let day = props.days;

    let header_cls = "orange_header";

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>
                <Image style={{ zIndex: 2, maxHeight: "130px", maxWidth: "130px", width: "auto" }} avatar spaced="right" size="small" src={"/assets/user.png"} />

                <div className={header_cls}>
                    <div className="ui right aligned header" style={{ marginRight: 40, marginTop: 5, color: "white" }}>
                        <h3>{day.status}</h3>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                        <h2 className="ui center aligned header">APPROVE/DENY REMOTE WORKING DAYS</h2>
                    </div>

                    <Divider />

                    <Formik
                        initialValues={{ denyReason: "" }}
                        validate={(values) => {
                            const error = {};
                            return error;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            await firestore.collection("remoteWork").doc(day.id).update(values);
                            dispatch(closeModal());
                            setSubmitting(false);
                            // window.location.reload();
                        }}
                    >
                        {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Form.Group inline>
                                    <label>Status:</label>
                                    <MyRadioButton name="status" type="radio" value="Approved" label="" labelcb="Approve" />

                                    <MyRadioButton name="status" type="radio" value="Denied" label="" labelcb="Deny" />
                                </Form.Group>

                                <p>If denied, please provide a reason:</p>

                                <MyTextArea name="denyReason" />

                                <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue">
                                    SUBMIT
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
