import React, { useState } from "react";
import { Modal, Button, Image, Divider, Form, Grid, Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MySelectInput from "../../app/common/form/MySelectInput";
import { multiExpenseApprove } from "../manager/managerActions";
import format from "date-fns/format";
import MyRadioButton from "../../app/common/form/MyRadioButton";

export default function MultiExpenseAppModal(props) {
    let { travel, fuel, nonTax, tax, profileClient } = props;
    const dispatch = useDispatch();
    const { currentUserProfile } = useSelector((state) => state.profile);

    const [allTravel, setAlltravel] = useState(false);
    const [allFuel, setAllFuel] = useState(false);
    const [allNontax, setAllNontax] = useState(false);
    const [allTax, setAllTax] = useState(false);

    let profile = {};

    if (profileClient.hasOwnProperty("id")) {
        profile = profileClient;
    } else {
        profile = currentUserProfile;
    }

    function changeTravelAll(value) {
        setAlltravel(value);
    }
    function changeFuelAll(value) {
        setAllFuel(value);
    }
    function changeNonTaxAll(value) {
        setAllNontax(value);
    }
    function changeTaxAll(value) {
        setAllTax(value);
    }

    const sumTotal = (arr) => arr.reduce((sum, { amount }) => sum + parseFloat(amount), 0);

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>
                <Image style={{ zIndex: 2, maxHeight: "130px", maxWidth: "130px", width: "auto" }} avatar spaced="right" size="small" src={profile.photoURL || "/assets/user.png"} />

                <div className="orange_header">
                    <div className="ui right aligned header" style={{ marginRight: 40, marginTop: 5, color: "white" }}>
                        <h3>Pending</h3>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                        <h2 className="ui center aligned header">APPROVE/DENY EXPENSES</h2>
                    </div>

                    <Divider />

                    <Formik
                        initialValues={{ email: "", password: "" }}
                        enableReinitialize
                        validate={(values) => {
                            const errors = {};

                            if (!values.status) {
                                errors.status = "Required";
                            }

                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            await multiExpenseApprove(values);
                            setSubmitting(false);
                            dispatch(closeModal());
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <br />
                                <Grid inline>
                                    <label>Status:</label>
                                    <MyRadioButton name="status" type="radio" value="Approved" label="" labelcb="Approve" />

                                    <MyRadioButton name="status" type="radio" value="Denied" label="" labelcb="Deny" />
                                    <span style={{ color: "red" }}>{errors.status && errors.status}</span>
                                </Grid>
                                <br />
                                <Divider />
                                {travel && travel.length > 0 && (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                                <Table.Cell colspan="3" style={{ textAlign: "center" }}>
                                                    <b>TRAVEL</b>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {travel &&
                                                travel.length > 0 &&
                                                allTravel === false &&
                                                travel.map((expenseTravel, index) => (
                                                    <>
                                                        <Table.Row>
                                                            <Table.Cell style={{ width: "50px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={`check.${expenseTravel.id}`}
                                                                    value="true"
                                                                    className="ui checkbox"
                                                                    onChange={(value) => {
                                                                        setFieldValue(`check.${expenseTravel.id}`, value.target.checked);
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {expenseTravel.dateTimeFrom && expenseTravel.dateTimeFrom !== undefined
                                                                    ? format(expenseTravel.dateTimeFrom.toDate(), "dd MMM yyyy HH:mm") + " - "
                                                                    : null}
                                                                {expenseTravel.dateTimeTo && expenseTravel.dateTimeTo !== undefined
                                                                    ? format(expenseTravel.dateTimeTo.toDate(), "dd MMM yyyy HH:mm")
                                                                    : null}
                                                                <br />
                                                                {expenseTravel.locationFrom && expenseTravel.locationFrom !== undefined ? expenseTravel.locationFrom + " - " : null}
                                                                {expenseTravel.locationTo && expenseTravel.locationTo !== undefined ? expenseTravel.locationTo : null}
                                                                <br />
                                                                {expenseTravel.description && expenseTravel.description !== undefined ? expenseTravel.description : null}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: "200px" }}>
                                                                R {expenseTravel.amount && expenseTravel.amount !== undefined ? expenseTravel.amount : null}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                ))}
                                            <Table.Row>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    <input
                                                        type="checkbox"
                                                        name="alltravel"
                                                        value="true"
                                                        className="ui checkbox"
                                                        onChange={(value) => {
                                                            {
                                                                changeTravelAll(value.target.checked);
                                                                setFieldValue("alltravel", value.target.checked);
                                                                Object.keys(travel).forEach(function (key) {
                                                                    setFieldValue(`check.${travel[key].id}`, value.target.checked);
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <b>All Travel expenses</b>
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>
                                                    <b>TOTAL: </b> R {sumTotal(travel)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                )}

                                {fuel && fuel.length > 0 && (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                                <Table.Cell colspan="3" style={{ textAlign: "center" }}>
                                                    <b>FUEL</b>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {fuel &&
                                                fuel.length > 0 &&
                                                allFuel === false &&
                                                fuel.map((expenseFuel, index) => (
                                                    <>
                                                        <Table.Row>
                                                            <Table.Cell style={{ width: "50px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={`check.${expenseFuel.id}`}
                                                                    value="true"
                                                                    className="ui checkbox"
                                                                    onChange={(value) => {
                                                                        setFieldValue(`check.${expenseFuel.id}`, value.target.checked);
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {expenseFuel.dateTimeFrom && expenseFuel.dateTimeFrom !== undefined
                                                                    ? format(expenseFuel.dateTimeFrom.toDate(), "dd MMM yyyy HH:mm") + " - "
                                                                    : null}
                                                                {expenseFuel.dateTimeTo && expenseFuel.dateTimeTo !== undefined ? format(expenseFuel.dateTimeTo.toDate(), "dd MMM yyyy HH:mm") : null}
                                                                <br />
                                                                {expenseFuel.locationFrom && expenseFuel.locationFrom !== undefined ? expenseFuel.locationFrom + " - " : null}
                                                                {expenseFuel.locationTo && expenseFuel.locationTo !== undefined ? expenseFuel.locationTo : null}
                                                                <br />
                                                                {expenseFuel.description && expenseFuel.description !== undefined ? expenseFuel.description : null}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: "200px" }}>R {expenseFuel.amount && expenseFuel.amount !== undefined ? expenseFuel.amount : null}</Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                ))}
                                            <Table.Row>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    <input
                                                        type="checkbox"
                                                        name="allfuel"
                                                        value="true"
                                                        className="ui checkbox"
                                                        onChange={(value) => {
                                                            {
                                                                changeFuelAll(value.target.checked);
                                                                setFieldValue("allfuel", value.target.checked);
                                                                Object.keys(fuel).forEach(function (key) {
                                                                    setFieldValue(`check.${fuel[key].id}`, value.target.checked);
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <b>All Fuel expenses</b>
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>
                                                    <b>TOTAL: </b> R {sumTotal(fuel)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                )}

                                {nonTax && nonTax.length > 0 && (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                                <Table.Cell colspan="3" style={{ textAlign: "center" }}>
                                                    <b>REIMBURSEMENT NON TAXABLE</b>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {nonTax &&
                                                nonTax.length > 0 &&
                                                allNontax == false &&
                                                nonTax.map((expenseNonTax, index) => (
                                                    <>
                                                        <Table.Row>
                                                            <Table.Cell style={{ width: "50px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={`check.${expenseNonTax.id}`}
                                                                    value="true"
                                                                    className="ui checkbox"
                                                                    onChange={(value) => {
                                                                        setFieldValue(`check.${expenseNonTax.id}`, value.target.checked);
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {expenseNonTax.dateTimeFrom && expenseNonTax.dateTimeFrom !== undefined
                                                                    ? format(expenseNonTax.dateTimeFrom.toDate(), "dd MMM yyyy HH:mm") + " - "
                                                                    : null}
                                                                {expenseNonTax.dateTimeTo && expenseNonTax.dateTimeTo !== undefined
                                                                    ? format(expenseNonTax.dateTimeTo.toDate(), "dd MMM yyyy HH:mm")
                                                                    : null}
                                                                <br />
                                                                {expenseNonTax.locationFrom && expenseNonTax.locationFrom !== undefined ? expenseNonTax.locationFrom + " - " : null}
                                                                {expenseNonTax.locationTo && expenseNonTax.locationTo !== undefined ? expenseNonTax.locationTo : null}
                                                                <br />
                                                                {expenseNonTax.description && expenseNonTax.description !== undefined ? expenseNonTax.description : null}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: "200px" }}>
                                                                R {expenseNonTax.amount && expenseNonTax.amount !== undefined ? expenseNonTax.amount : null}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                ))}
                                            <Table.Row>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    <input
                                                        type="checkbox"
                                                        name="allnonTax"
                                                        value="true"
                                                        className="ui checkbox"
                                                        onChange={(value) => {
                                                            {
                                                                changeNonTaxAll(value.target.checked);
                                                                setFieldValue("allnonTax", value.target.checked);
                                                                Object.keys(nonTax).forEach(function (key) {
                                                                    setFieldValue(`check.${nonTax[key].id}`, value.target.checked);
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <b>All Reimbursement non taxable expenses</b>
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>
                                                    <b>TOTAL: </b> R {sumTotal(nonTax)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                )}

                                {tax && tax.length > 0 && (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                                <Table.Cell colspan="3" style={{ textAlign: "center" }}>
                                                    <b>REIMBURSEMENT TAXABLE</b>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {tax &&
                                                tax.length > 0 &&
                                                allTax === false &&
                                                tax.map((expenseTax, index) => (
                                                    <>
                                                        <Table.Row>
                                                            <Table.Cell style={{ width: "50px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={`check.${expenseTax.id}`}
                                                                    value="true"
                                                                    className="ui checkbox"
                                                                    onChange={(value) => {
                                                                        setFieldValue(`check.${expenseTax.id}`, value.target.checked);
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {expenseTax.dateTimeFrom && expenseTax.dateTimeFrom !== undefined
                                                                    ? format(expenseTax.dateTimeFrom.toDate(), "dd MMM yyyy HH:mm") + " - "
                                                                    : null}
                                                                {expenseTax.dateTimeTo && expenseTax.dateTimeTo !== undefined ? format(expenseTax.dateTimeTo.toDate(), "dd MMM yyyy HH:mm") : null}
                                                                <br />
                                                                {expenseTax.locationFrom && expenseTax.locationFrom !== undefined ? expenseTax.locationFrom + " - " : null}
                                                                {expenseTax.locationTo && expenseTax.locationTo !== undefined ? expenseTax.locationTo : null}
                                                                <br />
                                                                {expenseTax.description && expenseTax.description !== undefined ? expenseTax.description : null}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ width: "200px" }}>R {expenseTax.amount && expenseTax.amount !== undefined ? expenseTax.amount : null}</Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                ))}
                                            <Table.Row>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    <input
                                                        type="checkbox"
                                                        name="allTax"
                                                        value="true"
                                                        className="ui checkbox"
                                                        onChange={(value) => {
                                                            {
                                                                changeTaxAll(value.target.checked);
                                                                setFieldValue("allTax", value.target.checked);
                                                                Object.keys(tax).forEach(function (key) {
                                                                    setFieldValue(`check.${tax[key].id}`, value.target.checked);
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <b>All Reimbursement non taxable expenses</b>
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>
                                                    <b>TOTAL: </b> R {sumTotal(tax)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                )}

                                <br />

                                <Button type="submit" loading={isSubmitting} disabled={isSubmitting} color="blue" size="large" fluid>
                                    Submit
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
