import React, { useState, useEffect } from "react";
import { Container, Table, Button, Form, Segment } from "semantic-ui-react";
import { addBlockedDate, deleteBlockedDate, getBlockedDates } from "../superActions";
import format from "date-fns/format";
import { Formik } from "formik";
import MyDateInput from "../../../app/common/form/MyDateInput";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function BlockedDatesPage(props) {
    const [blockedDates, setBlockedDates] = useState([]);
    useEffect(() => {
        getBlockedDates().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                setBlockedDates(list);
            },
            (err) => {
                console.log(err);
            }
        );
    }, [blockedDates]);
    return (
        <>
            <Container>
                <Segment>
                    <Formik
                        initialValues={{ description: "" }}
                        validate={(values) => {
                            const error = {};
                            return error;
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            addBlockedDate(values);
                            resetForm();
                            setSubmitting(false);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Date</label>
                                        <MyDateInput name="blockDate" dateFormat="yyyy-MM-dd" showYearDropdown={true} showMonthDropdown={true} dropdownMode="select" />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Description</label>
                                        <MyTextInput name="description" type="text" placeholder="Enter a description for this public holiday" />
                                    </Form.Field>

                                    <Form.Field style={{ paddingTop: 24 }}>
                                        <Button disabled={!dirty || isSubmitting} loading={isSubmitting} fluid color="blue">
                                            ADD DATE
                                        </Button>
                                    </Form.Field>
                                </Form.Group>
                            </form>
                        )}
                    </Formik>
                </Segment>

                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={3}>Date</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {blockedDates &&
                            blockedDates.length > 0 &&
                            blockedDates.map((bd) => (
                                <Table.Row key={bd.id}>
                                    <Table.Cell>{format(bd.blockDate.toDate(), "d MMMM yyyy")}</Table.Cell>
                                    <Table.Cell>{bd.description}</Table.Cell>

                                    <Table.Cell>
                                        <Button color="blue" onClick={() => deleteBlockedDate(bd)}>
                                            Delete Date
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Container>
        </>
    );
}
