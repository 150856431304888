import React, { useEffect, useState, useCallback } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserList, getAuthUser, getCompanies, getLeaveAction, getLeaveInfo } from "../reportsAction";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { Formik } from "formik";
import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
import { date } from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { app } from "firebase";

const firestore = firebase.firestore();

export default function ReportUserLeave(props) {
    const [users, setUsers] = useState([]);
    const [companyIdFilter, setCompanyIdFilter] = useState("0");
    const [profile, setProfile] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [profile_company, setProfile_company] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [leaveDays, setLeaveDays] = useState([]);
    let leave = [];

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

    const { currentUserProfile } = useSelector((state) => state.profile);

    const [yearNow, setYearNow] = useState([]);
    const [monthNow, setMonthNow] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    setProfile_company(res.data().companyId);

                    getCompanies().then(
                        (resData) => {
                            let list = [];
                            resData.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    if (appObj.hasLeave == true) {
                                        list.push(appObj);
                                    }
                                }
                            });

                            let companiesList = [
                                {
                                    key: "",
                                    text: "Choose a Company",
                                    value: "",
                                },
                            ];

                            Object.keys(list).forEach(function (key) {
                                if (
                                    res.data().isSuper === true ||
                                    (res.data().isAdmin === true && res.data().companyId == list[key].id) ||
                                    (res.data().isHR === true && res.data().companyId == list[key].id) ||
                                    (res.data().isLineManager === true && res.data().companyId == list[key].id)
                                ) {
                                    companiesList.push({
                                        key: list[key].id,
                                        text: list[key].companyName,
                                        value: list[key].id,
                                    });
                                }
                            });

                            setCompaniesList(companiesList);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, [companyIdFilter, yearNow, monthNow, profile_company, endDate, startDate]);

    const updateUserData = useCallback((value) => {
        setCompanyIdFilter(value);
    }, []);

    async function changeUserUid(value, startDate, endDate) {
        setSelectedUser(value);

        // console.log(value);

        await firestore
            .collection("leave")
            .where("userUid", "==", value)
            .orderBy("annualFrom")
            .get()
            .then((res) => {
                let list = [];
                res.forEach(async (doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        if (appObj.createDate.toDate() >= startDate && appObj.createDate.toDate() <= endDate) {
                            list.push(appObj);
                        }
                    }
                });

                setLeaveDays(list);
            });
    }

    async function changeCompanyId(value) {
        updateUserData(value);

        await firestore
            .collection("users")
            .where("companyId", "==", value)
            .where("isTerminated", "==", false)
            .get()
            .then(
                (res) => {
                    let list = [];
                    res.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), id: doc.id };
                            list.push(appObj);
                        }
                    });

                    let usersListNew = [
                        {
                            key: "",
                            text: "Choose a User",
                            value: "",
                        },
                    ];

                    Object.keys(list).forEach(function (key) {
                        if (
                            currentUserProfile.isSuper === true ||
                            currentUserProfile.isAdmin === true ||
                            currentUserProfile.isHR === true ||
                            (currentUserProfile.isLineManager === true && currentUserProfile.departments.includes(list[key].departmentId))
                        )
                            usersListNew.push({
                                key: list[key].id,
                                text: list[key].displayName,
                                value: list[key].id,
                            });
                    });

                    setUserList(usersListNew);
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    function filterData(value) {
        setYearNow(format(new Date(value), "yyyy"));
        setMonthNow(format(new Date(value), "M"));

        setStartDate(value);
    }
    function filterDataTo(value) {
        setEndDate(value);
    }

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Leave Per User</h1>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik initialValues={{ email: "" }} validate={(values) => {}} onSubmit={(values, { setSubmitting }) => {}}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={5}>
                                    <Grid.Column>
                                        <label>From:</label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(value) => {
                                                filterData(value);
                                                changeUserUid(selectedUser, value, endDate);
                                            }}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label>To:</label>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(value) => {
                                                filterDataTo(value);
                                                changeUserUid(selectedUser, startDate, value);
                                            }}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="companyId"
                                            value={values.companyId}
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            onChange={(field, value) => changeCompanyId(value.value)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="userUid"
                                            value={values.userUid}
                                            options={userList}
                                            placeholder="Choose a User"
                                            onChange={(field, value) => changeUserUid(value.value, startDate, endDate)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            {loading === true ? (
                <LoadingComponent />
            ) : (
                <table className="ui celled table selectable">
                    <thead>
                        <tr style={{ fontSize: 11 }}>
                            <th>Leave Type</th>
                            <th>Date from</th>
                            <th>Date To</th>
                            <th>Total Days</th>
                            <th>Half Day</th>
                            <th>Added By</th>
                            <th>Description</th>
                            <th>Requested On</th>
                            <th>Status</th>
                            <th>Approval By</th>
                            <th>Actioned Date</th>
                        </tr>
                    </thead>

                    {console.log(leaveDays)}

                    <tbody style={{ fontSize: 11 }}>
                        {leaveDays &&
                            leaveDays.length > 0 &&
                            leaveDays.map((leaveDay) => (
                                <tr key={leaveDay.id}>
                                    <td>
                                        <strong>{leaveDay.leaveType}</strong>
                                    </td>
                                    <td>
                                        <strong>{leaveDay.annualFrom !== "" && format(new Date(leaveDay.annualFrom.toDate()), "d MMMM yyyy")}</strong>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.annualTo !== "" && format(new Date(leaveDay.annualTo.toDate()), "d MMMM yyyy")}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.annualDays}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.halfDay}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.addedByDisplayName}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.description}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{format(new Date(leaveDay.createDate.toDate()), "d MMMM yyyy")}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.status}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.approvalType}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{leaveDay.actionDate !== "" && leaveDay.actionDate !== undefined && format(new Date(leaveDay.actionDate.toDate()), "d MMMM yyyy")}</strong>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </Container>
    );
}
