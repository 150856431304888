import React from "react";
import { useEffect } from "react";
import LeaveLeftNew from "./LeaveLeftNew";
import LeaveLeftOld from "./LeaveLeftOld";
import firebase from "../../../app/config/firebase";
import { useState } from "react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Button, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
const firestore = firebase.firestore();

export default function ComparePage() {
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    const [companyData, setCompanyData] = useState([]);
    const [profile, setPofile] = useState([]);

    // console.log(userId);

    useEffect(() => {
        firestore
            .collection("users")
            .doc(userId)
            .get()
            .then((snapshot) => {
                if (snapshot.exists) {
                    setPofile({ ...snapshot.data(), id: snapshot.id });
                }
            });
    }, []);

    return (
        <>
            <Button color="grey" as={Link} to={"/clients/leaveview/" + userId}>
                BACK
            </Button>
            <Segment>
                <h2>New Method</h2>
                <>{profile.length === 0 ? <LoadingComponent /> : <LeaveLeftNew profileClient={profile} selectedYear={""} layout="horizontal" />}</>
            </Segment>

            <Segment>
                <h2>Old Method</h2>
                <>{profile.length === 0 ? <LoadingComponent /> : <LeaveLeftOld profileClient={profile} selectedYear={""} layout="horizontal" />}</>
            </Segment>
        </>
    );
}
