import React, { useEffect, useState, useCallback } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserList, getAuthUser, getCompanies, getLeaveAction, getLeaveInfo } from "../reportsAction";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { Formik } from "formik";
import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
import { date } from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { app } from "firebase";
import { subYears } from "date-fns/esm";

const firestore = firebase.firestore();

export default function ReportLongService(props) {
    const [companyIdFilter, setCompanyIdFilter] = useState("0");
    const [profile, setProfile] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [profile_company, setProfile_company] = useState("");
    const [loading, setLoading] = useState(false);

    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

    const { currentUserProfile } = useSelector((state) => state.profile);

    const [yearNow, setYearNow] = useState([]);
    const [monthNow, setMonthNow] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    setProfile_company(res.data().companyId);
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getCompanies().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        if (appObj.hasLeave == true) {
                            list.push(appObj);
                        }
                    }
                });

                let companiesList = [
                    {
                        key: "",
                        text: "Choose a Company",
                        value: "",
                    },
                ];

                console.log(profile);

                Object.keys(list).forEach(function (key) {
                    if (profile.isSuper === true) {
                        companiesList.push({
                            key: list[key].id,
                            text: list[key].companyName,
                            value: list[key].id,
                        });
                    } else if (list[key].id === profile.companyId) {
                        companiesList.push({
                            key: list[key].id,
                            text: list[key].companyName,
                            value: list[key].id,
                        });
                    }
                });

                setCompaniesList(companiesList);
            },
            (err) => {
                console.log(err);
            }
        );
    }, [companyIdFilter, yearNow, monthNow, profile_company, endDate]);

    async function changeCompanyId(value, endOfdate) {
        let allDepartments = [];

        await firestore
            .collection("departments")
            .where("companyId", "==", value)
            .get()
            .then((document) => {
                let departments = [];
                document.forEach(async (doc) => {
                    departments.push(doc.data());
                });

                allDepartments.push(departments);
            });

        await firestore
            .collection("users")
            .where("companyId", "==", value)
            .where("isTerminated", "==", false)
            .get()
            .then(
                async (res) => {
                    let list = [];
                    res.forEach(async (doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), id: doc.id };

                            Object.keys(allDepartments).forEach(function (key) {
                                if (allDepartments[key].id == appObj.departmentId) {
                                    appObj.departmentName = allDepartments[key].departmentName;
                                }
                            });

                            if (appObj.employmentStartDate !== undefined && appObj.employmentStartDate !== "") {
                                appObj.EmpStartDate = appObj.employmentStartDate;
                            } else if (appObj.takeOnStartDate !== undefined && appObj.takeOnStartDate !== "") {
                                appObj.EmpStartDate = appObj.takeOnStartDate;
                            } else {
                                appObj.EmpStartDate = appObj.createdAt;
                            }

                            list.push(appObj);
                        }
                    });

                    let usersListNew = [];
                    let StartDateReq = subYears(endOfdate, 3);

                    Object.keys(list).forEach(function (key) {
                        if (list[key].EmpStartDate.toDate() <= StartDateReq) {
                            if (
                                currentUserProfile.isSuper === true ||
                                currentUserProfile.isAdmin === true ||
                                currentUserProfile.isHR === true ||
                                (currentUserProfile.isLineManager === true && currentUserProfile.departments.includes(list[key].departmentId))
                            )
                                usersListNew.push({
                                    displayName: list[key].displayName,
                                    email: list[key].email,
                                    departmentName: list[key].departmentName,
                                    EmpStartDate: list[key].EmpStartDate,
                                    DownloadStartDate: list[key].EmpStartDate !== undefined && format(new Date(list[key].EmpStartDate.toDate()), "d MMMM yyyy"),
                                });
                        }
                    });

                    setUserList(usersListNew);
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    function filterDataTo(value, compId) {
        setEndDate(value);
        if (compId !== "") {
            changeCompanyId(compId, value);
        }
    }

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Long Service - 3 years or longer</h1>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            {userList && userList.length > 0 && (
                                <ExcelFile element={<Button color="blue">Download</Button>}>
                                    <ExcelSheet data={userList} name="Users">
                                        <ExcelColumn label="Display Name" value="displayName" />
                                        <ExcelColumn label="Email" value="email" />
                                        <ExcelColumn label="Department" value="departmentName" />
                                        <ExcelColumn label="Start Date" value="DownloadStartDate" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )}
                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik initialValues={{ companyId: "", endDate: endDate }} validate={(values) => {}} onSubmit={(values, { setSubmitting }) => {}}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={5}>
                                    <Grid.Column>
                                        <label>Date:</label>
                                        <DatePicker
                                            selected={endDate}
                                            name="endDate"
                                            onChange={(value) => {
                                                filterDataTo(value, values.companyId);
                                                setFieldValue("endDate", value);
                                            }}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {/* {profile && profile.isSuper && (
                                            )} */}
                                        <MySelectInput
                                            name="companyId"
                                            value={values.companyId}
                                            options={companiesList}
                                            placeholder="Choose a Company"
                                            onChange={(field, value) => {
                                                changeCompanyId(value.value, values.endDate);
                                                setFieldValue("companyId", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            {loading === true ? (
                <LoadingComponent />
            ) : (
                <table className="ui celled table selectable">
                    <thead>
                        <tr style={{ fontSize: 11 }}>
                            <th>Display Name</th>
                            <th>Email</th>
                            <th>Department</th>
                            <th>Start Date</th>
                        </tr>
                    </thead>

                    <tbody style={{ fontSize: 11 }}>
                        {userList &&
                            userList.length > 0 &&
                            userList.map((user) => (
                                <tr key={user.email}>
                                    <td>
                                        <strong>{user.displayName}</strong>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{user.email}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{user.departmentName}</strong>
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            <strong>{user.EmpStartDate !== undefined && format(new Date(user.EmpStartDate.toDate()), "d MMMM yyyy")}</strong>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </Container>
    );
}
