import React, { useEffect, useState, useCallback } from "react";
import { Segment, Container, Button, Grid, List, ListItem, Label, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserList, getAuthUser, getCompanies } from "./reportsAction";
import MySelectInput from "../../app/common/form/MySelectInput";
import { Formik } from "formik";
import firebase from "../../app/config/firebase";
import { useSelector } from "react-redux";
import { subWeeks } from "date-fns/esm";
import LeaveLeftTbl from "../staff/LeaveList/LeaveLeftOld";
import { Dimmer, Loader } from "semantic-ui-react";

const firestore = firebase.firestore();

export default function ReportLeaveDuePage(props) {
    const [userList, setUserList] = useState([]);
    const [chosenUser, setChosenUser] = useState("");
    // const [fromDate, setFromDate] = useState(subWeeks(new Date(), 2));
    // const [toDate, setToDate] = useState(new Date());
    const [allLeave, setAllLeave] = useState([]);
    let yearNow = format(Date.now(), "yyyy");
    let monthNow = format(Date.now(), "M");
    let nonGrowingYear = parseInt(yearNow);

    const [showMonths, setShowMonths] = useState(false);
    const [loading, setLoading] = useState(false);

    const { currentUserProfile } = useSelector((state) => state.profile);

    const [usedSpecial, setUsedSpecial] = useState("0");

    const [left, setLeft] = useState("0");
    const [total, setTotal] = useState("0");
    const [used, setUsed] = useState("0");

    const [showAccum, setShowAccum] = useState(false);
    const [accumUsed, setAccumUsed] = useState("0");
    const [accumTotal, setAccumTotal] = useState("0");
    const [leftAccum, setLeftAccum] = useState("0");
    const [accumForfeit, setAccumForfeit] = useState("");
    const [showAccumExpired, setShowAccumExpired] = useState(false);

    const [showAccum2, setShowAccum2] = useState(false);
    const [accumUsed2, setAccumUsed2] = useState("0");
    const [accumTotal2, setAccumTotal2] = useState("0");
    const [leftAccum2, setLeftAccum2] = useState("0");

    const [showSick, setShowSick] = useState(false);
    const [leftSick, setLeftSick] = useState("0");
    const [totalSick, setTotalSick] = useState("0");
    const [usedSick, setUsedSick] = useState("0");

    const [showFamily, setShowFamily] = useState(false);
    const [usedFamily, setUsedFamily] = useState("0");
    const [totalFamily, setTotalFamily] = useState("0");
    const [leftFamily, setLeftFamily] = useState("0");

    const [showMaternity, setShowMaternity] = useState(false);
    const [usedMaternity, setUsedMaternity] = useState("0");
    const [totalMaternity, setTotalMaternity] = useState("0");
    const [leftMaternity, setLeftMaternity] = useState("0");

    const [showPaternity, setShowPaternity] = useState(false);
    const [usedPaternity, setUsedPaternity] = useState("0");
    const [totalPaternity, setTotalPaternity] = useState("0");
    const [leftPaternity, setLeftPaternity] = useState("0");

    const [showIOD, setShowIOD] = useState(false);
    const [usedIOD, setUsedIOD] = useState("0");
    const [totalIOD, setTotalIOD] = useState("0");
    const [leftIOD, setLeftIOD] = useState("0");

    const [showMoving, setShowMoving] = useState(false);
    const [usedMoving, setUsedMoving] = useState("0");
    const [totalMoving, setTotalMoving] = useState("0");
    const [leftMoving, setLeftMoving] = useState("0");

    const [showCSR, setShowCSR] = useState(false);
    const [usedCSR, setUsedCSR] = useState("0");
    const [totalCSR, setTotalCSR] = useState("0");
    const [leftCSR, setLeftCSR] = useState("0");

    const [showReligious, setShowReligious] = useState(false);
    const [usedReligious, setUsedReligious] = useState("0");
    const [totalReligious, setTotalReligious] = useState("0");
    const [leftReligious, setLeftReligious] = useState("0");

    const [showStudy, setShowStudy] = useState(false);
    const [usedStudy, setUsedStudy] = useState("0");
    const [totalStudy, setTotalStudy] = useState("0");
    const [leftStudy, setLeftStudy] = useState("0");

    const [downloadData, setDownloadData] = useState([]);
    const [downloadDataTransactions, setDownloadDataTransactions] = useState([]);

    const [method, setMethod] = useState("");

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    useEffect(() => {
        if (
            currentUserProfile &&
            (currentUserProfile.isSuper === undefined || currentUserProfile.isSuper === false) &&
            (currentUserProfile.isAdmin === true || currentUserProfile.isHR === true || currentUserProfile.isLineManager === true)
        ) {
            firestore
                .collection("users")
                .where("isTerminated", "==", false)
                .where("companyId", "==", currentUserProfile.companyId)
                .onSnapshot((res) => {
                    let assignUsers = [
                        {
                            key: "",
                            text: "Choose a User",
                            value: "",
                        },
                    ];
                    res.docs.forEach((doc) => {
                        let usrObj = { ...doc.data(), id: doc.id };
                        if (
                            currentUserProfile.isAdmin === true ||
                            currentUserProfile.isHR === true ||
                            (currentUserProfile.isLineManager === true && currentUserProfile.departments.includes(usrObj.departmentId))
                        ) {
                            assignUsers.push({
                                key: doc.id,
                                text: usrObj.displayName && usrObj.displayName.length > 0 ? usrObj.displayName : usrObj.email,
                                value: doc.id,
                            });
                        }
                    });
                    setUserList(assignUsers);
                });
        } else {
            firestore
                .collection("users")
                .where("isTerminated", "==", false)
                .onSnapshot((res) => {
                    let assignUsers = [
                        {
                            key: "",
                            text: "Choose a User",
                            value: "",
                        },
                    ];
                    res.docs.forEach((doc) => {
                        let usrObj = { ...doc.data(), id: doc.id };
                        assignUsers.push({
                            key: doc.id,
                            text: usrObj.displayName && usrObj.displayName.length > 0 ? usrObj.companyName + " - " + usrObj.displayName : usrObj.companyName + " - " + usrObj.email,
                            value: doc.id,
                        });
                    });
                    setUserList(assignUsers);
                });
        }
    }, []);

    async function reportStats(field, value) {
        if (field === "userId") {
            setShowMonths(false);
            setLoading(true);
            let mainLeave = [];
            await firestore
                .collection("leave")
                .where("userUid", "==", value)
                .where("annualFrom", ">=", new Date("2021-01-01"))
                .onSnapshot((res) => {
                    res.docs.forEach((doc) => {
                        mainLeave.push({ ...doc.data(), id: doc.id });
                    });
                });

            await firestore
                .collection("leave")
                .where("userUid", "==", value)
                .where("fromTime", ">=", new Date("2021-01-01 00:00:00"))
                .onSnapshot((res) => {
                    let totHours = 0;
                    res.docs.forEach((doc) => {
                        mainLeave.push({ ...doc.data(), id: doc.id });
                        if (doc.data().status === "Approved") {
                            totHours += parseFloat(doc.data().timeHours);
                        }
                    });

                    setUsedSpecial(totHours);
                });

            const someTimeoutAction = () => {
                return new Promise((resolve) => {
                    setTimeout(function () {
                        resolve(mainLeave);
                    }, 4000);
                });
            };

            const status = await someTimeoutAction();
            status.sort((a, b) => (a.annualFrom < b.annualFrom ? 1 : -1));
            setAllLeave(status);

            let createSort = status.sort((a, b) => (b.createDate < a.createDate ? 1 : -1));

            console.log(createSort);
            let downloadArr = [];
            let totalLeaveUsed = 0;
            let totalSickUsed = 0;
            let totalStudyUsed = 0;
            let totalFamilyUsed = 0;
            let totalMaternityUsed = 0;
            let totalPaternityUsed = 0;
            let totalIODUsed = 0;
            let totalMovingUsed = 0;
            let totalCSRUsed = 0;
            let totalReligiousUsed = 0;
            let totalAccumUsed = 0;
            let totalAccum2Used = 0;

            Object.keys(createSort).forEach(async function (key) {
                if (createSort[key].status == "Approved" && (!createSort[key].unpaidLeave || createSort[key].unpaidLeave == "No")) {
                    let leaveTypeMethod = "";
                    let title = "";
                    let companyDataInfo = await firestore
                        .collection("companies")
                        .doc(createSort[key].companyId)
                        .onSnapshot(async (comp) => {
                            let companyData = { ...comp.data(), id: comp.id };

                            if ((companyData.leaveMethodType && companyData.leaveMethodType === "Old Method") || companyData.leaveMethodType === undefined) {
                                leaveTypeMethod = "Old";
                            } else {
                                leaveTypeMethod = "New";
                            }
                        });
                    let userData = await firestore.collection("users").doc(createSort[key].userUid).get();

                    let left = "";
                    let month = createSort[key].createDate.toDate().getMonth() + 1;
                    if (createSort[key].leaveType == "Annual") {
                        console.log(leaveTypeMethod);

                        if (leaveTypeMethod == "Old") {
                            console.log(">");

                            if (createSort[key].useAccumulative && createSort[key].useAccumulative === "Yes") {
                                totalAccumUsed += createSort[key].annualDays;
                                left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["accumTotal"]) - parseFloat(totalAccumUsed);
                                title = "Accumulative Expired";
                            } else if (createSort[key].useAccumulative2 && createSort[key].useAccumulative2 === "Yes") {
                                totalAccum2Used += createSort[key].annualDays;
                                left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["accumTotal2"]) - parseFloat(totalAccum2Used);
                                title = "Accumulative";
                            } else {
                                totalLeaveUsed += createSort[key].annualDays;
                                left = parseFloat(userData.data().leaveMonths["2021"]["" + month + ""].total) - parseFloat(totalLeaveUsed);
                                title = "Annual";
                            }
                        }
                    } else if (createSort[key].leaveType == "Sick") {
                        totalSickUsed += createSort[key].annualDays;

                        console.log(leaveTypeMethod);

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["sick"]["sickTotal"]) - parseFloat(totalSickUsed);
                            title = "Sick";
                        }
                    } else if (createSort[key].leaveType == "Study") {
                        totalStudyUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["studyTotal"]) - parseFloat(totalStudyUsed);
                            title = "Study";
                        }
                    } else if (createSort[key].leaveType == "Family") {
                        totalFamilyUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["familyTotal"]) - parseFloat(totalFamilyUsed);
                            title = "Family";
                        }
                    } else if (createSort[key].leaveType == "Maternity") {
                        totalMaternityUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["maternityTotal"]) - parseFloat(totalMaternityUsed);
                            title = "Maternity";
                        }
                    } else if (createSort[key].leaveType == "Paternity") {
                        totalPaternityUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["paternityTotal"]) - parseFloat(totalPaternityUsed);
                            title = "Paternity";
                        }
                    } else if (createSort[key].leaveType == "IOD") {
                        totalIODUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["IODTotal"]) - parseFloat(totalIODUsed);
                            title = "IOD";
                        }
                    } else if (createSort[key].leaveType == "Moving") {
                        totalMovingUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["movingTotal"]) - parseFloat(totalMovingUsed);
                            title = "Moving";
                        }
                    } else if (createSort[key].leaveType == "CSR") {
                        totalCSRUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["csrTotal"]) - parseFloat(totalCSRUsed);
                            title = "CSR";
                        }
                    } else if (createSort[key].leaveType == "Religious") {
                        totalReligiousUsed += createSort[key].annualDays;

                        if (leaveTypeMethod == "Old") {
                            console.log(">");
                            left = parseFloat(userData.data().leaveMonths["2021"]["nonGrowing"]["religiousTotal"]) - parseFloat(totalReligiousUsed);
                            title = "Religious";
                        }
                    } else if (createSort[key].leaveType == "Special Leave") {
                        left = "N/A";
                        title = "Special Leave";
                    }

                    downloadArr.push({
                        user: createSort[key].displayName,
                        date: createSort[key].createDate.toDate(),
                        leave_type: title,
                        days_taken: createSort[key].annualDays,
                        days_left: left,
                    });
                }
            });

            setDownloadDataTransactions(downloadArr);

            await firestore
                .collection("users")
                .doc(value)
                .onSnapshot(async (res) => {
                    let profileClient = { ...res.data(), id: res.id };

                    await firestore
                        .collection("companies")
                        .doc(profileClient.companyId)
                        .onSnapshot(async (comp) => {
                            let companyData = { ...comp.data(), id: comp.id };

                            if ((companyData.leaveMethodType && companyData.leaveMethodType === "Old Method") || companyData.leaveMethodType === undefined) {
                                //OLD LEAVE METHOD
                                setMethod("old");
                                let downloadInfoUsed = { type: "Days Used" };
                                let downloadInfoLeft = { type: "Days Left" };
                                let downloadInfoTotal = { type: "Days Total" };

                                if (profileClient && profileClient["leaveMonths"] && profileClient["leaveMonths"][yearNow] && profileClient["leaveMonths"][yearNow][monthNow]) {
                                    let left = parseFloat(profileClient["leaveMonths"][yearNow][monthNow]["left"]);
                                    let total = parseFloat(profileClient["leaveMonths"][yearNow][monthNow]["total"]);
                                    let used = parseFloat(profileClient["leaveMonths"][yearNow][monthNow]["runningTotal"]);
                                    setLeft(left);
                                    setTotal(total);
                                    setUsed(used);
                                    downloadInfoUsed.annual = used;
                                    downloadInfoLeft.annual = left;
                                    downloadInfoTotal.annual = total;
                                } else {
                                    setLeft("0");
                                    setTotal("0");
                                    setUsed("0");
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] > 0
                                ) {
                                    let accumUsed = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed"]);
                                    let accumTotal = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"]);
                                    let leftAccum = accumTotal - accumUsed;

                                    setAccumUsed(accumUsed);
                                    setAccumTotal(accumTotal);
                                    setLeftAccum(leftAccum);
                                    setShowAccum(true);

                                    downloadInfoUsed.expired_accumulative = accumUsed;
                                    downloadInfoLeft.expired_accumulative = leftAccum;
                                    downloadInfoTotal.expired_accumulative = accumTotal;

                                    if (profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumForfeitDate"]) {
                                        let accumForfeit = profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumForfeitDate"];
                                        setAccumForfeit(accumForfeit);

                                        if (new Date() > accumForfeit.toDate()) {
                                            setShowAccumExpired(true);
                                        }
                                    }
                                } else {
                                    setShowAccumExpired(false);
                                    setAccumForfeit("");
                                    setAccumUsed("0");
                                    setAccumTotal("0");
                                    setLeftAccum("0");
                                    setShowAccum(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"] > 0 &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] > 0
                                ) {
                                    let accumUsed2 = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed2"]);
                                    let accumTotal2 = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"]);
                                    let leftAccum2 = accumTotal2 - accumUsed2;
                                    setShowAccum2(true);
                                    setAccumTotal2(accumTotal2);
                                    setAccumUsed2(accumUsed2);
                                    setLeftAccum2(leftAccum2);
                                    downloadInfoUsed.accumulative = accumUsed2;
                                    downloadInfoLeft.accumulative = leftAccum2;
                                    downloadInfoTotal.accumulative = accumTotal2;
                                } else {
                                    setShowAccum2(false);
                                    setAccumTotal2("0");
                                    setAccumUsed2("0");
                                    setLeftAccum2("0");
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"]["sick"] &&
                                    profileClient["leaveMonths"]["sick"]["sickTotal"] &&
                                    profileClient["leaveMonths"]["sick"]["sickTotal"] > 0
                                ) {
                                    let usedSick = parseFloat(profileClient["leaveMonths"]["sick"]["sickUsed"]);
                                    let totalSick = parseFloat(profileClient["leaveMonths"]["sick"]["sickTotal"]);
                                    let leftSick = totalSick - usedSick;
                                    setShowSick(true);
                                    setUsedSick(usedSick);
                                    setTotalSick(totalSick);
                                    setLeftSick(leftSick);
                                    downloadInfoUsed.sick = usedSick;
                                    downloadInfoLeft.sick = leftSick;
                                    downloadInfoTotal.sick = totalSick;
                                } else {
                                    setShowSick(false);
                                    setUsedSick("0");
                                    setTotalSick("0");
                                    setLeftSick("0");
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"] > 0
                                ) {
                                    let usedFamily = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyUsed"]);
                                    let totalFamily = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["familyTotal"]);
                                    let leftFamily = totalFamily - usedFamily;
                                    setUsedFamily(usedFamily);
                                    setTotalFamily(totalFamily);
                                    setLeftFamily(leftFamily);
                                    setShowFamily(true);
                                    downloadInfoUsed.family = usedFamily;
                                    downloadInfoLeft.family = leftFamily;
                                    downloadInfoTotal.family = totalFamily;
                                } else {
                                    setUsedFamily("0");
                                    setTotalFamily("0");
                                    setLeftFamily("0");
                                    setShowFamily(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"] > 0
                                ) {
                                    let usedMaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityUsed"]);
                                    let totalMaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["maternityTotal"]);
                                    let leftMaternity = totalMaternity - usedMaternity;
                                    setUsedMaternity(usedMaternity);
                                    setTotalMaternity(totalMaternity);
                                    setLeftMaternity(leftMaternity);
                                    setShowMaternity(true);
                                    downloadInfoUsed.maternity = usedMaternity;
                                    downloadInfoLeft.maternity = leftMaternity;
                                    downloadInfoTotal.maternity = totalMaternity;
                                } else {
                                    setUsedMaternity("0");
                                    setTotalMaternity("0");
                                    setLeftMaternity("0");
                                    setShowMaternity(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"] > 0
                                ) {
                                    let usedPaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityUsed"]);
                                    let totalPaternity = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["paternityTotal"]);
                                    let leftPaternity = totalPaternity - usedPaternity;

                                    setUsedPaternity(usedPaternity);
                                    setTotalPaternity(totalPaternity);
                                    setLeftPaternity(leftPaternity);
                                    setShowPaternity(true);
                                    downloadInfoUsed.paternity = usedPaternity;
                                    downloadInfoLeft.paternity = leftPaternity;
                                    downloadInfoTotal.paternity = totalPaternity;
                                } else {
                                    setUsedPaternity("0");
                                    setTotalPaternity("0");
                                    setLeftPaternity("0");
                                    setShowPaternity(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"] > 0
                                ) {
                                    let usedIOD = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODUsed"]);
                                    let totalIOD = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["IODTotal"]);
                                    let leftIOD = totalIOD - usedIOD;

                                    setUsedIOD(usedIOD);
                                    setTotalIOD(totalIOD);
                                    setLeftIOD(leftIOD);
                                    setShowIOD(true);
                                    downloadInfoUsed.IOD = usedIOD;
                                    downloadInfoLeft.IOD = leftIOD;
                                    downloadInfoTotal.IOD = totalIOD;
                                } else {
                                    setUsedIOD("0");
                                    setTotalIOD("0");
                                    setLeftIOD("0");
                                    setShowIOD(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"] > 0
                                ) {
                                    let usedMoving = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingUsed"]);
                                    let totalMoving = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["movingTotal"]);
                                    let leftMoving = totalMoving - usedMoving;

                                    setUsedMoving(usedMoving);
                                    setTotalMoving(totalMoving);
                                    setLeftMoving(leftMoving);
                                    setShowMoving(true);

                                    downloadInfoUsed.moving = usedMoving;
                                    downloadInfoLeft.moving = leftMoving;
                                    downloadInfoTotal.moving = totalMoving;
                                } else {
                                    setUsedMoving("0");
                                    setTotalMoving("0");
                                    setLeftMoving("0");
                                    setShowMoving(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"] > 0
                                ) {
                                    let usedCSR = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrUsed"]);
                                    let totalCSR = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["csrTotal"]);
                                    let leftCSR = totalCSR - usedCSR;

                                    setUsedCSR(usedCSR);
                                    setTotalCSR(totalCSR);
                                    setLeftCSR(leftCSR);
                                    setShowCSR(true);
                                    downloadInfoUsed.csr = usedCSR;
                                    downloadInfoLeft.csr = leftCSR;
                                    downloadInfoTotal.csr = totalCSR;
                                } else {
                                    setUsedCSR("0");
                                    setTotalCSR("0");
                                    setLeftCSR("0");
                                    setShowCSR(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"] > 0
                                ) {
                                    let usedReligious = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousUsed"]);
                                    let totalReligious = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["religiousTotal"]);
                                    let leftReligious = totalReligious - usedReligious;

                                    setUsedReligious(usedReligious);
                                    setTotalReligious(totalReligious);
                                    setLeftReligious(leftReligious);
                                    setShowReligious(true);
                                    // downloadInfoUsed.csr = usedCSR
                                } else {
                                    setUsedReligious("0");
                                    setTotalReligious("0");
                                    setLeftReligious("0");
                                    setShowReligious(false);
                                }

                                if (
                                    profileClient &&
                                    profileClient["leaveMonths"] &&
                                    profileClient["leaveMonths"][nonGrowingYear] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"] &&
                                    profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"] > 0
                                ) {
                                    let usedStudy = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyUsed"]);
                                    let totalStudy = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["studyTotal"]);
                                    let leftStudy = totalStudy - usedStudy;

                                    setUsedStudy(usedStudy);
                                    setTotalStudy(totalStudy);
                                    setLeftStudy(leftStudy);
                                    setShowStudy(true);
                                    downloadInfoUsed.study = usedStudy;
                                    downloadInfoLeft.study = leftStudy;
                                    downloadInfoTotal.study = totalStudy;
                                } else {
                                    setUsedStudy("0");
                                    setTotalStudy("0");
                                    setLeftStudy("0");
                                    setShowStudy(true);
                                }

                                // console.log(downloadInfoUsed);
                                let info = [downloadInfoUsed, downloadInfoLeft, downloadInfoTotal];
                                setDownloadData(info);
                                setShowMonths(true);
                                setLoading(false);
                            } else {
                                //NEW LEAVE METHOD
                                setMethod("new");

                                await firestore
                                    .collection("leaveStatus")
                                    .where("periodType", "==", "Leave")
                                    .where("userUid", "==", profileClient.id)
                                    .orderBy("fromDate", "desc")
                                    .onSnapshot(async (leave) => {
                                        // console.log(leave.docs[0].id);
                                        let leaveData = { ...leave.docs[0].data(), id: leave.docs[0].id };

                                        setMethod("new");
                                        let downloadInfoUsed = { type: "Days Used" };
                                        let downloadInfoLeft = { type: "Days Left" };
                                        let downloadInfoTotal = { type: "Days Total" };

                                        await firestore
                                            .collection("leaveStatus")
                                            .where("periodType", "==", "Sick")
                                            .where("userUid", "==", profileClient.id)
                                            .orderBy("fromDate", "desc")
                                            .onSnapshot(async (sick) => {
                                                let sickData = { ...sick.docs[0].data(), id: sick.docs[0].id };

                                                setShowSick(true);
                                                setUsedSick(sickData.sickUsed);
                                                setTotalSick(sickData.sickTotal);
                                                setLeftSick(sickData.sickLeft);
                                                downloadInfoUsed.sick = sickData.sickUsed;
                                                downloadInfoLeft.sick = sickData.sickLeft;
                                                downloadInfoTotal.sick = sickData.sickTotal;
                                            });

                                        if (leaveData.familyTotal > 0) {
                                            setUsedFamily(leaveData.familyUsed);
                                            setTotalFamily(leaveData.familyTotal);
                                            setLeftFamily(leaveData.familyLeft);
                                            setShowFamily(true);
                                            downloadInfoUsed.family = leaveData.familyUsed;
                                            downloadInfoLeft.family = leaveData.familyLeft;
                                            downloadInfoTotal.family = leaveData.familyTotal;
                                        } else {
                                            setUsedFamily("0");
                                            setTotalFamily("0");
                                            setLeftFamily("0");
                                            setShowFamily(false);
                                        }

                                        if (leaveData.maternityTotal > 0) {
                                            setUsedMaternity(leaveData.maternityUsed);
                                            setTotalMaternity(leaveData.maternityTotal);
                                            setLeftMaternity(leaveData.maternityLeft);
                                            setShowMaternity(true);
                                            downloadInfoUsed.maternity = leaveData.maternityUsed;
                                            downloadInfoLeft.maternity = leaveData.maternityLeft;
                                            downloadInfoTotal.maternity = leaveData.maternityTotal;
                                        } else {
                                            setUsedMaternity("0");
                                            setTotalMaternity("0");
                                            setLeftMaternity("0");
                                            setShowMaternity(false);
                                        }

                                        if (leaveData.paternityTotal > 0) {
                                            setUsedPaternity(leaveData.paternityUsed);
                                            setTotalPaternity(leaveData.paternityTotal);
                                            setLeftPaternity(leaveData.paternityLeft);
                                            setShowPaternity(true);
                                            downloadInfoUsed.paternity = leaveData.paternityUsed;
                                            downloadInfoLeft.paternity = leaveData.paternityLeft;
                                            downloadInfoTotal.paternity = leaveData.paternityTotal;
                                        } else {
                                            setUsedPaternity("0");
                                            setTotalPaternity("0");
                                            setLeftPaternity("0");
                                            setShowPaternity(false);
                                        }

                                        if (leaveData.IODTotal > 0) {
                                            setUsedIOD(leaveData.IODUsed);
                                            setTotalIOD(leaveData.IODTotal);
                                            setLeftIOD(leaveData.IODLeft);
                                            setShowIOD(true);
                                            downloadInfoUsed.IOD = leaveData.IODUsed;
                                            downloadInfoLeft.IOD = leaveData.IODLeft;
                                            downloadInfoTotal.IOD = leaveData.IODTotal;
                                        } else {
                                            setUsedIOD("0");
                                            setTotalIOD("0");
                                            setLeftIOD("0");
                                            setShowIOD(false);
                                        }

                                        if (leaveData.movingTotal > 0) {
                                            setUsedMoving(leaveData.movingUsed);
                                            setTotalMoving(leaveData.movingTotal);
                                            setLeftMoving(leaveData.movingLeft);
                                            setShowMoving(true);
                                            downloadInfoUsed.moving = leaveData.movingUsed;
                                            downloadInfoLeft.moving = leaveData.movingLeft;
                                            downloadInfoTotal.moving = leaveData.movingTotal;
                                        } else {
                                            setUsedMoving("0");
                                            setTotalMoving("0");
                                            setLeftMoving("0");
                                            setShowMoving(false);
                                        }

                                        if (leaveData.csrTotal > 0) {
                                            setUsedCSR(leaveData.csrUsed);
                                            setTotalCSR(leaveData.csrTotal);
                                            setLeftCSR(leaveData.csrLeft);
                                            setShowCSR(true);
                                            downloadInfoUsed.csr = leaveData.csrUsed;
                                            downloadInfoLeft.csr = leaveData.csrLeft;
                                            downloadInfoTotal.csr = leaveData.csrTotal;
                                        } else {
                                            setUsedCSR("0");
                                            setTotalCSR("0");
                                            setLeftCSR("0");
                                            setShowCSR(false);
                                        }

                                        if (leaveData.religiousTotal > 0) {
                                            setUsedReligious(leaveData.religiousUsed);
                                            setTotalReligious(leaveData.religiousTotal);
                                            setLeftReligious(leaveData.religiousLeft);
                                            setShowReligious(true);
                                        } else {
                                            setUsedReligious("0");
                                            setTotalReligious("0");
                                            setLeftReligious("0");
                                            setShowReligious(false);
                                        }

                                        if (leaveData.studyTotal > 0) {
                                            setUsedStudy(leaveData.studyUsed);
                                            setTotalStudy(leaveData.studyTotal);
                                            setLeftStudy(leaveData.studyLeft);
                                            setShowStudy(true);
                                            downloadInfoUsed.study = leaveData.studyUsed;
                                            downloadInfoLeft.study = leaveData.studyLeft;
                                            downloadInfoTotal.study = leaveData.studyTotal;
                                        } else {
                                            setUsedStudy("0");
                                            setTotalStudy("0");
                                            setLeftStudy("0");
                                            setShowStudy(false);
                                        }

                                        await firestore
                                            .collection("leaveStatus")
                                            .doc(leave.docs[0].id)
                                            .collection("Months")
                                            .onSnapshot(async (months) => {
                                                //
                                                months.docs.forEach((document) => {
                                                    let docData = { ...document.data(), id: document.id };
                                                    if (format(document.data().datePeriod.toDate(), "yyyy-MM") === format(new Date(), "yyyy-MM")) {
                                                        console.log(docData);

                                                        setLeft(docData.annualLeft);
                                                        setTotal(docData.annualTotal);
                                                        setUsed(docData.annualUsed);
                                                        downloadInfoUsed.annual = docData.annualUsed;
                                                        downloadInfoLeft.annual = docData.annualLeft;
                                                        downloadInfoTotal.annual = docData.annualTotal;

                                                        if (docData.accumTotal > 0) {
                                                            setAccumUsed(docData.accumUsed);
                                                            setAccumTotal(docData.accumTotal);
                                                            setLeftAccum(parseFloat(docData.accumTotal) - parseFloat(docData.accumUsed));
                                                            setShowAccum(true);

                                                            downloadInfoUsed.expired_accumulative = docData.accumUsed;
                                                            downloadInfoLeft.expired_accumulative = parseFloat(docData.accumTotal) - parseFloat(docData.accumUsed);
                                                            downloadInfoTotal.expired_accumulative = docData.accumTotal;
                                                        } else {
                                                            setShowAccumExpired(false);
                                                            setAccumForfeit("");
                                                            setAccumUsed("0");
                                                            setAccumTotal("0");
                                                            setLeftAccum("0");
                                                            setShowAccum(false);
                                                        }

                                                        if (docData.accumTotal2 > 0 && docData.accumTotal > 0) {
                                                            setShowAccum2(true);
                                                            setAccumTotal2(docData.accumTotal2);
                                                            setAccumUsed2(docData.accumUsed2);
                                                            setLeftAccum2(parseFloat(docData.accumTotal2) - parseFloat(docData.accumUsed2));
                                                            downloadInfoUsed.accumulative = docData.accumUsed2;
                                                            downloadInfoLeft.accumulative = parseFloat(docData.accumTotal2) - parseFloat(docData.accumUsed2);
                                                            downloadInfoTotal.accumulative = docData.accumTotal2;
                                                        } else {
                                                            setShowAccum2(false);
                                                            setAccumTotal2("0");
                                                            setAccumUsed2("0");
                                                            setLeftAccum2("0");
                                                        }
                                                    }
                                                });
                                            });
                                        let info = [downloadInfoUsed, downloadInfoLeft, downloadInfoTotal];
                                        setDownloadData(info);
                                    });

                                setShowMonths(true);
                                setLoading(false);
                            }
                        });
                });
        }
    }

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <h2>Leave Transaction Report</h2>
                        </Grid.Column>
                        <Grid.Column textAlign="center">
                            <h3>1 January 2021 - Current</h3>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>

                            {/* {showMonths === true && (
                                <ExcelFile element={<Button color="blue">Download Totals Table</Button>}>
                                    <ExcelSheet data={downloadData} name="Users">
                                        <ExcelColumn label="Type" value="type" />
                                        <ExcelColumn label="Annual" value="annual" />
                                        <ExcelColumn label="Expired Accumulative" value="expired_accumulative" />
                                        <ExcelColumn label="Accumulative" value="accumulative" />
                                        <ExcelColumn label="Sick" value="sick" />
                                        <ExcelColumn label="Family" value="family" />
                                        <ExcelColumn label="Maternity" value="maternity" />
                                        <ExcelColumn label="Paternity" value="paternity" />
                                        <ExcelColumn label="Moving" value="moving" />
                                        <ExcelColumn label="CSR" value="csr" />
                                        <ExcelColumn label="Study" value="study" />
                                        <ExcelColumn label="Special" value="special" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )} */}

                            {showMonths === true && (
                                <ExcelFile element={<Button color="blue">Download</Button>}>
                                    <ExcelSheet data={downloadDataTransactions} name="Users">
                                        <ExcelColumn label="User" value="user" />
                                        <ExcelColumn label="Date" value="date" />
                                        <ExcelColumn label="Leave Type" value="leave_type" />
                                        <ExcelColumn label="Days Taken" value="days_taken" />
                                        <ExcelColumn label="Days Left" value="days_left" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Formik initialValues={{ email: "" }} enableReinitialize validate={(values) => { }} onSubmit={(values, { setSubmitting }) => { }}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit} className="form ui">
                                    {/* <Grid.Column style={{ marginLeft: "20px", float: "left" }}>
                                        <label>From Date:</label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(value) => {
                                                setFromDate(value);
                                                reportStats("fromDate", value);
                                            }}
                                            inline
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM"
                                        />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginLeft: "20px", float: "left" }}>
                                        <label>To Date:</label>
                                        <DatePicker
                                            selected={toDate}
                                            minDate={fromDate}
                                            onChange={(value) => {
                                                setToDate(value);
                                                reportStats("toDate", value);
                                            }}
                                            inline
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM"
                                        />
                                    </Grid.Column> */}

                                    <Grid.Column style={{ marginLeft: "20px", float: "left" }}>
                                        <label>User:</label>
                                        <MySelectInput
                                            name="userId"
                                            options={userList}
                                            placeholder="Choose a User"
                                            onChange={(field, value) => {
                                                setFieldValue("userId", value.value);
                                                setChosenUser(value.value);
                                                reportStats("userId", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                </form>
                            )}
                        </Formik>
                    </Grid.Row>
                </Grid>
            </Segment>

            {showMonths === true && (
                <Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <h2>Leave Tracking</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {/* {method && method === "old" ? ( */}
                                <Table basic="very" celled collapsing unstackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Annual</Table.HeaderCell>
                                            {showAccum && showAccumExpired === false && <Table.HeaderCell>Accumulative</Table.HeaderCell>}

                                            {showAccum && !showAccum2 && showAccumExpired === true && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                                            {showAccum && showAccum2 && showAccumExpired === true && <Table.HeaderCell>Expired Accumulative</Table.HeaderCell>}

                                            {showAccum2 && showAccumExpired === true && <Table.HeaderCell>Accumulative</Table.HeaderCell>}
                                            {showSick && <Table.HeaderCell>Sick</Table.HeaderCell>}
                                            {showFamily && <Table.HeaderCell>Family</Table.HeaderCell>}
                                            {showMaternity && <Table.HeaderCell>Maternity</Table.HeaderCell>}
                                            {showPaternity && <Table.HeaderCell>Paternity</Table.HeaderCell>}
                                            {showIOD && <Table.HeaderCell>IOD</Table.HeaderCell>}
                                            {showMoving && <Table.HeaderCell>Moving</Table.HeaderCell>}
                                            {showCSR && <Table.HeaderCell>CSR</Table.HeaderCell>}
                                            {showReligious && <Table.HeaderCell>Religious</Table.HeaderCell>}
                                            {showStudy && <Table.HeaderCell>Study</Table.HeaderCell>}
                                            <Table.HeaderCell>Special</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>Days Used</Table.Cell>
                                            <Table.Cell>{used}</Table.Cell>
                                            {showAccum && <Table.Cell>{accumUsed}</Table.Cell>}
                                            {showAccum2 && showAccumExpired === true && <Table.Cell>{accumUsed2}</Table.Cell>}
                                            {showSick && <Table.Cell>{usedSick}</Table.Cell>}
                                            {showFamily && <Table.Cell>{usedFamily}</Table.Cell>}
                                            {showMaternity && <Table.Cell>{usedMaternity}</Table.Cell>}
                                            {showPaternity && <Table.Cell>{usedPaternity}</Table.Cell>}
                                            {showIOD && <Table.Cell>{usedIOD}</Table.Cell>}
                                            {showMoving && <Table.Cell>{usedMoving}</Table.Cell>}
                                            {showCSR && <Table.Cell>{usedCSR}</Table.Cell>}
                                            {showReligious && <Table.Cell>{usedReligious}</Table.Cell>}
                                            {showStudy && <Table.Cell>{usedStudy}</Table.Cell>}
                                            <Table.Cell>{usedSpecial} h</Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>Days Left</Table.Cell>
                                            <Table.Cell>{left}</Table.Cell>
                                            {showAccum && <Table.Cell>{leftAccum}</Table.Cell>}
                                            {showAccum2 && showAccumExpired === true && <Table.Cell>{leftAccum2}</Table.Cell>}
                                            {showSick && <Table.Cell>{leftSick}</Table.Cell>}
                                            {showFamily && <Table.Cell>{leftFamily}</Table.Cell>}
                                            {showMaternity && <Table.Cell>{leftMaternity}</Table.Cell>}
                                            {showPaternity && <Table.Cell>{leftPaternity}</Table.Cell>}
                                            {showIOD && <Table.Cell>{leftIOD}</Table.Cell>}
                                            {showMoving && <Table.Cell>{leftMoving}</Table.Cell>}
                                            {showCSR && <Table.Cell>{leftCSR}</Table.Cell>}
                                            {showReligious && <Table.Cell>{leftReligious}</Table.Cell>}
                                            {showStudy && <Table.Cell>{leftStudy}</Table.Cell>}
                                            <Table.Cell>N/A</Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>Days Total</Table.Cell>
                                            <Table.Cell>{total}</Table.Cell>
                                            {showAccum && <Table.Cell>{accumTotal}</Table.Cell>}
                                            {showAccum2 && showAccumExpired === true && <Table.Cell>{accumTotal2}</Table.Cell>}
                                            {showSick && <Table.Cell>{totalSick}</Table.Cell>}
                                            {showFamily && <Table.Cell>{totalFamily}</Table.Cell>}
                                            {showMaternity && <Table.Cell>{totalMaternity}</Table.Cell>}
                                            {showPaternity && <Table.Cell>{totalPaternity}</Table.Cell>}
                                            {showIOD && <Table.Cell>{totalIOD}</Table.Cell>}
                                            {showMoving && <Table.Cell>{totalMoving}</Table.Cell>}
                                            {showCSR && <Table.Cell>{totalCSR}</Table.Cell>}
                                            {showReligious && <Table.Cell>{totalReligious}</Table.Cell>}
                                            {showStudy && <Table.Cell>{totalStudy}</Table.Cell>}
                                            <Table.Cell>N/A</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                {/* ) : null} */}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <hr />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <List style={{ width: "100%" }}>
                                {allLeave &&
                                    allLeave.length > 0 &&
                                    allLeave.map((leave) => (
                                        <ListItem key={leave.id}>
                                            <Segment>
                                                <Grid>
                                                    <Grid.Row columns={3} verticalAlign="middle">
                                                        <Grid.Column width={6}>
                                                            <div className="header">
                                                                {leave.annualFrom && format(leave.annualFrom.toDate(), "d MMMM yyyy")}
                                                                {leave.annualTo && " - " + format(leave.annualTo.toDate(), "d MMMM yyyy")}
                                                                {leave.fromTime && format(leave.fromTime.toDate(), "d MMMM yyyy H:m")}
                                                                {leave.toTime && " - " + format(leave.toTime.toDate(), "H:m")}
                                                                {(leave.useAccumulative && leave.useAccumulative === "Yes") || (leave.useAccumulative2 && leave.useAccumulative2 === "Yes")
                                                                    ? " - (Accumulative)"
                                                                    : ""}
                                                            </div>
                                                            <div style={{ color: "black" }}>
                                                                {leave.leaveType} Leave {leave.description ? " - " + leave.description : ""}
                                                            </div>
                                                        </Grid.Column>

                                                        <Grid.Column width={6}>
                                                            <div style={{ color: "black" }}>
                                                                {leave.annualDays === 1 || leave.annualDays === -1 ? leave.annualDays + " Day" : leave.annualDays !== 0 && leave.annualDays + " Days"}
                                                                {leave.unpaidLeave === true ? " (Unpaid)" : ""}
                                                                {leave.timeHours && (leave.timeHours === 1 ? leave.timeHours + " Hour" : leave.timeHours !== 0 && leave.timeHours + " Hours")}
                                                            </div>
                                                            {leave.addedBy !== leave.userUid && leave.addedByDisplayName && <div style={{ color: "black" }}>Added By: {leave.addedByDisplayName}</div>}
                                                        </Grid.Column>

                                                        <Grid.Column width={4} textAlign="right">
                                                            {leave.status === "Approved" && <Label color="green">{leave.status}</Label>}
                                                            {leave.status === "Denied" && <Label color="red">{leave.status}</Label>}
                                                            {(leave.status === "Pending" || leave.status === "Pending Change") && <Label color="orange">{leave.status}</Label>}
                                                            {leave.status === "Cancelled" && <Label color="grey">{leave.status}</Label>}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </ListItem>
                                    ))}
                            </List>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}

            {loading && loading === true ? (
                <>
                    <Segment style={{ height: "100px" }}>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                </>
            ) : null}
        </Container>
    );
}
