import React, { useEffect, useState } from "react";
import { Button, Container, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getAuthUser, getCompany } from "../clientActions";

export default function ClientsDashboardPage(props) {
    const [profile, setProfile] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getCompany().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
                setCompanies(list);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    return (
        <>
            {profile.isSuper === false || profile.isSuper === undefined ? (
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            ) : (
                <Container>
                    {profile.isSuper && (
                        <div className="ui grid dashboard_blocks">
                            {companies &&
                                companies.length > 0 &&
                                companies.map((company) => (
                                    <div className="five wide column" key={company.id}>
                                        <Segment>
                                            <h2>{company.companyName}</h2>

                                            <div className="img">
                                                <img className="ui fluid image" src={company.logoUrl || "/assets/logo.png"} alt="logo" />
                                            </div>

                                            {console.log(company.logoUrl)}

                                            <Button color="blue" as={Link} to={`/clients/staff/${company.id}`}>
                                                View Profile
                                            </Button>
                                        </Segment>
                                    </div>
                                ))}
                        </div>
                    )}
                </Container>
            )}
        </>
    );
}
