import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import { getProfileData, totalYearlyHours } from "../staffActions";
import LoadingComponent from "../../../app/layouts/LoadingComponent";

import firebase from "../../../app/config/firebase";
import { Table } from "semantic-ui-react";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";
const firestore = firebase.firestore();

export default function TotalTimeReport(props) {
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    const [Row, setRow] = useState([]);
    const [rowTotals, SetRowTotals] = useState([]);
    const [totalAll, setTotalAll] = useState([]);
    const [projectTotals, setProjectTotals] = useState([]);
    const [allPerc, setAllPerc] = useState([]);
    const [projectPerc, setProjectPerc] = useState([]);

    useEffect(() => {
        totalReportData();
    }, []);

    async function totalReportData() {
        let data = await getProfileData(userId);

        axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

        const response = await axios.post(apiURL + "/api/get_time_total_report", { allData: data });
        let responseData = response.data;

        let totalsAll = responseData[0];
        let totals = responseData[1];
        setRow(responseData);

        let jan = 0;
        let feb = 0;
        let mar = 0;
        let apr = 0;
        let may = 0;
        let jun = 0;
        let jul = 0;
        let aug = 0;
        let sept = 0;
        let oct = 0;
        let nov = 0;
        let dec = 0;
        let perProj = [];
        let projTotal = 0;

        for (var i = 0; i < totals.length; i++) {
            jan += parseFloat(totals[i].JanTotal);
            feb += parseFloat(totals[i].FebTotal);
            mar += parseFloat(totals[i].MarTotal);
            apr += parseFloat(totals[i].AprTotal);
            may += parseFloat(totals[i].MayTotal);
            jun += parseFloat(totals[i].JunTotal);
            jul += parseFloat(totals[i].JulTotal);
            aug += parseFloat(totals[i].AugTotal);
            sept += parseFloat(totals[i].SeptTotal);
            oct += parseFloat(totals[i].OctTotal);
            nov += parseFloat(totals[i].NovTotal);
            dec += parseFloat(totals[i].DecTotal);
            projTotal += parseFloat(totals[i].JanTotal);
            projTotal += parseFloat(totals[i].FebTotal);
            projTotal += parseFloat(totals[i].MarTotal);
            projTotal += parseFloat(totals[i].AprTotal);
            projTotal += parseFloat(totals[i].MayTotal);
            projTotal += parseFloat(totals[i].JunTotal);
            projTotal += parseFloat(totals[i].JulTotal);
            projTotal += parseFloat(totals[i].AugTotal);
            projTotal += parseFloat(totals[i].SeptTotal);
            projTotal += parseFloat(totals[i].OctTotal);
            projTotal += parseFloat(totals[i].NovTotal);
            projTotal += parseFloat(totals[i].DecTotal);
            perProj.push({ projectId: totals[i].projectId, projTotal: projTotal });
        }

        let totalRowsPM = { jan: jan, feb: feb, mar: mar, apr: apr, may: may, jun: jun, jul: jul, aug: aug, sept: sept, oct: oct, nov: nov, dec: dec };
        SetRowTotals([totalRowsPM]);

        let allT = 0;
        setProjectTotals(perProj);

        for (i = 0; i < totalsAll.length; i++) {
            allT += parseFloat(totalsAll[i].total);
        }

        let allMonthTotal = Object.values(totalRowsPM).reduce((a, b) => a + b, 0);

        let perProjPerc = [];
        for (i = 0; i < perProj.length; i++) {
            perProjPerc.push({ projectId: perProj[i].projectId, projTotal: Math.round((perProj[i].projTotal / allT) * 100) });
        }

        setProjectPerc(perProjPerc);
        setTotalAll({ type: "TOTAL Hours worked", allTotal: allT, workedTotal: allMonthTotal });
        setAllPerc({ type: "Proportion of required", workedTotal: Math.round((allMonthTotal / allT) * 100) });
    }

    if (Row.length == 0) {
        return <LoadingComponent />;
    }

    return (
        <div>
            <Table basic="very" celled collapsing unstackable style={{ width: "100%" }}>
                <Table.Header>
                    <Table.Row style={{ backgroundColor: "red", textAlign: "center", fontSize: "15px" }}>
                        <Table.HeaderCell>{format(new Date(), "yyyy")}</Table.HeaderCell>
                        <Table.HeaderCell>Total Required</Table.HeaderCell>
                        <Table.HeaderCell>Total Worked</Table.HeaderCell>
                        {Row[1] &&
                            Row[1].length > 0 &&
                            Row[1].map((entry) => (
                                <>
                                    {entry.projectName !== undefined && (
                                        <>
                                            <Table.HeaderCell>{entry.projectName}</Table.HeaderCell>
                                        </>
                                    )}
                                </>
                            ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Row[0] &&
                        Row[0].length > 0 &&
                        Row[0].map((entry, index) => (
                            <Table.Row>
                                <Table.Cell style={{ paddingLeft: 20 }}>{entry.year}</Table.Cell>
                                <Table.Cell>{entry.total}</Table.Cell>

                                {rowTotals &&
                                    rowTotals.length > 0 &&
                                    rowTotals.map((total) => (
                                        <>
                                            {index == 0 && <Table.Cell>{total.jan}</Table.Cell>}
                                            {index == 1 && <Table.Cell>{total.feb}</Table.Cell>}
                                            {index == 2 && <Table.Cell>{total.mar}</Table.Cell>}
                                            {index == 3 && <Table.Cell>{total.apr}</Table.Cell>}
                                            {index == 4 && <Table.Cell>{total.may}</Table.Cell>}
                                            {index == 5 && <Table.Cell>{total.jun}</Table.Cell>}
                                            {index == 6 && <Table.Cell>{total.jul}</Table.Cell>}
                                            {index == 7 && <Table.Cell>{total.aug}</Table.Cell>}
                                            {index == 8 && <Table.Cell>{total.sept}</Table.Cell>}
                                            {index == 9 && <Table.Cell>{total.oct}</Table.Cell>}
                                            {index == 10 && <Table.Cell>{total.nov}</Table.Cell>}
                                            {index == 11 && <Table.Cell>{total.dec}</Table.Cell>}
                                        </>
                                    ))}

                                {Row[1] &&
                                    Row[1].length > 0 &&
                                    Row[1].map((entry2) => (
                                        <>
                                            {index == 0 && <Table.Cell>{entry2.JanTotal}</Table.Cell>}
                                            {index == 1 && <Table.Cell>{entry2.FebTotal}</Table.Cell>}
                                            {index == 2 && <Table.Cell>{entry2.MarTotal}</Table.Cell>}
                                            {index == 3 && <Table.Cell>{entry2.AprTotal}</Table.Cell>}
                                            {index == 4 && <Table.Cell>{entry2.MayTotal}</Table.Cell>}
                                            {index == 5 && <Table.Cell>{entry2.JunTotal}</Table.Cell>}
                                            {index == 6 && <Table.Cell>{entry2.JulTotal}</Table.Cell>}
                                            {index == 7 && <Table.Cell>{entry2.AugTotal}</Table.Cell>}
                                            {index == 8 && <Table.Cell>{entry2.SeptTotal}</Table.Cell>}
                                            {index == 9 && <Table.Cell>{entry2.OctTotal}</Table.Cell>}
                                            {index == 10 && <Table.Cell>{entry2.NovTotal}</Table.Cell>}
                                            {index == 11 && <Table.Cell>{entry2.DecTotal}</Table.Cell>}
                                        </>
                                    ))}
                            </Table.Row>
                        ))}
                    <Table.Row style={{ backgroundColor: "lightgrey" }}>
                        <Table.Cell style={{ paddingLeft: 20 }}>
                            <b>{totalAll.type}</b>
                        </Table.Cell>
                        <Table.Cell>{totalAll.allTotal}</Table.Cell>
                        <Table.Cell>{totalAll.workedTotal}</Table.Cell>
                        {projectTotals && projectTotals.length > 0 && projectTotals.map((entry) => <Table.Cell>{entry.projTotal}</Table.Cell>)}
                    </Table.Row>
                    <Table.Row style={{ backgroundColor: "lightblue" }}>
                        <Table.Cell style={{ paddingLeft: 20 }}>
                            <b>{allPerc.type}</b>
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>{allPerc.workedTotal} %</Table.Cell>
                        {projectPerc && projectPerc.length > 0 && projectPerc.map((entry) => <Table.Cell>{entry.projTotal} %</Table.Cell>)}
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
}
