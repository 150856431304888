import React, { useState, useEffect } from "react";
import { Form, Button, Table, Grid } from "semantic-ui-react";
import { getInProgressTime, getProjectsUser, getThisTime } from "../staffActions";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Formik, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { timeTrackClear, getProfile, timeTrack, timeTrackRow, getCompany, getAuthUser, timeTrackRemove } from "../staffActions";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";

export default function TimeTrackForm(props) {
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    let typeAction = props.typeAction;
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [entry, setEntry] = useState([]);
    const [profile, setProfile] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [company, setCompany] = useState(false);
    const [auth, setAuth] = useState(false);
    const dispatch = useDispatch();
    const [idUser, setIdUser] = useState("");
    const [idCompany, setIdCompany] = useState("");
    const [idDepartment, setIdDepartment] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [clientView, setClientView] = useState(false);

    let type = props.type;
    let timeId = props.timeId;

    useEffect(() => {
        // let companyObj = [];
        getAuthUser().then(
            (doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    setAuth(appObj);
                    if (userId !== "home" && appObj.id !== userId && userId !== "landing") {
                        setClientView(true);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getProfile(userId).then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    setProfile(appObj);
                    getCompany(appObj.companyId).then(
                        (doc) => {
                            if (doc.exists) {
                                let companyObj = { ...doc.data(), id: doc.id };
                                setCompany(companyObj);
                                // companyObj.push(companyObj);
                            }
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );
        if (type !== "edit") {
            getInProgressTime(userId).then(
                (res) => {
                    const timetrack = Object.values(res);

                    Object.keys(timetrack).forEach(function (key) {
                        if (timetrack[key].projects) {
                            timetrack[key].hoursWorked = 0;

                            Object.keys(timetrack[key].projects).forEach(function (proj) {
                                timetrack[key].hoursWorked += parseFloat(timetrack[key].projects[proj]);
                            });
                        }

                        if (timetrack[key].day) {
                            var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            var dayName = weekday[timetrack[key].day.getDay()];

                            // timetrack[key].day = format(timetrack[key].day, "dd MMM");
                            timetrack[key].dayDate = "";
                            timetrack[key].dayDate += dayName;
                            // timetrack[key].hoursRequired = "";
                            // timetrack[key].hoursRequired += 8;
                        }
                    });

                    setEntry({ timetrack: timetrack });
                },
                (err) => {
                    console.log(err);
                }
            );
        } else {
            getThisTime(props.timeId).then(
                (res) => {
                    const timetrack = res;

                    Object.keys(timetrack).forEach(function (key) {
                        setIdUser(timetrack[key].userUid);
                        setIdCompany(timetrack[key].companyId);
                        setIdDepartment(timetrack[key].departmentId);
                        setDisplayName(timetrack[key].displayName);

                        if (timetrack[key].projects) {
                            timetrack[key].hoursWorked = 0;

                            Object.keys(timetrack[key].projects).forEach(function (proj) {
                                timetrack[key].hoursWorked += parseFloat(timetrack[key].projects[proj]);
                            });
                        }

                        if (timetrack[key].day) {
                            var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            var dayName = weekday[timetrack[key].day.getDay()];

                            // timetrack[key].day = format(timetrack[key].day, "dd MMM");
                            timetrack[key].dayDate = "";
                            timetrack[key].dayDate += dayName;
                            // timetrack[key].hoursRequired = "";
                            // timetrack[key].hoursRequired += 8;
                        }
                    });

                    setEntry({ timetrack: timetrack });
                },
                (err) => {
                    console.log(err);
                }
            );
        }
        getProjectsUser(userId, type).then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let projectList = [
                    {
                        key: "",
                        text: "None",
                        value: "",
                    },
                ];

                let cnt = -1;
                projectList = list.map(function (project) {
                    cnt++;
                    return {
                        key: cnt,
                        text: project.projectPrefix ? project.projectPrefix : project.projectName,
                        value: project.id + "|" + project.projectName,
                        hover: project.projectPrefix ? project.projectName : "",
                    };
                });

                setProjectList(projectList);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    function updateDate(value) {
        setFromDate(value);
    }
    function updateToDate(value) {
        setToDate(value);
    }

    function bulk(fromDate, toDate) {
        let currentDate = fromDate;
        let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        while (currentDate <= toDate) {
            if (format(currentDate, "eee") !== "Sat" && format(currentDate, "eee") !== "Sun") {
                let hours = 8;

                if (format(currentDate, "eee") === "Mon") {
                    if (company.mondayHours !== undefined) {
                        hours = company.mondayHours;
                    }
                } else if (format(currentDate, "eee") === "Tue") {
                    if (company.tuesdayHours !== undefined) {
                        hours = company.tuesdayHours;
                    }
                } else if (format(currentDate, "eee") === "Wed") {
                    if (company.wednesdayHours !== undefined) {
                        hours = company.wednesdayHours;
                    }
                } else if (format(currentDate, "eee") === "Thu") {
                    if (company.thursdayHours !== undefined) {
                        hours = company.thursdayHours;
                    }
                } else if (format(currentDate, "eee") === "Fri") {
                    if (company.fridayHours !== undefined) {
                        hours = company.fridayHours;
                    }
                }

                boundArrayHelpers.push({ hoursRequired: hours, hoursWorked: 0, day: currentDate, dayDate: weekday[currentDate.getDay()] });
            }

            currentDate = addDays(currentDate, 1);
        }
    }

    let boundArrayHelpers;
    const bindArrayHelpers = (arrayHelpers) => {
        boundArrayHelpers = arrayHelpers;
    };

    function setSubmitting() {
        setSubmit(true);
    }
    function unsetSubmitting() {
        setSubmit(false);
    }

    let term = false;
    let currDay = format(new Date(), "dd");
    if (company.fromDay !== undefined && company.toDate !== undefined) {
        term = currDay >= company.fromDay && currDay <= company.toDate;
    }

    function changeRequired(weekday) {
        if (weekday === "Monday") {
            if (company.mondayHours !== undefined) {
                return company.mondayHours;
            }
        } else if (weekday === "Tuesday") {
            if (company.tuesdayHours !== undefined) {
                return company.tuesdayHours;
            }
        } else if (weekday === "Wednesday") {
            if (company.wednesdayHours !== undefined) {
                return company.wednesdayHours;
            }
        } else if (weekday === "Thursday") {
            if (company.thursdayHours !== undefined) {
                return company.thursdayHours;
            }
        } else if (weekday === "Friday") {
            if (company.fridayHours !== undefined) {
                return company.fridayHours;
            }
        }
    }

    function updateTime(values, index) {
        let timeData = values.timetrack;
        let totalHoursWorked = 0;
        // if (isNaN(parseFloat(newValue))) {
        //     totalHoursWorked = 0;
        // } else {
        //     totalHoursWorked = parseFloat(newValue);
        // }

        Object.keys(timeData).forEach(function (key) {
            if (timeData[key].projects) {
                let timeProjects = timeData[key].projects;
                if (key == index) {
                    Object.keys(timeProjects).forEach(function (key2) {
                        let entry = timeProjects[key2].replace(",", ".").replace(" ", ".");
                        if (isNaN(parseFloat(entry))) {
                            totalHoursWorked += 0;
                        } else {
                            totalHoursWorked += parseFloat(entry);
                        }
                    });
                }
            }
        });

        // console.log(totalHoursWorked);

        return totalHoursWorked;
    }

    return (
        <Formik
            initialValues={entry}
            enableReinitialize="true"
            validate={(values) => {
                // const errors = {};
                // return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                if (submit === false) {
                    values.status = "In Progress";
                    values.userUid = props.userUid;
                } else {
                    values.status = "Pending";
                    values.submitted_by = auth.displayName;
                }

                if (type === "edit") {
                    values.status = "Pending";
                }

                let clear = {
                    clientView: clientView,
                    timetrackUser: userId,
                };

                //First delete all In Progress items
                if (type !== "edit") {
                    await timeTrackClear(clear);
                } else {
                    await timeTrackRemove(userId);
                }

                //Now add in new Time Tracking
                if (values.fromDate) {
                    delete values.fromDate;
                }
                if (values.toDate) {
                    delete values.toDate;
                }

                values.companyId = profile.companyId;
                values.departmentId = profile.departmentId;
                values.displayName = profile.displayName;
                values.daysCount = values.timetrack.length;

                let timetrack_sub = values["timetrack"];
                delete values.timetrack;

                let minDate = "";
                let maxDate = "";

                let totalHoursWorked = 0;
                let totalHoursRequired = 0;

                Object.keys(timetrack_sub).forEach(function (key) {
                    if (timetrack_sub[key].projects) {
                        totalHoursWorked += timetrack_sub[key].hoursWorked;
                        totalHoursRequired += parseFloat(timetrack_sub[key].hoursRequired);
                        Object.keys(timetrack_sub[key].projects).forEach(function (keySub) {
                            if (minDate === "") {
                                minDate = timetrack_sub[key].day;
                                maxDate = timetrack_sub[key].day;
                            }

                            if (minDate > timetrack_sub[key].day) {
                                minDate = timetrack_sub[key].day;
                            }

                            if (maxDate < timetrack_sub[key].day) {
                                maxDate = timetrack_sub[key].day;
                            }
                        });
                    } else {
                        if (minDate === "") {
                            minDate = timetrack_sub[key].day;
                            maxDate = timetrack_sub[key].day;
                        }

                        if (minDate > timetrack_sub[key].day) {
                            minDate = timetrack_sub[key].day;
                        }

                        if (maxDate < timetrack_sub[key].day) {
                            maxDate = timetrack_sub[key].day;
                        }
                    }
                });

                values.hoursTotal = totalHoursWorked;
                values.hoursRequired = totalHoursRequired;

                values.minDate = minDate;
                values.maxDate = maxDate;
                if (profile.approvalType === "Specific User") {
                    values.approvalUser = profile.approvalUser;
                }

                if (type === "edit") {
                    values.submitType = "edit";
                    values.editUserId = idUser;
                    values.companyId = idCompany;
                    values.departmentId = idDepartment;
                    values.displayName = displayName;
                } else {
                    values.clientView = clientView;
                    values.timetrackUser = userId;
                }

                let createdTimeId = await timeTrack(values);
                let timetrack_sub_new = [];
                let projects = projectList;

                Object.keys(timetrack_sub).forEach(function (key) {
                    if (timetrack_sub[key].projects) {
                        Object.keys(timetrack_sub[key].projects).forEach(function (keySub) {
                            let project = keySub.split("|");

                            if (!timetrack_sub[key].comments) {
                                timetrack_sub[key].comments = "";
                            }

                            timetrack_sub_new.push({
                                timeId: createdTimeId,
                                day: timetrack_sub[key].day,
                                hoursRequired: timetrack_sub[key].hoursRequired,
                                projectId: project[0],
                                projectName: project[1],
                                timeWorked: timetrack_sub[key].projects[keySub] === "" ? "0" : timetrack_sub[key].projects[keySub].replace(",", ".").replace(" ", "."),
                                comments: timetrack_sub[key].comments,
                                clientView: clientView,
                                timetrackUser: userId,
                            });
                        });
                    } else {
                        Object.keys(projects).forEach(function (keySub) {
                            if (!timetrack_sub[key].comments) {
                                timetrack_sub[key].comments = "";
                            }
                            let project = projects[keySub]["value"].split("|");
                            timetrack_sub_new.push({
                                timeId: createdTimeId,
                                day: timetrack_sub[key].day,
                                hoursRequired: timetrack_sub[key].hoursRequired,
                                projectId: project[0],
                                projectName: project[1],
                                timeWorked: "0",
                                comments: timetrack_sub[key].comments,
                                clientView: clientView,
                                timetrackUser: userId,
                            });
                        });
                    }
                });

                if (timetrack_sub_new !== "") {
                    await timetrack_sub_new.reduce(async (promise, row) => {
                        await promise;

                        if (type === "edit") {
                            row.submitType = "edit";
                            row.editUserId = idUser;
                        }
                        await timeTrackRow(row);
                    }, undefined);
                }

                setSubmitting(false);

                if (type === "edit") {
                    window.location.replace("/clients/time/" + idUser);
                }

                dispatch(closeModal());
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Grid style={{ marginBottom: 10 }}>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <label>From Date:</label>
                                <DatePicker
                                    name="fromDate"
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                    dropdownMode="select"
                                    value={values.fromDate}
                                    selected={fromDate}
                                    onChange={(value) => {
                                        updateDate(value);
                                        setFieldValue("fromDate", value);
                                    }}
                                    disabledKeyboardNavigation
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <label>To Date:</label>
                                <DatePicker
                                    name="toDate"
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                    dropdownMode="select"
                                    value={values.toDate}
                                    selected={toDate}
                                    onChange={(value) => {
                                        updateToDate(value);
                                        setFieldValue("toDate", value);
                                    }}
                                    disabledKeyboardNavigation
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Button size="medium" color="blue" content="Bulk Add Days" as="a" onClick={() => bulk(fromDate, toDate)} style={{ marginTop: "24px" }} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <FieldArray
                                    name="timetrack"
                                    render={(arrayHelpers) => (
                                        <div>
                                            {bindArrayHelpers(arrayHelpers)}
                                            {values.timetrack && values.timetrack.length > 0 ? (
                                                <>
                                                    <div>
                                                        <Table striped celled structured>
                                                            <Table.Header style={{ display: "inherit", width: "100%" }}>
                                                                <Table.Row>
                                                                    <Table.HeaderCell rowSpan={2} style={{ width: "10%" }}>
                                                                        Date
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell rowSpan={2} style={{ width: "10%" }}>
                                                                        Day
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell rowSpan={2} style={{ width: "10%" }}>
                                                                        Total Required
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell rowSpan={2} style={{ width: "10%" }}>
                                                                        Total Worked
                                                                    </Table.HeaderCell>

                                                                    <Table.HeaderCell colSpan={projectList.length} textAlign="center">
                                                                        Projects
                                                                    </Table.HeaderCell>

                                                                    <Table.HeaderCell rowSpan={2} style={{ width: "10%" }}>
                                                                        Comments
                                                                    </Table.HeaderCell>

                                                                    <Table.HeaderCell rowSpan={2} style={{ width: "10%" }}>
                                                                        {" "}
                                                                        <Button
                                                                            type="button"
                                                                            size="medium"
                                                                            color="blue"
                                                                            onClick={() => arrayHelpers.push({ day: "", dayDate: "", hoursWorked: 0, hoursRequired: 8, comments: "" })}
                                                                        >
                                                                            Add Day
                                                                        </Button>
                                                                    </Table.HeaderCell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    {projectList &&
                                                                        projectList.map((proj) => (
                                                                            <Table.HeaderCell textAlign="center" key={proj.value} style={{ width: "8%" }} data-tip={proj.hover}>
                                                                                {proj.hover !== "" ? (
                                                                                    <Popup trigger={<h5>{proj.text}</h5>} position={"top"} on={["hover", "focus"]} arrow="center top">
                                                                                        <p style={{ textAlign: "center", zIndex: "10000" }}> {proj.hover} </p>
                                                                                    </Popup>
                                                                                ) : (
                                                                                    <h5>{proj.text}</h5>
                                                                                )}
                                                                            </Table.HeaderCell>
                                                                        ))}
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body style={{ display: "block", maxHeight: "30vh", overflowY: "auto", overflowX: "hidden" }}>
                                                                {values.timetrack.map((row, index) => (
                                                                    <Table.Row key={index}>
                                                                        <Table.Cell style={{ width: "10.2%" }}>
                                                                            <DatePicker
                                                                                name={`timetrack.${index}.day`}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                showYearDropdown={true}
                                                                                showMonthDropdown={true}
                                                                                selected={values["timetrack"][index]["day"]}
                                                                                dropdownMode="select"
                                                                                onChange={(value) => {
                                                                                    setFieldValue(`timetrack.${index}.day`, value);
                                                                                    let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                                                    setFieldValue(`timetrack.${index}.dayDate`, weekday[value.getDay()]);
                                                                                    let day = changeRequired(weekday[value.getDay()]);
                                                                                    setFieldValue(`timetrack.${index}.hoursRequired`, day);
                                                                                }}
                                                                                disabledKeyboardNavigation
                                                                            />
                                                                        </Table.Cell>

                                                                        <Table.Cell style={{ width: "10.2%" }}>
                                                                            <MyTextInput name={`timetrack.${index}.dayDate`} width="120px" type="text" readOnly={true} />
                                                                        </Table.Cell>

                                                                        <Table.Cell style={{ width: "10.1%" }}>
                                                                            <MyTextInput name={`timetrack.${index}.hoursRequired`} width="60px" type="text" readOnly={true} />
                                                                        </Table.Cell>

                                                                        <Table.Cell style={{ width: "10.2%" }}>
                                                                            <MyTextInput name={`timetrack.${index}.hoursWorked`} width="60px" readOnly={true} type="text" />
                                                                        </Table.Cell>

                                                                        {projectList &&
                                                                            projectList.map((proj) => (
                                                                                <Table.Cell key={index + "-" + proj.value} style={{ width: "8.1%" }}>
                                                                                    <MyTextInput
                                                                                        name={`timetrack.${index}.projects.${proj.value}`}
                                                                                        onChange={(value) => {
                                                                                            setFieldValue(`timetrack.${index}.projects.${proj.value}`, value.target.value);
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            let time = updateTime(values, index);
                                                                                            setFieldValue(`timetrack.${index}.hoursWorked`, time);
                                                                                        }}
                                                                                        type="text"
                                                                                        width="60px"
                                                                                    />
                                                                                </Table.Cell>
                                                                            ))}

                                                                        <Table.Cell style={{ width: "10%" }}>
                                                                            <MyTextArea name={`timetrack.${index}.comments`} rows={3} />
                                                                        </Table.Cell>

                                                                        <Table.Cell textAlign="right" style={{ width: "10%" }}>
                                                                            <Button size="medium" color="red" content="Delete" as="a" onClick={() => arrayHelpers.remove(index)} />
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                ))}
                                                            </Table.Body>
                                                        </Table>
                                                    </div>
                                                    <Form.Group inline>
                                                        {typeAction !== "edit" ? (
                                                            <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" type="submit" color="blue" onClick={() => unsetSubmitting()}>
                                                                SAVE TIME TRACKING
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {company.trackAlways === undefined ||
                                                        company.trackAlways === true ||
                                                        auth.isAdmin === true ||
                                                        term === true ||
                                                        company.autoSubmit === false ||
                                                        company.autoSubmit === undefined ? (
                                                            <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="red" onClick={() => setSubmitting()}>
                                                                SUBMIT TIME TRACKING
                                                            </Button>
                                                        ) : null}
                                                    </Form.Group>
                                                </>
                                            ) : (
                                                <Table striped celled structured>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell rowSpan={2}>Date</Table.HeaderCell>
                                                            <Table.HeaderCell rowSpan={2}>Day</Table.HeaderCell>
                                                            <Table.HeaderCell rowSpan={2}>Total Required</Table.HeaderCell>
                                                            <Table.HeaderCell rowSpan={2}>Total Worked</Table.HeaderCell>

                                                            <Table.HeaderCell colSpan={projectList.length} textAlign="center">
                                                                Projects
                                                            </Table.HeaderCell>

                                                            <Table.HeaderCell rowSpan={2}>Comments</Table.HeaderCell>

                                                            <Table.HeaderCell rowSpan={2}>
                                                                {" "}
                                                                <Button type="button" size="medium" color="blue" onClick={() => arrayHelpers.push({ hoursWorked: 0, hoursRequired: 8 })}>
                                                                    Add Day
                                                                </Button>
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            {projectList &&
                                                                projectList.map((proj) => (
                                                                    <Table.HeaderCell textAlign="center" key={proj.value} style={{ width: "130px" }} data-tip={proj.hover}>
                                                                        {proj.hover !== "" ? (
                                                                            <Popup trigger={<h5>{proj.text}</h5>} position={"top"} on={["hover", "focus"]} arrow="center top">
                                                                                <p style={{ textAlign: "center", zIndex: "10000" }}> {proj.hover} </p>
                                                                            </Popup>
                                                                        ) : (
                                                                            <h5>{proj.text}</h5>
                                                                        )}
                                                                    </Table.HeaderCell>
                                                                ))}
                                                        </Table.Row>
                                                    </Table.Header>
                                                </Table>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}
