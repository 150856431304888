import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Segment, Grid, Container, Table } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { getTimeLists, moveTime, getProjectsUser } from "../staffActions";
import axios from "axios";
import format from "date-fns/format";
import apiURL from "../../../app/config/apiURL";
import Swal from "sweetalert2";

export default function TimeDashboardStats(props) {
    let profile = props.profile;
    let userUid = props.userUid;
    let auth = props.auth;
    let profileClient = props.profileClient;
    const [timeList, setTimeList] = useState([]);
    const [timeWorkedTotal, setTimeWorkedTotal] = useState(0);
    const [timeRequiredTotal, setTimeRequiredTotal] = useState(0);
    const [projectList, setProjectList] = useState([]);
    const [timeProjectList, setTimeProjectList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emptyEntries, setEmptyEntries] = useState(0);

    const dispatch = useDispatch();
    let clientView = props.clientView;
    let type = "add";

    if (clientView == true) {
        type = "edit";
    }

    useEffect(() => {
        getTimeLists(userUid).then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let timeListArr = {};
                let houresRequiredArr = {};
                let timeWorkedTotalData = 0;
                let timeRequiredTotalData = 0;

                //First create an Array with totals per project
                Object.keys(list).forEach(function (key) {
                    let thisObj = list[key];

                    if (timeListArr[thisObj["projectName"]]) {
                        timeListArr[thisObj["projectName"]] += parseInt(thisObj["timeWorked"]);
                        houresRequiredArr[thisObj["projectName"]] += parseInt(thisObj["hoursRequired"]);
                    } else {
                        timeListArr[thisObj["projectName"]] = parseInt(thisObj["timeWorked"]);
                        houresRequiredArr[thisObj["projectName"]] = parseInt(thisObj["hoursRequired"]);
                    }

                    timeWorkedTotalData += parseInt(thisObj["timeWorked"]);
                    timeRequiredTotalData += parseInt(thisObj["hoursRequired"]);
                });

                let timeList = [];

                //Then convert the array to an Object
                Object.keys(timeListArr).forEach(function (key) {
                    timeList.push({
                        projectName: key,
                        timeWorked: timeListArr[key],
                        hoursRequired: houresRequiredArr[key],
                    });
                });

                setTimeList(timeList);
                setTimeWorkedTotal(timeWorkedTotalData);
                setTimeRequiredTotal(timeRequiredTotalData);
            },
            (err) => {
                console.log(err);
            }
        );
        getProjectsUser(userUid).then(async (res) => {
            let list = [];
            res.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });

            // setProjectList(list);
            getTotals(list);
        });
    }, []);

    async function getTotals(list) {
        let fromDateFilter = format(new Date(), "yyyy-MM-01");
        const response = await axios.post(apiURL + "/api/get_time_percentage", { profileId: userUid, fromDateFilter: fromDateFilter });
        // console.log(response.data);
        let responseData = response.data[0];

        setEmptyEntries(response.data[2]);

        // setTimeProjectList(response.data[1]);
        // console.log(response.data[1]);
        let dataList = response.data[1];
        // console.log(list);

        let projectListData = [];

        for (var i = 0; i < list.length; i++) {
            // console.log(list[i]);
            let total = 0;
            for (var a = 0; a < dataList.length; a++) {
                if (dataList[a].projectId == list[i].id) {
                    total += parseFloat(dataList[a].time);
                }
            }

            projectListData.push({ projectName: list[i].projectName, time: total });
        }

        // console.log(projectListData);
        setTimeProjectList(projectListData);

        setTimeWorkedTotal(responseData && responseData.length > 0 && responseData.map((item) => (item.timeWorked !== null ? parseFloat(item.timeWorked) : 0)).reduce((prev, next) => prev + next));
        setTimeRequiredTotal(
            responseData && responseData.length > 0 && responseData.map((item) => (item.hoursRequired !== "" ? parseFloat(item.hoursRequired) : 0)).reduce((prev, next) => prev + next)
        );
    }

    async function moveCode() {
        await moveTime(profileClient);
    }

    async function deleteEmptyEntries() {
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to delete empty entries!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const response = await axios.post(apiURL + "/api/delete_empty", { profileId: userUid });

                window.location.reload();
            }
        });
    }

    return (
        <div>
            <Container>
                {profile && profile.hasTimeTracking && (
                    <Segment>
                        <h2>Time Tracking Overview</h2>

                        <Grid>
                            {timeWorkedTotal !== 0 && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Table celled>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Project</Table.HeaderCell>

                                                    {timeProjectList.map((proj, index) => (
                                                        <Table.HeaderCell key={`"project"${index}`}>
                                                            {proj.projectName} ({Math.round((proj.time / timeWorkedTotal) * 100 * 100) / 100}%)
                                                        </Table.HeaderCell>
                                                    ))}

                                                    <Table.HeaderCell>Total Worked</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>Time Worked</Table.Cell>

                                                    {timeProjectList.map((time, index) => (
                                                        <Table.Cell key={`"time"${index}`}>{time.time}</Table.Cell>
                                                    ))}

                                                    <Table.Cell>{timeWorkedTotal}</Table.Cell>
                                                </Table.Row>

                                                <Table.Row>
                                                    <Table.Cell>Hours Required</Table.Cell>

                                                    {timeProjectList.map((time, index) => (
                                                        <Table.Cell key={`"timeReq"${index}`}>{/* {time.hoursRequired} */}</Table.Cell>
                                                    ))}
                                                    <Table.Cell>{timeRequiredTotal}</Table.Cell>

                                                    {/* <Table.Cell>-</Table.Cell> */}
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            )}

                            <Grid.Row>
                                <Grid.Column>
                                    <Button as={Link} to={"/timetracking/input/" + profileClient.id} color="blue">
                                        INPUT TIME TRACKING (CURRENT)
                                    </Button>

                                    <Button as={Link} to={"/timetracking/previous/" + profileClient.id} color="blue">
                                        INPUT PREVIOUS TIME TRACKING
                                    </Button>

                                    <Button as={Link} to={"/timetracking/monthly_report/" + profileClient.id} color="blue">
                                        MONTHLY REPORT
                                    </Button>

                                    <Button onClick={() => dispatch(openModal({ modalType: "TotalReport" }))} color="blue">
                                        TOTAL REPORT
                                    </Button>

                                    {emptyEntries > 0 && (
                                        <Button onClick={() => deleteEmptyEntries()} loading={loading} color="red">
                                            Delete Empty Entries
                                        </Button>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                )}
            </Container>
        </div>
    );
}
