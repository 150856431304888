import React, { useState, useEffect, useCallback } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getAllDepartments, getAuthUser, deleteDepartment } from "../superActions";
import SmartDataTable from "react-smart-data-table";
import LoadingComponent from "../../../app/layout/LoadingComponent";

import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
const firestore = firebase.firestore();

export default function DepartmentsPage(props) {
    const [departmentsNew, setDepartmentsNew] = useState([]);
    // const [profile, setProfile] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [loading, setLoading] = useState(false);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        if (currentUserProfile.hasOwnProperty("id")) {
            setLoading(true);
            const unsubscribe = firestore.collection("departments").onSnapshot(async (snapshot) => {
                let list = [];
                snapshot.docs.forEach(async (doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };

                        await firestore
                            .collection("users")
                            .where("departmentId", "==", appObj.id)
                            .get()
                            .then((res) => {
                                if (res.docs.length > 0) {
                                    appObj.delete = "false";
                                } else {
                                    appObj.delete = "true";
                                }
                            });

                        list.push(appObj);
                    }
                });

                const someTimeoutAction = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve(list);
                        }, 3000);
                    });
                };

                const status = await someTimeoutAction();

                let departmentsNewData = [];
                if (currentUserProfile.isSuper !== true && status && status.length > 0) {
                    departmentsNewData = status.filter((proj) => {
                        return proj.companyId === currentUserProfile.companyId;
                    });
                } else {
                    departmentsNewData = status;
                }

                departmentsNewData.sort((a, b) => (a.departmentName < b.departmentName ? 1 : -1));
                departmentsNewData.sort((a, b) => (b.companyName < a.companyName ? 1 : -1));
                setDepartmentsNew(departmentsNewData);
                setLoading(false);
            });

            return () => unsubscribe();
        }

        // getAuthUser().then(
        //     (res) => {
        //         if (res.exists) {
        //             // setProfile(res.data());

        //             getAllDepartments().then(
        //                 async (dev) => {
        //                     let list = [];
        //                     dev.forEach(async (doc) => {
        //                         if (doc.exists) {
        //                             let appObj = { ...doc.data(), id: doc.id };

        //                             await firestore
        //                                 .collection("users")
        //                                 .where("departmentId", "==", appObj.id)
        //                                 .get()
        //                                 .then((res) => {
        //                                     if (res.docs.length > 0) {
        //                                         appObj.delete = "false";
        //                                     } else {
        //                                         appObj.delete = "true";
        //                                     }
        //                                 });

        //                             list.push(appObj);
        //                         }
        //                     });

        //                     const someTimeoutAction = () => {
        //                         return new Promise((resolve) => {
        //                             setTimeout(function () {
        //                                 resolve(list);
        //                             }, 500);
        //                         });
        //                     };

        //                     const status = await someTimeoutAction();

        //                     let departmentsNewData = [];
        //                     if (res.data().isSuper !== true && status && status.length > 0) {
        //                         departmentsNewData = status.filter((proj) => {
        //                             return proj.companyId === res.data().companyId;
        //                         });
        //                     } else {
        //                         departmentsNewData = status;
        //                     }

        //                     departmentsNewData.sort((a, b) => (b.companyName < a.companyName ? 1 : -1));
        //                     setDepartmentsNew(departmentsNewData);
        //                 },
        //                 (err) => {
        //                     console.log(err);
        //                 }
        //             );
        //         }
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );
    }, [currentUserProfile]);

    const updateFilter = useCallback((value) => {
        setFilterValue(value);
    }, []);
    function handleOnChange({ target: { name, value } }) {
        updateFilter(value);
    }

    async function removeFile(departmentId) {
        await deleteDepartment(departmentId);
    }

    const orderedHeaders = ["Company", "Department", "Action"];

    const headers = {
        Company: {
            text: "Company",
            transform: (value, index, row) => {
                let res = row.companyName;
                return res;
            },
        },
        Department: {
            text: "Department",
            transform: (value, index, row) => {
                let res = row.departmentName;
                return res;
            },
        },
        Action: {
            text: "Action",
            transform: (value, index, row) => {
                let res = (
                    <>
                        <Button as={Link} to={`/super/department/${row.id}`}>
                            Edit
                        </Button>
                        {row.delete === "true" && (
                            <Button color="red" onClick={() => window.confirm("Are you sure you wish to delete this department?") && removeFile(row.id)}>
                                Delete
                            </Button>
                        )}
                    </>
                );
                return res;
            },
        },
    };

    if (loading === true) return <LoadingComponent content="Loading app..." />;

    return (
        <>
            <Container>
                <div style={{ marginBottom: 20 }}>
                    <Button as={Link} to={`/super/department/0`}>
                        Add Department
                    </Button>
                    <Button as={Link} to={`/super/landing`}>
                        Back to Super
                    </Button>
                    <div className="ui icon input">
                        <input type="text" name="filterValue" value={filterValue} placeholder="Filter results..." onChange={handleOnChange} />
                        <i className="search icon" />
                    </div>
                </div>

                <SmartDataTable
                    data={departmentsNew}
                    name="users-table"
                    className="ui compact selectable table celled"
                    sortable
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={true}
                    filterValue={filterValue}
                />
            </Container>
        </>
    );
}
