import React, { useState, useEffect } from "react";
import { Form, Container, Button, Segment, Grid, Label, Header, Icon, Loader, Dimmer, Menu } from "semantic-ui-react";
import { updateOnboardingProfile, getAuthUser, getProfileClient } from "../user/userActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import MyRadioButton from "../../app/common/form/MyRadioButton";
import { generateLeaveStats } from "../manager/managerActions";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import apiURL from "../../app/config/apiURL";

const version = "3.13.33";

export default function OnboardingPage(props) {
    const [dob, setDob] = useState("");
    const [submitFailed, setSubmitFailed] = useState(false);
    const [profile, setProfile] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();

    const { currentUserProfile } = useSelector((state) => state.profile);

    // console.log(props);

    useEffect(() => {
        // console.log(id == "landing");
        if (id !== "basic") {
            getProfileClient(id).then(
                (res) => {
                    if (res.exists) {
                        setInitialValues(res.data());
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
        }

        if (id == "landing") {
            if (props.profile && props.profile.id !== undefined) {
                getProfileClient(props.profile.id).then(
                    (res) => {
                        if (res.exists) {
                            setInitialValues(res.data());
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            }
        }

        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                    if (res.data().dateOfBirth !== undefined) {
                        setDob(res.data().dateOfBirth.toDate());
                    }
                    if (id == "basic") {
                        setInitialValues(res.data());
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, [props]);

    const race = [
        { key: "", text: "Choose an Option", value: "" },
        { key: "African", text: "African", value: "African" },
        { key: "White", text: "White", value: "White" },
        { key: "Coloured", text: "Coloured", value: "Coloured" },
        { key: "Indian", text: "Indian", value: "Indian" },
        { key: "Asian", text: "Asian", value: "Asian" },
    ];

    const employee_marital_status = [
        { key: "", text: "Choose an Option", value: "" },
        { key: "Single", text: "Single", value: "Single" },
        { key: "Married", text: "Married", value: "Married" },
        { key: "Divorced", text: "Divorced", value: "Divorced" },
        { key: "Widowed", text: "Widowed", value: "Widowed" },
    ];

    function updateFrom(value) {
        setDob(value);
    }

    function onIDChange(value) {
        //
    }
    return (
        <Container>
            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validate={(values) => {
                    const error = {};
                    if (!values.employee_id_no) {
                        error.employee_id_no = "Identity No is required";
                    }
                    if (!values.employee_initials) {
                        error.employee_initials = "Initials are required";
                    }
                    if (!values.employee_full_first_names) {
                        error.employee_full_first_names = "Full First Names are required";
                    }
                    if (!values.employee_surname) {
                        error.employee_surname = "Surname is required";
                    }
                    if (profile && !profile.onboardingDone) {
                        if (!values.newPassword1) {
                            error.newPassword1 = "Please enter a password";
                        }
                        if (!values.newPassword2) {
                            error.newPassword2 = "Please confirm your new password";
                        }
                    }
                    return error;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    if (profile && profile.length > 0) {
                        values.onboardingDone = profile.onboardingDone;
                    }

                    if (props.creds !== undefined && props.creds.password !== undefined) {
                        values.creds_password = props.creds.password;
                        values.creds_email = props.creds.email;
                    }

                    if (id !== "basic" && id !== "landing") {
                        values.EditUserId = id;
                        values.userType = "edit";
                    } else {
                        values.userType = "own";
                        values.onboardingDone = true;
                    }

                    // console.log(currentUserProfile.id)

                    // console.log(initialValues.id);

                    if (values.file !== undefined) {
                        var formData = new FormData();
                        var imagefile = document.querySelector("#file");
                        formData.append("file", imagefile.files[0]);
                        formData.append("id", initialValues.id);

                        await axios.post(apiURL + "/api/save_img", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                        delete values.file;
                    }

                    await generateLeaveStats(currentUserProfile.id, "");
                    await updateOnboardingProfile(values);
                    setSubmitting(false);
                    if (id !== "basic" && id !== "landing") {
                        window.location.replace("/home/" + id);
                    } else {
                        window.location.replace("/home");
                    }
                }}
            >
                {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid stackable>
                            <Grid.Row columns={2}>
                                <Grid.Column width={7}>
                                    <Segment>
                                        <Header dividing size="large" content="Personal Details" />

                                        <Grid>
                                            {profile && !profile.onboardingDone && (
                                                <Grid.Row verticalAlign="middle" columns={2}>
                                                    <Grid.Column width={4}>
                                                        <label>Password*</label>
                                                    </Grid.Column>

                                                    <Grid.Column width={6}>
                                                        <MyTextInput name="newPassword1" type="password" pointing="left" placeholder="New Password" />
                                                        {error && (
                                                            <Label basic color="red">
                                                                {error}
                                                            </Label>
                                                        )}
                                                    </Grid.Column>

                                                    <Grid.Column width={6}>
                                                        <MyTextInput name="newPassword2" type="password" pointing="left" placeholder="Confirm Password" />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Employee Title</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="employee_title" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Initials*</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="employee_initials" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Full First Names*</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="employee_full_first_names" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Surname*</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="employee_surname" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Known As Name</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="displayName" type="text" disabled />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Identity No*</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="employee_id_no" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    <label>Date of Birth</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <DatePicker
                                                        name="dateOfBirth"
                                                        dateFormat="yyyy-MM-dd"
                                                        showYearDropdown={true}
                                                        showMonthDropdown={true}
                                                        dropdownMode="select"
                                                        value={values.dateOfBirth}
                                                        selected={dob}
                                                        onChange={(value) => {
                                                            updateFrom(value);
                                                            setFieldValue("dateOfBirth", value);
                                                        }}
                                                        disabledKeyboardNavigation
                                                        inline
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Race</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MySelectInput
                                                        name="employee_race"
                                                        options={race}
                                                        value={values.employee_race}
                                                        onChange={(field, value) => setFieldValue("employee_race", value.value)}
                                                        placeholder="Select your Race"
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    <label>Gender</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <Form.Group inline>
                                                        <MyRadioButton name="gender" type="radio" value="male" label="" labelcb="Male" />

                                                        <MyRadioButton name="gender" type="radio" value="female" label="" labelcb="Female" />
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Marital Status</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MySelectInput
                                                        name="employee_marital_status"
                                                        options={employee_marital_status}
                                                        value={values.employee_marital_status}
                                                        onChange={(field, value) => setFieldValue("employee_marital_status", value.value)}
                                                        placeholder="Select your Marital Status"
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    <label>Profile Picture</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <input
                                                        id="uploadFile"
                                                        name="uploadFile"
                                                        type="file"
                                                        onChange={(event) => {
                                                            console.log(event.currentTarget.files[0]);
                                                            setFieldValue("uploadFile", event.currentTarget.files[0]);
                                                        }}
                                                        className="form-control"
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    <label>Signature</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <input
                                                        id="file"
                                                        name="file"
                                                        type="file"
                                                        onChange={(event) => {
                                                            setFieldValue("file", event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>

                                    <Segment>
                                        <Header dividing size="large" content="Your Contact Details" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Home Telephone Number</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="home_tel_nr" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Cellphone Number</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="cell_nr" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Work Email Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="email_work" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Personal Email Addess</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="email_personal" type="email" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    <Segment>
                                        <Header dividing size="large" content="Your Physical Address" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address Line 1</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="physical_address_1" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address Line 2</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="physical_address_2" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Postal Code</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="physical_postal_code" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    <Segment>
                                        <Header dividing size="large" content="Your Postal Address" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address Line 1</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="postal_address_1" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address Line 2</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="postal_address_2" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Postal Code</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="postal_postal_code" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    <Segment>
                                        <Header dividing size="large" content="Details of Dependents" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Number of Children</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="dependent_nr_children" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Names & Age</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="dependent_name_age" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    <Segment>
                                        <Header dividing size="large" content="Spouses / Partners Contact Details" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Spouses / Partners Name</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="spouse_name" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Home Telephone Number</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="spouse_home_tel_nr" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Cellphone Number</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="spouse_cell_nr" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="spouse_address" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Postal Code</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="spouse_postal_code" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Email Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="spouse_email" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    <Segment>
                                        <Header dividing size="large" content="Medical Aid" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Aid Name</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="medical_aid_name" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Plan</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="medical_aid_plan" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Number</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="medical_aid_number" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Cellular Telephone No</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="doctor_cell" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            {/* <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="doctor_address" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Postal Code</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="doctor_postal_code" type="text" />
                                                </Grid.Column>
                                            </Grid.Row> */}
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={7}>
                                    <Segment>
                                        <Header dividing size="large" content="Details of Family or Friend (Not Living with You)" />

                                        <Grid>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Name</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_name" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Relationship</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_relationship" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Home Telephone No</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_home_tel_no" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Work Telephone No</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_work_tel_no" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Cellular Telephone No</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_cell" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_address" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Postal Code</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_postal_code" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row verticalAlign="middle" columns={2}>
                                                <Grid.Column width={4}>
                                                    <label>Email Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="family_email" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    {profile && !profile.onboardingDone && (
                                        <Segment>
                                            <Header dividing size="large" content="Banking Details" />

                                            <Grid>
                                                <Grid.Row columns={2} verticalAlign="middle">
                                                    <Grid.Column width={4}>
                                                        <label>Type of Bank A/C:</label>
                                                    </Grid.Column>

                                                    <Grid.Column width={12}>
                                                        <MyTextInput name="bank_type" type="text" />
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row columns={2} verticalAlign="middle">
                                                    <Grid.Column width={4}>
                                                        <label>Name of Bank</label>
                                                    </Grid.Column>

                                                    <Grid.Column width={12}>
                                                        <MyTextInput name="bank_name" type="text" />
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row columns={2} verticalAlign="middle">
                                                    <Grid.Column width={4}>
                                                        <label>Branch Name & Code</label>
                                                    </Grid.Column>

                                                    <Grid.Column width={12}>
                                                        <MyTextInput name="bank_branch" type="text" />
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row columns={2} verticalAlign="middle">
                                                    <Grid.Column width={4}>
                                                        <label>Bank Account No</label>
                                                    </Grid.Column>

                                                    <Grid.Column width={12}>
                                                        <MyTextInput name="bank_acc_no" type="text" />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    )}
                                    <Segment>
                                        <Header dividing size="large" content="Contract Information" />

                                        <Grid>
                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Job Title</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_job_title" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Type of Contract</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_type" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Commencement Date</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_commencement_date" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Termination Date</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_termination_date" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={3} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Working Hours</label>
                                                </Grid.Column>

                                                <Grid.Column width={4}>
                                                    <MyTextInput name="contract_working_pw" type="text" />
                                                </Grid.Column>

                                                <Grid.Column width={2}>p/w</Grid.Column>

                                                <Grid.Column width={4}>
                                                    <MyTextInput name="contract_working_pm" type="text" />
                                                </Grid.Column>

                                                <Grid.Column width={2}>p/m</Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Annual Leave Days</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_annual_leave_days" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Gross Basic Salary</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_gross_basic_salary" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            {/* <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Commission</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_commission" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Incentives</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_incentives" type="text" />
                                                </Grid.Column>
                                            </Grid.Row> */}

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Benefits</label>
                                                </Grid.Column>

                                                <Grid.Column width={4}>Provident Fund</Grid.Column>

                                                <Grid.Column width={3} textAlign="right">
                                                    State % / R
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <MyTextInput name="contract_provident_fund" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}></Grid.Column>

                                                <Grid.Column width={4}>Cell phone allowance</Grid.Column>

                                                <Grid.Column width={3} textAlign="right">
                                                    State % / R
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <MyTextInput name="contract_phone_allowance" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}></Grid.Column>

                                                <Grid.Column width={4}>Pension Fund</Grid.Column>

                                                <Grid.Column width={3} textAlign="right">
                                                    State % / R
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <MyTextInput name="contract_pension_fund" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}></Grid.Column>

                                                <Grid.Column width={4}>Medical Aid</Grid.Column>

                                                <Grid.Column width={3} textAlign="right">
                                                    State
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <MyTextInput name="contract_medical_aid" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}></Grid.Column>

                                                <Grid.Column width={4}>Travel</Grid.Column>

                                                <Grid.Column width={3} textAlign="right">
                                                    State
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <MyTextInput name="contract_travel" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}></Grid.Column>

                                                <Grid.Column width={4}>Petrol</Grid.Column>

                                                <Grid.Column width={3} textAlign="right">
                                                    State
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <MyTextInput name="contract_petrol" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Tax Number (SARS)</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_tax_nr" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Based at (Co Name)</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_based_at" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Company Address</label>
                                                </Grid.Column>

                                                <Grid.Column width={12} style={{ marginBottom: 20 }}>
                                                    <MyTextInput name="contract_company_address_1" type="text" placeholder="Address Line 1" />
                                                </Grid.Column>

                                                <Grid.Column width={4}></Grid.Column>
                                                <Grid.Column width={12} style={{ marginBottom: 20 }}>
                                                    <MyTextInput name="contract_company_address_2" type="text" placeholder="Address Line 2" />
                                                </Grid.Column>

                                                <Grid.Column width={4}></Grid.Column>
                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_company_address_3" type="text" placeholder="Address Line 3" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Office / Work tel number</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_contact_no" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Reporting to</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="contract_reporting_to" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    {profile && !profile.onboardingDone && (
                                        <></>
                                        // <Segment>
                                        //     <Header dividing size="large" content="Document Uploads" />

                                        //     <Grid.Row>
                                        //         <Grid.Column width={16}>
                                        //             <p>
                                        //                 This following uploads are required:
                                        //                 <br />
                                        //                 Identity Document
                                        //                 <br />
                                        //                 Banking Confirmation Letter
                                        //             </p>
                                        //             {/* <Dropzone disabled={this.state.uploading} onDrop={this.onDrop} multiple={false}>
                                        //             <div style={{ paddingTop: "30px", textAlign: "center" }}>
                                        //                 <Icon name="upload" size="huge" />
                                        //                 <Header content="Drop file here or click to add" />

                                        //                 <Dimmer active={this.state.uploading}>
                                        //                     <Loader>Uploading...</Loader>
                                        //                 </Dimmer>
                                        //             </div>
                                        //         </Dropzone> */}
                                        //         </Grid.Column>
                                        //     </Grid.Row>
                                        // </Segment>
                                    )}
                                </Grid.Column>

                                {id === "basic" && (
                                    <Grid.Column width={2}>
                                        <p>V {version}</p>
                                        <Menu vertical>
                                            <Header icon="settings" attached inverted color="grey" content="Account" />
                                            <Menu.Item as={Link} to="/account">
                                                My Account
                                            </Menu.Item>
                                        </Menu>

                                        <Grid.Row />
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                        </Grid>
                        <Segment>
                            <Button disabled={isSubmitting} size="large" positive content="Save" type="submit" color="blue" />
                            {submitFailed && submitFailed === true && (
                                <Label basic color="red">
                                    Please fill in all required fields
                                </Label>
                            )}
                        </Segment>
                    </form>
                )}
            </Formik>
        </Container>
    );
}
