import React from "react";
import { connect } from "react-redux";
import LeaveTrackModal from "./LeaveTrackModal";
import LeaveManualTrackModal from "./LeaveManualTrackModal";
import TimeTrackModal from "./TimeTrackModal";
import TotalReport from "./TotalReport";
import TimeViewModal from "./TimeViewModal";
//import LeaveViewModal from "./LeaveViewModal";
import SupportForm from "./SupportForm";
import LeaveChangeModal from "./LeaveChangeModal";
import LeaveAppModal from "./LeaveAppModal";
import ExpenseAppModal from "./ExpenseAppModal";
import ExpenseEditModal from "./ExpenseEditModal";
import TimeAppModal from "./TimeAppModal";
import MultiExpenseAppModal from "./MultiExpenseAppModal";
import RemoteWorkModal from "./RemoteWorkModal";
import EditRemoteWorkModal from "./EditRemoteWorkModal";

const modalLookup = {
    LeaveTrackModal,
    TimeTrackModal,
    TimeViewModal,
    //LeaveViewModal,
    SupportForm,
    LeaveManualTrackModal,
    LeaveChangeModal,
    LeaveAppModal,
    TimeAppModal,
    RemoteWorkModal,
    EditRemoteWorkModal,
    TotalReport,
    ExpenseAppModal,
    ExpenseEditModal,
    MultiExpenseAppModal,
};

const mapState = (state) => ({
    currentModal: state.modals,
});

const ModalManager = ({ currentModal }) => {
    let renderedModal;

    if (currentModal) {
        const { modalType, modalProps } = currentModal;
        const ModalComponent = modalLookup[modalType];

        renderedModal = <ModalComponent {...modalProps} />;
    }
    return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
