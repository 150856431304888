import React, { useCallback, useState } from "react";
import { Container, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SmartDataTable from "react-smart-data-table";
import { useEffect } from "react";
import { getUsers } from "../superActions";
import firebase from "../../../app/config/firebase.js";

const firestore = firebase.firestore();

export default function UsersPage(props) {
    const [filterValue, setFilterValue] = useState("");
    const [users, setUsers] = useState([]);
    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        if (currentUserProfile.hasOwnProperty("id")) {
            if (currentUserProfile.isSuper === true) {
                const unsubscribe = firestore.collection("users").onSnapshot((snapshot) => {
                    let list = [];
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), id: doc.id };
                            list.push(appObj);
                        }
                    });

                    list.sort((a, b) => (a.email > b.email ? 1 : -1));
                    setUsers(list);
                });

                return () => unsubscribe();
            } else {
                const unsubscribe = firestore
                    .collection("users")
                    .where("companyId", "==", currentUserProfile.companyId)
                    .onSnapshot((snapshot) => {
                        let list = [];
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });

                        list.sort((a, b) => (a.email > b.email ? 1 : -1));
                        setUsers(list);
                    });

                return () => unsubscribe();
            }
        }

        // getUsers().then(
        //     (res) => {
        //         let list = [];
        //         res.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), id: doc.id };
        //                 list.push(appObj);
        //             }
        //         });

        //         list.sort((a, b) => (a.email > b.email ? 1 : -1));
        //         setUsers(list);
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );
    }, []);

    const headers = {
        isSuper: {
            text: "Super",
            transform: (value, index, row) => {
                let res = "";

                if (value === true) {
                    res = "Yes";
                }

                return res;
            },
        },
        isAdmin: {
            text: "Admin",
            transform: (value, index, row) => {
                let res = "";

                if (value === true) {
                    res = "Yes";
                }

                return res;
            },
        },
        isLineManager: {
            text: "Line Manager",
            transform: (value, index, row) => {
                let res = "";

                if (value === true) {
                    res = "Yes";
                }

                return res;
            },
        },
        isStaff: {
            text: "Staff",
            transform: (value, index, row) => {
                let res = "";

                if (value === true) {
                    res = "Yes";
                }

                return res;
            },
        },
        isTerminated: {
            text: "Terminated",
            transform: (value, index, row) => {
                let res = "";

                if (value === true) {
                    res = "Yes";
                }

                return res;
            },
        },
    };

    const orderedHeaders = ["displayName", "email", "companyName", "departmentName", "isSuper", "isAdmin", "isLineManager", "isStaff", "isTerminated"];

    const updateFilter = useCallback((value) => {
        setFilterValue(value);
    }, []);
    function handleOnChange({ target: { name, value } }) {
        updateFilter(value);
    }

    function onRowClick(event, { rowData, rowIndex, tableData }) {
        const { fullName, name, id } = rowData;
        let value = fullName || name || id;

        if (!value) {
            const [key] = Object.keys(rowData);
            value = `${key}: ${rowData[key]}`;
        }

        props.history.push("/super/user/" + value);
    }

    return (
        <Container>
            <div style={{ marginBottom: 20 }}>
                <Button as={Link} to={`/super/user/0`}>
                    Add User
                </Button>
                <Button as={Link} to={`/super/landing`}>
                    Back to Super
                </Button>

                <div className="ui icon input">
                    <input type="text" name="filterValue" value={filterValue} placeholder="Filter results..." onChange={handleOnChange} />
                    <i className="search icon" />
                </div>
            </div>

            <SmartDataTable
                data={users}
                name="users-table"
                className="ui compact selectable table celled"
                sortable
                onRowClick={onRowClick}
                headers={headers}
                orderedHeaders={orderedHeaders}
                hideUnordered={true}
                filterValue={filterValue}
            />
        </Container>
    );
}
