import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Segment } from "semantic-ui-react";

export default function AdminDashboardPage(props) {
    return (
        <Container>
            <Segment>
                <h1>Admin Dashboard Page</h1>

                {/* <Button color="blue" as={Link} to="/admin/loadleave/takeon/">
                    Load Take On Balances
                </Button> */}

                {/* <Button color="blue" as={Link} to="/admin/loadleave/manual/">
                    Load Manual Leave
                </Button> */}
            </Segment>
        </Container>
    );
}
