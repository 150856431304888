import cuid from "cuid";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";
import axios from "axios";
import apiURL from "../../app/config/apiURL";

export async function uploadIRP5(irp5) {
    const firestore = firebase.firestore();

    // const path = `${irp5.userUid}/irp5`;
    // const options = {
    //     name: cuid() + "-" + irp5.uploadFile.name,
    // };
    // const file = irp5.uploadFile;

    try {
        // let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        let new_irp5 = {};
        // new_irp5.fileName = irp5.uploadFile.name;
        new_irp5.fileUrl = irp5.downloadURL;
        new_irp5.documentName = irp5.documentName;
        // new_irp5.documentNameRand = options.name;
        new_irp5.userUid = irp5.userUid;
        new_irp5.status = "Mapped";

        new_irp5.date = new Date();
        new_irp5.version = 1;

        await firestore.collection("irp5").add(new_irp5);

        toast.success("IRP5 added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function customUploadIRP5(irp5) {
    const firestore = firebase.firestore();

    const path = `${irp5.userUid}/irp5`;
    const options = {
        name: cuid() + "-" + irp5.uploadFile.name,
    };
    const file = irp5.uploadFile;

    try {
        let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        let new_irp5 = {};
        new_irp5.fileName = irp5.uploadFile.name;
        new_irp5.fileUrl = downloadURL;
        new_irp5.documentName = irp5.documentName;
        new_irp5.documentNameRand = options.name;
        new_irp5.userUid = irp5.userUid;
        new_irp5.status = "Mapped";

        new_irp5.date = new Date();
        new_irp5.version = 1;

        await firestore.collection("irp5").add(new_irp5);

        toast.success("IRP5 added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function deleteIRP5(irp5) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    var storageRef = firebase.storage().ref();

    storageRef.child(`${user.uid}/irp5/${irp5.documentNameRand}`).getDownloadURL().then(onResolve, onReject);

    async function onResolve(foundURL) {
        await firebase.deleteFile(`${user.uid}/irp5/${irp5.documentNameRand}`);
        await firestore.collection("irp5").doc(irp5.id).delete();
    }

    async function onReject(error) {
        await firestore.collection("irp5").doc(irp5.id).delete();
    }
}

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getIrp5(id) {
    let userId = "";
    if (id == "irp5") {
        userId = firebase.auth().currentUser.uid;
    } else {
        userId = id;
    }
    const firestore = firebase.firestore();
    return firestore.collection("irp5").where("userUid", "==", userId).where("status", "!=", "Unsorted").get();
}
export async function getUsers() {
    const firestore = firebase.firestore();
    return firestore.collection("users").get();
}
export async function getProfile(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userId).get();
}

export async function getCompanies() {
    const firestore = firebase.firestore();
    return firestore.collection("companies").get();
}

export async function confirmMapping(irp5) {
    const firestore = firebase.firestore();

    try {
        let new_irp5 = {
            status: "Mapped",
        };

        if (irp5.encrypt === true) {
            let user = await firestore
                .collection("users")
                .where("employeeNr", "==", irp5.employeeNr)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        if (doc.exists) {
                            irp5.userUid = doc.id;
                            irp5.displayName = doc.data().displayName;
                            irp5.employeeNr = doc.data().employeeNr;

                            let userData = doc.data();

                            var formData = new FormData();
                            formData.append("documentName", irp5.documentName);
                            formData.append("fileUrl", irp5.fileUrl);
                            formData.append("fileUrlString", irp5.fileUrl.split("/").pop());
                            formData.append("fileName", irp5.fileName);
                            formData.append("pass", userData.employee_id_no);
                            formData.append("id", doc.id);
                            formData.append("employeeNr", irp5.employeeNr);

                            let FileUpload = await axios.post(apiURL + "/api/encrypt_bulk_pdf", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });

                            new_irp5.fileUrl = apiURL + "/" + FileUpload.data;

                            await firestore.collection("irp5").doc(irp5.id).update(new_irp5);
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error find user: ", error);
                });
        } else {
            await firestore.collection("irp5").doc(irp5.id).update(new_irp5);
        }

        //Action Center
        let notification = {
            createDate: new Date(Date.now()),
            notificationType: "Notification",
            rowModule: "IRP5",
            rowType: "",
            rowId: irp5.id,
            description: "IRP5 Uploaded - " + irp5.documentName,
            read: false,
            userUid: irp5.userUid,
            displayName: irp5.displayName,
        };

        await firestore.collection("notifications").add(notification);

        toast.success("IRP5 added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function customBulkIRP5(irp5) {
    console.log(irp5);
    const firestore = firebase.firestore();
    try {
        let new_irp5 = {};
        new_irp5.fileName = irp5.documentFullName;
        new_irp5.fileUrl = irp5.downloadURL;
        new_irp5.date = new Date();
        new_irp5.status = "Unsorted";
        new_irp5.documentName = irp5.documentName;
        new_irp5.companyId = irp5.companyId;
        new_irp5.encrypt = irp5.encrypt;

        console.log(new_irp5);
        //Get company data
        let company = await firestore.collection("companies").doc(irp5.companyId).get();
        company = company.data();
        new_irp5.companyName = company.companyName;
        //     //Try to map to user
        let search = new_irp5.fileName.replace(".pdf", "");
        search = search.replace(".PDF", "");

        if (irp5.mapBy === "Employee Number") {
            await firestore
                .collection("users")
                .where("employeeNr", "==", search)
                .where("companyId", "==", irp5.companyId)
                .limit(1)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            new_irp5.userUid = doc.id;
                            new_irp5.displayName = doc.data().displayName;
                            new_irp5.employeeNr = doc.data().employeeNr;
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error find user: ", error);
                });
        } else {
            await firestore
                .collection("users")
                .where("companyId", "==", irp5.companyId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            let parts = search.split("_");
                            if (doc.data().employee_initials !== undefined) {
                                if (parts[1] == doc.data().employee_initials.toLowerCase() && parts[2] == doc.data().employee_surname.toLowerCase()) {
                                    new_irp5.userUid = doc.id;
                                    new_irp5.displayName = doc.data().displayName;
                                    new_irp5.employeeNr = doc.data().employeeNr;
                                }
                            }
                        }
                    });
                });
        }
        await firestore.collection("irp5").add(new_irp5);
        toast.success("IRP5 added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function uploadBulkIRP5(irp5, documentName, companyId, mapBy, encrypt) {
    const firestore = firebase.firestore();
    const path = `irp5`;
    const options = {
        name: cuid() + "-" + irp5.name,
    };
    const file = irp5;

    try {
        let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());
        let new_irp5 = {};
        new_irp5.fileName = irp5.name;
        new_irp5.fileUrl = downloadURL;
        new_irp5.documentNameRand = options.name;
        new_irp5.date = new Date();
        new_irp5.status = "Unsorted";
        new_irp5.documentName = documentName;
        new_irp5.companyId = companyId;
        new_irp5.encrypt = encrypt;
        //Get company data
        let company = await firestore.collection("companies").doc(companyId).get();
        company = company.data();
        new_irp5.companyName = company.companyName;

        //Try to map to user
        let search = new_irp5.fileName.replace(".pdf", "");
        search = search.replace(".PDF", "");

        if (mapBy === "Employee Number") {
            await firestore
                .collection("users")
                .where("employeeNr", "==", search)
                .where("companyId", "==", companyId)
                .limit(1)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            new_irp5.userUid = doc.id;
                            new_irp5.displayName = doc.data().displayName;
                            new_irp5.employeeNr = doc.data().employeeNr;
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error find user: ", error);
                });
        } else {
            await firestore
                .collection("users")
                .where("companyId", "==", companyId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            let parts = search.split("_");
                            if (doc.data().employee_initials !== undefined) {
                                if (parts[1] == doc.data().employee_initials.toLowerCase() && parts[2] == doc.data().employee_surname.toLowerCase()) {
                                    new_irp5.userUid = doc.id;
                                    new_irp5.displayName = doc.data().displayName;
                                    new_irp5.employeeNr = doc.data().employeeNr;
                                }
                            }
                        }
                    });
                });
        }
        await firestore.collection("irp5").add(new_irp5);
        toast.success("IRP5 added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}
