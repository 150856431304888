import React, { useState, useEffect } from "react";
import { Button, Container, Grid, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getAuthUser } from "./superActions";
import { moveTime } from "../staff/staffActions";
import firebase from "../../app/config/firebase";
import axios from "axios";
import apiURL from "../../app/config/apiURL";

const firestore = firebase.firestore();

export default function SuperPage(props) {
    const [auth, setAuth] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    setAuth(appObj);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    // async function migrateData() {
    //     await moveTime();
    // }

    async function companyUpdate() {
        firestore.collection("users").onSnapshot(async (snapshot) => {
            let list = [];
            snapshot.docs.forEach((result) => {
                if (result.exists) {
                    let obj = {
                        id: result.id,
                        companyId: result.data().companyId,
                        displayName: result.data().displayName,
                        departmentId: result.data().departmentId,
                        approvalType: result.data().approvalType,
                        approvalUser: result.data().approvalUser,
                    };
                    list.push(obj);
                }
            });

            const response = await axios.post(apiURL + "/api/update_departments", { userList: list });
            console.log(response.data);
        });
    }

    return (
        <Container>
            <h2>DRG Admin</h2>

            {(auth.isSuper || auth.isAdmin) && (
                <Grid stackable className="dashboard_blocks">
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Segment>
                                <h2>Users</h2>
                                <Button color="blue" as={Link} to="/super/users">
                                    Users
                                </Button>
                            </Segment>
                        </Grid.Column>

                        <Grid.Column>
                            <Segment>
                                <h2>Departments</h2>
                                <Button color="blue" as={Link} to="/super/departments">
                                    Departments
                                </Button>
                            </Segment>
                        </Grid.Column>

                        <Grid.Column>
                            <Segment>
                                <h2>Projects</h2>
                                <Button color="blue" as={Link} to="/super/projects">
                                    Projects
                                </Button>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>

                    {auth.isSuper && (
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <Segment>
                                    <h2>Companies</h2>
                                    <Button color="blue" as={Link} to="/super/companies">
                                        Companies
                                    </Button>
                                </Segment>
                            </Grid.Column>

                            <Grid.Column>
                                <Segment>
                                    <h2>Public Holidays</h2>
                                    <Button color="blue" as={Link} to="/super/blocked_dates">
                                        Public Holidays
                                    </Button>
                                </Segment>
                            </Grid.Column>

                            <Grid.Column>
                                <Segment>
                                    <h2>Sites</h2>
                                    <Button color="blue" as={Link} to="/super/sites">
                                        Sites
                                    </Button>
                                </Segment>
                            </Grid.Column>

                            {/* <Grid.Column>
                                <Segment>
                                    <h2>Time Company Department Update</h2>
                                    <Button color="blue" onClick={() => companyUpdate()}>
                                        Update
                                    </Button>
                                </Segment>
                            </Grid.Column> */}

                            {/* <Grid.Column>
                                <Segment>
                                    <h2>Data Migrate</h2>
                                    <Button color="blue" onClick={() => migrateData()}>
                                        Migrate
                                    </Button>
                                </Segment>
                            </Grid.Column> */}
                        </Grid.Row>
                    )}
                </Grid>
            )}
        </Container>
    );
}
