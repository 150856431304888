import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

export default function LeaveHomeFormBtn(props) {
    const { leave } = props;
    return (
        <Link as="a" to={`/staff/view/${leave.id}`}>
            <Button color="orange">VIEW REQUEST</Button>
        </Link>
    );
}
