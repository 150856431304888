import React, { useEffect, useState } from "react";
import { Button, Container, Grid, GridRow, GridColumn, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SmartDataTable from "react-smart-data-table";
import { getAuthUser, getStaff } from "../clientActions";

export default function StaffDashboardPage(props) {
    const [profile, setProfile] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [users, setUsers] = useState([]);
    let parts = window.location.href.split("/");
    let companyId = parts.pop() || parts.pop();

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getStaff(companyId).then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        if (appObj["isTerminated"] === true) {
                            appObj["displayName"] = appObj["displayName"] + " (TERMINATED)";
                        }
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.email > b.email ? 1 : -1));
                setUsers(list);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    function handleOnChange({ target: { name, value } }) {
        setFilterValue(value);
    }

    function onRowClick(event, { rowData, rowIndex, tableData }) {
        const { fullName, name, id } = rowData;
        let value = fullName || name || id;

        if (!value) {
            const [key] = Object.keys(rowData);
            value = `${key}: ${rowData[key]}`;
        }

        props.history.push("/home/" + value);
    }

    const orderedHeaders = ["displayName", "email", "departmentName"];

    return (
        <>
            {profile.isAdmin === false && profile.isLineManager === false && profile.isHR === false ? (
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            ) : (
                <Container>
                    <Grid style={{ marginTop: 20, marginBottom: 10 }}>
                        <GridRow columns={3} verticalAlign="middle">
                            <GridColumn width={6}>
                                <h2>
                                    Showing all staff for {users && users.length > 0 ? users[0].companyName : ""}
                                    {!profile.isSuper && profile.departmentName ? " - " + profile.departmentName : ""}
                                </h2>
                            </GridColumn>

                            <Grid.Column width={7}>
                                <div className="ui icon input">
                                    <input type="text" name="filterValue" value={filterValue} placeholder="Filter results..." onChange={handleOnChange} />
                                    <i className="search icon" />
                                </div>
                            </Grid.Column>

                            <GridColumn textAlign="right" width={3}>
                                {profile && profile.isSuper && (
                                    <Button color="blue" as={Link} to={`/clients/landing`}>
                                        Back to Clients
                                    </Button>
                                )}
                            </GridColumn>
                        </GridRow>
                    </Grid>

                    <SmartDataTable
                        data={users}
                        name="users-table"
                        className="ui compact selectable table celled"
                        sortable
                        onRowClick={onRowClick}
                        orderedHeaders={orderedHeaders}
                        hideUnordered={true}
                        filterValue={filterValue}
                    />
                </Container>
            )}
        </>
    );
}
