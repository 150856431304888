import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Button, Segment, List, Grid, Label } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function SupportDashboardPage(props) {
    const [support, setSupport] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = firestore
            .collection("support")
            .where("userUid", "==", firebase.auth().currentUser.uid)
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setSupport(list);
            });

        return () => unsubscribe();
    }, []);

    return (
        <Container>
            <Button onClick={() => dispatch(openModal({ modalType: "SupportForm" }))} color="blue">
                Open a Support Ticket
            </Button>

            <div style={{ marginTop: 20 }}>
                <h2>Support Requests</h2>

                <List>
                    {support &&
                        support.length > 0 &&
                        support.map((sup) => (
                            <List.Item key={sup.id}>
                                <Segment>
                                    <Grid stackable verticalAlign="middle">
                                        <Grid.Column width={4}>
                                            Type: {sup.requestType}
                                            <br />
                                            Employee: {sup.displayName}
                                            <br />
                                            Assigned To: {sup.assignedDisplayName}
                                        </Grid.Column>

                                        <Grid.Column width={4}>Assigned On: {format(sup.createDate.toDate(), "d MMMM yyyy")}</Grid.Column>

                                        <Grid.Column width={4} textAlign="right">
                                            {sup.status === "Complete" && <Label color="green">{sup.status}</Label>}
                                            {sup.status === "New Request" && <Label color="red">{sup.status}</Label>}
                                            {sup.status === "Reply Sent" && <Label color="orange">{sup.status}</Label>}
                                            {sup.status === "Admin Reply Sent" && <Label color="yellow">{sup.status}</Label>}
                                        </Grid.Column>

                                        <Grid.Column width={4} textAlign="right">
                                            <Button as={Link} to={"/support/view/" + sup.id} color="blue">
                                                VIEW REQUEST
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </List.Item>
                        ))}
                </List>
            </div>
        </Container>
    );
}
