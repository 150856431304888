import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Divider } from "semantic-ui-react";
import { leaveTrack, leaveTrackSpecial, getBlockedDates } from "../staffActions";
import { closeModal } from "../../../app/common/modals/modalReducer";
import LeaveLeftTbl from "./LeaveLeftTbl";
import LeaveLeftNew from "./LeaveLeftNew";
import LeaveLeftOld from "./LeaveLeftOld";
import differenceInHours from "date-fns/differenceInHours";
import format from "date-fns/format";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import addDays from "date-fns/addDays";
import { generateLeaveStats } from "../../manager/managerActions";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "../../../app/config/firebase";
import { startOfMonth } from "date-fns/esm";
import { addMonths, parse, subDays } from "date-fns";
const firestore = firebase.firestore();

export default function LeaveTrackForm(props) {
    let profile = props.profile;
    let profileClient = props.profileClient;
    let actionType = props.type;
    let parts = window.location.href.split("/");
    let leaveId = parts.pop() || parts.pop();
    let company = props.company;
    const dispatch = useDispatch();
    const [leaveType, setLeaveType] = useState("");
    const [blockedDates, setBlockedDates] = useState([]);
    const [leaveList, setLeaveList] = useState([]);
    const [annualFrom, setAnnualFrom] = useState("");
    const [annualTo, setAnnualTo] = useState("");
    const [halfDay, setHalfDay] = useState("no");
    const [annualdays, setAnnualDays] = useState("0");
    const [availDays, setAvailDays] = useState("0");
    const [availDate, setAvailDate] = useState("");
    const [timeHours, setTimeHours] = useState("0");
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [toMinDate, setToMinDate] = useState();
    const [initialValues, setInitialValues] = useState({
        leaveType: "",
        unpaidLeave: "No",
        description: "",
        useAccumulative: "",
        useAccumulative2: "",
        uploadFile: "",
        annualDays: "0",
        annualFrom: "",
        annualTo: "",
        halfDay: "no",
        fromTime: "",
        toTime: "",
        timeHours: "",
    });
    const [leave, setLeave] = useState([]);
    const [leaveMonthly, setLeaveMonthly] = useState([]);
    const [companyData, setCompanyData] = useState([]);

    const [allowSaturday, setAllowSaturday] = useState(props.company.saturdayWorkDay);
    const [allowSunday, setAllowSunday] = useState(props.company.sundayWorkDay);

    useEffect(() => {
        getBlockedDates().then(
            (res) => {
                setBlockedDates(res);
            },
            (err) => {
                console.log(err);
            }
        );
        const unsubscribe = firestore
            .collection("leave")
            .doc(leaveId)
            .onSnapshot((snapshot) => {
                let obj = { ...snapshot.data(), id: snapshot.id };
                if (actionType === "edit") {
                    setAnnualFrom(obj.annualFrom.toDate());
                    setAnnualTo(obj.annualTo.toDate());
                    setAnnualDays(obj.annualDays);
                    setInitialValues({
                        leaveType: obj.leaveType,
                        unpaidLeave: obj.unpaidLeave,
                        description: obj.description,
                        useAccumulative: obj.useAccumulative,
                        useAccumulative2: obj.useAccumulative2,
                        uploadFile: obj.uploadFile,
                        annualDays: obj.annualDays,
                        annualFrom: obj.annualFrom.toDate(),
                        annualTo: obj.annualTo.toDate(),
                        halfDay: obj.halfDay,
                        createDate: obj.createDate,
                        editDate: obj.editDate,
                        fromTime: "",
                        toTime: "",
                        timeHours: "",
                    });
                }
            });

        if (company.hasOwnProperty("leaveMethodType") && company.leaveMethodType === "New Method") {
            const unsubscribe2 = firestore
                .collection("leaveStatus")
                .where("userUid", "==", profileClient.id)
                // .where("toDate", ">", new Date())
                .where("periodType", "==", "Leave")
                .onSnapshot(async (snapshot) => {
                    let docs = snapshot.docs;
                    let dataExists = false;
                    let KeyId = 0;

                    Object.keys(docs).forEach((key) => {
                        let dateData = docs[key].data().toDate.toDate();
                        if (new Date(dateData.toDateString()) >= new Date(new Date().toDateString())) {
                            // console.log(dateData.toDateString());
                            dataExists = true;
                            KeyId = key;
                        }
                    });

                    if (dataExists === true) {
                        let thisCompany = await firestore.collection("companies").where("id", "==", profileClient.companyId).get();
                        let companyData = thisCompany.docs[0].data();
                        let startDate =
                            companyData.leaveMonthType && companyData.leaveMonthType == "Custom From and To Date"
                                ? new Date(new Date().getFullYear(), new Date().getMonth(), parseFloat(companyData.leaveFromDay))
                                : startOfMonth(new Date());

                        setLeave(snapshot.docs[KeyId].data());
                        firestore
                            .collection("leaveStatus")
                            .doc(snapshot.docs[KeyId].id)
                            .collection("Months")
                            .where("datePeriod", "==", startDate)
                            .onSnapshot((snapshot2) => {
                                setLeaveMonthly(snapshot2.docs[0].data());
                                setAvailDays(Number.isInteger(snapshot2.docs[0].data().annualLeft) ? snapshot2.docs[0].data().annualLeft : parseFloat(snapshot2.docs[0].data().annualLeft).toFixed(2));
                                setAvailDate(format(snapshot2.docs[0].data().datePeriod.toDate(), "MMMM yyyy"));
                            });

                        firestore
                            .collection("leaveStatus")
                            .doc(snapshot.docs[KeyId].id)
                            .collection("Months")
                            .onSnapshot((snapshot3) => {
                                let listLeave = [];
                                snapshot3.docs.forEach((result) => {
                                    if (result.exists) {
                                        let obj = { ...result.data(), id: result.id, datePeriod: result.data().datePeriod.toDate() };
                                        listLeave.push(obj);
                                    }
                                });
                                setLeaveList(listLeave);
                            });
                    }
                });

            return () => {
                unsubscribe2();
            };
        }

        const unsubscribe3 = firestore
            .collection("companies")
            .doc(profileClient.companyId)
            .onSnapshot((snapshot) => {
                if (snapshot.exists) {
                    setCompanyData(snapshot.data());
                }
            });

        return () => {
            unsubscribe();
            unsubscribe3();
        };
    }, [company]);

    // console.log(company);
    const leaveTypeOptions = [
        { key: "Annual", text: "Annual Leave", value: "Annual" },
        company.leaveTotalSick && company.leaveTotalSick > 0 ? { key: "Sick", text: "Sick Leave", value: "Sick" } : "",
        company.leaveTotalStudy && company.leaveTotalStudy > 0 ? { key: "Study", text: "Study Leave", value: "Study" } : "",
        company.leaveTotalFamily && company.leaveTotalFamily > 0 ? { key: "Family", text: "Family Leave", value: "Family" } : "",
        company.leaveTotalMaternity && company.leaveTotalMaternity > 0 ? { key: "Maternity", text: "Maternity Leave", value: "Maternity" } : "",
        company.leaveTotalPaternity && company.leaveTotalPaternity > 0 ? { key: "Paternity", text: "Paternity Leave", value: "Paternity" } : "",
        company.leaveTotalIOD && company.leaveTotalIOD > 0 ? { key: "IOD", text: "IOD Leave", value: "IOD" } : "",
        company.leaveTotalParental && company.leaveTotalParental > 0 ? { key: "Parental", text: "Parental Leave", value: "Parental" } : "",
        company.leaveTotalMoving && company.leaveTotalMoving > 0 ? { key: "Moving", text: "Moving Leave", value: "Moving" } : "",
        company.leaveTotalCSR && company.leaveTotalCSR > 0 ? { key: "CSR", text: "CSR Leave", value: "CSR" } : "",
        company.leaveTotalReligious && company.leaveTotalReligious > 0 ? { key: "Religious", text: "Religious Leave", value: "Religious" } : "",
        { key: "Special Leave", text: "Special Leave", value: "Special Leave" },
    ].filter((el) => el != "");

    const accumulativeChoices = [
        { key: "Yes", text: "Yes", value: "Yes" },
        { key: "No", text: "No", value: "No" },
    ];

    const unpaidChoices = [
        { key: "No", text: "No", value: "No" },
        { key: "Yes", text: "Yes", value: "Yes" },
    ];

    let accumTotal = 0;
    let accumUsed = 0;
    let accumLeft = 0;
    let accumTotal2 = 0;
    let accumUsed2 = 0;
    let accumLeft2 = 0;
    let accumForfeit = "";
    let showAccum = false;
    let showAccum2 = false;
    let nonGrowingYear = new Date().getFullYear();

    if (company.leaveMethodType === undefined || (company.hasOwnProperty("leaveMethodType") && company.leaveMethodType !== "New Method")) {
        if (
            profileClient &&
            profileClient["leaveMonths"] &&
            profileClient["leaveMonths"][nonGrowingYear] &&
            profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] &&
            profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"] > 0
        ) {
            accumUsed = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed"]);
            accumTotal = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal"]);
            accumLeft = accumTotal - accumUsed;
            showAccum = true;
        }

        if (
            profileClient &&
            profileClient["leaveMonths"] &&
            profileClient["leaveMonths"][nonGrowingYear] &&
            profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"] &&
            profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"] > 0
        ) {
            accumUsed2 = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumUsed2"]);
            accumTotal2 = parseFloat(profileClient["leaveMonths"][nonGrowingYear]["nonGrowing"]["accumTotal2"]);
            accumLeft2 = accumTotal2 - accumUsed2;
            showAccum2 = true;
            showAccum = false;
        }
    }

    if (leaveMonthly.accumTotal > 0) {
        accumTotal = leaveMonthly.accumTotal;
        accumUsed = leaveMonthly.accumUsed;
        accumLeft = accumTotal - accumUsed;
        showAccum = true;

        if (company.leaveRolloverType === "Accumulative") {
            accumForfeit = subDays(addMonths(leave.fromDate.toDate(), company.leaveAccumulativeForfeit), 1);

            if (new Date() > accumForfeit) {
                accumForfeit = "";
                showAccum = false;

                if (leaveMonthly.accumTotal2 > 0) {
                    accumTotal = leaveMonthly.accumTotal2;
                    accumUsed = leaveMonthly.accumUsed2;
                    accumLeft2 = accumTotal2 - accumUsed2;
                    showAccum2 = true;
                    showAccum = false;
                    accumLeft = 0;
                } else {
                    accumLeft2 = 0;
                    accumLeft = 0;
                }
            }
        }
    }

    const updateAnnualFrom = useCallback((value) => {
        setAnnualFrom(new Date(value));
        setToMinDate(new Date(value));
    }, []);
    const updateAnnualTo = useCallback((value) => {
        setAnnualTo(value);
    }, []);
    const updateHalfDay = useCallback((value) => {
        setHalfDay(value);
    }, []);
    const updateAnnualDays = useCallback((value) => {
        setAnnualDays(value);
    }, []);

    function calcDays(type, val, annualFromDate, annualToDate, halfDaySpec, blockedDatesDays) {
        let annualFrom = annualFromDate;
        let annualTo = annualToDate;
        let halfDay = halfDaySpec;
        let c = 0;

        if (type === "annualFrom") {
            updateAnnualFrom(val);
            annualFrom = val;

            for (var i = 0; i < leaveList.length; i++) {
                if (leaveList[i].datePeriod.getMonth() == val.getMonth()) {
                    setAvailDays(leaveList[i].annualLeft);
                    setAvailDate(format(leaveList[i].datePeriod, "MMMM yyyy"));
                }
            }
        }
        if (type === "annualTo") {
            updateAnnualTo(val);
            annualTo = val;
        }
        if (type === "halfDay") {
            updateHalfDay(val);
            halfDay = val;
        }

        let blockedDates = blockedDatesDays;

        if (annualFrom !== "" && annualTo === "" && halfDay !== "no") {
            c = 0.5;

            if (type === "annualFrom") {
                annualTo = annualFrom;
            }
            if (type === "annualTo") {
                annualFrom = annualTo;
            }
        }

        if (annualFrom !== "" && annualTo !== "") {
            if (halfDay !== "no") {
                updateAnnualTo(annualFrom);
                annualTo = annualFrom;
                c = 0.5;
            } else {
                let a = annualFrom;
                let b = annualTo;
                let foundblock = 0;
                let compare_dates = function (date1, date2) {
                    if (date1 > date2) return "<";
                    else if (date1 < date2) return ">";
                    else return "=";
                };
                let compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));

                while (compared === ">" || compared === "=") {
                    if ((format(a, "EEE") == "Sat" && allowSaturday == true) || (format(a, "EEE") == "Sun" && allowSunday == true) || (format(a, "EEE") !== "Sat" && format(a, "EEE") !== "Sun")) {
                        foundblock = 0;
                        // eslint-disable-next-line no-loop-func
                        Object.keys(blockedDates).forEach(function (key) {
                            let helperDate = blockedDates[key];
                            if (format(helperDate, "yyyy-MM-dd") === format(a, "yyyy-MM-dd")) {
                                foundblock = 1;
                            }
                        });
                        if (foundblock === 0) {
                            c++; //add 1 to your counter if its not a weekend day
                        }
                    }
                    // if (format(a, "EEE") !== "Sat" && format(a, "EEE") !== "Sun") {
                    //     foundblock = 0;
                    //     // eslint-disable-next-line no-loop-func
                    //     Object.keys(blockedDates).forEach(function (key) {
                    //         let helperDate = blockedDates[key];
                    //         if (format(helperDate, "yyyy-MM-dd") === format(a, "yyyy-MM-dd")) {
                    //             foundblock = 1;
                    //         }
                    //     });
                    //     if (foundblock === 0) {
                    //         c++; //add 1 to your counter if its not a weekend day
                    //     }
                    // }
                    a = addDays(new Date(a), 1);
                    compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));
                }
            }
        }

        if (c === 0) {
            c = null;
        }

        updateAnnualDays(c);
    }

    const updateFromTime = useCallback((value) => {
        setFromTime(value);
    }, []);
    const updateToTime = useCallback((value) => {
        setToTime(value);
    }, []);
    const updateTimeHours = useCallback((value) => {
        setTimeHours(value);
    }, []);

    // Time Calculation
    function calcHours(type, val, time1, time2) {
        let fromTime = time1;
        let toTime = time2;
        let hours = 0;
        if (type === "fromTime") {
            updateFromTime(val);
        }
        if (type === "toTime") {
            updateToTime(val);
        }

        if (fromTime !== "" && toTime !== "") {
            let a = fromTime;
            let b = toTime;
            let fromTimeDif = format(a, "yyyy-MM-dd HH:mm:ss");
            let toTimeDif = format(b, "yyyy-MM-dd HH:mm:ss");
            hours = differenceInHours(new Date(toTimeDif), new Date(fromTimeDif));
            updateTimeHours(hours);

            return hours;
        }
    }

    const updateMinSelected = useCallback((value) => {
        var oneMonthAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 2, new Date().getDate());
        if (value === "Sick") {
            //
        } else {
            //
        }
    }, []);

    function changeType(value) {
        setLeaveType(value);
        updateMinSelected(value);
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validate={(values) => {
                    const errors = {};
                    if (!values.leaveType) {
                        errors.leaveType = "Choose a Type of Leave";
                    } else {
                        if (values.leaveType === "Special Leave") {
                            if (!values.fromTime) {
                                errors.fromTime = "Please choose a From Date/Time";
                            }

                            if (!values.toTime) {
                                errors.toTime = "Please choose a To Time";
                            }

                            if (!values.timeHours || values.timeHours == "0") {
                                errors.timeHours = "Please choose a time period of at least 1 hour";
                            }
                        } else {
                            if (values.annualFrom === "") {
                                errors.annualFrom = "Please choose a date";
                            }
                            if (values.halfDay === "no") {
                                if (values.annualTo === "") {
                                    errors.annualTo = "Please choose a date";
                                }
                            }
                        }
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values.annualDays = annualdays;
                    values.companyId = profileClient.companyId;
                    values.departmentId = profileClient.departmentId;
                    values.displayName = profileClient.displayName;

                    if (profileClient.approvalType) {
                        values.approvalType = profileClient.approvalType;

                        if (profileClient.approvalType === "Specific User" && profileClient.approvalUser) {
                            values.approvalUser = profileClient.approvalUser;
                        }
                    }

                    // if (values.annualFrom === "") {
                    //     values.annualFrom = new Date();
                    // }

                    if (values.halfDay !== "no" && values.annualTo === "") {
                        values.annualTo = values.annualFrom;
                    }

                    // if (values.annualTo === "") {
                    //     values.annualTo = values.annualFrom;
                    // }

                    if (actionType === "edit") {
                        values.actionType = actionType;
                        values.editLeaveId = leaveId;
                        values.userUid = profileClient.id;
                    } else {
                        values.actionType = "add";
                        values.userUid = profile.id;
                    }

                    if (values.leaveType === "Special Leave") {
                        await leaveTrackSpecial(values);
                    } else {
                        await leaveTrack(values);
                        if (actionType === "edit") {
                            await generateLeaveStats(profileClient.id, "");
                        }
                    }

                    // if (availDays - annualdays < 0) {
                    //     toast.warning("You are requesting negative leave");
                    // }

                    setSubmitting(false);
                    dispatch(closeModal());
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Form.Group widths="equal">
                            <Form.Field>
                                <MySelectInput
                                    label="Type"
                                    name="leaveType"
                                    options={leaveTypeOptions}
                                    value={values.leaveType}
                                    onChange={(field, value) => {
                                        changeType(value.value);
                                        setFieldValue("leaveType", value.value);
                                    }}
                                    placeholder="Select your Leave Type"
                                />
                            </Form.Field>

                            <Form.Field>
                                <MySelectInput
                                    label="Unpaid Leave"
                                    name="unpaidLeave"
                                    options={unpaidChoices}
                                    value={values.unpaidLeave || "No"}
                                    onChange={(field, value) => setFieldValue("unpaidLeave", value.value)}
                                    placeholder="Unpaid Leave"
                                    removeclearable="true"
                                />
                            </Form.Field>

                            <Form.Field>
                                <MyTextInput label="Description" name="description" type="text" value={values.description} placeholder="Additional description for leave taken" />
                            </Form.Field>
                        </Form.Group>

                        <div hidden={leaveType === "Special Leave"}>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    {showAccum === true && accumLeft && accumLeft > 0 && (
                                        <label>
                                            Accumulative Leave Days = {accumLeft}
                                            {accumForfeit ? "\nExpires " + format(accumForfeit, "d MMMM yyyy") : ""}
                                        </label>
                                    )}
                                    {showAccum2 === true && accumLeft2 && accumLeft2 > 0 && (
                                        <label>
                                            Accumulative Leave Days = {accumLeft2}
                                            {accumForfeit ? "\nExpires " + format(accumForfeit, "d MMMM yyyy") : ""}
                                        </label>
                                    )}

                                    {showAccum === true && accumLeft && accumLeft > 0 && (
                                        <MySelectInput
                                            name="useAccumulative"
                                            options={accumulativeChoices}
                                            value={values.useAccumulative}
                                            onChange={(field, value) => setFieldValue("useAccumulative", value.value)}
                                            placeholder="Use Accumulative Leave"
                                        />
                                    )}
                                    {showAccum2 === true && accumLeft2 && accumLeft2 > 0 && (
                                        <MySelectInput
                                            name="useAccumulative2"
                                            options={accumulativeChoices}
                                            value={values.useAccumulative2}
                                            onChange={(field, value) => setFieldValue("useAccumulative2", value.value)}
                                            placeholder="Use Accumulative Leave"
                                        />
                                    )}

                                    <label style={{ marginTop: 20 }}>Supporting Documentation</label>
                                    <input
                                        id="uploadFile"
                                        name="uploadFile"
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue("uploadFile", event.currentTarget.files[0]);
                                        }}
                                        className="form-control"
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Half Day</label>

                                    <label>
                                        <input
                                            name="halfDay"
                                            type="radio"
                                            value="no"
                                            checked={halfDay === "no" ? true : false}
                                            onChange={(event) => {
                                                calcDays("halfDay", "no", annualFrom, annualTo, halfDay, blockedDates);
                                                setFieldValue("halfDay", "no");
                                            }}
                                        />{" "}
                                        No
                                    </label>

                                    <label>
                                        <input
                                            name="halfDay"
                                            type="radio"
                                            value="morning"
                                            onChange={(event) => {
                                                calcDays("halfDay", "morning", annualFrom, annualTo, halfDay, blockedDates);
                                                setFieldValue("halfDay", "morning");
                                            }}
                                        />{" "}
                                        Yes - Morning
                                    </label>

                                    <label>
                                        <input
                                            name="halfDay"
                                            type="radio"
                                            value="afternoon"
                                            onChange={(event) => {
                                                calcDays("halfDay", "afternoon", annualFrom, annualTo, halfDay, blockedDates);
                                                setFieldValue("halfDay", "afternoon");
                                            }}
                                        />{" "}
                                        Yes - Afternoon
                                    </label>
                                </Form.Field>

                                <Form.Field>
                                    <label>From</label>
                                    <DatePicker
                                        labelTop="From"
                                        name="annualFrom"
                                        dateFormat="yyyy-MM-dd"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                        value={values.annualFrom}
                                        selected={annualFrom}
                                        disabledKeyboardNavigation
                                        inline
                                        onChange={(value) => {
                                            setFieldValue("annualFrom", value);
                                            calcDays("annualFrom", value, annualFrom, annualTo, halfDay, blockedDates);
                                        }}
                                        excludeDates={blockedDates}
                                    // minDate={minSelected}
                                    />

                                    {errors && errors.annualFrom ? <p className="ui red basic label">{errors.annualFrom}</p> : null}
                                </Form.Field>

                                <Form.Field>
                                    <label>To</label>
                                    <DatePicker
                                        labelTop="To"
                                        name="annualTo"
                                        dateFormat="yyyy-MM-dd"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                        value={values.annualTo}
                                        selected={annualTo}
                                        onChange={(value) => {
                                            calcDays("annualTo", value, annualFrom, annualTo, halfDay, blockedDates);
                                            setFieldValue("annualTo", value);
                                        }}
                                        disabledKeyboardNavigation
                                        inline
                                        excludeDates={blockedDates}
                                        minDate={toMinDate}
                                    />
                                    {errors && errors.annualTo ? <p className="ui red basic label">{errors.annualTo}</p> : null}
                                </Form.Field>

                                <Form.Field>
                                    <label>No. of Days</label>
                                    <MyTextInput name="annualDays" type="text" value={annualdays || "0"} readOnly={true} />
                                    <br />
                                    {company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method" && (
                                        <label>
                                            {" "}
                                            Annual Leave Available ({availDate}) = {availDays}
                                        </label>
                                    )}
                                </Form.Field>
                            </Form.Group>
                        </div>

                        <div hidden={leaveType !== "Special Leave"}>
                            <Form.Group hidden={leaveType !== "Special Leave"}>
                                <Form.Field>
                                    <label>From Date/Time</label>
                                    <DatePicker
                                        name="fromTime"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        showTimeSelect={true}
                                        dropdownMode="select"
                                        value={values.fromTime}
                                        selected={fromTime}
                                        onChange={(value) => {
                                            let calc = calcHours("fromTime", value, value, toTime);
                                            setFieldValue("fromTime", value);
                                            setFieldValue("timeHours", calc);
                                        }}
                                        disabledKeyboardNavigation
                                        excludeDates={blockedDates}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>To Time</label>
                                    <DatePicker
                                        name="toTime"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        showTimeSelect={true}
                                        dropdownMode="select"
                                        value={values.toTime}
                                        selected={toTime}
                                        onChange={(value) => {
                                            let calc = calcHours("toTime", value, fromTime, value);
                                            setFieldValue("toTime", value);
                                            setFieldValue("timeHours", calc);
                                        }}
                                        disabledKeyboardNavigation
                                        excludeDates={blockedDates}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>No. of Hours</label>
                                    <MyTextInput name="timeHours" type="text" value={timeHours || "0"} readOnly={true} />
                                </Form.Field>
                            </Form.Group>
                        </div>

                        <Divider />

                        <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue" type="submit" style={{ marginBottom: 10 }}>
                            SUBMIT APPLICATION
                        </Button>

                        <Divider />

                        {company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method" ? <LeaveLeftNew profileClient={profileClient} /> : null}

                        {(company && company.leaveMethodType === undefined) || company.leaveMethodType === "Old Method" ? <LeaveLeftOld profileClient={profileClient} /> : null}
                    </form>
                )}
            </Formik>
        </>
    );
}
